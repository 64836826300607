:root {
  --sidebar-border: none;
}

.layout-container .layout-sidebar {
  background-repeat: no-repeat;
  background-position: top left;
  background-size: contain;
  box-shadow: none;
  // box-shadow: var(--sidebar-shadow); // shadow has rounding at the top and bottom
  border-right: var(--sidebar-border);
}

.layout-container .layout-sidebar .sidebar-header img.app-logo {
  background-size: cover;
  display: block;
  width: 40px;
  height: 40px;
  margin: 0 -8px 0 8px;
}

.dark-theme {
  --sidebar-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25), 0px 0px 22px rgba(0, 0, 0, 0.1);
}

.light-theme {
  --sidebar-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25), 0px 0px 22px rgb(47, 90, 198, 0.15);
}

@media screen and (min-width: 992px) {

  // shadow will be taller than sidebar to avoid rounding issue
  .layout-container .layout-sidebar::before {
    content: '';
    position: fixed;
    width: 252px;
    height: 100%;
    padding-top: 80px;
    padding-bottom: 80px;
    margin-top: -80px;
    box-shadow: var(--sidebar-shadow);
  }
}