.p-checkbox {
  position: relative;
  display: inline-flex;
  user-select: none;
  vertical-align: bottom;
}

.p-checkbox-input {
  appearance: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
  z-index: 1;
  outline: 0 none;
  cursor: pointer;
}

.p-checkbox-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.p-checkbox.p-highlight .p-checkbox-box {
  border-color: var(--primary-color);
  background: var(--primary-color);
}

.p-checkbox:not(.p-disabled):has(.p-checkbox-input:hover) .p-checkbox-box {
  border-color: var(--primary-color);
}

.p-checkbox:not(.p-disabled):has(.p-checkbox-input:hover).p-highlight .p-checkbox-box {
  border-color: var(--primary-color);
  background: var(--primary-color);
  color: #ffffff;
}