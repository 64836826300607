#ts-table-container .rs-table-body-row-wrapper {
  height: calc(100% + 10px) !important;
}

.rs-table-row:has(.row-textbox){
  max-height: var(--heightOverride) !important;
  .rs-table-cell, .rs-table-cell-content {
    max-height: var(--heightOverride) !important;
  }
  .textarea {
    height: var(--textBoxHeightOverride) !important;
    max-height: var(--textBoxHeightOverride) !important;
  }
} 

.scroll-cell {
  max-height: var(--cellScrollHeight) !important;
  overflow-x: scroll;
}

.rs-picker-block {
  width: 100%;
}