.startup {
  position: fixed;
  width: 100%;
  height: 100%;
}

.startup > div {
  position: absolute;
  width: 128px;
  height: 148px;
}

.startup > div.startup-ready {
  animation: 0.2s zoom-in-fade-out 1s forwards linear 1;
}

@keyframes zoom-in-fade-out {
  0% {
    transform: scale(1, 1);
  }

  25% {
    transform: scale(1.25, 1.25);
    opacity: 1;
  }

  100% {
    transform: scale(2, 2);
    opacity: 0;
  }
}

.startup > div > div.startup-title {
  position: absolute;
  text-align: center;
  width: 128px;
  height: 148px;
  font-size: 1.2rem;
  color: var(--text-color-secondary);
}

.startup .p-progress-spinner {
  position: absolute;
  width: 128px;
  height: 128px;
}

.startup img {
  position: absolute;
  width: 72px;
  height: 72px;
  margin: 28px;
}

.startup .p-progress-spinner .p-progress-spinner-circle {
  stroke: #888 !important;
}