$comm-color: #f14668;
$comm-sub-color: #dc405d;
$comm-text-color: white;
.wb-background-disable {
  height: 100vh;
  width: 100vw;
  opacity: 50%;
  position: absolute;
  z-index: 99;
  background-color: black;
}

.wb-messages {
  z-index: 100;
  position: relative;
  height: 0px;
}

.wb-message-container {
  color: $comm-text-color;
  border-top: 1px solid $comm-sub-color;
  border-left: 30px solid $comm-sub-color;
  background-color: $comm-color;
  display: block;
  top: 50px;
  margin: 0 auto;
  position: relative;
  min-height: 100px;
  min-width: 350px;
  max-width: 75%;
  box-shadow: 1px 3px 5px rgba(10, 10, 10, .75);
  border-radius: 5px;
  z-index: 101;
  animation-name: show-notify;
  animation-duration: 300ms;
  animation-fill-mode: forwards;
}

.wb-message-container.hide {
  animation-name: hide-notify;
  animation-duration: 300ms;
  animation-fill-mode: forwards;
}

@keyframes hide-notify {
  0% {left: 0px};
  100% {left: -100vw};
}

@keyframes show-notify {
  0% {left: -100vw};
  100% {left: 0px};
}

.wb-message-contents {
  padding: 12px;
}

.wb-message-header {
  height: 30px;
  position: relative;
}

.wb-message-title {
  font-weight: bold;
}

.wb-message-closing-x {
  height: 20px;
  width: 20px;
  background-color: $comm-sub-color;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wb-closing-x {
  font-weight: bold;
}

.wb-message-header-title-container {
  color: $comm-text-color;
  border-bottom: 1px double $comm-sub-color;
  height: 30px;
  padding: 0px 12px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  position: absolute;
  top:0px;
  left:0px;
}

.wb-message-actions {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 12px;
}