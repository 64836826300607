.top-drawer-container {
  max-height: 0;
  border-bottom: 1px solid #d7d7d7;
  box-shadow: 0px 2px 4px rgba($color: #d0d0d0, $alpha: 1.0);
  transition: .4s ease-in;
  flex:1;
  overflow: auto;
  background-color: #fff;
  z-index: 1;
  width: -webkit-fill-available;
}

.top-drawer-container.active {
  max-height: var(--maxDrawerHeight);
  transition: .4s ease-in;
}

.top-drawer-contents {
  height: var(--drawerBodyHeight);
  overflow: auto;
}

.top-drawer-contents::-webkit-scrollbar {
  width: .5em;
}

.top-drawer-contents::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: .25em;
}

.top-drawer-contents::-webkit-scrollbar-thumb {
  background-color: rgb(49,49,100);
  border-radius: .25em;
}