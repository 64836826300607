// test animation
$speedU: 3.5s;
$flipValues: 250px;

.custom-flip {
  .button {
    z-index: 9;
  }

  .upside-down {
    transform: rotateX(-180deg);
  }

  .custom-flip-container {
    z-index: 1;
    height: calc($flipValues*2);
    max-height: 0;
    width: 300px;
    // bottom: 0px;
    bottom: -$flipValues;
    right: 0;
    overflow: auto;
    border: 5px dashed blue;
    transition:
      // max-height $speedU $speedU*0,
      transform $speedU $speedU*0;
    // animation: flipBox 3s backwards;
  }

  .custom-flip-container.show {
    visibility: hidden;
    max-height: calc($flipValues*2);
    transform: rotateX(180deg);
    transform-style: preserve-3d;
    perspective: 40em;
    animation: flipBox 3s forwards;
  }

  .flip-contents {
    margin-top: 83%;
    padding: 20px;
    background: black;
    height: $flipValues;
  }

  @keyframes flipBox {
    0% {
      visibility: hidden;
    }

    34% {
      visibility: hidden;
    }

    100% {
      visibility: visible;
    }
  }
}