@import "../icons/font/wbi";
@import "input";
@import "sidebar";
@import "startup";
@import "login";
@import "datatable";
@import "tabmenu";

body {
  background-color: var(--surface-ground);
  --transition-duration: 0.2s;
}

.wbi {
  font-size: 1.5rem;
}

.p-dialog .p-dialog-header:has(+.p-dialog-content) {
  padding-bottom: 8px;
}
.p-dialog .p-dialog-content:has(+.p-dialog-footer) {
  padding-bottom: 8px;
}

// Simpler alternative to scrollpanels

.layout-content-wrapper:not(.anchored) .layout-content .p-card .p-card-body .p-card-content {
  max-height: calc(100vh - 35px - 118px);
  overflow: scroll;
}

.layout-content-wrapper:not(.anchored) .layout-content .p-tabmenu.p-component+.p-card .p-card-body .p-card-content {
  max-height: calc(100vh - 35px - 118px - 54px);
}

.p-menu .p-menuitem-active>div>a {
  font-weight: 600;

  .p-menuitem-icon,
  .p-menuitem-text {
    color: var(--primary-color);
  }
}

.moveinright {
  animation: moveinright .15s linear
}

@keyframes moveinright {
  0% {
    opacity: 0;
    transform: translateX(50px);
    transition: transform .12s cubic-bezier(0, 0, .2, 1), opacity .12s cubic-bezier(0, 0, .2, 1)
  }

  to {
    opacity: 1;
    transform: translateX(0)
  }
}

.chip-remove-icon {
  width: 1.5rem;
  height: 1.5rem
}

.chip-remove-icon i {
  font-size: 9px
}

.upload-button-hidden.p-fileupload {
  padding: 0
}

.upload-button-hidden.p-fileupload .p-fileupload-buttonbar {
  display: none
}

.upload-button-hidden.p-fileupload .p-fileupload-content {
  border: 0
}

.upload-button-hidden.p-fileupload .p-progressbar {
  display: none
}

@media screen and (max-width: 768px) {

  .fc .fc-toolbar,
  .fc-header-toolbar {
    justify-content: center
  }

  .fc-header-toolbar {
    display: flex;
    align-items: center;
    gap: 1rem;
    text-align: center;
    flex-wrap: wrap
  }

  .fc-header-toolbar .fc-toolbar-chunk:nth-child(2) {
    width: 100%;
    order: 0
  }

  .fc-header-toolbar .fc-toolbar-chunk:first-child {
    order: 1
  }

  .fc-header-toolbar .fc-toolbar-chunk:nth-child(3) {
    order: 2
  }

  .fc-header-toolbar .fc-toolbar-chunk:nth-child(3) .fc-button-group .fc-today-button {
    margin-left: 2rem
  }
}

:root {
  --sidebar-shadow: 0px 0px 40px #f1f6fd;
  --sidebar-border: 1px solid transparent;
  --card-shadow: 0px 4px 30px rgba(221, 224, 255, .54)
}

body.dark {
  --sidebar-shadow: 0px 0px 40px rgba(255, 255, 255, 0.025);
}

* {
  box-sizing: border-box
}

html {
  height: 100%;
  font-size: 14px
}

body {
  font-weight: 400;
  padding: 0;
  margin: 0;
  min-height: 100%;
  color: var(--text-color);
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

a {
  text-decoration: none
}

.layout-container {
  min-height: 100vh
}

.layout-sidebar {
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
  width: 18rem;
  background: var(--menu-bg);
  border-right: var(--sidebar-border);
  display: flex;
  flex-direction: column
}

.layout-sidebar .sidebar-header {
  padding: 2.5rem 0 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.layout-sidebar .sidebar-header .app-logo {
  position: relative;
}

.layout-sidebar .sidebar-header .app-logo .app-logo-normal {
  display: inline;
  width: 124px;
  height: 28px;
  margin-top: -3px;
  margin-bottom: -3px;
}

.layout-sidebar .sidebar-header .app-logo .app-logo-beta {
  position: absolute;
  z-index: 1000;
  top: -10px;
  left: 170px;
  font-size: 8px;
  line-height: 12px;
  vertical-align: middle;
  height: 13px;
  background-image: linear-gradient(45deg,rgb(64, 0, 0) 25%,rgb(255, 0, 0));
  font-weight: 600;
  color: #fff;
  padding: 1px 5px;
  border-radius: 12px 12px 12px 0;
}

.layout-sidebar .sidebar-header .app-logo .app-logo-beta:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 0;
  border: 7px solid transparent;
  border-top-color: rgb(64,0,0);
  border-bottom: 0;
  border-left: 0;
  margin-left: -1px;
  margin-bottom: -5px;
  transform: rotate(22.5deg);
}

.layout-sidebar .sidebar-header .app-logo .app-logo-beta.env-qa {
  background-image: linear-gradient(45deg,rgb(0, 64, 0) 25%,rgb(0, 180, 0));
}
.layout-sidebar .sidebar-header .app-logo .app-logo-beta.env-qa:after {
  border-top-color: rgb(0,64,0);
}

.layout-sidebar .sidebar-header .app-logo .app-logo-beta.env-dev {
  background-image: linear-gradient(45deg,rgb(32, 0, 32) 25%,rgb(180, 0, 180));
}
.layout-sidebar .sidebar-header .app-logo .app-logo-beta.env-dev:after {
  border-top-color: rgb(32,0,32);
}

.layout-sidebar .sidebar-header .app-logo .app-logo-small {
  display: none
}

.layout-sidebar .layout-menu-container {
  z-index: 1;
  overflow: auto;
  flex: 1 1;
  padding-bottom: 2rem
}

.layout-sidebar .layout-menu {
  margin: 0;
  padding: 0;
  list-style-type: none
}

.layout-sidebar .layout-menu .layout-root-menuitem>.layout-menuitem-root-text {
  font-size: .857rem;
  text-transform: uppercase;
  font-weight: 700;
  color: var(--root-menuitem-text-color);
  padding: 1rem 2rem
}

.layout-sidebar .layout-menu .layout-root-menuitem>a {
  display: none
}

.layout-sidebar .layout-menu a {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none
}

.layout-sidebar .layout-menu a.active-menuitem>.layout-submenu-toggler,
.layout-sidebar .layout-menu li.active-menuitem>a .layout-submenu-toggler {
  transform: rotate(-180deg)
}

.layout-sidebar .layout-menu li.active-menuitem>ul {
  max-height: 1000px;
  transition: max-height 1s ease-in-out
}

.layout-sidebar .layout-menu ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  overflow: hidden
}

.layout-sidebar .layout-menu ul a {
  display: flex;
  align-items: center;
  position: relative;
  outline: 0 none;
  color: var(--menuitem-text-color);
  cursor: pointer;
  padding: .75rem 2rem;
  transition: background-color var(--transition-duration), box-shadow var(--transition-duration)
}

.layout-sidebar .layout-menu ul a .layout-menuitem-icon {
  margin-right: .5rem;
  color: var(--menuitem-icon-color)
}

.layout-sidebar .layout-menu ul a .layout-submenu-toggler {
  font-size: 75%;
  margin-left: auto;
  transition: transform var(--transition-duration)
}

.layout-sidebar .layout-menu ul a.active-route {
  font-weight: 700
}

.layout-sidebar .layout-menu ul a:hover {
  background-color: var(--menuitem-hover-bg-color)
}

.layout-sidebar .layout-menu ul ul {
  overflow: hidden;
  border-radius: var(--border-radius);
  max-height: 0;
  transition: max-height .45s cubic-bezier(.86, 0, .07, 1)
}

.layout-sidebar .layout-menu ul ul li a {
  padding-left: 2.5rem
}

.layout-sidebar .layout-menu ul ul li li a {
  padding-left: 3rem
}

.layout-sidebar .layout-menu ul ul li li li a {
  padding-left: 3.5rem
}

.layout-sidebar .layout-menu ul ul li li li li a {
  padding-left: 4rem
}

.layout-sidebar .layout-menu ul ul li li li li li a {
  padding-left: 5.5rem
}

.layout-sidebar .layout-menu ul ul li li li li li li a {
  padding-left: 5rem
}

@media screen and (min-width: 992px) {

  .layout-container.layout-slim .layout-topbar .topbar-menubutton,
  .layout-container.layout-slim .sidebar-header .app-logo .app-logo-normal {
    display: none
  }

  .layout-container.layout-slim .sidebar-header .app-logo .app-logo-small {
    display: inline
  }

  .layout-container.layout-slim .layout-sidebar {
    width: 5rem;
    overflow: visible;
    z-index: 999
  }

  .layout-container.layout-slim .layout-sidebar .layout-menu-container {
    overflow: auto
  }

  .layout-container.layout-slim .layout-sidebar .layout-menu-container::-webkit-scrollbar {
    display: none
  }

  .layout-container.layout-slim .layout-content-wrapper {
    margin-left: 5rem
  }

  .layout-container.layout-slim .layout-menu ul {
    display: none
  }

  .layout-container.layout-slim .layout-menu li.active-menuitem>ul {
    display: block
  }

  .layout-container.layout-slim .layout-menu .layout-root-menuitem>.layout-menuitem-root-text {
    display: none
  }

  .layout-container.layout-slim .layout-menu .layout-root-menuitem>a {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    outline: none;
    transition: background-color var(--transition-duration);
    width: 3rem;
    height: 3rem;
    margin: 0 auto 1rem
  }

  .layout-container.layout-slim .layout-menu .layout-root-menuitem>a .layout-submenu-toggler {
    display: none
  }

  .layout-container.layout-slim .layout-menu .layout-root-menuitem>a .layout-menuitem-icon {
    font-size: 1.5rem;
    color: var(--menuitem-icon-color)
  }

  .layout-container.layout-slim .layout-menu .layout-root-menuitem>a .layout-menuitem-text {
    display: none
  }

  .layout-container.layout-slim .layout-menu .layout-root-menuitem>a:hover {
    background-color: var(--menuitem-hover-bg-color)
  }

  .layout-container.layout-slim .layout-menu .layout-root-menuitem>ul {
    display: none;
    position: absolute;
    left: 5rem;
    top: 0;
    min-width: 15rem;
    background-color: var(--surface-overlay);
    border-radius: var(--border-radius);
    box-shadow: var(--sidebar-shadow);
    border: var(--sidebar-border);
    padding: 1rem;
    max-height: 20rem;
    overflow: auto;
    z-index: 999
  }

  .layout-container.layout-slim .layout-menu .layout-root-menuitem>ul a {
    padding-right: .5rem;
    color: var(--popup-submenu-item-text-color);
    border-radius: var(--border-radius)
  }

  .layout-container.layout-slim .layout-menu .layout-root-menuitem>ul a .layout-menuitem-icon {
    color: var(--popup-submenu-item-icon-color)
  }

  .layout-container.layout-slim .layout-menu .layout-root-menuitem>ul a:hover {
    background-color: var(--popup-submenu-item-hover-bg-color)
  }

  .layout-container.layout-slim .layout-menu .layout-root-menuitem>ul li a {
    padding-left: .5rem
  }

  .layout-container.layout-slim .layout-menu .layout-root-menuitem>ul li li a {
    padding-left: 1rem
  }

  .layout-container.layout-slim .layout-menu .layout-root-menuitem>ul li li li a {
    padding-left: 1.5rem
  }

  .layout-container.layout-slim .layout-menu .layout-root-menuitem>ul li li li li a {
    padding-left: 2rem
  }

  .layout-container.layout-slim .layout-menu .layout-root-menuitem>ul li li li li li a {
    padding-left: 2.5rem
  }

  .layout-container.layout-slim .layout-menu .layout-root-menuitem>ul li li li li li li a {
    padding-left: 3rem
  }

  .layout-container.layout-slim .layout-menu .layout-root-menuitem.active-menuitem>ul {
    display: block
  }

  .layout-container.layout-slim-plus .layout-topbar .topbar-menubutton {
    display: none
  }

  .layout-container.layout-slim-plus .sidebar-header {
    justify-content: center
  }

  .layout-container.layout-slim-plus .sidebar-header .app-logo .app-logo-normal {
    display: none
  }

  .layout-container.layout-slim-plus .sidebar-header .app-logo .app-logo-small {
    display: inline;
    align-items: center
  }

  .layout-container.layout-slim-plus .layout-sidebar {
    width: 7rem;
    overflow: visible;
    z-index: 999
  }

  .layout-container.layout-slim-plus .layout-sidebar .layout-menu-container {
    overflow: auto
  }
}

@media screen and (min-width: 992px) {
  .layout-container.layout-slim-plus .layout-sidebar .layout-menu-container::-webkit-scrollbar {
    display: none
  }

  .layout-container.layout-slim-plus .layout-content-wrapper {
    margin-left: 7rem
  }

  .layout-container.layout-slim-plus .layout-menu ul {
    display: none
  }

  .layout-container.layout-slim-plus .layout-menu li.active-menuitem>ul {
    display: block
  }

  .layout-container.layout-slim-plus .layout-menu .layout-root-menuitem>.layout-menuitem-root-text {
    display: none
  }

  .layout-container.layout-slim-plus .layout-menu .layout-root-menuitem>a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: var(--border-radius);
    text-align: center;
    cursor: pointer;
    outline: none;
    transition: background-color var(--transition-duration);
    width: auto;
    height: auto;
    margin: 0 auto 1rem;
    padding: .75rem 0
  }

  .layout-container.layout-slim-plus .layout-menu .layout-root-menuitem>a .layout-submenu-toggler {
    display: none
  }

  .layout-container.layout-slim-plus .layout-menu .layout-root-menuitem>a .layout-menuitem-icon {
    font-size: 1.5rem;
    color: var(--menuitem-icon-color)
  }

  .layout-container.layout-slim-plus .layout-menu .layout-root-menuitem>a .layout-menuitem-text {
    font-size: .875rem;
    display: block;
    margin-top: .25rem;
    color: var(--menuitem-text-color)
  }

  .layout-container.layout-slim-plus .layout-menu .layout-root-menuitem>a:hover {
    background-color: var(--menuitem-hover-bg-color)
  }

  .layout-container.layout-slim-plus .layout-menu .layout-root-menuitem>ul {
    display: none;
    position: absolute;
    left: 7rem;
    top: 0;
    min-width: 15rem;
    background-color: var(--surface-overlay);
    border-radius: var(--border-radius);
    box-shadow: var(--sidebar-shadow);
    border: var(--sidebar-border);
    padding: 1rem;
    max-height: 20rem;
    overflow: auto;
    z-index: 999
  }

  .layout-container.layout-slim-plus .layout-menu .layout-root-menuitem>ul a {
    padding-right: .5rem;
    color: var(--popup-submenu-item-text-color);
    border-radius: var(--border-radius)
  }

  .layout-container.layout-slim-plus .layout-menu .layout-root-menuitem>ul a .layout-menuitem-icon {
    color: var(--popup-submenu-item-icon-color)
  }

  .layout-container.layout-slim-plus .layout-menu .layout-root-menuitem>ul a:hover {
    background-color: var(--popup-submenu-item-hover-bg-color)
  }

  .layout-container.layout-slim-plus .layout-menu .layout-root-menuitem>ul li a {
    padding-left: .5rem
  }

  .layout-container.layout-slim-plus .layout-menu .layout-root-menuitem>ul li li a {
    padding-left: 1rem
  }

  .layout-container.layout-slim-plus .layout-menu .layout-root-menuitem>ul li li li a {
    padding-left: 1.5rem
  }

  .layout-container.layout-slim-plus .layout-menu .layout-root-menuitem>ul li li li li a {
    padding-left: 2rem
  }

  .layout-container.layout-slim-plus .layout-menu .layout-root-menuitem>ul li li li li li a {
    padding-left: 2.5rem
  }

  .layout-container.layout-slim-plus .layout-menu .layout-root-menuitem>ul li li li li li li a {
    padding-left: 3rem
  }

  .layout-container.layout-slim-plus .layout-menu .layout-root-menuitem.active-menuitem>ul {
    display: block
  }

  .layout-container.layout-horizontal .layout-topbar .topbar-menubutton,
  .layout-container.layout-horizontal .sidebar-header .app-logo .app-logo-normal {
    display: none
  }

  .layout-container.layout-horizontal .sidebar-header .app-logo .app-logo-small {
    display: inline
  }

  .layout-container.layout-horizontal .layout-sidebar {
    width: auto;
    height: auto;
    top: 0;
    z-index: 999;
    overflow: visible;
    flex-direction: row;
    position: static
  }

  .layout-container.layout-horizontal .layout-sidebar .layout-menu-container {
    overflow: auto;
    padding-bottom: 0
  }
}

@media screen and (min-width: 992px) {
  .layout-container.layout-horizontal .layout-sidebar .layout-menu-container::-webkit-scrollbar {
    display: none
  }

  .layout-container.layout-horizontal .layout-sidebar .sidebar-header {
    padding: 0 2rem
  }

  .layout-container.layout-horizontal .layout-menu {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    height: 100%
  }

  .layout-container.layout-horizontal .layout-menu .layout-root-menuitem {
    border-radius: var(--border-radius)
  }

  .layout-container.layout-horizontal .layout-menu .layout-root-menuitem>.layout-menuitem-root-text {
    display: none
  }

  .layout-container.layout-horizontal .layout-menu .layout-root-menuitem>a {
    display: flex;
    align-items: center;
    padding: .75rem;
    height: 2.786rem;
    border-radius: 0;
    cursor: pointer;
    outline: none;
    color: var(--menuitem-text-color);
    transition: background-color var(--transition-duration)
  }

  .layout-container.layout-horizontal .layout-menu .layout-root-menuitem>a .layout-submenu-toggler {
    display: block;
    margin-left: auto;
    transition: transform .2s
  }

  .layout-container.layout-horizontal .layout-menu .layout-root-menuitem>a .layout-menuitem-icon {
    font-size: 1.25rem;
    color: var(--menuitem-icon-color)
  }

  .layout-container.layout-horizontal .layout-menu .layout-root-menuitem>a .layout-menuitem-text {
    font-size: .875rem;
    display: block;
    margin-left: .75rem;
    margin-right: .75rem;
    white-space: nowrap
  }

  .layout-container.layout-horizontal .layout-menu .layout-root-menuitem>a:hover {
    background-color: var(--menuitem-hover-bg-color)
  }

  .layout-container.layout-horizontal .layout-menu .layout-root-menuitem>ul {
    display: none;
    position: absolute;
    left: auto;
    top: 3rem;
    min-width: 15rem;
    background-color: var(--surface-overlay);
    box-shadow: var(--sidebar-shadow);
    padding: 1rem;
    border-radius: var(--border-radius);
    max-height: 20rem;
    transition: none;
    overflow: auto;
    z-index: 999
  }

  .layout-container.layout-horizontal .layout-menu .layout-root-menuitem>ul a {
    padding-right: .5rem;
    color: var(--popup-submenu-item-text-color);
    border-radius: var(--border-radius)
  }

  .layout-container.layout-horizontal .layout-menu .layout-root-menuitem>ul a .layout-menuitem-icon {
    color: var(--popup-submenu-item-icon-color)
  }

  .layout-container.layout-horizontal .layout-menu .layout-root-menuitem>ul a:hover {
    background-color: var(--popup-submenu-item-hover-bg-color)
  }

  .layout-container.layout-horizontal .layout-menu .layout-root-menuitem>ul li a {
    padding-left: .5rem
  }

  .layout-container.layout-horizontal .layout-menu .layout-root-menuitem>ul li li a {
    padding-left: 1rem
  }

  .layout-container.layout-horizontal .layout-menu .layout-root-menuitem>ul li li li a {
    padding-left: 1.5rem
  }

  .layout-container.layout-horizontal .layout-menu .layout-root-menuitem>ul li li li li a {
    padding-left: 2rem
  }

  .layout-container.layout-horizontal .layout-menu .layout-root-menuitem>ul li li li li li a {
    padding-left: 2.5rem
  }

  .layout-container.layout-horizontal .layout-menu .layout-root-menuitem>ul li li li li li li a {
    padding-left: 3rem
  }

  .layout-container.layout-horizontal .layout-menu .layout-root-menuitem.active-menuitem>ul {
    display: block
  }

  .layout-container.layout-reveal .layout-topbar .topbar-menubutton {
    display: none
  }

  .layout-container.layout-reveal .layout-sidebar {
    height: 100%;
    top: 0;
    transition: transform .3s cubic-bezier(0, 0, .2, 1);
    transform: translateX(-14.75rem);
    z-index: 999
  }

  .layout-container.layout-reveal .layout-sidebar .sidebar-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
    padding: 1.5rem 1rem
  }

  .layout-container.layout-reveal .layout-sidebar .sidebar-header .app-logo .app-logo-small {
    display: inline;
    order: 1
  }

  .layout-container.layout-reveal .layout-sidebar .sidebar-header .app-logo .app-logo-normal {
    display: none
  }

  .layout-container.layout-reveal .layout-sidebar .sidebar-header .layout-sidebar-anchor {
    display: none;
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 50%;
    border: 2px solid var(--logo-color);
    background-color: transparent;
    transition: background-color var(--transition-duration), transform .3s
  }

  .layout-container.layout-reveal .layout-sidebar .layout-menu-container {
    overflow: hidden
  }

  .layout-container.layout-reveal .layout-sidebar .layout-menu .layout-root-menuitem>.layout-menuitem-root-text>span {
    margin-right: auto
  }

  .layout-container.layout-reveal .layout-sidebar .layout-menu .layout-root-menuitem>.layout-menuitem-root-text>.layout-menuitem-root-icon {
    display: block;
    margin-right: .125rem
  }

  .layout-container.layout-reveal .layout-sidebar .layout-menu ul {
    margin: 0;
    padding: 0;
    list-style-type: none
  }

  .layout-container.layout-reveal .layout-sidebar .layout-menu ul a {
    padding: .75rem 1rem
  }

  .layout-container.layout-reveal .layout-sidebar .layout-menu ul a .layout-menuitem-text {
    order: 1;
    margin-right: auto
  }

  .layout-container.layout-reveal .layout-sidebar .layout-menu ul a .layout-submenu-toggler {
    order: 2;
    display: none;
    margin-right: .5rem
  }

  .layout-container.layout-reveal .layout-sidebar .layout-menu ul a .layout-menuitem-icon {
    order: 3;
    margin-right: 0;
    font-size: 1.25rem;
    width: auto
  }

  .layout-container.layout-reveal.layout-reveal .layout-content-wrapper {
    margin-left: 4.25rem;
    transition: margin-left .3s cubic-bezier(0, 0, .2, 1)
  }

  .layout-container.layout-reveal.layout-sidebar-active .layout-sidebar {
    transform: translateX(0)
  }

  .layout-container.layout-reveal.layout-sidebar-active .layout-sidebar .layout-menu-container {
    overflow: auto
  }

  .layout-container.layout-reveal.layout-sidebar-active .layout-sidebar .sidebar-header {
    padding: 1.5rem 2rem;
    justify-content: space-between
  }

  .layout-container.layout-reveal.layout-sidebar-active .layout-sidebar .sidebar-header .app-logo .app-logo-normal {
    display: inline
  }

  .layout-container.layout-reveal.layout-sidebar-active .layout-sidebar .sidebar-header .app-logo .app-logo-small {
    display: none
  }

  .layout-container.layout-reveal.layout-sidebar-active .layout-sidebar .sidebar-header .layout-sidebar-anchor {
    display: block;
    animation: px-fadein .15s linear
  }

  .layout-container.layout-reveal.layout-sidebar-active .layout-sidebar .layout-menu .layout-root-menuitem>.layout-menuitem-root-text>.layout-menuitem-root-icon {
    display: none
  }

  .layout-container.layout-reveal.layout-sidebar-active .layout-sidebar .layout-menu ul a {
    padding: .75rem 2rem
  }

  .layout-container.layout-reveal.layout-sidebar-active .layout-sidebar .layout-menu ul a .layout-submenu-toggler {
    display: block
  }

  .layout-container.layout-reveal.layout-sidebar-active .layout-sidebar .layout-menu ul ul {
    overflow: hidden;
    border-radius: var(--border-radius)
  }

  .layout-container.layout-reveal.layout-sidebar-active .layout-sidebar .layout-menu ul ul li a {
    padding-left: 2.5rem
  }

  .layout-container.layout-reveal.layout-sidebar-active .layout-sidebar .layout-menu ul ul li li a {
    padding-left: 3rem
  }

  .layout-container.layout-reveal.layout-sidebar-active .layout-sidebar .layout-menu ul ul li li li a {
    padding-left: 3.5rem
  }

  .layout-container.layout-reveal.layout-sidebar-active .layout-sidebar .layout-menu ul ul li li li li a {
    padding-left: 4rem
  }

  .layout-container.layout-reveal.layout-sidebar-active .layout-sidebar .layout-menu ul ul li li li li li a {
    padding-left: 5.5rem
  }

  .layout-container.layout-reveal.layout-sidebar-active .layout-sidebar .layout-menu ul ul li li li li li li a {
    padding-left: 5rem
  }

  .layout-container.layout-reveal.layout-sidebar-anchored .sidebar-header .layout-sidebar-anchor {
    background-color: var(--logo-color)
  }

  .layout-container.layout-reveal.layout-sidebar-anchored .layout-content-wrapper {
    margin-left: 18rem
  }

  .layout-container.layout-drawer .layout-topbar .topbar-menubutton {
    display: none
  }

  .layout-container.layout-drawer .layout-sidebar {
    height: 100%;
    top: 0;
    transition: width .3s cubic-bezier(0, 0, .2, 1);
    width: 5.25rem;
    z-index: 999
  }

  .layout-container.layout-drawer .layout-sidebar .sidebar-header {
    display: flex;
    justify-content: center;
    gap: 1rem;
    padding: 1.5rem 0
  }

  .layout-container.layout-drawer .layout-sidebar .sidebar-header .app-logo .app-logo-small {
    display: inline;
    order: 1
  }

  .layout-container.layout-drawer .layout-sidebar .sidebar-header .app-logo .app-logo-normal {
    display: none;
    width: 0;
    transition: width .3s cubic-bezier(0, 0, .2, 1);
    transition-delay: .3s
  }

  .layout-container.layout-drawer .layout-sidebar .sidebar-header .layout-sidebar-anchor {
    display: none;
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 50%;
    border: 2px solid var(--logo-color);
    background-color: transparent;
    transition: background-color var(--transition-duration), transform .3s
  }

  .layout-container.layout-drawer .layout-sidebar .layout-menu-container {
    overflow: hidden
  }

  .layout-container.layout-drawer .layout-sidebar .layout-menu {
    transition: all .4s
  }

  .layout-container.layout-drawer .layout-sidebar .layout-menu .layout-root-menuitem>.layout-menuitem-root-text {
    opacity: 0;
    white-space: nowrap;
    transition: all .1s
  }

  .layout-container.layout-drawer .layout-sidebar .layout-menu .layout-root-menuitem>.layout-menuitem-root-text>span {
    margin-right: auto
  }

  .layout-container.layout-drawer .layout-sidebar .layout-menu .layout-root-menuitem>.layout-menuitem-root-text .layout-menuitem-icon {
    font-size: 1.25rem;
    width: 1.25rem
  }

  .layout-container.layout-drawer .layout-sidebar .layout-menu .layout-root-menuitem>.layout-menuitem-root-text>.layout-menuitem-root-icon {
    display: block;
    margin-right: .125rem;
    font-size: 1.25rem
  }

  .layout-container.layout-drawer .layout-sidebar .layout-menu ul {
    margin: 0;
    padding: 0;
    list-style-type: none
  }

  .layout-container.layout-drawer .layout-sidebar .layout-menu ul a {
    padding: .75rem 2rem;
    width: 1.25rem
  }

  .layout-container.layout-drawer .layout-sidebar .layout-menu ul a span {
    opacity: 0;
    white-space: nowrap;
    transition: all .1s
  }

  .layout-container.layout-drawer .layout-sidebar .layout-menu ul a .layout-menuitem-icon {
    font-size: 1.25rem;
    width: 1.25rem
  }

  .layout-container.layout-drawer.layout-drawer .layout-content-wrapper {
    margin-left: 5.25rem;
    transition: margin-left .3s cubic-bezier(0, 0, .2, 1);
    overflow-x: hidden
  }

  .layout-container.layout-drawer.layout-sidebar-active .layout-sidebar {
    width: 18rem
  }

  .layout-container.layout-drawer.layout-sidebar-active .layout-sidebar .layout-menu-container {
    overflow: auto
  }

  .layout-container.layout-drawer.layout-sidebar-active .layout-sidebar .sidebar-header {
    padding: 1.5rem 2rem;
    justify-content: space-between
  }

  .layout-container.layout-drawer.layout-sidebar-active .layout-sidebar .sidebar-header .app-logo .app-logo-normal {
    display: inline;
    width: 100%;
    transition: width .3s cubic-bezier(0, 0, .2, 1);
    transition-delay: .3s
  }

  .layout-container.layout-drawer.layout-sidebar-active .layout-sidebar .sidebar-header .app-logo .app-logo-small {
    display: none
  }

  .layout-container.layout-drawer.layout-sidebar-active .layout-sidebar .sidebar-header .layout-sidebar-anchor {
    display: block;
    animation: px-fadein .15s linear
  }

  .layout-container.layout-drawer.layout-sidebar-active .layout-sidebar .layout-menu .layout-root-menuitem>.layout-menuitem-root-text {
    padding: 1rem 1.25rem 1rem 2rem;
    opacity: 1;
    white-space: nowrap;
    transition: all .3s
  }

  .layout-container.layout-drawer.layout-sidebar-active .layout-sidebar .layout-menu .layout-root-menuitem>.layout-menuitem-root-text>.layout-menuitem-root-icon {
    display: none
  }

  .layout-container.layout-drawer.layout-sidebar-active .layout-sidebar .layout-menu ul a {
    padding: .75rem 1.25rem .75rem 2rem;
    width: auto
  }

  .layout-container.layout-drawer.layout-sidebar-active .layout-sidebar .layout-menu ul a .layout-submenu-toggler {
    display: block
  }

  .layout-container.layout-drawer.layout-sidebar-active .layout-sidebar .layout-menu ul a span {
    opacity: 1;
    white-space: nowrap;
    transition: all .3s
  }

  .layout-container.layout-drawer.layout-sidebar-active .layout-sidebar .layout-menu ul ul {
    overflow: hidden;
    border-radius: var(--border-radius)
  }

  .layout-container.layout-drawer.layout-sidebar-active .layout-sidebar .layout-menu ul ul li a {
    padding-left: 2.5rem
  }

  .layout-container.layout-drawer.layout-sidebar-active .layout-sidebar .layout-menu ul ul li li a {
    padding-left: 3rem
  }

  .layout-container.layout-drawer.layout-sidebar-active .layout-sidebar .layout-menu ul ul li li li a {
    padding-left: 3.5rem
  }

  .layout-container.layout-drawer.layout-sidebar-active .layout-sidebar .layout-menu ul ul li li li li a {
    padding-left: 4rem
  }

  .layout-container.layout-drawer.layout-sidebar-active .layout-sidebar .layout-menu ul ul li li li li li a {
    padding-left: 5.5rem
  }

  .layout-container.layout-drawer.layout-sidebar-active .layout-sidebar .layout-menu ul ul li li li li li li a {
    padding-left: 5rem
  }

  .layout-container.layout-drawer.layout-sidebar-anchored .sidebar-header .layout-sidebar-anchor {
    background-color: var(--logo-color)
  }

  .layout-container.layout-drawer.layout-sidebar-anchored .layout-content-wrapper {
    margin-left: 18rem
  }
}

.layout-colorscheme-menu {
  --logo-color: var(--primary-color);
  --menu-bg: var(--surface-overlay);
  --root-menuitem-text-color: var(--primary-color);
  --menuitem-text-color: var(--text-color);
  --menuitem-icon-color: var(--primary-color);
  --menuitem-hover-bg-color: var(--surface-hover);
  --popup-submenu-item-text-color: var(--text-color);
  --popup-submenu-item-icon-color: var(--primary-color);
  --popup-submenu-item-hover-bg-color: var(--surface-hover)
}

.layout-primarycolor-menu {
  --logo-color: var(--primary-color-text);
  --menu-bg: linear-gradient(to left top, var(--primary-400), var(--primary-500), var(--primary-600));
  --root-menuitem-text-color: var(--primary-color-text);
  --menuitem-text-color: var(--primary-color-text);
  --menuitem-icon-color: var(--primary-color-text);
  --menuitem-hover-bg-color: hsla(0, 0%, 100%, .1);
  --popup-submenu-item-text-color: var(--text-color);
  --popup-submenu-item-icon-color: var(--primary-color);
  --popup-submenu-item-hover-bg-color: var(--surface-hover)
}

.layout-dark.layout-primarycolor-menu,
.layout-dim.layout-primarycolor-menu {
  --menu-bg: linear-gradient(to left top, var(--primary-300), var(--primary-400), var(--primary-500))
}

.layout-transparent-menu {
  --logo-color: var(--primary-color);
  --menu-bg: var(--surface-overlay);
  --root-menuitem-text-color: var(--primary-color);
  --menuitem-text-color: var(--text-color);
  --menuitem-icon-color: var(--primary-color);
  --menuitem-hover-bg-color: var(--surface-hover);
  --popup-submenu-item-text-color: var(--text-color);
  --popup-submenu-item-icon-color: var(--primary-color);
  --popup-submenu-item-hover-bg-color: var(--surface-hover)
}

@media screen and (min-width: 992px) {

  .layout-container.layout-transparent-menu.layout-horizontal .layout-sidebar,
  .layout-container.layout-transparent-menu.layout-slim .layout-sidebar,
  .layout-container.layout-transparent-menu.layout-slim-plus .layout-sidebar,
  .layout-container.layout-transparent-menu.layout-static .layout-sidebar {
    background-color: transparent;
    border-right: 1px solid transparent;
    box-shadow: none
  }

  .layout-container.layout-static .layout-sidebar {
    transition: transform var(--transition-duration)
  }

  .layout-container.layout-static .layout-content-wrapper {
    margin-left: 18rem;
    transition: margin-left var(--transition-duration)
  }

  .layout-container.layout-static-inactive .layout-sidebar {
    transform: translateX(-100%)
  }

  .layout-container.layout-overlay .layout-content-wrapper,
  .layout-container.layout-static-inactive .layout-content-wrapper {
    margin-left: 0
  }

  .layout-container.layout-overlay .layout-sidebar {
    z-index: 999;
    transform: translateX(-100%);
    transition: transform var(--transition-duration)
  }

  .layout-container.layout-overlay.layout-overlay-active .layout-sidebar {
    transform: translateX(0)
  }

  .layout-container .content-breadcrumb {
    display: none
  }
}

@media screen and (max-width: 991px) {
  .blocked-scroll {
    overflow: hidden
  }

  .layout-container .layout-content-wrapper {
    margin-left: 0;
    padding: 1rem
  }

  .layout-container .layout-sidebar {
    z-index: 999;
    transform: translateX(-100%);
    transition: transform var(--transition-duration);
    box-shadow: none
  }

  .layout-container.layout-mobile-active .layout-sidebar {
    transform: translateX(0)
  }

  .layout-container.layout-mobile-active .layout-mask {
    display: block;
    animation: fadein var(--transition-duration)
  }

  .layout-container .layout-mask {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 998;
    width: 100%;
    height: 100%;
    background-color: var(--maskbg)
  }

  .layout-container .topbar-breadcrumb {
    display: none
  }

  .layout-container .content-breadcrumb {
    display: block
  }
}

@media screen and (min-width: 1960px) {

  .landing-wrapper,
  .layout-content,
  .layout-topbar {
    width: 1504px;
    margin-left: auto !important;
    margin-right: auto !important
  }
}

.layout-topbar {
  margin-bottom: 2rem;
  justify-content: space-between
}

.layout-topbar,
.layout-topbar .topbar-start {
  display: flex;
  align-items: center
}

.layout-topbar .topbar-start .topbar-menubutton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 2rem;
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  flex-shrink: 0;
  transition: background-color var(--transition-duration)
}

.layout-topbar .topbar-end .topbar-menubutton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.5rem;
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  flex-shrink: 0;
  transition: background-color var(--transition-duration)
}

.layout-topbar .topbar-start .topbar-menubutton i,
.layout-topbar .topbar-end .topbar-menubutton i {
  font-size: 1.25rem;
  color: var(--text-color);
  transition: color var(--transition-duration);
  align-self: center;
}

.layout-topbar .topbar-end .topbar-menubutton i.wbi {
  font-size: 1.75rem;
  line-height: 1.1rem;
  overflow: hidden;
}

.layout-topbar .topbar-start .topbar-menubutton:hover,
.layout-topbar .topbar-end .topbar-menubutton:hover {
  background-color: var(--primary-color)
}

.layout-topbar .topbar-start .topbar-menubutton:hover i,
.layout-topbar .topbar-end .topbar-menubutton:hover i {
  color: var(--primary-color-text)
}

.layout-topbar .topbar-menu {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none
}

.layout-topbar .topbar-menu li.topbar-profile {
  margin-left: 1.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center
}

.layout-topbar .topbar-menu li.topbar-profile img {
  width: 2rem;
  height: 2rem
}

.layout-topbar .topbar-menu li.topbar-profile button {
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  transition: box-shadow var(--transition-duration)
}

.layout-topbar .topbar-menu li.topbar-profile button:focus {
  box-shadow: var(--focus-ring)
}

.layout-topbar .topbar-menu li.topbar-search .p-inputtext {
  padding-top: .5rem;
  padding-bottom: .5rem;
  border-radius: 2rem
}

.layout-breadcrumb ol {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
  gap: 1rem;
  flex-wrap: wrap;
  color: var(--text-color-secondary)
}

.layout-breadcrumb ol li {
  font-weight: 600
}

.content-breadcrumb {
  margin-bottom: 2rem;
  padding: 0 .5rem
}

.layout-profile-sidebar.p-sidebar {
  width: 25rem
}

.layout-profile-sidebar.p-sidebar .p-sidebar-content {
  padding-left: 2rem;
  padding-right: 2rem
}

.layout-config-button {
  display: block;
  position: fixed;
  width: 3rem;
  height: 3rem;
  line-height: 3rem;
  background: var(--primary-color);
  color: var(--primary-color-text);
  text-align: center;
  top: 50%;
  right: 0;
  margin-top: -1.5rem;
  border-top-left-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  transition: background-color var(--transition-duration);
  overflow: hidden;
  cursor: pointer;
  z-index: 999;
  box-shadow: -.25rem 0 1rem rgba(0, 0, 0, .15)
}

.layout-config-button.config-link {
  font-size: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  border: none
}

.layout-config-button .config-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--focus-ring)
}

.layout-config-button i {
  font-size: 2rem;
  line-height: inherit;
  transform: rotate(0deg);
  transition: transform 1s
}

.layout-config-button:hover {
  background: var(--primary-400)
}

.layout-config-sidebar.p-sidebar .p-sidebar-content {
  padding-left: 2rem;
  padding-right: 2rem
}

.layout-content-wrapper {
  padding: 2rem
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 1.5rem 0 1rem;
  font-family: inherit;
  font-weight: 600;
  line-height: 1.2;
}

h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child {
  margin-top: 0
}

h1 {
  font-size: 2.5rem
}

h2 {
  font-size: 2rem
}

h3 {
  font-size: 1.75rem
}

h4 {
  font-size: 1.5rem
}

h5 {
  font-size: 1.25rem
}

h6 {
  font-size: 1rem
}

mark {
  background: #fff8e1;
  padding: .25rem .4rem;
  border-radius: var(--border-radius);
  font-family: monospace
}

blockquote {
  margin: 1rem 0;
  padding: 0 2rem;
  border-left: 4px solid #90a4ae
}

hr {
  border-top: solid var(--surface-border);
  border-width: 1px 0 0;
  margin: 1rem 0
}

p {
  margin: 0 0 1rem;
  line-height: 1.5
}

p:last-child {
  margin-bottom: 0
}

.clearfix:after {
  content: " ";
  display: block;
  clear: both
}

.card {
  background: var(--surface-card);
  border: 1px solid var(--surface-border);
  padding: 2rem;
  margin-bottom: 2rem;
  box-shadow: var(--card-shadow);
  border-radius: 8px
}

.card:last-child {
  margin-bottom: 0
}

.p-toast.p-toast-top-center,
.p-toast.p-toast-top-left,
.p-toast.p-toast-top-right {
  top: 70px
}

.p-button-icon-only-custom {
  padding: 0 !important;
  width: 1rem !important;
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.no-row-border tbody tr td {
  border-bottom: 0
}