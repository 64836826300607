.rs-theme-light,
:root {
  --rs-font-family: Apple-System, Arial, Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", STXihei, sans-serif;
  --rs-font-size: 14px;
  --rs-gray-0: #fff;
  --rs-gray-50: #f7f7fa;
  --rs-gray-100: #f2f2f5;
  --rs-gray-200: #e5e5ea;
  --rs-gray-300: #d9d9d9;
  --rs-gray-400: #c5c6c7;
  --rs-gray-500: #a6a6a6;
  --rs-gray-600: #8e8e93;
  --rs-gray-700: #7a7a7a;
  --rs-gray-800: #575757;
  --rs-gray-900: #272c36;
  --rs-primary-50: #f2faff;
  --rs-primary-100: #cce9ff;
  --rs-primary-200: #a6d7ff;
  --rs-primary-300: #80c4ff;
  --rs-primary-400: #59afff;
  --rs-primary-500: #3498ff;
  --rs-primary-600: #2589f5;
  --rs-primary-700: #1675e0;
  --rs-primary-800: #0a5dc2;
  --rs-primary-900: #004299;
  --rs-color-red: #f44336;
  --rs-color-orange: #fa8900;
  --rs-color-yellow: #ffb300;
  --rs-color-green: #4caf50;
  --rs-color-cyan: #00bcd4;
  --rs-color-blue: #2196f3;
  --rs-color-violet: #673ab7;
  --rs-red-50: #fff2f2;
  --rs-red-100: #fccfcf;
  --rs-red-200: #faa9a7;
  --rs-red-300: #fa8682;
  --rs-red-400: #f7635c;
  --rs-red-500: #f44336;
  --rs-red-600: #eb3626;
  --rs-red-700: #d62915;
  --rs-red-800: #b81c07;
  --rs-red-900: #8f1300;
  --rs-orange-50: #fff8f2;
  --rs-orange-100: #ffdfc2;
  --rs-orange-200: #fcc690;
  --rs-orange-300: #fcb160;
  --rs-orange-400: #fa9b2f;
  --rs-orange-500: #fa8900;
  --rs-orange-600: #f08800;
  --rs-orange-700: #db8000;
  --rs-orange-800: #bd7100;
  --rs-orange-900: #945b00;
  --rs-yellow-50: #fffaf2;
  --rs-yellow-100: #ffe9c2;
  --rs-yellow-200: #ffd991;
  --rs-yellow-300: #ffca61;
  --rs-yellow-400: #ffbe30;
  --rs-yellow-500: #ffb300;
  --rs-yellow-600: #f5af00;
  --rs-yellow-700: #e0a500;
  --rs-yellow-800: #c29100;
  --rs-yellow-900: #997500;
  --rs-green-50: #eeffed;
  --rs-green-100: #c8f0c7;
  --rs-green-200: #a5e0a4;
  --rs-green-300: #82cf82;
  --rs-green-400: #65bf67;
  --rs-green-500: #4caf50;
  --rs-green-600: #37ab3c;
  --rs-green-700: #22a12a;
  --rs-green-800: #0f9119;
  --rs-green-900: #007d0c;
  --rs-cyan-50: #f2ffff;
  --rs-cyan-100: #bcf4f7;
  --rs-cyan-200: #87e6ed;
  --rs-cyan-300: #57dae6;
  --rs-cyan-400: #2acadb;
  --rs-cyan-500: #00bcd4;
  --rs-cyan-600: #00b1cc;
  --rs-cyan-700: #00a0bd;
  --rs-cyan-800: #008aa6;
  --rs-cyan-900: #006e87;
  --rs-blue-50: #f0f9ff;
  --rs-blue-100: #c5e7fc;
  --rs-blue-200: #9bd4fa;
  --rs-blue-300: #72c0f7;
  --rs-blue-400: #49abf5;
  --rs-blue-500: #2196f3;
  --rs-blue-600: #1787e8;
  --rs-blue-700: #0d73d4;
  --rs-blue-800: #045cb5;
  --rs-blue-900: #00448c;
  --rs-violet-50: #f6f2ff;
  --rs-violet-100: #d5c9f0;
  --rs-violet-200: #b6a1e3;
  --rs-violet-300: #987bd4;
  --rs-violet-400: #805ac7;
  --rs-violet-500: #673ab7;
  --rs-violet-600: #5f2bb3;
  --rs-violet-700: #531ba8;
  --rs-violet-800: #470c99;
  --rs-violet-900: #390085;
  --rs-state-success: var(--rs-color-green);
  --rs-state-info: var(--rs-color-blue);
  --rs-state-warning: var(--rs-color-yellow);
  --rs-state-error: var(--rs-color-red);
  --rs-body: var(--rs-gray-0);
  --rs-bg-success: #edfae1;
  --rs-bg-info: #e9f5fe;
  --rs-bg-warning: #fff9e6;
  --rs-bg-error: #fde9ef;
  --rs-text-link: var(--rs-primary-700);
  --rs-text-link-hover: var(--rs-primary-800);
  --rs-text-link-active: var(--rs-primary-900);
  --rs-text-primary: var(--rs-gray-800);
  --rs-text-secondary: var(--rs-gray-600);
  --rs-text-tertiary: var(--rs-gray-500);
  --rs-text-heading: var(--rs-gray-900);
  --rs-text-inverse: var(--rs-gray-50);
  --rs-text-heading-inverse: var(--rs-gray-0);
  --rs-text-active: var(--rs-primary-700);
  --rs-text-disabled: var(--rs-gray-400);
  --rs-text-error: var(--rs-color-red);
  --rs-border-primary: var(--rs-gray-200);
  --rs-border-secondary: var(--rs-gray-100);
  --rs-bg-card: var(--rs-gray-0);
  --rs-bg-overlay: var(--rs-gray-0);
  --rs-bg-well: var(--rs-gray-50);
  --rs-bg-active: var(--rs-primary-500);
  --rs-bg-backdrop: rgb(from var(--rs-gray-900) r g b/30%);
  --rs-state-hover-bg: var(--rs-primary-50);
  --rs-color-focus-ring: rgb(from var(--rs-primary-500) r g b/25%);
  --rs-state-focus-shadow: 0 0 0 3px rgb(from var(--rs-primary-500) r g b/25%);
  --rs-state-focus-outline: 3px solid rgb(from var(--rs-primary-500) r g b/25%);
  --rs-state-error-outline: 3px solid rgb(from var(--rs-color-red) r g b/25%);
  --rs-shadow-overlay: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  --rs-btn-default-bg: var(--rs-gray-50);
  --rs-btn-default-text: var(--rs-gray-800);
  --rs-btn-default-hover-bg: var(--rs-gray-200);
  --rs-btn-default-active-bg: var(--rs-gray-300);
  --rs-btn-default-active-text: var(--rs-gray-900);
  --rs-btn-default-disabled-bg: var(--rs-gray-50);
  --rs-btn-default-disabled-text: var(--rs-gray-400);
  --rs-btn-primary-bg: var(--rs-primary-500);
  --rs-btn-primary-text: var(--rs-gray-0);
  --rs-btn-primary-hover-bg: var(--rs-primary-600);
  --rs-btn-primary-active-bg: var(--rs-primary-700);
  --rs-btn-subtle-text: var(--rs-gray-800);
  --rs-btn-subtle-hover-bg: var(--rs-gray-200);
  --rs-btn-subtle-hover-text: var(--rs-gray-800);
  --rs-btn-subtle-active-bg: var(--rs-gray-200);
  --rs-btn-subtle-active-text: var(--rs-gray-900);
  --rs-btn-subtle-disabled-text: var(--rs-gray-400);
  --rs-btn-ghost-border: var(--rs-primary-700);
  --rs-btn-ghost-text: var(--rs-primary-700);
  --rs-btn-ghost-hover-border: var(--rs-primary-800);
  --rs-btn-ghost-hover-text: var(--rs-primary-800);
  --rs-btn-ghost-active-border: var(--rs-primary-900);
  --rs-btn-ghost-active-text: var(--rs-primary-900);
  --rs-btn-link-text: var(--rs-primary-700);
  --rs-btn-link-hover-text: var(--rs-primary-800);
  --rs-btn-link-active-text: var(--rs-primary-900);
  --rs-iconbtn-addon: var(--rs-gray-100);
  --rs-iconbtn-activated-addon: var(--rs-gray-300);
  --rs-iconbtn-pressed-addon: var(--rs-gray-400);
  --rs-iconbtn-primary-addon: var(--rs-primary-600);
  --rs-iconbtn-primary-activated-addon: var(--rs-primary-700);
  --rs-iconbtn-primary-pressed-addon: var(--rs-primary-800);
  --rs-divider-border: var(--rs-gray-200);
  --rs-loader-ring: rgb(from var(--rs-gray-50) r g b/80%);
  --rs-loader-rotor: var(--rs-gray-500);
  --rs-loader-backdrop: rgb(from var(--rs-gray-0) r g b/90%);
  --rs-loader-ring-inverse: rgb(from var(--rs-gray-50) r g b/30%);
  --rs-loader-rotor-inverse: var(--rs-gray-0);
  --rs-loader-backdrop-inverse: rgb(from var(--rs-gray-900) r g b/83%);
  --rs-message-success-header: var(--rs-text-heading);
  --rs-message-success-text: var(--rs-text-primary);
  --rs-message-success-icon: var(--rs-color-green);
  --rs-message-success-icon-border: var(--rs-green-200);
  --rs-message-success-bg: rgb(from var(--rs-green-100) r g b/60%);
  --rs-message-success-border: var(--rs-color-green);
  --rs-message-info-header: var(--rs-text-heading);
  --rs-message-info-text: var(--rs-text-primary);
  --rs-message-info-icon: var(--rs-color-blue);
  --rs-message-info-icon-border: var(--rs-blue-200);
  --rs-message-info-bg: rgb(from var(--rs-blue-100) r g b/60%);
  --rs-message-info-border: var(--rs-color-blue);
  --rs-message-warning-header: var(--rs-text-heading);
  --rs-message-warning-text: var(--rs-text-primary);
  --rs-message-warning-icon: var(--rs-color-yellow);
  --rs-message-warning-icon-border: var(--rs-yellow-200);
  --rs-message-warning-bg: rgb(from var(--rs-yellow-100) r g b/60%);
  --rs-message-warning-border: var(--rs-color-yellow);
  --rs-message-error-header: var(--rs-text-heading);
  --rs-message-error-text: var(--rs-text-primary);
  --rs-message-error-icon: var(--rs-color-red);
  --rs-message-error-icon-border: var(--rs-red-200);
  --rs-message-error-bg: rgb(from var(--rs-red-100) r g b/60%);
  --rs-message-error-border: var(--rs-color-red);
  --rs-tooltip-bg: var(--rs-gray-900);
  --rs-tooltip-text: var(--rs-gray-0);
  --rs-progress-bg: var(--rs-gray-200);
  --rs-progress-bar: var(--rs-primary-500);
  --rs-progress-bar-success: var(--rs-color-green);
  --rs-progress-bar-fail: var(--rs-color-red);
  --rs-placeholder: var(--rs-gray-100);
  --rs-placeholder-active: var(--rs-gray-200);
  --rs-breadcrumb-item-active-text: var(--rs-gray-900);
  --rs-dropdown-divider: var(--rs-gray-200);
  --rs-dropdown-item-bg-hover: rgb(from var(--rs-primary-100) r g b/50%);
  --rs-dropdown-item-bg-active: var(--rs-primary-50);
  --rs-dropdown-item-text-active: var(--rs-primary-700);
  --rs-dropdown-header-text: var(--rs-gray-500);
  --rs-dropdown-shadow: 0 0 10px rgba(0, 0, 0, 0.06), 0 4px 4px rgba(0, 0, 0, 0.12);
  --rs-menuitem-active-bg: rgb(from var(--rs-primary-100) r g b/50%);
  --rs-menuitem-active-text: var(--rs-primary-700);
  --rs-steps-border: var(--rs-gray-600);
  --rs-steps-state-finish: var(--rs-primary-500);
  --rs-steps-border-state-finish: var(--rs-primary-500);
  --rs-steps-state-wait: var(--rs-gray-600);
  --rs-steps-state-process: var(--rs-primary-500);
  --rs-steps-state-error: var(--rs-color-red);
  --rs-steps-border-state-error: var(--rs-color-red);
  --rs-steps-icon-state-process: var(--rs-primary-500);
  --rs-steps-icon-state-error: var(--rs-color-red);
  --rs-navs-text: var(--rs-gray-800);
  --rs-navs-text-hover: var(--rs-gray-800);
  --rs-navs-bg-hover: var(--rs-gray-200);
  --rs-navs-text-active: var(--rs-gray-900);
  --rs-navs-bg-active: var(--rs-gray-200);
  --rs-navs-tab-border: var(--rs-gray-300);
  --rs-navs-subtle-border: var(--rs-gray-50);
  --rs-navs-selected: var(--rs-primary-700);
  --rs-navbar-default-bg: var(--rs-gray-50);
  --rs-navbar-default-text: var(--rs-gray-800);
  --rs-navbar-default-selected-text: var(--rs-primary-700);
  --rs-navbar-default-hover-bg: var(--rs-gray-200);
  --rs-navbar-default-hover-text: var(--rs-gray-800);
  --rs-navbar-inverse-bg: var(--rs-primary-500);
  --rs-navbar-inverse-text: #fff;
  --rs-navbar-inverse-selected-bg: var(--rs-primary-700);
  --rs-navbar-inverse-hover-bg: var(--rs-primary-600);
  --rs-navbar-inverse-hover-text: #fff;
  --rs-navbar-subtle-bg: #fff;
  --rs-navbar-subtle-text: var(--rs-gray-800);
  --rs-navbar-subtle-selected-text: var(--rs-primary-700);
  --rs-navbar-subtle-hover-bg: var(--rs-gray-50);
  --rs-navbar-subtle-hover-text: var(--rs-gray-800);
  --rs-sidenav-default-bg: var(--rs-gray-50);
  --rs-sidenav-default-text: var(--rs-gray-800);
  --rs-sidenav-default-selected-text: var(--rs-primary-700);
  --rs-sidenav-default-hover-bg: var(--rs-gray-200);
  --rs-sidenav-default-hover-text: var(--rs-gray-800);
  --rs-sidenav-default-footer-border: var(--rs-gray-200);
  --rs-sidenav-inverse-bg: var(--rs-primary-500);
  --rs-sidenav-inverse-text: #fff;
  --rs-sidenav-inverse-selected-bg: var(--rs-primary-700);
  --rs-sidenav-inverse-hover-bg: var(--rs-primary-600);
  --rs-sidenav-inverse-footer-border: var(--rs-primary-600);
  --rs-sidenav-subtle-bg: #fff;
  --rs-sidenav-subtle-text: var(--rs-gray-800);
  --rs-sidenav-subtle-selected-text: var(--rs-primary-700);
  --rs-sidenav-subtle-hover-bg: var(--rs-gray-50);
  --rs-sidenav-subtle-hover-text: var(--rs-gray-800);
  --rs-sidenav-subtle-footer-border: var(--rs-gray-200);
  --rs-input-bg: var(--rs-gray-0);
  --rs-input-focus-border: var(--rs-primary-500);
  --rs-input-disabled-bg: var(--rs-gray-50);
  --rs-listbox-option-hover-bg: rgb(from var(--rs-primary-100) r g b/50%);
  --rs-listbox-option-hover-text: var(--rs-primary-700);
  --rs-listbox-option-selected-text: var(--rs-primary-700);
  --rs-listbox-option-selected-bg: var(--rs-primary-50);
  --rs-listbox-option-disabled-text: var(--rs-gray-400);
  --rs-listbox-option-disabled-selected-text: var(--rs-primary-200);
  --rs-checkbox-icon: #fff;
  --rs-checkbox-border: var(--rs-gray-300);
  --rs-checkbox-checked-bg: var(--rs-primary-500);
  --rs-checkbox-disabled-bg: var(--rs-gray-50);
  --rs-radio-marker: #fff;
  --rs-radio-border: var(--rs-gray-300);
  --rs-radio-checked-bg: var(--rs-primary-500);
  --rs-radio-disabled-bg: var(--rs-gray-50);
  --rs-radio-tile-border: var(--rs-gray-300);
  --rs-radio-tile-bg: var(--rs-gray-0);
  --rs-radio-tile-checked-color: var(--rs-primary-500);
  --rs-radio-tile-checked-mark-color: #fff;
  --rs-radio-tile-checked-disabled-color: var(--rs-primary-100);
  --rs-radio-tile-icon-size: 32px;
  --rs-rate-symbol: var(--rs-gray-600);
  --rs-rate-symbol-checked: var(--rs-blue-500);
  --rs-toggle-bg: var(--rs-gray-300);
  --rs-toggle-thumb: #fff;
  --rs-toggle-loader-ring: rgb(from var(--rs-gray-50) r g b/30%);
  --rs-toggle-loader-rotor: var(--rs-gray-0);
  --rs-toggle-hover-bg: var(--rs-gray-400);
  --rs-toggle-disabled-bg: var(--rs-gray-50);
  --rs-toggle-disabled-thumb: #fff;
  --rs-toggle-checked-bg: var(--rs-primary-500);
  --rs-toggle-checked-thumb: #fff;
  --rs-toggle-checked-hover-bg: var(--rs-primary-600);
  --rs-toggle-checked-disabled-bg: var(--rs-primary-100);
  --rs-toggle-checked-disabled-thumb: #fff;
  --rs-slider-bar: var(--rs-gray-100);
  --rs-slider-hover-bar: var(--rs-gray-200);
  --rs-slider-thumb-border: var(--rs-primary-500);
  --rs-slider-thumb-bg: #fff;
  --rs-slider-thumb-hover-shadow: 0 0 0 8px rgb(from var(--rs-gray-500) r g b/25%);
  --rs-slider-progress: var(--rs-primary-500);
  --rs-uploader-item-bg: var(--rs-gray-300);
  --rs-uploader-item-hover-bg: var(--rs-gray-50);
  --rs-uploader-overlay-bg: rgb(from #fff r g b/80%);
  --rs-uploader-dnd-bg: var(--rs-gray-0);
  --rs-uploader-dnd-border: var(--rs-gray-200);
  --rs-uploader-dnd-hover-border: var(--rs-primary-500);
  --rs-avatar-bg: var(--rs-gray-300);
  --rs-avatar-text: var(--rs-gray-0);
  --rs-badge-bg: var(--rs-color-red);
  --rs-badge-text: var(--rs-gray-0);
  --rs-close-button-hover-color: var(--rs-color-red);
  --rs-tag-bg: var(--rs-gray-50);
  --rs-carousel-bg: var(--rs-gray-600);
  --rs-carousel-indicator: rgb(from var(--rs-gray-0) r g b/40%);
  --rs-carousel-indicator-hover: var(--rs-gray-0);
  --rs-carousel-indicator-active: var(--rs-primary-500);
  --rs-panel-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  --rs-list-bg: var(--rs-gray-0);
  --rs-list-border: var(--rs-gray-200);
  --rs-list-hover-bg: var(--rs-primary-50);
  --rs-list-placeholder-bg: rgb(from var(--rs-primary-50) r g b/50%);
  --rs-list-placeholder-border: var(--rs-primary-500);
  --rs-timeline-indicator-bg: var(--rs-gray-300);
  --rs-timeline-indicator-active-bg: var(--rs-primary-500);
  --rs-table-shadow: rgba(9, 9, 9, 0.08);
  --rs-table-sort: var(--rs-primary-500);
  --rs-table-resize: var(--rs-primary-500);
  --rs-table-scrollbar-track: var(--rs-gray-200);
  --rs-table-scrollbar-thumb: var(--rs-gray-800);
  --rs-table-scrollbar-thumb-active: var(--rs-gray-900);
  --rs-table-scrollbar-vertical-track: rgb(from var(--rs-gray-200) r g b/40%);
  --rs-drawer-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  --rs-modal-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  --rs-form-errormessage-text: var(--rs-color-red);
  --rs-form-errormessage-bg: #fff;
  --rs-form-errormessage-border: var(--rs-gray-200);
  --rs-picker-value: var(--rs-primary-700);
  --rs-picker-count-bg: var(--rs-primary-500);
  --rs-picker-count-text: #fff;
  --rs-calendar-today-bg: var(--rs-primary-500);
  --rs-calendar-today-text: #fff;
  --rs-calendar-range-bg: rgb(from var(--rs-primary-100) r g b/50%);
  --rs-calendar-time-unit-bg: var(--rs-gray-50);
  --rs-calendar-date-selected-text: #fff;
  --rs-calendar-cell-selected-hover-bg: var(--rs-primary-700);
  --rs-popover-shadow: 0 1px 8px rgba(0, 0, 0, 0.12);
}
@supports not (color: rgb(from white r g b)) {
  .rs-theme-light,
  :root {
    --rs-bg-backdrop: rgba(39, 44, 54, 0.3);
    --rs-color-focus-ring: rgba(52, 152, 255, 0.25);
    --rs-state-focus-shadow: 0 0 0 3px rgba(52, 152, 255, 0.25);
    --rs-state-focus-outline: 3px solid rgba(52, 152, 255, 0.25);
    --rs-loader-ring: rgba(247, 247, 250, 0.8);
    --rs-loader-backdrop: hsla(0, 0%, 100%, 0.9);
    --rs-loader-ring-inverse: rgba(247, 247, 250, 0.3);
    --rs-loader-backdrop-inverse: rgba(39, 44, 54, 0.83);
    --rs-message-success-bg: rgba(200, 240, 199, 0.6);
    --rs-message-info-bg: rgba(197, 231, 252, 0.6);
    --rs-message-warning-bg: rgba(255, 233, 194, 0.6);
    --rs-message-error-bg: hsla(0, 88%, 90%, 0.6);
    --rs-dropdown-item-bg-hover: rgba(204, 233, 255, 0.5);
    --rs-menuitem-active-bg: rgba(204, 233, 255, 0.5);
    --rs-listbox-option-hover-bg: rgba(204, 233, 255, 0.5);
    --rs-toggle-loader-ring: rgba(247, 247, 250, 0.3);
    --rs-slider-thumb-hover-shadow: 0 0 0 8px rgba(52, 152, 255, 0.25);
    --rs-uploader-overlay-bg: hsla(0, 0%, 100%, 0.8);
    --rs-carousel-indicator: hsla(0, 0%, 100%, 0.4);
    --rs-list-placeholder-bg: rgba(242, 250, 255, 0.5);
    --rs-table-scrollbar-vertical-track: rgba(229, 229, 234, 0.4);
    --rs-calendar-range-bg: rgba(204, 233, 255, 0.5);
  }
}
.rs-theme-dark {
  --rs-gray-0: #fff;
  --rs-gray-50: #e9ebf0;
  --rs-gray-100: #cbced4;
  --rs-gray-200: #a4a9b3;
  --rs-gray-300: #858b94;
  --rs-gray-400: #6a6f76;
  --rs-gray-500: #5c6066;
  --rs-gray-600: #3c3f43;
  --rs-gray-700: #292d33;
  --rs-gray-800: #1a1d24;
  --rs-gray-900: #0f131a;
  --rs-primary-50: #f2fcff;
  --rs-primary-100: #ccf3ff;
  --rs-primary-200: #a6e9ff;
  --rs-primary-300: #80ddff;
  --rs-primary-400: #59d0ff;
  --rs-primary-500: #34c3ff;
  --rs-primary-600: #25b3f5;
  --rs-primary-700: #169de0;
  --rs-primary-800: #0a81c2;
  --rs-primary-900: #006199;
  --rs-color-red: #f04f43;
  --rs-color-orange: #ff9800;
  --rs-color-yellow: #ffc757;
  --rs-color-green: #58b15b;
  --rs-color-cyan: #00bcd4;
  --rs-color-blue: #1499ef;
  --rs-color-violet: #673ab7;
  --rs-red-50: #ffeded;
  --rs-red-100: #fccaca;
  --rs-red-200: #faa9a7;
  --rs-red-300: #f58884;
  --rs-red-400: #f26a63;
  --rs-red-500: #f04f43;
  --rs-red-600: #e63f30;
  --rs-red-700: #d12f1d;
  --rs-red-800: #b3200c;
  --rs-red-900: #8a1200;
  --rs-orange-50: #fff9f2;
  --rs-orange-100: #ffe2c2;
  --rs-orange-200: #ffce91;
  --rs-orange-300: #ffba61;
  --rs-orange-400: #ffa930;
  --rs-orange-500: #ff9800;
  --rs-orange-600: #f59700;
  --rs-orange-700: #e08e00;
  --rs-orange-800: #c27e00;
  --rs-orange-900: #960;
  --rs-yellow-50: #fff9f0;
  --rs-yellow-100: #ffedd1;
  --rs-yellow-200: #ffe2b3;
  --rs-yellow-300: #ffd894;
  --rs-yellow-400: #ffcf75;
  --rs-yellow-500: #ffc757;
  --rs-yellow-600: #f5bb3d;
  --rs-yellow-700: #e0a824;
  --rs-yellow-800: #c28f0e;
  --rs-yellow-900: #997000;
  --rs-green-50: #f3fff2;
  --rs-green-100: #cff0ce;
  --rs-green-200: #aee0ad;
  --rs-green-300: #8ccf8c;
  --rs-green-400: #71bf72;
  --rs-green-500: #58b15b;
  --rs-green-600: #3fab45;
  --rs-green-700: #27a12f;
  --rs-green-800: #10911b;
  --rs-green-900: #007d0c;
  --rs-cyan-50: #f2ffff;
  --rs-cyan-100: #bcf4f7;
  --rs-cyan-200: #87e6ed;
  --rs-cyan-300: #57dae6;
  --rs-cyan-400: #2acadb;
  --rs-cyan-500: #00bcd4;
  --rs-cyan-600: #00b1cc;
  --rs-cyan-700: #00a0bd;
  --rs-cyan-800: #008aa6;
  --rs-cyan-900: #006e87;
  --rs-blue-50: #edf9ff;
  --rs-blue-100: #c0e8fc;
  --rs-blue-200: #93d6fa;
  --rs-blue-300: #67c1f5;
  --rs-blue-400: #3dadf2;
  --rs-blue-500: #1499ef;
  --rs-blue-600: #0e8ce6;
  --rs-blue-700: #087ad1;
  --rs-blue-800: #0464b3;
  --rs-blue-900: #00498a;
  --rs-violet-50: #f6f2ff;
  --rs-violet-100: #d5c9f0;
  --rs-violet-200: #b6a1e3;
  --rs-violet-300: #987bd4;
  --rs-violet-400: #805ac7;
  --rs-violet-500: #673ab7;
  --rs-violet-600: #5f2bb3;
  --rs-violet-700: #531ba8;
  --rs-violet-800: #470c99;
  --rs-violet-900: #390085;
  --rs-state-success: var(--rs-color-green);
  --rs-state-info: var(--rs-color-blue);
  --rs-state-warning: var(--rs-color-yellow);
  --rs-state-error: var(--rs-color-red);
  --rs-body: var(--rs-gray-900);
  --rs-text-link: var(--rs-primary-500);
  --rs-text-link-hover: var(--rs-primary-400);
  --rs-text-link-active: var(--rs-primary-300);
  --rs-text-primary: var(--rs-gray-50);
  --rs-text-secondary: var(--rs-gray-200);
  --rs-text-tertiary: var(--rs-gray-300);
  --rs-text-heading: var(--rs-gray-0);
  --rs-text-inverse: var(--rs-gray-800);
  --rs-text-heading-inverse: var(--rs-gray-900);
  --rs-text-active: var(--rs-primary-500);
  --rs-text-disabled: var(--rs-gray-500);
  --rs-border-primary: var(--rs-gray-600);
  --rs-border-secondary: var(--rs-gray-700);
  --rs-bg-card: var(--rs-gray-800);
  --rs-bg-overlay: var(--rs-gray-700);
  --rs-bg-well: var(--rs-gray-900);
  --rs-bg-active: var(--rs-primary-700);
  --rs-bg-backdrop: rgb(from var(--rs-gray-900) r g b/80%);
  --rs-state-hover-bg: var(--rs-gray-600);
  --rs-color-focus-ring: 0 0 0 3px rgb(from var(--rs-gray-500) r g b/25%);
  --rs-state-focus-shadow: 0 0 0 3px rgb(from var(--rs-gray-500) r g b/25%);
  --rs-state-focus-outline: 3px solid rgb(from var(--rs-gray-500) r g b/25%);
  --rs-state-error-outline: 3px solid rgb(from var(--rs-color-red) r g b/25%);
  --rs-shadow-overlay: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  --rs-btn-default-bg: var(--rs-gray-600);
  --rs-btn-default-text: var(--rs-gray-50);
  --rs-btn-default-hover-bg: var(--rs-gray-500);
  --rs-btn-default-active-bg: var(--rs-gray-300);
  --rs-btn-default-active-text: var(--rs-gray-0);
  --rs-btn-default-disabled-bg: var(--rs-gray-600);
  --rs-btn-default-disabled-text: var(--rs-gray-400);
  --rs-btn-primary-bg: var(--rs-primary-700);
  --rs-btn-primary-text: var(--rs-gray-0);
  --rs-btn-primary-hover-bg: var(--rs-primary-600);
  --rs-btn-primary-active-bg: var(--rs-primary-400);
  --rs-btn-subtle-text: var(--rs-gray-200);
  --rs-btn-subtle-hover-bg: var(--rs-gray-500);
  --rs-btn-subtle-hover-text: var(--rs-gray-50);
  --rs-btn-subtle-active-bg: var(--rs-gray-400);
  --rs-btn-subtle-active-text: var(--rs-gray-0);
  --rs-btn-subtle-disabled-text: var(--rs-gray-500);
  --rs-btn-ghost-border: var(--rs-primary-500);
  --rs-btn-ghost-text: var(--rs-primary-500);
  --rs-btn-ghost-hover-border: var(--rs-primary-400);
  --rs-btn-ghost-hover-text: var(--rs-primary-400);
  --rs-btn-ghost-active-border: var(--rs-primary-200);
  --rs-btn-ghost-active-text: var(--rs-primary-200);
  --rs-btn-link-text: var(--rs-primary-500);
  --rs-btn-link-hover-text: var(--rs-primary-400);
  --rs-btn-link-active-text: var(--rs-primary-200);
  --rs-iconbtn-addon: var(--rs-gray-500);
  --rs-iconbtn-activated-addon: var(--rs-gray-400);
  --rs-iconbtn-pressed-addon: var(--rs-gray-200);
  --rs-iconbtn-primary-addon: var(--rs-primary-600);
  --rs-iconbtn-primary-activated-addon: var(--rs-primary-500);
  --rs-iconbtn-primary-pressed-addon: var(--rs-primary-400);
  --rs-divider-border: var(--rs-gray-600);
  --rs-loader-ring: rgb(from var(--rs-gray-50) r g b/30%);
  --rs-loader-rotor: var(--rs-gray-0);
  --rs-loader-backdrop: rgb(from var(--rs-gray-900) r g b/83%);
  --rs-loader-ring-inverse: rgb(from var(--rs-gray-50) r g b/80%);
  --rs-loader-rotor-inverse: var(--rs-gray-500);
  --rs-loader-backdrop-inverse: rgb(from var(--rs-gray-0) r g b/90%);
  --rs-message-success-header: #fff;
  --rs-message-success-text: #fff;
  --rs-message-success-icon: #fff;
  --rs-message-success-bg: var(--rs-green-500);
  --rs-message-success-border: var(--rs-green-800);
  --rs-message-info-header: #fff;
  --rs-message-info-text: #fff;
  --rs-message-info-icon: #fff;
  --rs-message-info-bg: var(--rs-blue-500);
  --rs-message-info-border: var(--rs-blue-800);
  --rs-message-warning-header: var(--rs-gray-900);
  --rs-message-warning-text: var(--rs-gray-900);
  --rs-message-warning-icon: var(--rs-gray-900);
  --rs-message-warning-bg: var(--rs-yellow-500);
  --rs-message-warning-border: var(--rs-yellow-800);
  --rs-message-error-header: #fff;
  --rs-message-error-text: #fff;
  --rs-message-error-icon: #fff;
  --rs-message-error-bg: var(--rs-red-500);
  --rs-message-error-border: var(--rs-red-800);
  --rs-tooltip-bg: var(--rs-gray-500);
  --rs-tooltip-text: var(--rs-gray-0);
  --rs-progress-bg: var(--rs-gray-700);
  --rs-progress-bar: var(--rs-primary-500);
  --rs-progress-bar-success: var(--rs-color-green);
  --rs-progress-bar-fail: var(--rs-color-red);
  --rs-placeholder: var(--rs-gray-600);
  --rs-placeholder-active: var(--rs-gray-500);
  --rs-breadcrumb-item-active-text: #fff;
  --rs-dropdown-divider: var(--rs-gray-600);
  --rs-dropdown-item-bg-hover: var(--rs-gray-600);
  --rs-dropdown-item-bg-active: rgb(from var(--rs-primary-900) r g b/20%);
  --rs-dropdown-item-text-active: var(--rs-primary-500);
  --rs-dropdown-header-text: var(--rs-gray-500);
  --rs-dropdown-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2), 0 4px 4px 3px rgba(0, 0, 0, 0.24);
  --rs-menuitem-active-bg: var(--rs-gray-600);
  --rs-menuitem-active-text: currentColor;
  --rs-steps-border: var(--rs-gray-200);
  --rs-steps-state-finish: var(--rs-primary-500);
  --rs-steps-border-state-finish: var(--rs-primary-500);
  --rs-steps-state-wait: var(--rs-gray-200);
  --rs-steps-state-process: var(--rs-primary-700);
  --rs-steps-state-error: var(--rs-color-red);
  --rs-steps-border-state-error: var(--rs-color-red);
  --rs-steps-icon-state-process: var(--rs-primary-500);
  --rs-steps-icon-state-error: var(--rs-color-red);
  --rs-navs-text: var(--rs-gray-200);
  --rs-navs-text-hover: var(--rs-gray-100);
  --rs-navs-bg-hover: var(--rs-gray-400);
  --rs-navs-text-active: var(--rs-gray-0);
  --rs-navs-bg-active: var(--rs-gray-400);
  --rs-navs-tab-border: var(--rs-gray-600);
  --rs-navs-subtle-border: var(--rs-gray-600);
  --rs-navs-selected: var(--rs-primary-500);
  --rs-navbar-default-bg: var(--rs-gray-800);
  --rs-navbar-default-text: var(--rs-gray-200);
  --rs-navbar-default-selected-text: var(--rs-primary-500);
  --rs-navbar-default-hover-bg: var(--rs-gray-700);
  --rs-navbar-default-hover-text: var(--rs-gray-50);
  --rs-navbar-inverse-bg: var(--rs-primary-700);
  --rs-navbar-inverse-text: #fff;
  --rs-navbar-inverse-selected-bg: var(--rs-primary-400);
  --rs-navbar-inverse-hover-bg: var(--rs-primary-600);
  --rs-navbar-inverse-hover-text: #fff;
  --rs-navbar-subtle-bg: transparent;
  --rs-navbar-subtle-text: var(--rs-gray-200);
  --rs-navbar-subtle-selected-text: var(--rs-primary-500);
  --rs-navbar-subtle-hover-bg: var(--rs-gray-700);
  --rs-navbar-subtle-hover-text: var(--rs-gray-50);
  --rs-sidenav-default-bg: var(--rs-gray-800);
  --rs-sidenav-default-text: var(--rs-gray-200);
  --rs-sidenav-default-selected-text: var(--rs-primary-500);
  --rs-sidenav-default-hover-bg: var(--rs-gray-700);
  --rs-sidenav-default-hover-text: var(--rs-gray-50);
  --rs-sidenav-default-footer-border: var(--rs-gray-600);
  --rs-sidenav-inverse-bg: var(--rs-primary-700);
  --rs-sidenav-inverse-text: #fff;
  --rs-sidenav-inverse-selected-bg: var(--rs-primary-400);
  --rs-sidenav-inverse-hover-bg: var(--rs-primary-600);
  --rs-sidenav-inverse-footer-border: var(--rs-primary-600);
  --rs-sidenav-subtle-bg: transparent;
  --rs-sidenav-subtle-text: var(--rs-gray-200);
  --rs-sidenav-subtle-selected-text: var(--rs-primary-500);
  --rs-sidenav-subtle-hover-bg: var(--rs-gray-700);
  --rs-sidenav-subtle-hover-text: var(--rs-gray-50);
  --rs-sidenav-subtle-footer-border: var(--rs-gray-600);
  --rs-input-bg: var(--rs-gray-800);
  --rs-input-focus-border: var(--rs-primary-500);
  --rs-input-disabled-bg: var(--rs-gray-700);
  --rs-listbox-option-hover-bg: var(--rs-gray-600);
  --rs-listbox-option-hover-text: currentColor;
  --rs-listbox-option-selected-text: var(--rs-primary-500);
  --rs-listbox-option-selected-bg: rgb(from var(--rs-primary-900) r g b/20%);
  --rs-listbox-option-disabled-text: var(--rs-gray-500);
  --rs-listbox-option-disabled-selected-text: var(--rs-primary-200);
  --rs-checkbox-icon: var(--rs-gray-800);
  --rs-checkbox-border: var(--rs-gray-400);
  --rs-checkbox-checked-bg: var(--rs-primary-500);
  --rs-checkbox-disabled-bg: var(--rs-gray-500);
  --rs-radio-marker: var(--rs-gray-800);
  --rs-radio-border: var(--rs-gray-400);
  --rs-radio-checked-bg: var(--rs-primary-500);
  --rs-radio-disabled-bg: var(--rs-gray-500);
  --rs-radio-tile-border: var(--rs-gray-300);
  --rs-radio-tile-bg: var(--rs-gray-0);
  --rs-radio-tile-checked-color: var(--rs-primary-500);
  --rs-radio-tile-checked-mark-color: var(--rs-gray-800);
  --rs-radio-tile-checked-disabled-color: var(--rs-primary-900);
  --rs-rate-symbol: var(--rs-gray-600);
  --rs-rate-symbol-checked: var(--rs-yellow-500);
  --rs-toggle-bg: var(--rs-gray-400);
  --rs-toggle-thumb: #fff;
  --rs-toggle-hover-bg: var(--rs-gray-300);
  --rs-toggle-disabled-bg: var(--rs-gray-600);
  --rs-toggle-disabled-thumb: var(--rs-gray-500);
  --rs-toggle-checked-bg: var(--rs-primary-700);
  --rs-toggle-checked-thumb: #fff;
  --rs-toggle-checked-hover-bg: var(--rs-primary-600);
  --rs-toggle-checked-disabled-bg: var(--rs-primary-900);
  --rs-toggle-checked-disabled-thumb: var(--rs-gray-300);
  --rs-slider-bar: var(--rs-gray-600);
  --rs-slider-hover-bar: var(--rs-gray-600);
  --rs-slider-thumb-border: var(--rs-primary-500);
  --rs-slider-thumb-bg: var(--rs-gray-700);
  --rs-slider-thumb-hover-shadow: 0 0 0 8px rgb(from var(--rs-primary-500) r g b/25%);
  --rs-slider-progress: var(--rs-primary-500);
  --rs-uploader-item-bg: var(--rs-gray-300);
  --rs-uploader-item-hover-bg: var(--rs-gray-600);
  --rs-uploader-overlay-bg: rgb(from var(--rs-gray-600) r g b/80%);
  --rs-uploader-dnd-bg: var(--rs-gray-700);
  --rs-uploader-dnd-border: var(--rs-gray-200);
  --rs-uploader-dnd-hover-border: var(--rs-primary-500);
  --rs-avatar-bg: var(--rs-gray-400);
  --rs-avatar-text: var(--rs-gray-0);
  --rs-badge-bg: var(--rs-color-red);
  --rs-badge-text: var(--rs-gray-0);
  --rs-close-button-hover-color: var(--rs-color-red);
  --rs-tag-bg: var(--rs-gray-600);
  --rs-carousel-bg: var(--rs-gray-600);
  --rs-carousel-indicator: rgb(from var(--rs-gray-0) r g b/40%);
  --rs-carousel-indicator-hover: var(--rs-gray-0);
  --rs-carousel-indicator-active: var(--rs-primary-500);
  --rs-panel-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  --rs-list-bg: var(--rs-gray-900);
  --rs-list-border: var(--rs-gray-700);
  --rs-list-hover-bg: var(--rs-gray-600);
  --rs-list-placeholder-bg: rgb(from var(--rs-primary-900) r g b/20%);
  --rs-list-placeholder-border: var(--rs-primary-500);
  --rs-timeline-indicator-bg: var(--rs-gray-500);
  --rs-timeline-indicator-active-bg: var(--rs-primary-500);
  --rs-table-shadow: rgba(9, 9, 9, 0.99);
  --rs-table-sort: var(--rs-primary-500);
  --rs-table-resize: var(--rs-primary-500);
  --rs-table-scrollbar-track: var(--rs-gray-700);
  --rs-table-scrollbar-thumb: var(--rs-gray-200);
  --rs-table-scrollbar-thumb-active: var(--rs-gray-100);
  --rs-table-scrollbar-vertical-track: var(--rs-gray-700);
  --rs-drawer-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  --rs-modal-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  --rs-form-errormessage-text: #fff;
  --rs-form-errormessage-bg: var(--rs-color-red);
  --rs-form-errormessage-border: var(--rs-color-red);
  --rs-picker-value: var(--rs-primary-500);
  --rs-picker-count-bg: var(--rs-primary-700);
  --rs-picker-count-text: #fff;
  --rs-calendar-today-bg: var(--rs-primary-700);
  --rs-calendar-today-text: #fff;
  --rs-calendar-range-bg: rgb(from var(--rs-primary-900) r g b/50%);
  --rs-calendar-time-unit-bg: var(--rs-gray-600);
  --rs-calendar-date-selected-text: #fff;
  --rs-calendar-cell-selected-hover-bg: var(--rs-primary-700);
  --rs-popover-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}
@supports not (color: rgb(from white r g b)) {
  .rs-theme-dark {
    --rs-bg-backdrop: rgba(15, 19, 26, 0.8);
    --rs-color-focus-ring: 0 0 0 3px rgba(52, 195, 255, 0.25);
    --rs-state-focus-shadow: 0 0 0 3px rgba(52, 195, 255, 0.25);
    --rs-state-focus-outline: 3px solid rgba(52, 195, 255, 0.25);
    --rs-loader-ring: rgba(233, 235, 240, 0.3);
    --rs-loader-backdrop: rgba(15, 19, 26, 0.83);
    --rs-loader-ring-inverse: rgba(233, 235, 240, 0.8);
    --rs-loader-backdrop-inverse: hsla(0, 0%, 100%, 0.9);
    --rs-dropdown-item-bg-active: rgba(0, 97, 153, 0.2);
    --rs-listbox-option-selected-bg: rgba(0, 97, 153, 0.2);
    --rs-slider-thumb-hover-shadow: 0 0 0 8px rgba(52, 195, 255, 0.25);
    --rs-uploader-overlay-bg: rgba(60, 63, 67, 0.8);
    --rs-carousel-indicator: hsla(0, 0%, 100%, 0.4);
    --rs-list-placeholder-bg: rgba(0, 97, 153, 0.2);
    --rs-calendar-range-bg: rgba(0, 97, 153, 0.5);
    --rs-placeholder-active: #484c50;
  }
}
.rs-theme-high-contrast {
  --rs-gray-0: #fff;
  --rs-gray-50: #e9ebf0;
  --rs-gray-100: #cbced4;
  --rs-gray-200: #a4a9b3;
  --rs-gray-300: #858b94;
  --rs-gray-400: #6a6f76;
  --rs-gray-500: #5c6066;
  --rs-gray-600: #3c3f43;
  --rs-gray-700: #292d33;
  --rs-gray-800: #1a1d24;
  --rs-gray-900: #0f131a;
  --rs-primary-50: #fffef2;
  --rs-primary-100: #fffbc2;
  --rs-primary-200: #fffa91;
  --rs-primary-300: #fffa61;
  --rs-primary-400: #fffc30;
  --rs-primary-500: #ff0;
  --rs-primary-600: #f1f500;
  --rs-primary-700: #d9e000;
  --rs-primary-800: #b8c200;
  --rs-primary-900: #8f9900;
  --rs-color-red: #bd1732;
  --rs-color-orange: #ff9800;
  --rs-color-yellow: #ffc757;
  --rs-color-green: #0d822c;
  --rs-color-cyan: #00bcd4;
  --rs-color-blue: #1499ef;
  --rs-color-violet: #673ab7;
  --rs-red-50: #fff2f5;
  --rs-red-100: #f2c2cd;
  --rs-red-200: #e691a3;
  --rs-red-300: #d6637a;
  --rs-red-400: #c93a55;
  --rs-red-500: #bd1732;
  --rs-red-600: #b51029;
  --rs-red-700: #a60a1f;
  --rs-red-800: #8f0414;
  --rs-red-900: #70000b;
  --rs-orange-50: #fff9f2;
  --rs-orange-100: #ffe2c2;
  --rs-orange-200: #ffce91;
  --rs-orange-300: #ffba61;
  --rs-orange-400: #ffa930;
  --rs-orange-500: #ff9800;
  --rs-orange-600: #f59700;
  --rs-orange-700: #e08e00;
  --rs-orange-800: #c27e00;
  --rs-orange-900: #960;
  --rs-yellow-50: #fff9f0;
  --rs-yellow-100: #ffedd1;
  --rs-yellow-200: #ffe2b3;
  --rs-yellow-300: #ffd894;
  --rs-yellow-400: #ffcf75;
  --rs-yellow-500: #ffc757;
  --rs-yellow-600: #f5bb3d;
  --rs-yellow-700: #e0a824;
  --rs-yellow-800: #c28f0e;
  --rs-yellow-900: #997000;
  --rs-green-50: #f2fff5;
  --rs-green-100: #b3e6bd;
  --rs-green-200: #7ccc8e;
  --rs-green-300: #50b567;
  --rs-green-400: #2a9c46;
  --rs-green-500: #0d822c;
  --rs-green-600: #09802b;
  --rs-green-700: #057a28;
  --rs-green-800: #017325;
  --rs-green-900: #006923;
  --rs-cyan-50: #f2ffff;
  --rs-cyan-100: #bcf4f7;
  --rs-cyan-200: #87e6ed;
  --rs-cyan-300: #57dae6;
  --rs-cyan-400: #2acadb;
  --rs-cyan-500: #00bcd4;
  --rs-cyan-600: #00b1cc;
  --rs-cyan-700: #00a0bd;
  --rs-cyan-800: #008aa6;
  --rs-cyan-900: #006e87;
  --rs-blue-50: #edf9ff;
  --rs-blue-100: #c0e8fc;
  --rs-blue-200: #93d6fa;
  --rs-blue-300: #67c1f5;
  --rs-blue-400: #3dadf2;
  --rs-blue-500: #1499ef;
  --rs-blue-600: #0e8ce6;
  --rs-blue-700: #087ad1;
  --rs-blue-800: #0464b3;
  --rs-blue-900: #00498a;
  --rs-violet-50: #f6f2ff;
  --rs-violet-100: #d5c9f0;
  --rs-violet-200: #b6a1e3;
  --rs-violet-300: #987bd4;
  --rs-violet-400: #805ac7;
  --rs-violet-500: #673ab7;
  --rs-violet-600: #5f2bb3;
  --rs-violet-700: #531ba8;
  --rs-violet-800: #470c99;
  --rs-violet-900: #390085;
  --rs-state-success: var(--rs-color-green);
  --rs-state-info: var(--rs-color-blue);
  --rs-state-warning: var(--rs-color-yellow);
  --rs-state-error: var(--rs-color-red);
  --rs-body: var(--rs-gray-900);
  --rs-text-link: var(--rs-primary-500);
  --rs-text-link-hover: var(--rs-primary-400);
  --rs-text-link-active: var(--rs-primary-300);
  --rs-text-primary: var(--rs-gray-50);
  --rs-text-secondary: var(--rs-gray-200);
  --rs-text-tertiary: var(--rs-gray-300);
  --rs-text-heading: var(--rs-gray-0);
  --rs-text-inverse: var(--rs-gray-800);
  --rs-text-heading-inverse: var(--rs-gray-900);
  --rs-text-active: var(--rs-primary-500);
  --rs-text-disabled: var(--rs-gray-500);
  --rs-border-primary: var(--rs-gray-100);
  --rs-border-secondary: var(--rs-gray-700);
  --rs-bg-card: var(--rs-gray-800);
  --rs-bg-overlay: var(--rs-gray-800);
  --rs-bg-well: var(--rs-gray-900);
  --rs-bg-active: var(--rs-primary-500);
  --rs-bg-backdrop: rgb(from var(--rs-gray-900) r g b/80%);
  --rs-state-hover-bg: var(--rs-gray-600);
  --rs-color-focus-ring: var(--rs-gray-0);
  --rs-state-focus-shadow: 0 0 0 3px var(--rs-gray-900), 0 0 0 5px var(--rs-gray-0);
  --rs-state-focus-shadow-slim: 0 0 0 2px var(--rs-gray-0);
  --rs-state-focus-outline: 3px solid rgb(from var(--rs-primary-500) r g b/25%);
  --rs-state-error-outline: 3px solid rgb(from var(--rs-color-red) r g b/25%);
  --rs-shadow-overlay: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  --rs-btn-default-bg: transparent;
  --rs-btn-default-text: var(--rs-primary-500);
  --rs-btn-default-border: 1px solid var(--rs-primary-500);
  --rs-btn-default-hover-bg: transparent;
  --rs-btn-default-hover-text: var(--rs-primary-400);
  --rs-btn-default-active-bg: transparent;
  --rs-btn-default-active-text: var(--rs-primary-200);
  --rs-btn-default-disabled-bg: transparent;
  --rs-btn-default-disabled-text: var(--rs-primary-500);
  --rs-btn-primary-bg: var(--rs-primary-500);
  --rs-btn-primary-text: var(--rs-gray-900);
  --rs-btn-primary-hover-bg: var(--rs-primary-400);
  --rs-btn-primary-active-bg: var(--rs-primary-200);
  --rs-btn-subtle-text: var(--rs-primary-500);
  --rs-btn-subtle-hover-bg: transparent;
  --rs-btn-subtle-hover-text: var(--rs-primary-400);
  --rs-btn-subtle-active-bg: transparent;
  --rs-btn-subtle-active-text: var(--rs-primary-200);
  --rs-btn-subtle-disabled-text: var(--rs-gray-500);
  --rs-btn-ghost-border: var(--rs-primary-500);
  --rs-btn-ghost-text: var(--rs-primary-500);
  --rs-btn-ghost-hover-border: var(--rs-primary-400);
  --rs-btn-ghost-hover-text: var(--rs-primary-400);
  --rs-btn-ghost-active-border: var(--rs-primary-200);
  --rs-btn-ghost-active-text: var(--rs-primary-200);
  --rs-btn-link-text: var(--rs-primary-500);
  --rs-btn-link-hover-text: var(--rs-primary-400);
  --rs-btn-link-active-text: var(--rs-primary-200);
  --rs-iconbtn-addon: transparent;
  --rs-iconbtn-activated-addon: transparent;
  --rs-iconbtn-pressed-addon: transparent;
  --rs-iconbtn-primary-addon: var(--rs-primary-400);
  --rs-iconbtn-primary-activated-addon: var(--rs-primary-300);
  --rs-iconbtn-primary-pressed-addon: var(--rs-primary-100);
  --rs-divider-border: var(--rs-gray-600);
  --rs-loader-ring: rgb(from var(--rs-gray-50) r g b/30%);
  --rs-loader-rotor: var(--rs-gray-0);
  --rs-loader-backdrop: rgb(from var(--rs-gray-900) r g b/83%);
  --rs-loader-ring-inverse: rgb(from var(--rs-gray-50) r g b/80%);
  --rs-loader-rotor-inverse: var(--rs-gray-500);
  --rs-loader-backdrop-inverse: rgb(from var(--rs-gray-0) r g b/90%);
  --rs-message-success-header: #fff;
  --rs-message-success-text: #fff;
  --rs-message-success-icon: #fff;
  --rs-message-success-bg: var(--rs-green-900);
  --rs-message-success-border: var(--rs-green-300);
  --rs-message-info-header: #fff;
  --rs-message-info-text: #fff;
  --rs-message-info-icon: #fff;
  --rs-message-info-bg: var(--rs-blue-900);
  --rs-message-info-border: var(--rs-blue-500);
  --rs-message-warning-header: #fff;
  --rs-message-warning-text: #fff;
  --rs-message-warning-icon: #fff;
  --rs-message-warning-bg: var(--rs-yellow-900);
  --rs-message-warning-border: var(--rs-yellow-500);
  --rs-message-error-header: #fff;
  --rs-message-error-text: #fff;
  --rs-message-error-icon: #fff;
  --rs-message-error-bg: var(--rs-red-900);
  --rs-message-error-border: var(--rs-red-300);
  --rs-tooltip-bg: var(--rs-gray-800);
  --rs-tooltip-text: var(--rs-gray-0);
  --rs-progress-bg: var(--rs-gray-700);
  --rs-progress-bar: var(--rs-primary-500);
  --rs-progress-bar-success: var(--rs-color-green);
  --rs-progress-bar-fail: var(--rs-color-red);
  --rs-placeholder: var(--rs-gray-600);
  --rs-placeholder-active: var(--rs-gray-500);
  --rs-breadcrumb-item-active-text: #fff;
  --rs-dropdown-divider: var(--rs-gray-600);
  --rs-dropdown-item-bg-hover: var(--rs-gray-600);
  --rs-dropdown-item-bg-active: rgb(from var(--rs-primary-900) r g b/20%);
  --rs-dropdown-item-text-active: var(--rs-primary-500);
  --rs-dropdown-header-text: var(--rs-gray-500);
  --rs-dropdown-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2), 0 4px 4px 3px rgba(0, 0, 0, 0.24);
  --rs-menuitem-active-bg: transparent;
  --rs-menuitem-active-text: var(--rs-primary-500);
  --rs-steps-border: var(--rs-gray-200);
  --rs-steps-state-finish: var(--rs-primary-500);
  --rs-steps-border-state-finish: var(--rs-primary-500);
  --rs-steps-state-wait: var(--rs-gray-200);
  --rs-steps-state-process: var(--rs-primary-700);
  --rs-steps-state-error: var(--rs-color-red);
  --rs-steps-border-state-error: var(--rs-color-red);
  --rs-steps-icon-state-process: var(--rs-primary-500);
  --rs-steps-icon-state-error: var(--rs-color-red);
  --rs-navs-text: var(--rs-gray-200);
  --rs-navs-text-hover: var(--rs-primary-500);
  --rs-navs-bg-hover: transparent;
  --rs-navs-text-active: var(--rs-primary-500);
  --rs-navs-bg-active: var(--rs-gray-400);
  --rs-navs-tab-border: var(--rs-gray-600);
  --rs-navs-subtle-border: var(--rs-gray-600);
  --rs-navs-selected: var(--rs-primary-500);
  --rs-navbar-default-bg: var(--rs-gray-800);
  --rs-navbar-default-text: var(--rs-gray-50);
  --rs-navbar-default-selected-text: var(--rs-primary-500);
  --rs-navbar-default-hover-bg: transparent;
  --rs-navbar-default-hover-text: var(--rs-primary-500);
  --rs-navbar-inverse-bg: var(--rs-gray-800);
  --rs-navbar-inverse-text: var(--rs-gray-50);
  --rs-navbar-inverse-selected-bg: transparent;
  --rs-navbar-inverse-selected-text: var(--rs-primary-500);
  --rs-navbar-inverse-hover-bg: transparent;
  --rs-navbar-inverse-hover-text: var(--rs-primary-500);
  --rs-navbar-subtle-bg: var(--rs-gray-800);
  --rs-navbar-subtle-text: var(--rs-gray-50);
  --rs-navbar-subtle-selected-text: var(--rs-primary-500);
  --rs-navbar-subtle-hover-bg: transparent;
  --rs-navbar-subtle-hover-text: var(--rs-primary-500);
  --rs-sidenav-default-bg: var(--rs-gray-800);
  --rs-sidenav-default-text: var(--rs-gray-50);
  --rs-sidenav-default-selected-text: var(--rs-primary-500);
  --rs-sidenav-default-hover-bg: transparent;
  --rs-sidenav-default-hover-text: var(--rs-primary-500);
  --rs-sidenav-default-footer-border: var(--rs-gray-50);
  --rs-sidenav-inverse-bg: var(--rs-gray-800);
  --rs-sidenav-inverse-text: var(--rs-gray-50);
  --rs-sidenav-inverse-selected-bg: transparent;
  --rs-sidenav-inverse-selected-text: var(--rs-primary-500);
  --rs-sidenav-inverse-hover-bg: transparent;
  --rs-sidenav-inverse-footer-border: var(--rs-gray-50);
  --rs-sidenav-subtle-bg: var(--rs-gray-800);
  --rs-sidenav-subtle-text: var(--rs-gray-50);
  --rs-sidenav-subtle-selected-text: var(--rs-primary-500);
  --rs-sidenav-subtle-hover-bg: transparent;
  --rs-sidenav-subtle-hover-text: var(--rs-primary-500);
  --rs-sidenav-subtle-footer-border: var(--rs-gray-50);
  --rs-input-bg: var(--rs-gray-800);
  --rs-input-focus-border: var(--rs-primary-500);
  --rs-input-disabled-bg: var(--rs-gray-700);
  --rs-listbox-option-hover-bg: transparent;
  --rs-listbox-option-hover-text: var(--rs-primary-500);
  --rs-listbox-option-selected-text: var(--rs-primary-500);
  --rs-listbox-option-selected-bg: transparent;
  --rs-listbox-option-disabled-text: var(--rs-gray-500);
  --rs-listbox-option-disabled-selected-text: var(--rs-primary-200);
  --rs-checkbox-icon: var(--rs-gray-800);
  --rs-checkbox-border: var(--rs-gray-100);
  --rs-checkbox-checked-bg: var(--rs-primary-500);
  --rs-checkbox-disabled-bg: var(--rs-gray-500);
  --rs-radio-marker: var(--rs-gray-800);
  --rs-radio-border: var(--rs-gray-100);
  --rs-radio-checked-bg: var(--rs-primary-500);
  --rs-radio-disabled-bg: var(--rs-gray-500);
  --rs-radio-tile-border: var(--rs-gray-300);
  --rs-radio-tile-bg: var(--rs-gray-0);
  --rs-radio-tile-checked-color: var(--rs-primary-500);
  --rs-radio-tile-checked-mark-color: var(--rs-gray-800);
  --rs-radio-tile-checked-disabled-color: var(--rs-primary-900);
  --rs-rate-symbol: var(--rs-gray-100);
  --rs-rate-symbol-checked: var(--rs-primary-500);
  --rs-toggle-bg: var(--rs-gray-800);
  --rs-toggle-thumb: var(--rs-gray-100);
  --rs-toggle-hover-bg: var(--rs-gray-800);
  --rs-toggle-disabled-bg: var(--rs-gray-800);
  --rs-toggle-disabled-thumb: var(--rs-gray-300);
  --rs-toggle-checked-bg: var(--rs-primary-500);
  --rs-toggle-checked-thumb: var(--rs-gray-800);
  --rs-toggle-checked-hover-bg: var(--rs-primary-400);
  --rs-toggle-checked-disabled-bg: var(--rs-primary-900);
  --rs-toggle-checked-disabled-thumb: var(--rs-gray-800);
  --rs-slider-bar: var(--rs-gray-600);
  --rs-slider-hover-bar: var(--rs-gray-600);
  --rs-slider-thumb-border: var(--rs-primary-500);
  --rs-slider-thumb-bg: var(--rs-gray-700);
  --rs-slider-thumb-hover-shadow: 0 0 0 8px rgb(from var(--rs-primary-500) r g b/25%);
  --rs-slider-progress: var(--rs-primary-500);
  --rs-uploader-item-bg: var(--rs-gray-300);
  --rs-uploader-item-hover-bg: var(--rs-gray-800);
  --rs-uploader-item-hover-text: var(--rs-primary-500);
  --rs-uploader-overlay-bg: rgb(from var(--rs-gray-600) r g b/80%);
  --rs-uploader-dnd-bg: var(--rs-gray-700);
  --rs-uploader-dnd-border: var(--rs-gray-200);
  --rs-uploader-dnd-hover-border: var(--rs-primary-500);
  --rs-avatar-bg: var(--rs-gray-400);
  --rs-avatar-text: var(--rs-gray-0);
  --rs-badge-bg: var(--rs-red-500);
  --rs-badge-text: var(--rs-gray-0);
  --rs-close-button-hover-color: var(--rs-color-red);
  --rs-tag-bg: var(--rs-gray-600);
  --rs-carousel-bg: var(--rs-gray-600);
  --rs-carousel-indicator: rgb(from var(--rs-gray-0) r g b/40%);
  --rs-carousel-indicator-hover: var(--rs-gray-0);
  --rs-carousel-indicator-active: var(--rs-primary-500);
  --rs-panel-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  --rs-pagination-item-text: var(--rs-gray-50);
  --rs-pagination-item-current-text: var(--rs-primary-500);
  --rs-list-bg: transparent;
  --rs-list-border: var(--rs-gray-700);
  --rs-list-hover-bg: var(--rs-gray-600);
  --rs-list-placeholder-bg: rgb(from var(--rs-primary-900) r g b/20%);
  --rs-list-placeholder-border: var(--rs-primary-500);
  --rs-timeline-indicator-bg: var(--rs-gray-500);
  --rs-timeline-indicator-active-bg: var(--rs-primary-500);
  --rs-table-shadow: rgba(9, 9, 9, 0.99);
  --rs-table-sort: var(--rs-primary-500);
  --rs-table-resize: var(--rs-primary-500);
  --rs-table-scrollbar-track: var(--rs-gray-700);
  --rs-table-scrollbar-thumb: var(--rs-gray-200);
  --rs-table-scrollbar-thumb-active: var(--rs-gray-100);
  --rs-table-scrollbar-vertical-track: var(--rs-gray-700);
  --rs-drawer-bg: var(--rs-gray-700);
  --rs-drawer-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  --rs-modal-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  --rs-modal-border: 1px solid var(--rs-gray-100);
  --rs-form-errormessage-text: #fff;
  --rs-form-errormessage-bg: var(--rs-red-500);
  --rs-form-errormessage-border: var(--rs-red-500);
  --rs-picker-value: var(--rs-primary-500);
  --rs-picker-count-bg: var(--rs-primary-500);
  --rs-picker-count-text: var(--rs-gray-900);
  --rs-calendar-today-bg: var(--rs-primary-500);
  --rs-calendar-today-text: var(--rs-gray-900);
  --rs-calendar-range-bg: rgb(from var(--rs-primary-900) r g b/50%);
  --rs-calendar-time-unit-bg: var(--rs-gray-900);
  --rs-calendar-date-selected-text: var(--rs-gray-900);
  --rs-calendar-cell-selected-hover-bg: var(--rs-gray-50);
  --rs-popover-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}
@supports not (color: rgb(from white r g b)) {
  .rs-theme-high-contrast {
    --rs-bg-backdrop: rgba(15, 19, 26, 0.8);
    --rs-state-focus-outline: 3px solid rgba(255, 255, 0, 0.25);
    --rs-loader-ring: rgba(233, 235, 240, 0.3);
    --rs-loader-backdrop: rgba(15, 19, 26, 0.83);
    --rs-loader-ring-inverse: rgba(233, 235, 240, 0.8);
    --rs-loader-backdrop-inverse: hsla(0, 0%, 100%, 0.9);
    --rs-dropdown-item-bg-active: rgba(143, 153, 0, 0.2);
    --rs-slider-thumb-hover-shadow: 0 0 0 8px rgba(255, 255, 0, 0.25);
    --rs-uploader-overlay-bg: rgba(60, 63, 67, 0.8);
    --rs-carousel-indicator: hsla(0, 0%, 100%, 0.4);
    --rs-list-placeholder-bg: rgba(143, 153, 0, 0.2);
    --rs-calendar-range-bg: rgba(143, 153, 0, 0.5);
    --rs-placeholder-active: #484c50;
  }
}

.use-rsuite {
  font-family: var(--rs-font-family) !important;
  line-height: 1.42857143;
  color: #575757;
  color: var(--rs-text-primary);
  background-color: #fff;
  background-color: var(--rs-body);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;

  [class*="rs-"],
  [class*="rs-"]:after,
  [class*="rs-"]:before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  [hidden],
  template {
    display: none;
  }
  a:active,
  a:hover {
    outline: 0;
  }
  b,
  strong {
    font-weight: 700;
  }
  sub,
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }
  sup {
    top: -0.5em;
  }
  sub {
    bottom: -0.25em;
  }
  svg:not(:root) {
    overflow: hidden;
  }
  hr {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    height: 0;
    border: 0;
    border-top: 1px solid var(--rs-border-primary);
    margin-bottom: 20px;
    margin-top: 20px;
  }
  pre {
    overflow: auto;
  }
  button,
  input,
  optgroup,
  select,
  textarea {
    color: inherit;
    font: inherit;
    margin: 0;
  }
  button {
    line-height: 1.5rem !important;
    border-radius: 0;
    overflow: visible;
    border-radius: 4px !important;
  }
  button,
  select {
    text-transform: none;
  }
  button,
  html input[type="button"],
  input[type="reset"],
  input[type="submit"] {
    -webkit-appearance: button;
    cursor: pointer;
  }
  button[disabled],
  html input[disabled] {
    cursor: not-allowed;
  }
  button::-moz-focus-inner,
  input::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
  select.select-input {
    height: 40px;
  }
  .rs-table-cell-content .rs-picker-date input {
    height: 28px !important;
  }
  input {
    line-height: normal;
    height: 40px !important;
  }
  input[type="checkbox"],
  input[type="radio"] {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    height: auto;
  }
  textarea {
    overflow: auto;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  td,
  th {
    padding: 0;
  }
  [tabindex="-1"] {
    outline: none;
  }
  button,
  input[type="button"],
  input[type="reset"],
  input[type="submit"] {
    border-width: 0;
  }
  input::-ms-clear {
    display: none;
  }
  input[type="file"]::-ms-browse,
  input[type="file"]::-webkit-file-upload-button {
    background: transparent;
    border-width: 0;
    color: currentColor;
  }
  button,
  input,
  select,
  textarea {
    font-family: var(--rs-font-family) !important;
    font-size: inherit;
    line-height: inherit;
  }
  .help {
    font-size: 12px !important;
  }
  .is-size-6 {
    font-size: 16px !important;
  }
  .char-counter {
    font-size: 11.2px !important;
  }
  html {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  a {
    color: #1675e0;
    color: var(--rs-text-link);
    text-decoration: none;
    background-color: transparent;
  }
  a:focus,
  a:hover {
    color: #0a5dc2;
    color: var(--rs-text-link-hover);
  }
  a:active,
  a:focus,
  a:hover {
    text-decoration: underline;
  }
  a:active {
    color: #004299;
    color: var(--rs-text-link-active);
  }
  img {
    border: 0;
    vertical-align: middle;
  }
  [role="button"] {
    cursor: pointer;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: inherit;
    font-family: var(--rs-font-family) !important;
    font-weight: bolder;
    margin: 0;
  }
  h1 small,
  h2 small,
  h3 small,
  h4 small,
  h5 small,
  h6 small {
    color: #c5c6c7;
    color: var(--rs-text-disabled);
    font-weight: 400;
    line-height: 1;
  }
  h1 {
    font-size: 46px;
    line-height: 62px;
  }
  h2 {
    font-size: 36px;
    line-height: 50px;
  }
  h3 {
    font-size: 28px;
    line-height: 42px;
  }
  h4 {
    font-size: 22px;
    line-height: 34px;
  }
  h5 {
    font-size: 18px;
    line-height: 24px;
  }
  h6 {
    font-size: 16px;
    line-height: 22px;
  }
  p {
    margin: 0;
  }
  p + p {
    margin-top: 8px;
  }
  small {
    font-size: 85%;
  }
  mark {
    background-color: #fff9e6;
    background-color: var(--rs-bg-warning);
    font-weight: bolder;
    padding: 0.2em;
  }
  ol,
  ul {
    margin-bottom: 10px;
    margin-top: 0;
  }
  ol ol,
  ol ul,
  ul ol,
  ul ul {
    margin-bottom: 0;
  }
  dl {
    margin-bottom: 10px;
    margin-top: 0;
  }
  dd,
  dt {
    line-height: 1.42857143;
  }
  dt {
    font-weight: 700;
  }
  dd {
    margin-bottom: 10px;
    margin-left: 0;
  }
  @font-face {
    font-family: var(--rs-font-family) !important;
    src: local(-apple-system), local(BlinkMacSystemFont), local(system-ui);
  }
  @-webkit-keyframes slideInLeft {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
    to {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
  @keyframes slideInLeft {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
    to {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
  @-webkit-keyframes slideOutLeft {
    0% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
    to {
      opacity: 0;
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }
  @keyframes slideOutLeft {
    0% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
    to {
      opacity: 0;
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }
  @-webkit-keyframes slideInRight {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(100%, 0, 0);
      transform: translate3d(100%, 0, 0);
    }
    to {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
  @keyframes slideInRight {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(100%, 0, 0);
      transform: translate3d(100%, 0, 0);
    }
    to {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
  @-webkit-keyframes slideOutRight {
    0% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
    to {
      opacity: 0;
      -webkit-transform: translate3d(100%, 0, 0);
      transform: translate3d(100%, 0, 0);
    }
  }
  @keyframes slideOutRight {
    0% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
    to {
      opacity: 0;
      -webkit-transform: translate3d(100%, 0, 0);
      transform: translate3d(100%, 0, 0);
    }
  }
  @-webkit-keyframes slideInTop {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(0, -100%, 0);
      transform: translate3d(0, -100%, 0);
    }
    to {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
  @keyframes slideInTop {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(0, -100%, 0);
      transform: translate3d(0, -100%, 0);
    }
    to {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
  @-webkit-keyframes slideOutTop {
    0% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
    to {
      opacity: 0;
      -webkit-transform: translate3d(0, -100%, 0);
      transform: translate3d(0, -100%, 0);
    }
  }
  @keyframes slideOutTop {
    0% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
    to {
      opacity: 0;
      -webkit-transform: translate3d(0, -100%, 0);
      transform: translate3d(0, -100%, 0);
    }
  }
  @-webkit-keyframes slideInBottom {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(0, 100%, 0);
      transform: translate3d(0, 100%, 0);
    }
    to {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
  @keyframes slideInBottom {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(0, 100%, 0);
      transform: translate3d(0, 100%, 0);
    }
    to {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
  @-webkit-keyframes slideOutBottom {
    0% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
    to {
      opacity: 0;
      -webkit-transform: translate3d(0, 100%, 0);
      transform: translate3d(0, 100%, 0);
    }
  }
  @keyframes slideOutBottom {
    0% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
    to {
      opacity: 0;
      -webkit-transform: translate3d(0, 100%, 0);
      transform: translate3d(0, 100%, 0);
    }
  }
  .rs-anim-slide-out {
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-timing-function: cubic-bezier(0.4, 0, 1, 1);
    animation-timing-function: cubic-bezier(0.4, 0, 1, 1);
  }
  .rs-anim-slide-in {
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  .rs-anim-right.rs-anim-slide-in {
    -webkit-animation-name: slideInRight;
    animation-name: slideInRight;
  }
  .rs-anim-right.rs-anim-slide-out {
    -webkit-animation-name: slideOutRight;
    animation-name: slideOutRight;
  }
  .rs-anim-left.rs-anim-slide-in {
    -webkit-animation-name: slideInLeft;
    animation-name: slideInLeft;
  }
  .rs-anim-left.rs-anim-slide-out {
    -webkit-animation-name: slideOutLeft;
    animation-name: slideOutLeft;
  }
  .rs-anim-top.rs-anim-slide-in {
    -webkit-animation-name: slideInTop;
    animation-name: slideInTop;
  }
  .rs-anim-top.rs-anim-slide-out {
    -webkit-animation-name: slideOutTop;
    animation-name: slideOutTop;
  }
  .rs-anim-bottom.rs-anim-slide-in {
    -webkit-animation-name: slideInBottom;
    animation-name: slideInBottom;
  }
  .rs-anim-bottom.rs-anim-slide-out {
    -webkit-animation-name: slideOutBottom;
    animation-name: slideOutBottom;
  }
  .rs-anim-bounce-in {
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-name: bounceIn;
    animation-name: bounceIn;
    -webkit-animation-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
    animation-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
  }
  @-webkit-keyframes bounceIn {
    0% {
      opacity: 0;
      -webkit-transform: scale(0.8);
      transform: scale(0.8);
    }
    to {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  @keyframes bounceIn {
    0% {
      opacity: 0;
      -webkit-transform: scale(0.8);
      transform: scale(0.8);
    }
    to {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  .rs-anim-bounce-out {
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-name: bounceOut;
    animation-name: bounceOut;
    -webkit-animation-timing-function: cubic-bezier(0.4, 0, 1, 1);
    animation-timing-function: cubic-bezier(0.4, 0, 1, 1);
  }
  @-webkit-keyframes bounceOut {
    0% {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    to {
      opacity: 0;
      -webkit-transform: scale(0.8);
      transform: scale(0.8);
    }
  }
  @keyframes bounceOut {
    0% {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    to {
      opacity: 0;
      -webkit-transform: scale(0.8);
      transform: scale(0.8);
    }
  }
  .rs-anim-collapse {
    display: none;
  }
  .rs-anim-collapse.rs-anim-in {
    display: block;
  }
  tr.rs-anim-collapse.rs-anim-in {
    display: table-row;
  }
  tbody.rs-anim-collapse.rs-anim-in {
    display: table-row-group;
  }
  .rs-anim-collapsing {
    height: 0;
    overflow: hidden;
    position: relative;
    -webkit-transition: height 0.35s ease, width 0.35s ease, visibility 0.35s ease;
    transition: height 0.35s ease, width 0.35s ease, visibility 0.35s ease;
  }
  .rs-anim-fade {
    opacity: 0;
    pointer-events: none;
    -webkit-transition: opacity 0.15s linear;
    transition: opacity 0.15s linear;
  }
  .rs-anim-fade.rs-anim-in {
    opacity: 1;
    pointer-events: unset;
  }
  .rs-auto-complete-menu {
    overflow-y: auto;
  }
  .rs-auto-complete-menu ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .rs-auto-complete-item {
    clear: both;
    color: #575757;
    color: var(--rs-text-primary);
    cursor: pointer;
    display: block;
    font-weight: 400;
    line-height: 1.42857143;
    padding: 8px 12px;
    text-decoration: none;
    width: 100%;
  }
  .rs-auto-complete-item-focus,
  .rs-auto-complete-item:focus,
  .rs-auto-complete-item:hover {
    background-color: rgb(from #cce9ff r g b/50%);
    background-color: var(--rs-listbox-option-hover-bg);
    color: #1675e0;
    color: var(--rs-listbox-option-hover-text);
  }
  .rs-theme-high-contrast .rs-auto-complete-item-focus,
  .rs-theme-high-contrast .rs-auto-complete-item:focus,
  .rs-theme-high-contrast .rs-auto-complete-item:hover {
    color: #1675e0;
    color: var(--rs-listbox-option-hover-text);
    outline: 3px solid rgb(from #3498ff r g b/25%);
    outline: 3px solid var(--rs-color-focus-ring);
    outline-offset: -3px;
    outline-width: 2px;
    text-decoration: underline;
  }
  .rs-theme-high-contrast .rs-theme-high-contrast .rs-auto-complete-item-focus,
  .rs-theme-high-contrast .rs-theme-high-contrast .rs-auto-complete-item:focus,
  .rs-theme-high-contrast .rs-theme-high-contrast .rs-auto-complete-item:hover {
    outline-offset: 2px;
  }
  .rs-auto-complete .rs-input:focus::-webkit-textfield-decoration-container {
    pointer-events: none;
    visibility: hidden;
  }
  .rs-avatar {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #d9d9d9;
    background-color: var(--rs-avatar-bg);
    border-radius: 4px;
    color: #fff;
    color: var(--rs-avatar-text);
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    font-size: 18px;
    height: 40px;
    justify-content: center;
    overflow: hidden;
    position: relative;
    width: 40px;
  }
  .rs-avatar > .rs-avatar-image {
    height: 40px;
    line-height: 40px;
    width: 40px;
  }
  .rs-avatar > .rs-icon {
    font-size: 29px;
    height: 29px;
    vertical-align: middle;
  }
  .rs-avatar-image:before {
    background: #d9d9d9;
    background: var(--rs-avatar-bg);
    content: attr(alt);
    height: inherit;
    left: 0;
    overflow: hidden;
    padding: 0 2px;
    position: absolute;
    text-align: center;
    text-overflow: ellipsis;
    top: 0;
    white-space: nowrap;
    width: 100%;
  }
  .rs-avatar-circle {
    border-radius: 50%;
  }
  .rs-avatar-lg {
    font-size: 26px;
    height: 60px;
    width: 60px;
  }
  .rs-avatar-lg > .rs-icon {
    font-size: 43px;
    height: 43px;
  }
  .rs-avatar-lg > .rs-avatar-image {
    height: 60px;
    line-height: 60px;
    width: 60px;
  }
  .rs-avatar-sm {
    font-size: 14px;
    height: 30px;
    width: 30px;
  }
  .rs-avatar-sm > .rs-icon {
    font-size: 22px;
    height: 22px;
  }
  .rs-avatar-sm > .rs-avatar-image {
    height: 30px;
    line-height: 30px;
    width: 30px;
  }
  .rs-avatar-xs {
    font-size: 12px;
    height: 20px;
    width: 20px;
  }
  .rs-avatar-xs > .rs-icon {
    font-size: 16px;
    height: 16px;
  }
  .rs-avatar-xs > .rs-avatar-image {
    height: 20px;
    line-height: 20px;
    width: 20px;
  }
  .rs-avatar-group {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .rs-avatar-group-stack .rs-avatar {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    margin-right: -10px;
    -webkit-transition: margin 0.15s;
    transition: margin 0.15s;
  }
  .rs-avatar-group-stack .rs-avatar:hover,
  .rs-avatar-group-stack .rs-avatar:last-child {
    margin-right: 0;
  }
  .rs-badge-wrapper {
    position: relative;
  }
  .rs-badge {
    display: inline-block;
  }
  .rs-badge-content,
  .rs-badge-independent {
    background-color: #f44336;
    background-color: var(--rs-badge-bg);
    border-radius: 8px;
    color: #fff;
    color: var(--rs-badge-text);
    font-size: 12px;
    line-height: 16px;
    padding: 0 5px;
  }
  .rs-badge-independent.rs-badge-dot,
  .rs-badge-wrapper .rs-badge-content:empty {
    border-radius: 4px;
    height: 8px;
    padding: 0;
    width: 8px;
  }
  .rs-badge-wrapper .rs-badge-content {
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transform: translate(50%, -50%);
    transform: translate(50%, -50%);
    z-index: 1;
  }
  .rs-badge-red .rs-badge-content,
  .rs-badge-red.rs-badge-independent {
    background-color: #f44336;
    background-color: var(--rs-red-500);
  }
  .rs-badge-orange .rs-badge-content,
  .rs-badge-orange.rs-badge-independent {
    background-color: #fa8900;
    background-color: var(--rs-orange-500);
  }
  .rs-badge-yellow .rs-badge-content,
  .rs-badge-yellow.rs-badge-independent {
    background-color: #ffb300;
    background-color: var(--rs-yellow-500);
  }
  .rs-badge-green .rs-badge-content,
  .rs-badge-green.rs-badge-independent {
    background-color: #4caf50;
    background-color: var(--rs-green-500);
  }
  .rs-badge-cyan .rs-badge-content,
  .rs-badge-cyan.rs-badge-independent {
    background-color: #00bcd4;
    background-color: var(--rs-cyan-500);
  }
  .rs-badge-blue .rs-badge-content,
  .rs-badge-blue.rs-badge-independent {
    background-color: #2196f3;
    background-color: var(--rs-blue-500);
  }
  .rs-badge-violet .rs-badge-content,
  .rs-badge-violet.rs-badge-independent {
    background-color: #673ab7;
    background-color: var(--rs-violet-500);
  }
  .rs-breadcrumb {
    color: #8e8e93;
    color: var(--rs-text-secondary);
    font-size: 12px;
    margin-bottom: 20px;
  }
  .rs-breadcrumb-item {
    -webkit-transition: color 0.3s linear;
    transition: color 0.3s linear;
  }
  .rs-breadcrumb-item:focus {
    outline: 0;
  }
  .rs-breadcrumb-item-active {
    color: #272c36;
    color: var(--rs-breadcrumb-item-active-text);
  }
  .rs-breadcrumb-separator {
    margin: 0 4px;
  }
  .rs-btn {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    align-items: center;
    background-color: #f7f7fa;
    background-color: var(--rs-btn-default-bg);
    border: none;
    border: var(--rs-btn-default-border, none);
    border-radius: 6px;
    color: #575757;
    color: var(--rs-btn-default-text);
    cursor: pointer;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    font-size: 14px;
    font-weight: 400;
    justify-content: center;
    line-height: 20px;
    margin-bottom: 0;
    overflow: hidden;
    padding: 8px 12px;
    position: relative;
    text-align: center;
    text-decoration: none;
    -webkit-transition: color 0.15s ease-out, background-color 0.15s ease-out;
    transition: color 0.15s ease-out, background-color 0.15s ease-out;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;
  }
  .rs-theme-high-contrast .rs-btn {
    -webkit-transition: none;
    transition: none;
  }
  .rs-btn-ghost.rs-btn {
    padding: 7px 11px;
  }
  .rs-btn-icon.rs-btn {
    line-height: 16px;
    padding: 10px;
  }
  .rs-btn-icon.rs-btn > .rs-icon {
    font-size: 16px;
  }
  .rs-btn-icon-with-text.rs-btn {
    line-height: 20px;
  }
  .rs-btn-icon-with-text.rs-btn > .rs-icon {
    height: 36px;
    padding: 10px;
    width: 36px;
  }
  .rs-btn-icon-with-text.rs-btn.rs-btn-icon-placement-left {
    padding: 8px 12px 8px 48px;
  }
  .rs-btn-icon-with-text.rs-btn.rs-btn-icon-placement-left > .rs-icon {
    border-right: none;
    border-right: var(--rs-btn-default-border, none);
    left: 0;
  }
  .rs-btn-icon-with-text.rs-btn.rs-btn-icon-placement-right {
    padding: 8px 48px 8px 12px;
  }
  .rs-btn-icon-with-text.rs-btn.rs-btn-icon-placement-right > .rs-icon {
    border-left: none;
    border-left: var(--rs-btn-default-border, none);
    right: 0;
  }
  .rs-btn:focus-visible {
    outline: 3px solid rgb(from #3498ff r g b/25%);
    outline: 3px solid var(--rs-color-focus-ring);
  }
  .rs-theme-high-contrast .rs-btn:focus-visible {
    outline-offset: 2px;
  }
  .rs-btn:focus,
  .rs-btn:hover {
    background-color: #e5e5ea;
    background-color: var(--rs-btn-default-hover-bg);
    color: var(--rs-btn-default-hover-text);
    text-decoration: none;
  }
  .rs-btn.rs-btn-active,
  .rs-btn:active {
    background-color: #d9d9d9;
    background-color: var(--rs-btn-default-active-bg);
    color: #272c36;
    color: var(--rs-btn-default-active-text);
  }
  .rs-btn.rs-btn-disabled,
  .rs-btn:disabled {
    background-color: #f7f7fa;
    background-color: var(--rs-btn-default-disabled-bg);
    color: #c5c6c7;
    color: var(--rs-btn-default-disabled-text);
    cursor: not-allowed;
  }
  .rs-theme-high-contrast .rs-btn.rs-btn-disabled,
  .rs-theme-high-contrast .rs-btn:disabled {
    opacity: 0.5;
  }
  @media not all and (min-resolution: 0.001dpcm) {
    .rs-btn {
      -webkit-mask-image: -webkit-radial-gradient(#fff, #000);
    }
  }
  .rs-btn-start-icon {
    line-height: 0;
    margin-right: 5px;
  }
  .rs-btn-end-icon {
    line-height: 0;
    margin-left: 5px;
  }
  .rs-btn-primary {
    background-color: #3498ff;
    background-color: var(--rs-btn-primary-bg);
    border: none;
    color: #fff;
    color: var(--rs-btn-primary-text);
  }
  .rs-btn-primary:focus,
  .rs-btn-primary:hover {
    background-color: #2589f5;
    background-color: var(--rs-btn-primary-hover-bg);
    color: #fff;
    color: var(--rs-btn-primary-text);
  }
  .rs-btn-primary.rs-btn-active,
  .rs-btn-primary:active {
    background-color: #1675e0;
    background-color: var(--rs-btn-primary-active-bg);
    color: #fff;
    color: var(--rs-btn-primary-text);
  }
  .rs-btn-primary.rs-btn-disabled,
  .rs-btn-primary:disabled {
    background-color: #3498ff;
    background-color: var(--rs-btn-primary-bg);
    color: #fff;
    color: var(--rs-btn-primary-text);
    opacity: 0.3;
  }
  .rs-theme-high-contrast .rs-btn-primary.rs-btn-disabled,
  .rs-theme-high-contrast .rs-btn-primary:disabled {
    opacity: 0.5;
  }
  .rs-btn-subtle {
    background-color: transparent;
    border: none;
    color: #575757;
    color: var(--rs-btn-subtle-text);
  }
  .rs-btn-subtle:focus,
  .rs-btn-subtle:hover {
    background-color: #e5e5ea;
    background-color: var(--rs-btn-subtle-hover-bg);
    color: #575757;
    color: var(--rs-btn-subtle-hover-text);
  }
  .rs-btn-subtle.rs-btn-active,
  .rs-btn-subtle:active {
    background-color: #e5e5ea;
    background-color: var(--rs-btn-subtle-active-bg);
    color: #272c36;
    color: var(--rs-btn-subtle-active-text);
  }
  .rs-btn-subtle.rs-btn-disabled,
  .rs-btn-subtle:disabled {
    background: none;
    color: #c5c6c7;
    color: var(--rs-btn-subtle-disabled-text);
  }
  .rs-theme-high-contrast .rs-btn-subtle.rs-btn-disabled,
  .rs-theme-high-contrast .rs-btn-subtle:disabled {
    opacity: 0.5;
  }
  .rs-btn-link {
    background-color: transparent;
    border: none;
    color: #1675e0;
    color: var(--rs-btn-link-text);
  }
  .rs-btn-link:focus,
  .rs-btn-link:hover {
    background-color: transparent;
    color: #0a5dc2;
    color: var(--rs-btn-link-hover-text);
    text-decoration: underline;
  }
  .rs-btn-link.rs-btn-active,
  .rs-btn-link:active {
    background-color: transparent;
    color: #004299;
    color: var(--rs-btn-link-active-text);
  }
  .rs-btn-link.rs-btn-disabled,
  .rs-btn-link:disabled {
    background-color: transparent;
    color: #0a5dc2;
    color: var(--rs-btn-link-hover-text);
    opacity: 0.3;
    text-decoration: none;
  }
  .rs-theme-high-contrast .rs-btn-link.rs-btn-disabled,
  .rs-theme-high-contrast .rs-btn-link:disabled {
    opacity: 0.5;
  }
  .rs-btn-ghost {
    background-color: transparent;
    border: 1px solid #1675e0;
    border: 1px solid var(--rs-btn-ghost-border);
    color: #1675e0;
    color: var(--rs-btn-ghost-text);
  }
  .rs-btn-ghost:focus,
  .rs-btn-ghost:hover {
    background-color: transparent;
    border-color: #0a5dc2;
    border-color: var(--rs-btn-ghost-hover-border);
    -webkit-box-shadow: 0 0 0 1px #0a5dc2;
    -webkit-box-shadow: 0 0 0 1px var(--rs-btn-ghost-hover-border);
    box-shadow: 0 0 0 1px #0a5dc2;
    box-shadow: 0 0 0 1px var(--rs-btn-ghost-hover-border);
    color: #0a5dc2;
    color: var(--rs-btn-ghost-hover-text);
  }
  .rs-btn-ghost.rs-btn-active,
  .rs-btn-ghost:active {
    background-color: transparent;
    border-color: #004299;
    border-color: var(--rs-btn-ghost-active-border);
    color: #004299;
    color: var(--rs-btn-ghost-active-text);
  }
  .rs-btn-ghost.rs-btn-disabled,
  .rs-btn-ghost:disabled {
    background-color: transparent;
    border-color: #1675e0;
    border-color: var(--rs-btn-ghost-border);
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #1675e0;
    color: var(--rs-btn-ghost-text);
    opacity: 0.3;
  }
  .rs-theme-high-contrast .rs-btn-ghost.rs-btn-disabled,
  .rs-theme-high-contrast .rs-btn-ghost:disabled {
    opacity: 0.5;
  }
  .rs-btn-red {
    --rs-btn-primary-bg: var(--rs-red-500);
    --rs-btn-primary-hover-bg: var(--rs-red-700);
    --rs-btn-primary-active-bg: var(--rs-red-800);
    --rs-btn-subtle-hover-bg: var(--rs-red-500);
    --rs-btn-subtle-hover-text: #e5e5ea;
    --rs-btn-subtle-active-bg: var(--rs-red-600);
    --rs-btn-subtle-active-text: #575757;
    --rs-btn-ghost-border: var(--rs-red-700);
    --rs-btn-ghost-text: var(--rs-red-700);
    --rs-btn-ghost-hover-border: var(--rs-red-900);
    --rs-btn-ghost-hover-text: var(--rs-red-800);
    --rs-btn-ghost-active-border: var(--rs-red-900);
    --rs-btn-ghost-active-text: var(--rs-red-900);
    --rs-btn-link-text: var(--rs-red-700);
    --rs-btn-link-hover-text: var(--rs-red-800);
    --rs-btn-link-active-text: var(--rs-red-900);
    --rs-iconbtn-primary-addon: var(--rs-red-600);
    --rs-iconbtn-primary-activated-addon: var(--rs-red-800);
    --rs-iconbtn-primary-pressed-addon: var(--rs-red-900);
  }
  .rs-theme-dark .rs-btn-red {
    --rs-btn-primary-bg: var(--rs-red-700);
    --rs-btn-primary-hover-bg: var(--rs-red-500);
    --rs-btn-primary-active-bg: var(--rs-red-400);
    --rs-btn-subtle-hover-bg: var(--rs-red-600);
    --rs-btn-subtle-hover-text: #fff;
    --rs-btn-subtle-active-bg: var(--rs-red-400);
    --rs-btn-subtle-active-text: #fff;
    --rs-btn-ghost-border: var(--rs-red-500);
    --rs-btn-ghost-text: var(--rs-red-500);
    --rs-btn-ghost-hover-border: var(--rs-red-400);
    --rs-btn-ghost-hover-text: var(--rs-red-400);
    --rs-btn-ghost-active-border: var(--rs-red-200);
    --rs-btn-ghost-active-text: var(--rs-red-200);
    --rs-btn-link-text: var(--rs-red-500);
    --rs-btn-link-hover-text: var(--rs-red-400);
    --rs-btn-link-active-text: var(--rs-red-200);
    --rs-iconbtn-primary-addon: var(--rs-red-600);
    --rs-iconbtn-primary-activated-addon: var(--rs-red-400);
    --rs-iconbtn-primary-pressed-addon: var(--rs-red-300);
  }
  .rs-theme-high-contrast .rs-btn-red {
    --rs-btn-primary-bg: var(--rs-red-700);
    --rs-btn-primary-hover-bg: var(--rs-red-600);
    --rs-btn-primary-active-bg: var(--rs-red-400);
    --rs-btn-subtle-hover-bg: var(--rs-red-600);
    --rs-btn-subtle-hover-text: var(--rs-gray-900);
    --rs-btn-subtle-active-bg: var(--rs-red-400);
    --rs-btn-subtle-active-text: var(--rs-gray-900);
    --rs-btn-ghost-border: var(--rs-red-500);
    --rs-btn-ghost-text: var(--rs-red-500);
    --rs-btn-ghost-hover-border: var(--rs-red-400);
    --rs-btn-ghost-hover-text: var(--rs-red-400);
    --rs-btn-ghost-active-border: var(--rs-red-200);
    --rs-btn-ghost-active-text: var(--rs-red-200);
    --rs-btn-link-text: var(--rs-red-500);
    --rs-btn-link-hover-text: var(--rs-red-400);
    --rs-btn-link-active-text: var(--rs-red-200);
  }
  .rs-btn-orange {
    --rs-btn-primary-bg: var(--rs-orange-500);
    --rs-btn-primary-hover-bg: var(--rs-orange-700);
    --rs-btn-primary-active-bg: var(--rs-orange-800);
    --rs-btn-subtle-hover-bg: var(--rs-orange-500);
    --rs-btn-subtle-hover-text: #e5e5ea;
    --rs-btn-subtle-active-bg: var(--rs-orange-600);
    --rs-btn-subtle-active-text: #575757;
    --rs-btn-ghost-border: var(--rs-orange-700);
    --rs-btn-ghost-text: var(--rs-orange-700);
    --rs-btn-ghost-hover-border: var(--rs-orange-900);
    --rs-btn-ghost-hover-text: var(--rs-orange-800);
    --rs-btn-ghost-active-border: var(--rs-orange-900);
    --rs-btn-ghost-active-text: var(--rs-orange-900);
    --rs-btn-link-text: var(--rs-orange-700);
    --rs-btn-link-hover-text: var(--rs-orange-800);
    --rs-btn-link-active-text: var(--rs-orange-900);
    --rs-iconbtn-primary-addon: var(--rs-orange-600);
    --rs-iconbtn-primary-activated-addon: var(--rs-orange-800);
    --rs-iconbtn-primary-pressed-addon: var(--rs-orange-900);
  }
  .rs-theme-dark .rs-btn-orange {
    --rs-btn-primary-bg: var(--rs-orange-700);
    --rs-btn-primary-hover-bg: var(--rs-orange-500);
    --rs-btn-primary-active-bg: var(--rs-orange-400);
    --rs-btn-subtle-hover-bg: var(--rs-orange-600);
    --rs-btn-subtle-hover-text: #fff;
    --rs-btn-subtle-active-bg: var(--rs-orange-400);
    --rs-btn-subtle-active-text: #fff;
    --rs-btn-ghost-border: var(--rs-orange-500);
    --rs-btn-ghost-text: var(--rs-orange-500);
    --rs-btn-ghost-hover-border: var(--rs-orange-400);
    --rs-btn-ghost-hover-text: var(--rs-orange-400);
    --rs-btn-ghost-active-border: var(--rs-orange-200);
    --rs-btn-ghost-active-text: var(--rs-orange-200);
    --rs-btn-link-text: var(--rs-orange-500);
    --rs-btn-link-hover-text: var(--rs-orange-400);
    --rs-btn-link-active-text: var(--rs-orange-200);
    --rs-iconbtn-primary-addon: var(--rs-orange-600);
    --rs-iconbtn-primary-activated-addon: var(--rs-orange-400);
    --rs-iconbtn-primary-pressed-addon: var(--rs-orange-300);
  }
  .rs-theme-high-contrast .rs-btn-orange {
    --rs-btn-primary-bg: var(--rs-orange-700);
    --rs-btn-primary-hover-bg: var(--rs-orange-600);
    --rs-btn-primary-active-bg: var(--rs-orange-400);
    --rs-btn-subtle-hover-bg: var(--rs-orange-600);
    --rs-btn-subtle-hover-text: var(--rs-gray-900);
    --rs-btn-subtle-active-bg: var(--rs-orange-400);
    --rs-btn-subtle-active-text: var(--rs-gray-900);
    --rs-btn-ghost-border: var(--rs-orange-500);
    --rs-btn-ghost-text: var(--rs-orange-500);
    --rs-btn-ghost-hover-border: var(--rs-orange-400);
    --rs-btn-ghost-hover-text: var(--rs-orange-400);
    --rs-btn-ghost-active-border: var(--rs-orange-200);
    --rs-btn-ghost-active-text: var(--rs-orange-200);
    --rs-btn-link-text: var(--rs-orange-500);
    --rs-btn-link-hover-text: var(--rs-orange-400);
    --rs-btn-link-active-text: var(--rs-orange-200);
  }
  .rs-btn-yellow {
    --rs-btn-primary-bg: var(--rs-yellow-500);
    --rs-btn-primary-hover-bg: var(--rs-yellow-700);
    --rs-btn-primary-active-bg: var(--rs-yellow-800);
    --rs-btn-subtle-hover-bg: var(--rs-yellow-500);
    --rs-btn-subtle-hover-text: #e5e5ea;
    --rs-btn-subtle-active-bg: var(--rs-yellow-600);
    --rs-btn-subtle-active-text: #575757;
    --rs-btn-ghost-border: var(--rs-yellow-700);
    --rs-btn-ghost-text: var(--rs-yellow-700);
    --rs-btn-ghost-hover-border: var(--rs-yellow-900);
    --rs-btn-ghost-hover-text: var(--rs-yellow-800);
    --rs-btn-ghost-active-border: var(--rs-yellow-900);
    --rs-btn-ghost-active-text: var(--rs-yellow-900);
    --rs-btn-link-text: var(--rs-yellow-700);
    --rs-btn-link-hover-text: var(--rs-yellow-800);
    --rs-btn-link-active-text: var(--rs-yellow-900);
    --rs-iconbtn-primary-addon: var(--rs-yellow-600);
    --rs-iconbtn-primary-activated-addon: var(--rs-yellow-800);
    --rs-iconbtn-primary-pressed-addon: var(--rs-yellow-900);
  }
  .rs-theme-dark .rs-btn-yellow {
    --rs-btn-primary-bg: var(--rs-yellow-700);
    --rs-btn-primary-hover-bg: var(--rs-yellow-500);
    --rs-btn-primary-active-bg: var(--rs-yellow-400);
    --rs-btn-subtle-hover-bg: var(--rs-yellow-600);
    --rs-btn-subtle-hover-text: #fff;
    --rs-btn-subtle-active-bg: var(--rs-yellow-400);
    --rs-btn-subtle-active-text: #fff;
    --rs-btn-ghost-border: var(--rs-yellow-500);
    --rs-btn-ghost-text: var(--rs-yellow-500);
    --rs-btn-ghost-hover-border: var(--rs-yellow-400);
    --rs-btn-ghost-hover-text: var(--rs-yellow-400);
    --rs-btn-ghost-active-border: var(--rs-yellow-200);
    --rs-btn-ghost-active-text: var(--rs-yellow-200);
    --rs-btn-link-text: var(--rs-yellow-500);
    --rs-btn-link-hover-text: var(--rs-yellow-400);
    --rs-btn-link-active-text: var(--rs-yellow-200);
    --rs-iconbtn-primary-addon: var(--rs-yellow-600);
    --rs-iconbtn-primary-activated-addon: var(--rs-yellow-400);
    --rs-iconbtn-primary-pressed-addon: var(--rs-yellow-300);
  }
  .rs-theme-high-contrast .rs-btn-yellow {
    --rs-btn-primary-bg: var(--rs-yellow-700);
    --rs-btn-primary-hover-bg: var(--rs-yellow-600);
    --rs-btn-primary-active-bg: var(--rs-yellow-400);
    --rs-btn-subtle-hover-bg: var(--rs-yellow-600);
    --rs-btn-subtle-hover-text: var(--rs-gray-900);
    --rs-btn-subtle-active-bg: var(--rs-yellow-400);
    --rs-btn-subtle-active-text: var(--rs-gray-900);
    --rs-btn-ghost-border: var(--rs-yellow-500);
    --rs-btn-ghost-text: var(--rs-yellow-500);
    --rs-btn-ghost-hover-border: var(--rs-yellow-400);
    --rs-btn-ghost-hover-text: var(--rs-yellow-400);
    --rs-btn-ghost-active-border: var(--rs-yellow-200);
    --rs-btn-ghost-active-text: var(--rs-yellow-200);
    --rs-btn-link-text: var(--rs-yellow-500);
    --rs-btn-link-hover-text: var(--rs-yellow-400);
    --rs-btn-link-active-text: var(--rs-yellow-200);
  }
  .rs-btn-green {
    --rs-btn-primary-bg: var(--rs-green-500);
    --rs-btn-primary-hover-bg: var(--rs-green-700);
    --rs-btn-primary-active-bg: var(--rs-green-800);
    --rs-btn-subtle-hover-bg: var(--rs-green-500);
    --rs-btn-subtle-hover-text: #e5e5ea;
    --rs-btn-subtle-active-bg: var(--rs-green-600);
    --rs-btn-subtle-active-text: #575757;
    --rs-btn-ghost-border: var(--rs-green-700);
    --rs-btn-ghost-text: var(--rs-green-700);
    --rs-btn-ghost-hover-border: var(--rs-green-900);
    --rs-btn-ghost-hover-text: var(--rs-green-800);
    --rs-btn-ghost-active-border: var(--rs-green-900);
    --rs-btn-ghost-active-text: var(--rs-green-900);
    --rs-btn-link-text: var(--rs-green-700);
    --rs-btn-link-hover-text: var(--rs-green-800);
    --rs-btn-link-active-text: var(--rs-green-900);
    --rs-iconbtn-primary-addon: var(--rs-green-600);
    --rs-iconbtn-primary-activated-addon: var(--rs-green-800);
    --rs-iconbtn-primary-pressed-addon: var(--rs-green-900);
  }
  .rs-theme-dark .rs-btn-green {
    --rs-btn-primary-bg: var(--rs-green-700);
    --rs-btn-primary-hover-bg: var(--rs-green-500);
    --rs-btn-primary-active-bg: var(--rs-green-400);
    --rs-btn-subtle-hover-bg: var(--rs-green-600);
    --rs-btn-subtle-hover-text: #fff;
    --rs-btn-subtle-active-bg: var(--rs-green-400);
    --rs-btn-subtle-active-text: #fff;
    --rs-btn-ghost-border: var(--rs-green-500);
    --rs-btn-ghost-text: var(--rs-green-500);
    --rs-btn-ghost-hover-border: var(--rs-green-400);
    --rs-btn-ghost-hover-text: var(--rs-green-400);
    --rs-btn-ghost-active-border: var(--rs-green-200);
    --rs-btn-ghost-active-text: var(--rs-green-200);
    --rs-btn-link-text: var(--rs-green-500);
    --rs-btn-link-hover-text: var(--rs-green-400);
    --rs-btn-link-active-text: var(--rs-green-200);
    --rs-iconbtn-primary-addon: var(--rs-green-600);
    --rs-iconbtn-primary-activated-addon: var(--rs-green-400);
    --rs-iconbtn-primary-pressed-addon: var(--rs-green-300);
  }
  .rs-theme-high-contrast .rs-btn-green {
    --rs-btn-primary-bg: var(--rs-green-700);
    --rs-btn-primary-hover-bg: var(--rs-green-600);
    --rs-btn-primary-active-bg: var(--rs-green-400);
    --rs-btn-subtle-hover-bg: var(--rs-green-600);
    --rs-btn-subtle-hover-text: var(--rs-gray-900);
    --rs-btn-subtle-active-bg: var(--rs-green-400);
    --rs-btn-subtle-active-text: var(--rs-gray-900);
    --rs-btn-ghost-border: var(--rs-green-500);
    --rs-btn-ghost-text: var(--rs-green-500);
    --rs-btn-ghost-hover-border: var(--rs-green-400);
    --rs-btn-ghost-hover-text: var(--rs-green-400);
    --rs-btn-ghost-active-border: var(--rs-green-200);
    --rs-btn-ghost-active-text: var(--rs-green-200);
    --rs-btn-link-text: var(--rs-green-500);
    --rs-btn-link-hover-text: var(--rs-green-400);
    --rs-btn-link-active-text: var(--rs-green-200);
  }
  .rs-btn-cyan {
    --rs-btn-primary-bg: var(--rs-cyan-500);
    --rs-btn-primary-hover-bg: var(--rs-cyan-700);
    --rs-btn-primary-active-bg: var(--rs-cyan-800);
    --rs-btn-subtle-hover-bg: var(--rs-cyan-500);
    --rs-btn-subtle-hover-text: #e5e5ea;
    --rs-btn-subtle-active-bg: var(--rs-cyan-600);
    --rs-btn-subtle-active-text: #575757;
    --rs-btn-ghost-border: var(--rs-cyan-700);
    --rs-btn-ghost-text: var(--rs-cyan-700);
    --rs-btn-ghost-hover-border: var(--rs-cyan-900);
    --rs-btn-ghost-hover-text: var(--rs-cyan-800);
    --rs-btn-ghost-active-border: var(--rs-cyan-900);
    --rs-btn-ghost-active-text: var(--rs-cyan-900);
    --rs-btn-link-text: var(--rs-cyan-700);
    --rs-btn-link-hover-text: var(--rs-cyan-800);
    --rs-btn-link-active-text: var(--rs-cyan-900);
    --rs-iconbtn-primary-addon: var(--rs-cyan-600);
    --rs-iconbtn-primary-activated-addon: var(--rs-cyan-800);
    --rs-iconbtn-primary-pressed-addon: var(--rs-cyan-900);
  }
  .rs-theme-dark .rs-btn-cyan {
    --rs-btn-primary-bg: var(--rs-cyan-700);
    --rs-btn-primary-hover-bg: var(--rs-cyan-500);
    --rs-btn-primary-active-bg: var(--rs-cyan-400);
    --rs-btn-subtle-hover-bg: var(--rs-cyan-600);
    --rs-btn-subtle-hover-text: #fff;
    --rs-btn-subtle-active-bg: var(--rs-cyan-400);
    --rs-btn-subtle-active-text: #fff;
    --rs-btn-ghost-border: var(--rs-cyan-500);
    --rs-btn-ghost-text: var(--rs-cyan-500);
    --rs-btn-ghost-hover-border: var(--rs-cyan-400);
    --rs-btn-ghost-hover-text: var(--rs-cyan-400);
    --rs-btn-ghost-active-border: var(--rs-cyan-200);
    --rs-btn-ghost-active-text: var(--rs-cyan-200);
    --rs-btn-link-text: var(--rs-cyan-500);
    --rs-btn-link-hover-text: var(--rs-cyan-400);
    --rs-btn-link-active-text: var(--rs-cyan-200);
    --rs-iconbtn-primary-addon: var(--rs-cyan-600);
    --rs-iconbtn-primary-activated-addon: var(--rs-cyan-400);
    --rs-iconbtn-primary-pressed-addon: var(--rs-cyan-300);
  }
  .rs-theme-high-contrast .rs-btn-cyan {
    --rs-btn-primary-bg: var(--rs-cyan-700);
    --rs-btn-primary-hover-bg: var(--rs-cyan-600);
    --rs-btn-primary-active-bg: var(--rs-cyan-400);
    --rs-btn-subtle-hover-bg: var(--rs-cyan-600);
    --rs-btn-subtle-hover-text: var(--rs-gray-900);
    --rs-btn-subtle-active-bg: var(--rs-cyan-400);
    --rs-btn-subtle-active-text: var(--rs-gray-900);
    --rs-btn-ghost-border: var(--rs-cyan-500);
    --rs-btn-ghost-text: var(--rs-cyan-500);
    --rs-btn-ghost-hover-border: var(--rs-cyan-400);
    --rs-btn-ghost-hover-text: var(--rs-cyan-400);
    --rs-btn-ghost-active-border: var(--rs-cyan-200);
    --rs-btn-ghost-active-text: var(--rs-cyan-200);
    --rs-btn-link-text: var(--rs-cyan-500);
    --rs-btn-link-hover-text: var(--rs-cyan-400);
    --rs-btn-link-active-text: var(--rs-cyan-200);
  }
  .rs-btn-blue {
    --rs-btn-primary-bg: var(--rs-blue-500);
    --rs-btn-primary-hover-bg: var(--rs-blue-700);
    --rs-btn-primary-active-bg: var(--rs-blue-800);
    --rs-btn-subtle-hover-bg: var(--rs-blue-500);
    --rs-btn-subtle-hover-text: #e5e5ea;
    --rs-btn-subtle-active-bg: var(--rs-blue-600);
    --rs-btn-subtle-active-text: #575757;
    --rs-btn-ghost-border: var(--rs-blue-700);
    --rs-btn-ghost-text: var(--rs-blue-700);
    --rs-btn-ghost-hover-border: var(--rs-blue-900);
    --rs-btn-ghost-hover-text: var(--rs-blue-800);
    --rs-btn-ghost-active-border: var(--rs-blue-900);
    --rs-btn-ghost-active-text: var(--rs-blue-900);
    --rs-btn-link-text: var(--rs-blue-700);
    --rs-btn-link-hover-text: var(--rs-blue-800);
    --rs-btn-link-active-text: var(--rs-blue-900);
    --rs-iconbtn-primary-addon: var(--rs-blue-600);
    --rs-iconbtn-primary-activated-addon: var(--rs-blue-800);
    --rs-iconbtn-primary-pressed-addon: var(--rs-blue-900);
  }
  .rs-theme-dark .rs-btn-blue {
    --rs-btn-primary-bg: var(--rs-blue-700);
    --rs-btn-primary-hover-bg: var(--rs-blue-500);
    --rs-btn-primary-active-bg: var(--rs-blue-400);
    --rs-btn-subtle-hover-bg: var(--rs-blue-600);
    --rs-btn-subtle-hover-text: #fff;
    --rs-btn-subtle-active-bg: var(--rs-blue-400);
    --rs-btn-subtle-active-text: #fff;
    --rs-btn-ghost-border: var(--rs-blue-500);
    --rs-btn-ghost-text: var(--rs-blue-500);
    --rs-btn-ghost-hover-border: var(--rs-blue-400);
    --rs-btn-ghost-hover-text: var(--rs-blue-400);
    --rs-btn-ghost-active-border: var(--rs-blue-200);
    --rs-btn-ghost-active-text: var(--rs-blue-200);
    --rs-btn-link-text: var(--rs-blue-500);
    --rs-btn-link-hover-text: var(--rs-blue-400);
    --rs-btn-link-active-text: var(--rs-blue-200);
    --rs-iconbtn-primary-addon: var(--rs-blue-600);
    --rs-iconbtn-primary-activated-addon: var(--rs-blue-400);
    --rs-iconbtn-primary-pressed-addon: var(--rs-blue-300);
  }
  .rs-theme-high-contrast .rs-btn-blue {
    --rs-btn-primary-bg: var(--rs-blue-700);
    --rs-btn-primary-hover-bg: var(--rs-blue-600);
    --rs-btn-primary-active-bg: var(--rs-blue-400);
    --rs-btn-subtle-hover-bg: var(--rs-blue-600);
    --rs-btn-subtle-hover-text: var(--rs-gray-900);
    --rs-btn-subtle-active-bg: var(--rs-blue-400);
    --rs-btn-subtle-active-text: var(--rs-gray-900);
    --rs-btn-ghost-border: var(--rs-blue-500);
    --rs-btn-ghost-text: var(--rs-blue-500);
    --rs-btn-ghost-hover-border: var(--rs-blue-400);
    --rs-btn-ghost-hover-text: var(--rs-blue-400);
    --rs-btn-ghost-active-border: var(--rs-blue-200);
    --rs-btn-ghost-active-text: var(--rs-blue-200);
    --rs-btn-link-text: var(--rs-blue-500);
    --rs-btn-link-hover-text: var(--rs-blue-400);
    --rs-btn-link-active-text: var(--rs-blue-200);
  }
  .rs-btn-violet {
    --rs-btn-primary-bg: var(--rs-violet-500);
    --rs-btn-primary-hover-bg: var(--rs-violet-700);
    --rs-btn-primary-active-bg: var(--rs-violet-800);
    --rs-btn-subtle-hover-bg: var(--rs-violet-500);
    --rs-btn-subtle-hover-text: #e5e5ea;
    --rs-btn-subtle-active-bg: var(--rs-violet-600);
    --rs-btn-subtle-active-text: #575757;
    --rs-btn-ghost-border: var(--rs-violet-700);
    --rs-btn-ghost-text: var(--rs-violet-700);
    --rs-btn-ghost-hover-border: var(--rs-violet-900);
    --rs-btn-ghost-hover-text: var(--rs-violet-800);
    --rs-btn-ghost-active-border: var(--rs-violet-900);
    --rs-btn-ghost-active-text: var(--rs-violet-900);
    --rs-btn-link-text: var(--rs-violet-700);
    --rs-btn-link-hover-text: var(--rs-violet-800);
    --rs-btn-link-active-text: var(--rs-violet-900);
    --rs-iconbtn-primary-addon: var(--rs-violet-600);
    --rs-iconbtn-primary-activated-addon: var(--rs-violet-800);
    --rs-iconbtn-primary-pressed-addon: var(--rs-violet-900);
  }
  .rs-theme-dark .rs-btn-violet {
    --rs-btn-primary-bg: var(--rs-violet-700);
    --rs-btn-primary-hover-bg: var(--rs-violet-500);
    --rs-btn-primary-active-bg: var(--rs-violet-400);
    --rs-btn-subtle-hover-bg: var(--rs-violet-600);
    --rs-btn-subtle-hover-text: #fff;
    --rs-btn-subtle-active-bg: var(--rs-violet-400);
    --rs-btn-subtle-active-text: #fff;
    --rs-btn-ghost-border: var(--rs-violet-500);
    --rs-btn-ghost-text: var(--rs-violet-500);
    --rs-btn-ghost-hover-border: var(--rs-violet-400);
    --rs-btn-ghost-hover-text: var(--rs-violet-400);
    --rs-btn-ghost-active-border: var(--rs-violet-200);
    --rs-btn-ghost-active-text: var(--rs-violet-200);
    --rs-btn-link-text: var(--rs-violet-500);
    --rs-btn-link-hover-text: var(--rs-violet-400);
    --rs-btn-link-active-text: var(--rs-violet-200);
    --rs-iconbtn-primary-addon: var(--rs-violet-600);
    --rs-iconbtn-primary-activated-addon: var(--rs-violet-400);
    --rs-iconbtn-primary-pressed-addon: var(--rs-violet-300);
  }
  .rs-theme-high-contrast .rs-btn-violet {
    --rs-btn-primary-bg: var(--rs-violet-700);
    --rs-btn-primary-hover-bg: var(--rs-violet-600);
    --rs-btn-primary-active-bg: var(--rs-violet-400);
    --rs-btn-subtle-hover-bg: var(--rs-violet-600);
    --rs-btn-subtle-hover-text: var(--rs-gray-900);
    --rs-btn-subtle-active-bg: var(--rs-violet-400);
    --rs-btn-subtle-active-text: var(--rs-gray-900);
    --rs-btn-ghost-border: var(--rs-violet-500);
    --rs-btn-ghost-text: var(--rs-violet-500);
    --rs-btn-ghost-hover-border: var(--rs-violet-400);
    --rs-btn-ghost-hover-text: var(--rs-violet-400);
    --rs-btn-ghost-active-border: var(--rs-violet-200);
    --rs-btn-ghost-active-text: var(--rs-violet-200);
    --rs-btn-link-text: var(--rs-violet-500);
    --rs-btn-link-hover-text: var(--rs-violet-400);
    --rs-btn-link-active-text: var(--rs-violet-200);
  }
  .rs-btn-lg {
    font-size: 16px;
    line-height: 22px;
    padding: 10px 16px;
  }
  .rs-btn-ghost.rs-btn-lg {
    padding: 9px 15px;
  }
  .rs-btn-icon.rs-btn-lg {
    line-height: 20px;
    padding: 11px;
  }
  .rs-btn-icon.rs-btn-lg > .rs-icon {
    font-size: 20px;
  }
  .rs-btn-icon-with-text.rs-btn-lg {
    line-height: 22px;
  }
  .rs-btn-icon-with-text.rs-btn-lg > .rs-icon {
    height: 42px;
    padding: 11px;
    width: 42px;
  }
  .rs-btn-icon-with-text.rs-btn-lg.rs-btn-icon-placement-left {
    padding: 10px 16px 10px 58px;
  }
  .rs-btn-icon-with-text.rs-btn-lg.rs-btn-icon-placement-left > .rs-icon {
    border-right: none;
    border-right: var(--rs-btn-default-border, none);
    left: 0;
  }
  .rs-btn-icon-with-text.rs-btn-lg.rs-btn-icon-placement-right {
    padding: 10px 58px 10px 16px;
  }
  .rs-btn-icon-with-text.rs-btn-lg.rs-btn-icon-placement-right > .rs-icon {
    border-left: none;
    border-left: var(--rs-btn-default-border, none);
    right: 0;
  }
  .rs-btn-md {
    font-size: 14px;
    line-height: 20px;
    padding: 8px 12px;
  }
  .rs-btn-ghost.rs-btn-md {
    padding: 7px 11px;
  }
  .rs-btn-icon.rs-btn-md {
    line-height: 16px;
    padding: 10px;
  }
  .rs-btn-icon.rs-btn-md > .rs-icon {
    font-size: 16px;
  }
  .rs-btn-icon-with-text.rs-btn-md {
    line-height: 20px;
  }
  .rs-btn-icon-with-text.rs-btn-md > .rs-icon {
    height: 36px;
    padding: 10px;
    width: 36px;
  }
  .rs-btn-icon-with-text.rs-btn-md.rs-btn-icon-placement-left {
    padding: 8px 12px 8px 48px;
  }
  .rs-btn-icon-with-text.rs-btn-md.rs-btn-icon-placement-left > .rs-icon {
    border-right: none;
    border-right: var(--rs-btn-default-border, none);
    left: 0;
  }
  .rs-btn-icon-with-text.rs-btn-md.rs-btn-icon-placement-right {
    padding: 8px 48px 8px 12px;
  }
  .rs-btn-icon-with-text.rs-btn-md.rs-btn-icon-placement-right > .rs-icon {
    border-left: none;
    border-left: var(--rs-btn-default-border, none);
    right: 0;
  }
  .rs-btn-sm {
    font-size: 14px;
    line-height: 20px;
    padding: 5px 10px;
  }
  .rs-btn-ghost.rs-btn-sm {
    padding: 4px 9px;
  }
  .rs-btn-icon.rs-btn-sm {
    line-height: 16px;
    padding: 7px;
  }
  .rs-btn-icon.rs-btn-sm > .rs-icon {
    font-size: 16px;
  }
  .rs-btn-icon-with-text.rs-btn-sm {
    line-height: 20px;
  }
  .rs-btn-icon-with-text.rs-btn-sm > .rs-icon {
    height: 30px;
    padding: 7px;
    width: 30px;
  }
  .rs-btn-icon-with-text.rs-btn-sm.rs-btn-icon-placement-left {
    padding: 5px 10px 5px 40px;
  }
  .rs-btn-icon-with-text.rs-btn-sm.rs-btn-icon-placement-left > .rs-icon {
    border-right: none;
    border-right: var(--rs-btn-default-border, none);
    left: 0;
  }
  .rs-btn-icon-with-text.rs-btn-sm.rs-btn-icon-placement-right {
    padding: 5px 40px 5px 10px;
  }
  .rs-btn-icon-with-text.rs-btn-sm.rs-btn-icon-placement-right > .rs-icon {
    border-left: none;
    border-left: var(--rs-btn-default-border, none);
    right: 0;
  }
  .rs-btn-xs {
    font-size: 12px;
    line-height: 20px;
    padding: 2px 8px;
  }
  .rs-btn-ghost.rs-btn-xs {
    padding: 1px 7px;
  }
  .rs-btn-icon.rs-btn-xs {
    line-height: 12px;
    padding: 6px;
  }
  .rs-btn-icon.rs-btn-xs > .rs-icon {
    font-size: 12px;
  }
  .rs-btn-icon-with-text.rs-btn-xs {
    line-height: 20px;
  }
  .rs-btn-icon-with-text.rs-btn-xs > .rs-icon {
    height: 24px;
    padding: 6px;
    width: 24px;
  }
  .rs-btn-icon-with-text.rs-btn-xs.rs-btn-icon-placement-left {
    padding: 2px 8px 2px 32px;
  }
  .rs-btn-icon-with-text.rs-btn-xs.rs-btn-icon-placement-left > .rs-icon {
    border-right: none;
    border-right: var(--rs-btn-default-border, none);
    left: 0;
  }
  .rs-btn-icon-with-text.rs-btn-xs.rs-btn-icon-placement-right {
    padding: 2px 32px 2px 8px;
  }
  .rs-btn-icon-with-text.rs-btn-xs.rs-btn-icon-placement-right > .rs-icon {
    border-left: none;
    border-left: var(--rs-btn-default-border, none);
    right: 0;
  }
  .rs-btn-block {
    display: block;
    width: 100%;
  }
  .rs-btn-block + .rs-btn-block {
    margin-top: 5px;
  }
  .rs-btn-loading {
    color: transparent !important;
    cursor: default;
    pointer-events: none;
    position: relative;
  }
  .rs-btn-loading > .rs-btn-spin:after,
  .rs-btn-loading > .rs-btn-spin:before {
    border-radius: 50%;
    bottom: 0;
    content: "";
    height: 18px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 18px;
    z-index: 1;
  }
  .rs-btn-xs.rs-btn-loading > .rs-btn-spin:after,
  .rs-btn-xs.rs-btn-loading > .rs-btn-spin:before {
    height: 16px;
    width: 16px;
  }
  .rs-btn-loading > .rs-btn-spin:before {
    border: 3px solid rgb(from #f7f7fa r g b/80%);
    border: 3px solid var(--rs-loader-ring);
  }
  .rs-btn-primary.rs-btn-loading > .rs-btn-spin:before {
    border-color: rgba(248, 247, 250, 0.3);
  }
  .rs-theme-high-contrast .rs-btn-primary.rs-btn-loading > .rs-btn-spin:before {
    border-color: rgb(from #f7f7fa r g b/30%);
    border-color: var(--rs-loader-ring-inverse);
  }
  .rs-btn-loading > .rs-btn-spin:after {
    -webkit-animation: buttonSpin 0.6s linear infinite;
    animation: buttonSpin 0.6s linear infinite;
    border-color: #a6a6a6 transparent transparent;
    border-color: var(--rs-loader-rotor) transparent transparent;
    border-style: solid;
    border-width: 3px;
  }
  .rs-btn-primary.rs-btn-loading > .rs-btn-spin:after {
    border-top-color: #fff;
  }
  .rs-theme-high-contrast .rs-btn-primary.rs-btn-loading > .rs-btn-spin:after {
    border-top-color: #fff;
    border-top-color: var(--rs-loader-rotor-inverse);
  }
  @-webkit-keyframes buttonSpin {
    0% {
      -webkit-transform: rotate(0);
      transform: rotate(0);
    }
    to {
      -webkit-transform: rotate(1turn);
      transform: rotate(1turn);
    }
  }
  @keyframes buttonSpin {
    0% {
      -webkit-transform: rotate(0);
      transform: rotate(0);
    }
    to {
      -webkit-transform: rotate(1turn);
      transform: rotate(1turn);
    }
  }
  .rs-btn-group {
    display: inline-block;
    vertical-align: middle;
  }
  .rs-btn-group,
  .rs-btn-group > .rs-btn {
    position: relative;
  }
  .rs-btn-group > .rs-btn:active,
  .rs-btn-group > .rs-btn:focus {
    z-index: 2;
  }
  .rs-btn-group:not(.rs-btn-group-vertical) > .rs-btn {
    float: left;
  }
  .rs-btn-group:not(.rs-btn-group-vertical) > .rs-btn:not(:last-child) {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
  .rs-btn-group:not(.rs-btn-group-vertical) > .rs-btn:not(:first-child) {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
  .rs-btn-group:not(.rs-btn-group-vertical) > .rs-btn-ghost + .rs-btn-ghost {
    margin-left: -1px;
  }
  .rs-btn-group-vertical > .rs-btn {
    display: block;
    max-width: 100%;
    width: 100%;
  }
  .rs-btn-group-vertical > .rs-btn:not(:last-child) {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .rs-btn-group-vertical > .rs-btn:not(:first-child) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .rs-btn-group-vertical > .rs-btn-ghost + .rs-btn-ghost {
    margin-top: -1px;
  }
  .rs-btn-group-justified {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
  }
  .rs-btn-group-justified > .rs-btn {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 1%;
    flex: 1 1 1%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .rs-btn-toolbar {
    line-height: 0;
  }
  .rs-picker-subtle .picker-subtle-toggle {
    display: inline-block;
    padding-right: 32px;
    position: relative;
    z-index: 5;
  }
  .rs-picker-subtle.rs-picker-disabled .picker-subtle-toggle {
    cursor: not-allowed;
  }
  .rs-picker-subtle.rs-picker-disabled .picker-subtle-toggle:active,
  .rs-picker-subtle.rs-picker-disabled .picker-subtle-toggle:focus,
  .rs-picker-subtle.rs-picker-disabled .picker-subtle-toggle:hover {
    background: none;
  }
  .rs-picker-subtle.rs-picker-disabled .picker-subtle-toggle:active:after,
  .rs-picker-subtle.rs-picker-disabled .picker-subtle-toggle:focus:after,
  .rs-picker-subtle.rs-picker-disabled .picker-subtle-toggle:hover:after {
    display: none;
  }
  .rs-calendar {
    min-height: 266px;
    overflow: hidden;
    padding-top: 12px;
    position: relative;
  }
  .rs-calendar-bordered .rs-calendar-table {
    border: 1px solid #e5e5ea;
    border: 1px solid var(--rs-border-primary);
    border-radius: 6px;
  }
  .rs-calendar-bordered .rs-calendar-table-header-row .rs-calendar-table-header-cell,
  .rs-calendar-bordered .rs-calendar-table-row:not(:last-child) .rs-calendar-table-cell {
    border-bottom: 1px solid #f2f2f5;
    border-bottom: 1px solid var(--rs-border-secondary);
  }
  .rs-calendar-bordered .rs-calendar-month-dropdown {
    border: 1px solid #e5e5ea;
    border: 1px solid var(--rs-border-primary);
    border-radius: 6px;
  }
  .rs-calendar-panel .rs-calendar-header {
    padding-bottom: 6px;
    width: 100%;
  }
  .rs-calendar-panel .rs-calendar-header:after,
  .rs-calendar-panel .rs-calendar-header:before {
    content: " ";
    display: table;
  }
  .rs-calendar-panel .rs-calendar-header:after {
    clear: both;
  }
  .rs-calendar-panel .rs-calendar-header .rs-calendar-btn-today {
    float: right;
  }
  .rs-calendar-panel .rs-calendar-header-backward,
  .rs-calendar-panel .rs-calendar-header-forward {
    float: none !important;
  }
  .rs-calendar-panel.rs-calendar-month-view .rs-calendar-header-backward,
  .rs-calendar-panel.rs-calendar-month-view .rs-calendar-header-forward {
    display: inline-block;
    pointer-events: none;
    visibility: hidden;
  }
  .rs-calendar-panel .rs-calendar-header-month-toolbar {
    width: auto !important;
  }
  .rs-calendar-panel .rs-calendar-month-view,
  .rs-calendar-panel.rs-calendar-month-view .rs-calendar-header-month-toolbar {
    padding-left: 0;
    padding-right: 0;
  }
  .rs-calendar-panel .rs-calendar-table-header-row .rs-calendar-table-header-cell-content {
    padding-bottom: 8px;
    padding-top: 8px;
  }
  .rs-calendar-panel .rs-calendar-table-cell-is-today .rs-calendar-table-cell-day {
    background-color: #3498ff;
    background-color: var(--rs-calendar-today-bg);
    border-radius: 50%;
    color: #fff;
    color: var(--rs-calendar-today-text);
    height: 20px;
    margin-left: auto;
    margin-right: auto;
    width: 20px;
  }
  .rs-calendar-panel .rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .rs-calendar-panel .rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
    background-color: inherit;
    -webkit-box-shadow: inset 0 0 0 1px #3498ff;
    -webkit-box-shadow: inset 0 0 0 1px var(--rs-input-focus-border);
    box-shadow: inset 0 0 0 1px #3498ff;
    box-shadow: inset 0 0 0 1px var(--rs-input-focus-border);
    color: inherit;
  }
  .rs-calendar-panel .rs-calendar-table-cell-day {
    display: block;
    margin-top: 3px;
    text-align: center;
  }
  .rs-calendar-panel .rs-calendar-month-dropdown {
    margin-left: 12px;
    margin-right: 12px;
    margin-top: -2px;
    top: 54px;
    width: calc(100% - 24px);
  }
  .rs-calendar-panel:not(.rs-calendar-compact) .rs-calendar-month-dropdown-scroll {
    height: 644px;
  }
  .rs-calendar-panel.rs-calendar-compact .rs-calendar-month-dropdown-scroll {
    height: 344px;
  }
  .rs-calendar-panel:not(.rs-calendar-compact) .rs-calendar-table-row:not(.rs-calendar-table-header-row) .rs-calendar-table-cell-content {
    height: 100px;
    overflow: hidden;
    width: 100%;
  }
  .rs-calendar-panel:not(.rs-calendar-compact) .rs-calendar-table-cell {
    vertical-align: top;
  }
  .rs-calendar-panel .rs-calendar-table-cell {
    line-height: 0;
    padding: 0 1px;
  }
  .rs-calendar-panel .rs-calendar-table-cell-content {
    border-radius: 0;
    width: 100%;
  }
  .rs-calendar-panel .rs-calendar-table-row:last-child :first-child .rs-calendar-table-cell-content {
    border-bottom-left-radius: 6px;
  }
  .rs-calendar-panel .rs-calendar-table-row:last-child :last-child .rs-calendar-table-cell-content {
    border-bottom-right-radius: 6px;
  }
  .rs-calendar-panel.rs-calendar-compact .rs-calendar-table-row:not(.rs-calendar-table-header-row) .rs-calendar-table-cell-content {
    height: 50px;
  }
  .rs-calendar-btn-close {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    background: #fff;
    background: var(--rs-bg-overlay);
    border: 0;
    bottom: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 14px;
    justify-content: center;
    left: 0;
    padding: 0;
    position: absolute;
    right: 0;
    z-index: 1;
  }
  .rs-calendar-panel .rs-calendar-btn-close {
    bottom: 11px;
    margin: 0 20px;
  }
  .rs-calendar-month-view .rs-calendar-header-month-toolbar {
    padding-left: 24px;
    padding-right: 24px;
  }
  .rs-calendar-month-view .rs-calendar-header-backward,
  .rs-calendar-month-view .rs-calendar-header-forward {
    display: none;
  }
  .rs-calendar-month-view .rs-calendar-header-title-date {
    background: transparent;
    color: #1675e0;
    color: var(--rs-text-active);
  }
  .rs-calendar-month-view .rs-calendar-header-title-date.rs-calendar-header-error {
    color: #f44336;
    color: var(--rs-text-error);
  }
  .rs-calendar-month-view .rs-calendar-header-title-date.rs-calendar-header-error:focus,
  .rs-calendar-month-view .rs-calendar-header-title-date.rs-calendar-header-error:hover:active {
    color: #fff !important;
  }
  .rs-calendar-time-view .rs-calendar-header-title-time {
    background: transparent;
    color: #1675e0;
    color: var(--rs-text-active);
  }
  .rs-calendar-time-view .rs-calendar-header-title-time.rs-calendar-header-error {
    color: #f44336;
    color: var(--rs-text-error);
  }
  .rs-calendar-time-view .rs-calendar-header-title-time.rs-calendar-header-error:hover:active {
    color: #fff;
  }
  .rs-calendar-table-cell-in-range,
  .rs-calendar-table-cell-selected {
    position: relative;
  }
  .rs-calendar-table-cell-in-range:before,
  .rs-calendar-table-cell-selected:before {
    content: "";
    display: block;
    height: 24px;
    margin-top: 4px;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }
  .rs-calendar-table-cell-in-range .rs-calendar-table-cell-content,
  .rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
    position: relative;
    z-index: 1;
  }
  .rs-calendar-table-cell-in-range:before {
    background-color: rgb(from #cce9ff r g b/50%);
    background-color: var(--rs-calendar-range-bg);
  }
  .rs-calendar-table-cell-in-range:hover:before {
    display: none;
  }
  .rs-calendar-month-dropdown-scroll div:focus {
    outline: none;
  }
  .rs-calendar-header {
    padding-left: 12px;
    padding-right: 12px;
    width: 280px;
  }
  .rs-calendar-header:after,
  .rs-calendar-header:before {
    content: " ";
    display: table;
  }
  .rs-calendar-header:after {
    clear: both;
  }
  .rs-calendar-header-month-toolbar,
  .rs-calendar-header-time-toolbar {
    display: inline-block;
  }
  .rs-calendar-header-month-toolbar {
    float: left;
  }
  .rs-calendar-header-time-toolbar {
    float: right;
  }
  .rs-calendar-header-title {
    margin: 0 4px;
  }
  .rs-calendar-header-meridian {
    font-size: 12px;
    margin-left: 4px;
  }
  .rs-calendar-header-error {
    --rs-btn-subtle-text: var(--rs-red-500);
    --rs-btn-subtle-hover-bg: var(--rs-red-500);
    --rs-btn-subtle-hover-text: #fff;
    --rs-btn-subtle-active-bg: var(--rs-red-600);
    --rs-btn-subtle-active-text: #fff;
    background-color: transparent;
    border: none;
    color: #575757;
    color: var(--rs-btn-subtle-text);
  }
  .rs-calendar-header-error:focus,
  .rs-calendar-header-error:hover {
    background-color: #e5e5ea;
    background-color: var(--rs-btn-subtle-hover-bg);
    color: #575757;
    color: var(--rs-btn-subtle-hover-text);
  }
  .rs-calendar-header-error.rs-btn-active,
  .rs-calendar-header-error:active {
    background-color: #e5e5ea;
    background-color: var(--rs-btn-subtle-active-bg);
    color: #272c36;
    color: var(--rs-btn-subtle-active-text);
  }
  .rs-calendar-header-error.rs-btn-disabled,
  .rs-calendar-header-error:disabled {
    background: none;
    color: #c5c6c7;
    color: var(--rs-btn-subtle-disabled-text);
  }
  .rs-theme-high-contrast .rs-calendar-header-error.rs-btn-disabled,
  .rs-theme-high-contrast .rs-calendar-header-error:disabled {
    opacity: 0.5;
  }
  .rs-theme-dark .rs-calendar-header-error {
    --rs-btn-subtle-hover-bg: var(--rs-red-600);
    --rs-btn-subtle-active-bg: var(--rs-red-400);
  }
  .rs-calendar-header-btn-disabled,
  .rs-calendar-header-btn-disabled:hover,
  .rs-calendar-header-btn-disabled:hover:focus {
    background: none;
    cursor: not-allowed;
    opacity: 0.3;
  }
  .rs-calendar-header-btn-disabled:after {
    display: none !important;
  }
  .rs-calendar-header-has-month:not(.rs-calendar-header-has-time) {
    margin: 0 auto;
    padding-left: 12px;
    padding-right: 12px;
  }
  .rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-month-toolbar {
    display: block;
    text-align: center;
    width: 100%;
  }
  .rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-month-toolbar:after,
  .rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-month-toolbar:before {
    content: " ";
    display: table;
  }
  .rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-month-toolbar:after {
    clear: both;
  }
  .rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-backward {
    float: left;
  }
  .rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-forward {
    float: right;
  }
  .rs-calendar-header-has-time:not(.rs-calendar-header-has-month) {
    margin: 0 auto;
    text-align: center;
  }
  .rs-calendar-header-has-time:not(.rs-calendar-header-has-month) .rs-calendar-header-time-toolbar {
    display: inline-block;
    float: none;
  }
  .rs-calendar-header-has-month.rs-calendar-header-has-time {
    margin: 0 auto;
  }
  [dir="rtl"] .rs-calendar-header-backward .rs-icon,
  [dir="rtl"] .rs-calendar-header-forward .rs-icon {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  .rs-calendar-body {
    padding: 4px 12px 12px;
  }
  .rs-calendar-table {
    display: table;
    table-layout: fixed;
    width: 100%;
  }
  .rs-calendar-table-row {
    display: table-row;
  }
  .rs-calendar-table-row:nth-child(2) .rs-calendar-table-cell-week-number {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }
  .rs-calendar-table-row:last-child .rs-calendar-table-cell-week-number {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
  .rs-calendar-table-cell,
  .rs-calendar-table-header-cell {
    display: table-cell;
    padding: 1px 0;
    text-align: center;
    vertical-align: middle;
    width: 1%;
  }
  .rs-calendar-table-cell-disabled .rs-calendar-table-cell-content,
  .rs-calendar-table-cell-disabled .rs-calendar-table-cell-content:hover,
  .rs-calendar-table-cell-disabled .rs-calendar-table-header-cell-content,
  .rs-calendar-table-cell-disabled .rs-calendar-table-header-cell-content:hover,
  .rs-calendar-table-cell-un-same-month .rs-calendar-table-cell-content,
  .rs-calendar-table-cell-un-same-month .rs-calendar-table-cell-content:hover,
  .rs-calendar-table-cell-un-same-month .rs-calendar-table-header-cell-content,
  .rs-calendar-table-cell-un-same-month .rs-calendar-table-header-cell-content:hover,
  .rs-calendar-table-header-cell-disabled .rs-calendar-table-cell-content,
  .rs-calendar-table-header-cell-disabled .rs-calendar-table-cell-content:hover,
  .rs-calendar-table-header-cell-disabled .rs-calendar-table-header-cell-content,
  .rs-calendar-table-header-cell-disabled .rs-calendar-table-header-cell-content:hover,
  .rs-calendar-table-header-cell-un-same-month .rs-calendar-table-cell-content,
  .rs-calendar-table-header-cell-un-same-month .rs-calendar-table-cell-content:hover,
  .rs-calendar-table-header-cell-un-same-month .rs-calendar-table-header-cell-content,
  .rs-calendar-table-header-cell-un-same-month .rs-calendar-table-header-cell-content:hover {
    color: #c5c6c7;
    color: var(--rs-text-disabled);
  }
  .rs-calendar-table-cell-disabled .rs-calendar-table-cell-content,
  .rs-calendar-table-cell-disabled .rs-calendar-table-cell-content:hover,
  .rs-calendar-table-cell-disabled .rs-calendar-table-header-cell-content,
  .rs-calendar-table-cell-disabled .rs-calendar-table-header-cell-content:hover,
  .rs-calendar-table-header-cell-disabled .rs-calendar-table-cell-content,
  .rs-calendar-table-header-cell-disabled .rs-calendar-table-cell-content:hover,
  .rs-calendar-table-header-cell-disabled .rs-calendar-table-header-cell-content,
  .rs-calendar-table-header-cell-disabled .rs-calendar-table-header-cell-content:hover {
    background: none;
    cursor: not-allowed;
    text-decoration: line-through;
  }
  .rs-calendar-table-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-cell-selected .rs-calendar-table-cell-content:hover,
  .rs-calendar-table-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-cell-selected .rs-calendar-table-header-cell-content:hover,
  .rs-calendar-table-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-header-cell-selected .rs-calendar-table-cell-content:hover,
  .rs-calendar-table-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-header-cell-selected
    .rs-calendar-table-header-cell-content:hover,
  .rs-calendar-table-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-cell-selected .rs-calendar-table-cell-content:hover,
  .rs-calendar-table-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-cell-selected
    .rs-calendar-table-header-cell-content:hover,
  .rs-calendar-table-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-header-cell-selected
    .rs-calendar-table-cell-content:hover,
  .rs-calendar-table-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-header-cell-selected
    .rs-calendar-table-header-cell-content:hover,
  .rs-calendar-table-header-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-cell-selected .rs-calendar-table-cell-content:hover,
  .rs-calendar-table-header-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-cell-selected
    .rs-calendar-table-header-cell-content:hover,
  .rs-calendar-table-header-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-header-cell-selected
    .rs-calendar-table-cell-content:hover,
  .rs-calendar-table-header-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-header-cell-selected
    .rs-calendar-table-header-cell-content:hover,
  .rs-calendar-table-header-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-cell-selected
    .rs-calendar-table-cell-content:hover,
  .rs-calendar-table-header-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-cell-selected
    .rs-calendar-table-header-cell-content:hover,
  .rs-calendar-table-header-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-header-cell-selected
    .rs-calendar-table-cell-content:hover,
  .rs-calendar-table-header-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-header-cell-selected
    .rs-calendar-table-header-cell-content:hover {
    background-color: #3498ff;
    background-color: var(--rs-bg-active);
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #fff;
    color: var(--rs-calendar-date-selected-text);
  }
  .rs-theme-high-contrast
    .rs-calendar-table-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-cell-selected
    .rs-calendar-table-cell-content:hover,
  .rs-theme-high-contrast
    .rs-calendar-table-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-cell-selected
    .rs-calendar-table-header-cell-content:hover,
  .rs-theme-high-contrast
    .rs-calendar-table-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-header-cell-selected
    .rs-calendar-table-cell-content:hover,
  .rs-theme-high-contrast
    .rs-calendar-table-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-header-cell-selected
    .rs-calendar-table-header-cell-content:hover,
  .rs-theme-high-contrast
    .rs-calendar-table-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-cell-selected
    .rs-calendar-table-cell-content:hover,
  .rs-theme-high-contrast
    .rs-calendar-table-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-cell-selected
    .rs-calendar-table-header-cell-content:hover,
  .rs-theme-high-contrast
    .rs-calendar-table-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-header-cell-selected
    .rs-calendar-table-cell-content:hover,
  .rs-theme-high-contrast
    .rs-calendar-table-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-header-cell-selected
    .rs-calendar-table-header-cell-content:hover,
  .rs-theme-high-contrast
    .rs-calendar-table-header-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-cell-selected
    .rs-calendar-table-cell-content:hover,
  .rs-theme-high-contrast
    .rs-calendar-table-header-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-cell-selected
    .rs-calendar-table-header-cell-content:hover,
  .rs-theme-high-contrast
    .rs-calendar-table-header-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-header-cell-selected
    .rs-calendar-table-cell-content:hover,
  .rs-theme-high-contrast
    .rs-calendar-table-header-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-header-cell-selected
    .rs-calendar-table-header-cell-content:hover,
  .rs-theme-high-contrast
    .rs-calendar-table-header-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-cell-selected
    .rs-calendar-table-cell-content:hover,
  .rs-theme-high-contrast
    .rs-calendar-table-header-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-cell-selected
    .rs-calendar-table-header-cell-content:hover,
  .rs-theme-high-contrast
    .rs-calendar-table-header-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-header-cell-selected
    .rs-calendar-table-cell-content:hover,
  .rs-theme-high-contrast
    .rs-calendar-table-header-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-header-cell-selected
    .rs-calendar-table-header-cell-content:hover {
    text-decoration: underline;
  }
  .rs-calendar-table-cell-disabled.rs-calendar-table-cell-selected .rs-calendar-table-cell-content,
  .rs-calendar-table-cell-disabled.rs-calendar-table-cell-selected .rs-calendar-table-header-cell-content,
  .rs-calendar-table-cell-disabled.rs-calendar-table-header-cell-selected .rs-calendar-table-cell-content,
  .rs-calendar-table-cell-disabled.rs-calendar-table-header-cell-selected .rs-calendar-table-header-cell-content,
  .rs-calendar-table-header-cell-disabled.rs-calendar-table-cell-selected .rs-calendar-table-cell-content,
  .rs-calendar-table-header-cell-disabled.rs-calendar-table-cell-selected .rs-calendar-table-header-cell-content,
  .rs-calendar-table-header-cell-disabled.rs-calendar-table-header-cell-selected .rs-calendar-table-cell-content,
  .rs-calendar-table-header-cell-disabled.rs-calendar-table-header-cell-selected .rs-calendar-table-header-cell-content {
    opacity: 0.3;
  }
  .rs-calendar-table-cell-content {
    border-radius: 6px;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    line-height: 1.42857143;
    padding: 5px;
  }
  .rs-calendar-table-cell-content:hover {
    background-color: #f2faff;
    background-color: var(--rs-state-hover-bg);
  }
  .rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
    -webkit-box-shadow: inset 0 0 0 1px #3498ff;
    -webkit-box-shadow: inset 0 0 0 1px var(--rs-input-focus-border);
    box-shadow: inset 0 0 0 1px #3498ff;
    box-shadow: inset 0 0 0 1px var(--rs-input-focus-border);
  }
  .rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
    background-color: #3498ff;
    background-color: var(--rs-bg-active);
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #fff;
    color: var(--rs-calendar-date-selected-text);
  }
  .rs-theme-high-contrast .rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
    text-decoration: underline;
  }
  .rs-calendar-table-header-cell-content {
    color: #8e8e93;
    color: var(--rs-text-secondary);
    display: inline-block;
    font-size: 12px;
    line-height: 1.66666667;
    padding-bottom: 2px;
    padding-top: 2px;
  }
  .rs-calendar-table-header-cell-content,
  .rs-calendar-table-header-cell-content:focus,
  .rs-calendar-table-header-cell-content:hover,
  .rs-calendar-table-header-cell-content:hover:focus {
    background: none;
    cursor: auto;
  }
  .rs-calendar-table-cell-week-number {
    background-color: #f7f7fa;
    background-color: var(--rs-bg-well);
    color: #8e8e93;
    color: var(--rs-text-secondary);
    display: table-cell;
    font-size: 12px;
    min-width: 30px;
    padding: 1px;
    text-align: center;
    vertical-align: middle;
  }
  .rs-calendar-month-dropdown {
    background-color: #fff;
    background-color: var(--rs-bg-overlay);
    border-top: 1px solid #e5e5ea;
    border-top: 1px solid var(--rs-border-primary);
    display: none;
    margin-top: 0;
    overflow: hidden;
    position: absolute;
    top: 44px;
    width: 100%;
    z-index: 1;
  }
  .rs-calendar-month-view .rs-calendar-month-dropdown {
    display: block;
  }
  .rs-calendar-month-view .rs-calendar-month-dropdown-content {
    -webkit-animation: slideDown 0.3s linear;
    animation: slideDown 0.3s linear;
  }
  .rs-calendar-month-dropdown-scroll {
    height: 230px;
  }
  .rs-calendar-month-dropdown-row {
    padding: 5px 12px 5px 50px;
    position: relative;
  }
  .rs-calendar-month-dropdown-row:not(:last-child) {
    border-bottom: 1px dashed #e5e5ea;
    border-bottom: 1px dashed var(--rs-border-primary);
  }
  .rs-calendar-month-dropdown-year {
    left: 12px;
    position: absolute;
    top: calc(50% - 0.5em);
  }
  .rs-calendar-month-dropdown-year-active {
    color: #1675e0;
    color: var(--rs-text-active);
  }
  .rs-calendar-month-dropdown-list {
    display: block;
    min-width: 200px;
  }
  .rs-calendar-month-dropdown-list:after,
  .rs-calendar-month-dropdown-list:before {
    content: " ";
    display: table;
  }
  .rs-calendar-month-dropdown-list:after {
    clear: both;
  }
  .rs-calendar-month-dropdown-cell {
    display: inline-block;
    float: left;
    margin: 1px;
    text-align: center;
    vertical-align: middle;
  }
  @media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
    .rs-calendar-month-dropdown-cell {
      width: calc(16.66667% - 2.16667px);
    }
  }
  @supports (-ms-ime-align: auto) {
    .rs-calendar-month-dropdown-cell {
      width: calc(16.66667% - 2.16667px);
    }
  }
  .rs-calendar-month-dropdown-cell-content {
    border-radius: 6px;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    line-height: 1.42857143;
    padding: 5px;
  }
  .rs-calendar-month-dropdown-cell-content:hover {
    background-color: #f2faff;
    background-color: var(--rs-state-hover-bg);
  }
  .rs-calendar-month-dropdown-cell:not(.rs-calendar-month-dropdown-cell-active).disabled .rs-calendar-month-dropdown-cell-content {
    background: none;
    color: #c5c6c7;
    color: var(--rs-text-disabled);
    cursor: not-allowed;
    text-decoration: line-through;
  }
  .rs-calendar-month-dropdown-cell-active.disabled .rs-calendar-month-dropdown-cell-content {
    cursor: not-allowed;
    opacity: 0.3;
  }
  .rs-calendar-month-dropdown-cell-active .rs-calendar-month-dropdown-cell-content {
    background-color: #3498ff;
    background-color: var(--rs-bg-active);
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #fff;
    color: var(--rs-calendar-date-selected-text);
  }
  .rs-theme-high-contrast .rs-calendar-month-dropdown-cell-active .rs-calendar-month-dropdown-cell-content {
    text-decoration: underline;
  }
  .rs-calendar-time-dropdown {
    background-color: #fff;
    background-color: var(--rs-bg-overlay);
    color: #575757;
    color: var(--rs-text-primary);
    display: none;
    overflow: hidden;
    padding-left: 12px;
    padding-right: 12px;
    position: absolute;
    top: 44px;
    width: 100%;
    z-index: 1;
  }
  .rs-calendar-time-view .rs-calendar-time-dropdown {
    display: block;
  }
  .rs-calendar-time-view .rs-calendar-time-dropdown-content {
    -webkit-animation: slideDown 0.3s linear;
    animation: slideDown 0.3s linear;
  }
  .rs-calendar-time-dropdown-row {
    display: table;
    width: 100%;
  }
  .rs-calendar-time-dropdown-column {
    display: table-cell;
    width: 1%;
  }
  .rs-calendar-time-dropdown-column > ul,
  .rs-calendar-time-dropdown-column > ul > li {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .rs-calendar-time-dropdown-column > ul {
    height: 230px;
    overflow-y: auto;
    padding-bottom: 200px;
  }
  .rs-calendar-time-dropdown-column-title {
    background-color: #f7f7fa;
    background-color: var(--rs-calendar-time-unit-bg);
    color: #8e8e93;
    color: var(--rs-text-secondary);
    font-size: 12px;
    line-height: 1.66666667;
    padding-bottom: 2px;
    padding-top: 2px;
    text-align: center;
    width: 100%;
  }
  .rs-calendar-time-dropdown-cell {
    color: inherit;
    cursor: pointer;
    display: block;
    font-size: 14px;
    line-height: 20px;
    padding: 5px;
    text-align: center;
  }
  .rs-calendar-time-dropdown-cell,
  .rs-calendar-time-dropdown-cell:focus,
  .rs-calendar-time-dropdown-cell:hover {
    text-decoration: none;
  }
  .rs-calendar-time-dropdown-cell:hover {
    background-color: rgb(from #cce9ff r g b/50%);
    background-color: var(--rs-listbox-option-hover-bg);
    color: #1675e0;
    color: var(--rs-listbox-option-hover-text);
  }
  .rs-theme-high-contrast .rs-calendar-time-dropdown-cell:hover {
    color: #1675e0;
    color: var(--rs-listbox-option-hover-text);
    outline: 3px solid rgb(from #3498ff r g b/25%);
    outline: 3px solid var(--rs-color-focus-ring);
    outline-offset: -3px;
    outline-width: 2px;
    text-decoration: underline;
  }
  .rs-theme-high-contrast .rs-theme-high-contrast .rs-calendar-time-dropdown-cell:hover {
    outline-offset: 2px;
  }
  .rs-calendar-time-dropdown-cell.rs-calendar-time-dropdown-cell-active {
    background-color: #3498ff;
    background-color: var(--rs-bg-active);
    color: #fff;
    color: var(--rs-calendar-date-selected-text);
  }
  .rs-theme-high-contrast .rs-calendar-time-dropdown-cell.rs-calendar-time-dropdown-cell-active:hover {
    background-color: rgb(from #cce9ff r g b/50%);
    background-color: var(--rs-listbox-option-hover-bg);
    color: #1675e0;
    color: var(--rs-listbox-option-hover-text);
  }
  .rs-theme-high-contrast .rs-theme-high-contrast .rs-calendar-time-dropdown-cell.rs-calendar-time-dropdown-cell-active:hover {
    color: #1675e0;
    color: var(--rs-listbox-option-hover-text);
    outline: 3px solid rgb(from #3498ff r g b/25%);
    outline: 3px solid var(--rs-color-focus-ring);
    outline-offset: -3px;
    outline-width: 2px;
    text-decoration: underline;
  }
  .rs-theme-high-contrast
    .rs-theme-high-contrast
    .rs-theme-high-contrast
    .rs-calendar-time-dropdown-cell.rs-calendar-time-dropdown-cell-active:hover {
    outline-offset: 2px;
  }
  .rs-calendar-time-dropdown-cell.rs-calendar-time-dropdown-cell-disabled {
    background: none;
    color: #c5c6c7;
    color: var(--rs-text-disabled);
    cursor: not-allowed;
    text-decoration: line-through;
  }
  .rs-calendar-time-dropdown-cell-active.rs-calendar-time-dropdown-cell-disabled.rs-calendar-time-dropdown-cell {
    cursor: not-allowed;
    opacity: 0.3;
  }
  @-webkit-keyframes slideDown {
    0% {
      -webkit-transform: translateY(-100%);
      transform: translateY(-100%);
    }
    to {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }
  @keyframes slideDown {
    0% {
      -webkit-transform: translateY(-100%);
      transform: translateY(-100%);
    }
    to {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }
  .rs-carousel {
    background-color: #8e8e93;
    background-color: var(--rs-carousel-bg);
    height: 400px;
    overflow: hidden;
    position: relative;
  }
  .rs-carousel-content {
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;
  }
  .rs-carousel-slider {
    height: 100%;
    left: 0;
    position: relative;
    -webkit-transition: -webkit-transform 0.3s ease;
    transition: -webkit-transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
    will-change: transform;
  }
  .rs-carousel-slider-item {
    float: left;
  }
  .rs-carousel-slider-after,
  .rs-carousel-slider-item {
    background-color: #8e8e93;
    background-color: var(--rs-carousel-bg);
    height: 100%;
    width: 100%;
  }
  .rs-carousel-slider-after {
    -webkit-animation: moveLeftHalf 0.3s ease forwards;
    animation: moveLeftHalf 0.3s ease forwards;
    left: 0;
    position: absolute;
    top: 0;
  }
  .rs-carousel-slider-after-vertical {
    -webkit-animation: moveLeftHalf-vertical 0.3s ease forwards;
    animation: moveLeftHalf-vertical 0.3s ease forwards;
  }
  .rs-carousel-toolbar {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    align-items: center;
    justify-content: center;
    position: absolute;
  }
  .rs-carousel-toolbar,
  .rs-carousel-toolbar > ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .rs-carousel-toolbar > ul {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .rs-carousel-label-wrapper {
    margin: 3px;
  }
  .rs-carousel-label-wrapper input {
    height: 0;
    opacity: 0;
    position: absolute;
    width: 0;
  }
  .rs-carousel-label-wrapper input:checked ~ label {
    background-color: #3498ff;
    background-color: var(--rs-carousel-indicator-active);
  }
  .rs-carousel-label {
    background-color: rgb(from #fff r g b/40%);
    background-color: var(--rs-carousel-indicator);
    cursor: pointer;
    display: block;
    position: relative;
    -webkit-transition: background 0.3s linear;
    transition: background 0.3s linear;
    -webkit-transition-property: background, width, height;
    transition-property: background, width, height;
  }
  .rs-carousel-label:after {
    bottom: -3px;
    content: "";
    left: -3px;
    position: absolute;
    right: -3px;
    top: -3px;
  }
  .rs-carousel-label:hover {
    background-color: #fff;
    background-color: var(--rs-carousel-indicator-hover);
  }
  .rs-carousel-shape-dot .rs-carousel-label {
    border-radius: 50%;
    height: 10px;
    width: 10px;
  }
  .rs-carousel-shape-bar .rs-carousel-label {
    border-radius: 2px;
  }
  .rs-carousel-placement-bottom.rs-carousel-shape-bar .rs-carousel-label,
  .rs-carousel-placement-top.rs-carousel-shape-bar .rs-carousel-label {
    height: 4px;
    width: 18px;
  }
  .rs-carousel-placement-bottom.rs-carousel-shape-bar .rs-carousel-label-wrapper input:checked ~ label,
  .rs-carousel-placement-top.rs-carousel-shape-bar .rs-carousel-label-wrapper input:checked ~ label {
    width: 28px;
  }
  .rs-carousel-placement-left.rs-carousel-shape-bar .rs-carousel-label,
  .rs-carousel-placement-right.rs-carousel-shape-bar .rs-carousel-label {
    height: 18px;
    width: 4px;
  }
  .rs-carousel-placement-left.rs-carousel-shape-bar .rs-carousel-label-wrapper input:checked ~ label,
  .rs-carousel-placement-right.rs-carousel-shape-bar .rs-carousel-label-wrapper input:checked ~ label {
    height: 28px;
  }
  .rs-carousel-placement-bottom .rs-carousel-toolbar,
  .rs-carousel-placement-top .rs-carousel-toolbar {
    left: 0;
    width: 100%;
  }
  .rs-carousel-placement-bottom .rs-carousel-toolbar > ul,
  .rs-carousel-placement-top .rs-carousel-toolbar > ul {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    align-items: center;
    justify-content: center;
  }
  .rs-carousel-placement-top .rs-carousel-toolbar {
    top: 17px;
  }
  .rs-carousel-placement-bottom .rs-carousel-toolbar {
    bottom: 17px;
  }
  .rs-carousel-placement-left .rs-carousel-toolbar,
  .rs-carousel-placement-right .rs-carousel-toolbar {
    height: 100%;
    top: 0;
    width: 1.2vw;
  }
  .rs-carousel-placement-left .rs-carousel-toolbar > ul,
  .rs-carousel-placement-right .rs-carousel-toolbar > ul {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    align-items: center;
    -ms-flex-direction: column;
    flex-direction: column;
    justify-content: center;
  }
  .rs-carousel-placement-left .rs-carousel-toolbar {
    left: 17px;
  }
  .rs-carousel-placement-right .rs-carousel-toolbar {
    right: 17px;
  }
  @-webkit-keyframes moveLeftHalf {
    0% {
      -webkit-transform: none;
      transform: none;
    }
    to {
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
    }
  }
  @keyframes moveLeftHalf {
    0% {
      -webkit-transform: none;
      transform: none;
    }
    to {
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
    }
  }
  @-webkit-keyframes moveLeftHalf-vertical {
    0% {
      -webkit-transform: none;
      transform: none;
    }
    to {
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
    }
  }
  @keyframes moveLeftHalf-vertical {
    0% {
      -webkit-transform: none;
      transform: none;
    }
    to {
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
    }
  }
  .rs-picker-cascader-menu-items > div:after,
  .rs-picker-cascader-menu-items > div:before {
    content: " ";
    display: table;
  }
  .rs-picker-cascader-menu-items > div:after {
    clear: both;
  }
  .rs-picker-cascader-search-panel {
    max-height: 300px;
    overflow: auto;
  }
  .rs-picker-cascader-row {
    clear: both;
    color: #575757;
    color: var(--rs-text-primary);
    cursor: pointer;
    display: block;
    font-weight: 400;
    line-height: 1.42857143;
    padding: 8px 12px;
    text-decoration: none;
    width: 100%;
  }
  .rs-picker-cascader-row:hover {
    background-color: rgb(from #cce9ff r g b/50%);
    background-color: var(--rs-listbox-option-hover-bg);
    color: #1675e0;
    color: var(--rs-listbox-option-hover-text);
  }
  .rs-theme-high-contrast .rs-picker-cascader-row:hover {
    color: #1675e0;
    color: var(--rs-listbox-option-hover-text);
    outline: 3px solid rgb(from #3498ff r g b/25%);
    outline: 3px solid var(--rs-color-focus-ring);
    outline-offset: -3px;
    outline-width: 2px;
    text-decoration: underline;
  }
  .rs-theme-high-contrast .rs-theme-high-contrast .rs-picker-cascader-row:hover {
    outline-offset: 2px;
  }
  .rs-picker-cascader-row.rs-picker-cascader-row-focus {
    background-color: rgb(from #cce9ff r g b/50%);
    background-color: var(--rs-listbox-option-hover-bg);
    color: #1675e0;
    color: var(--rs-listbox-option-hover-text);
  }
  .rs-theme-high-contrast .rs-picker-cascader-row.rs-picker-cascader-row-focus {
    color: #1675e0;
    color: var(--rs-listbox-option-hover-text);
    outline: 3px solid rgb(from #3498ff r g b/25%);
    outline: 3px solid var(--rs-color-focus-ring);
    outline-offset: -3px;
    outline-width: 2px;
    text-decoration: underline;
  }
  .rs-theme-high-contrast .rs-theme-high-contrast .rs-picker-cascader-row.rs-picker-cascader-row-focus {
    outline-offset: 2px;
  }
  .rs-picker-cascader-row.rs-picker-cascader-row-disabled,
  .rs-picker-cascader-row:disabled {
    background: none;
    color: #c5c6c7;
    color: var(--rs-listbox-option-disabled-text);
    cursor: not-allowed;
  }
  .rs-picker-cascader-row.rs-picker-cascader-row-disabled .rs-picker-cascader-search-match,
  .rs-picker-cascader-row:disabled .rs-picker-cascader-search-match {
    font-weight: 400;
    opacity: 0.7;
  }
  .rs-picker-cascader-col {
    display: inline-block;
  }
  .rs-picker-cascader-col:after {
    content: "\00a0/\00a0";
  }
  .rs-picker-cascader-col:last-child:after {
    display: none;
  }
  .rs-picker-cascader-search-match {
    color: #3498ff;
    color: var(--rs-primary-500);
    font-weight: 700;
  }
  .rs-picker-cascader-menu-column {
    float: left;
    overflow-y: auto;
  }
  .rs-picker-cascader-menu-column-loading {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    padding: 4px;
  }
  .rs-picker-cascader-menu-column-loading .rs-icon {
    margin: 4px;
  }
  .rs-picker-cascader-menu-items li,
  .rs-picker-cascader-menu-items ul {
    font-size: 14px;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .rs-picker-cascader-menu-item {
    clear: both;
    color: #575757;
    color: var(--rs-text-primary);
    cursor: pointer;
    display: block;
    font-weight: 400;
    line-height: 1.42857143;
    padding: 8px 28px 8px 12px;
    position: relative;
    text-decoration: none;
    width: 100%;
    word-break: break-word;
  }
  .rs-picker-cascader-menu-items-has-children .rs-picker-cascader-menu-item {
    padding-right: 32px;
  }
  .rs-picker-cascader-menu-item.rs-picker-cascader-menu-item-focus,
  .rs-picker-cascader-menu-item.rs-picker-select-menu-item-focus,
  .rs-picker-cascader-menu-item:focus,
  .rs-picker-cascader-menu-item:hover {
    background-color: rgb(from #cce9ff r g b/50%);
    background-color: var(--rs-listbox-option-hover-bg);
    color: #1675e0;
    color: var(--rs-listbox-option-hover-text);
  }
  .rs-theme-high-contrast .rs-picker-cascader-menu-item.rs-picker-cascader-menu-item-focus,
  .rs-theme-high-contrast .rs-picker-cascader-menu-item.rs-picker-select-menu-item-focus,
  .rs-theme-high-contrast .rs-picker-cascader-menu-item:focus,
  .rs-theme-high-contrast .rs-picker-cascader-menu-item:hover {
    color: #1675e0;
    color: var(--rs-listbox-option-hover-text);
    outline: 3px solid rgb(from #3498ff r g b/25%);
    outline: 3px solid var(--rs-color-focus-ring);
    outline-offset: -3px;
    outline-width: 2px;
    text-decoration: underline;
  }
  .rs-theme-high-contrast .rs-theme-high-contrast .rs-picker-cascader-menu-item.rs-picker-cascader-menu-item-focus,
  .rs-theme-high-contrast .rs-theme-high-contrast .rs-picker-cascader-menu-item.rs-picker-select-menu-item-focus,
  .rs-theme-high-contrast .rs-theme-high-contrast .rs-picker-cascader-menu-item:focus,
  .rs-theme-high-contrast .rs-theme-high-contrast .rs-picker-cascader-menu-item:hover {
    outline-offset: 2px;
  }
  .rs-picker-cascader-menu-item.rs-picker-cascader-menu-item-active {
    background-color: #f2faff;
    background-color: var(--rs-listbox-option-selected-bg);
    color: #1675e0;
    color: var(--rs-listbox-option-selected-text);
    font-weight: 700;
  }
  .rs-theme-high-contrast .rs-picker-cascader-menu-item.rs-picker-cascader-menu-item-active {
    text-decoration: underline;
  }
  .rs-picker-cascader-menu-item.rs-picker-cascader-menu-item-active .rs-picker-cascader-menu-caret {
    color: #575757;
    color: var(--rs-text-primary);
  }
  .rs-picker-cascader-menu-item.rs-picker-cascader-menu-item-disabled,
  .rs-picker-cascader-menu-item:disabled {
    background: none;
    color: #c5c6c7;
    color: var(--rs-listbox-option-disabled-text);
    cursor: not-allowed;
  }
  .rs-picker-cascader-menu-caret {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 12px;
    height: 20px;
    margin-left: 2px;
    position: absolute;
    right: 12px;
    top: 8px;
  }
  .rs-checkbox {
    display: inline-block;
    position: relative;
    vertical-align: middle;
  }
  .rs-checkbox-disabled label {
    cursor: not-allowed;
  }
  .rs-checkbox label {
    cursor: pointer;
    font-weight: 400;
    line-height: 1.14285714;
    margin-bottom: 0;
  }
  .rs-checkbox-inner:before {
    border-color: #3498ff;
    border-color: var(--rs-checkbox-checked-bg);
  }
  .rs-checkbox.rs-checkbox-disabled label {
    cursor: not-allowed;
  }
  .rs-checkbox-disabled > .rs-checkbox-checker > label {
    color: #c5c6c7;
    color: var(--rs-text-disabled);
  }
  .rs-checkbox-inline {
    display: inline-block;
    font-weight: 400;
    margin-bottom: 0;
    margin-right: 10px;
    margin-top: 0;
    position: relative;
    vertical-align: middle;
  }
  .rs-plaintext .rs-checkbox-inline:first-child {
    margin-left: 0;
  }
  .rs-checkbox [type="checkbox"]:focus-visible ~ .rs-checkbox-inner:before {
    -webkit-box-shadow: 0 0 0 3px rgb(from #3498ff r g b/25%);
    -webkit-box-shadow: var(--rs-state-focus-shadow);
    box-shadow: 0 0 0 3px rgb(from #3498ff r g b/25%);
    box-shadow: var(--rs-state-focus-shadow);
  }
  .rs-checkbox-checker {
    line-height: 1;
    min-height: 36px;
    padding-bottom: 10px;
    padding-left: 36px;
    padding-top: 10px;
    position: relative;
  }
  .rs-checkbox-wrapper .rs-checkbox-inner:after,
  .rs-checkbox-wrapper .rs-checkbox-inner:before,
  .rs-checkbox-wrapper:after,
  .rs-checkbox-wrapper:before {
    content: "";
    display: block;
    left: 0;
    position: absolute;
    top: 0;
  }
  .rs-checkbox-wrapper .rs-checkbox-inner:after {
    opacity: 0;
    -webkit-transform: rotate(45deg) scale(0);
    transform: rotate(45deg) scale(0);
    -webkit-transition: opacity 0.2s ease-in, -webkit-transform 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46);
    transition: opacity 0.2s ease-in, -webkit-transform 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46);
    transition: opacity 0.2s ease-in, transform 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46);
    transition: opacity 0.2s ease-in, transform 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46), -webkit-transform 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  }
  .rs-theme-high-contrast .rs-checkbox-wrapper .rs-checkbox-inner:after {
    -webkit-transition: none;
    transition: none;
  }
  .rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner:after,
  .rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner:after {
    border: solid #fff;
    border: solid var(--rs-checkbox-icon);
    height: 9px;
    margin-left: 5px;
    margin-top: 2px;
    opacity: 1;
    width: 6px;
  }
  .rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner:after {
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg) scale(1);
    transform: rotate(45deg) scale(1);
  }
  .rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner:after {
    border-width: 0 0 2px;
    margin-left: 3px;
    margin-top: 0;
    -webkit-transform: rotate(0deg) scale(1);
    transform: rotate(0deg) scale(1);
    width: 10px;
  }
  .rs-checkbox-wrapper {
    display: inline-block;
    height: 16px;
    left: 10px;
    position: absolute;
    top: 10px;
    width: 16px;
  }
  .rs-checkbox-wrapper [type="checkbox"] {
    height: 0;
    opacity: 0;
    width: 0;
  }
  .rs-checkbox-wrapper .rs-checkbox-inner:after,
  .rs-checkbox-wrapper .rs-checkbox-inner:before,
  .rs-checkbox-wrapper:before {
    height: 16px;
    width: 16px;
  }
  .rs-checkbox-wrapper:before {
    background-color: transparent;
    border: 1px solid #3498ff;
    border: 1px solid var(--rs-checkbox-checked-bg);
    border-radius: 3px;
    opacity: 0.7;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: opacity 0.2s linear, -webkit-transform 0.2s linear;
    transition: opacity 0.2s linear, -webkit-transform 0.2s linear;
    transition: transform 0.2s linear, opacity 0.2s linear;
    transition: transform 0.2s linear, opacity 0.2s linear, -webkit-transform 0.2s linear;
    visibility: hidden;
  }
  .rs-theme-high-contrast .rs-checkbox-wrapper:before {
    -webkit-transition: none;
    transition: none;
  }
  .rs-checkbox-checked .rs-checkbox-wrapper:before {
    opacity: 0;
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    visibility: visible;
  }
  .rs-checkbox-wrapper:after {
    bottom: -10px;
    left: -10px;
    right: -10px;
    top: -10px;
  }
  .rs-checkbox-wrapper .rs-checkbox-inner:before {
    background-color: transparent;
    border: 1px solid #d9d9d9;
    border: 1px solid var(--rs-checkbox-border);
    border-radius: 3px;
    -webkit-transition: background 0.2s linear, border 0.2s linear, -webkit-box-shadow 0.2s linear;
    transition: background 0.2s linear, border 0.2s linear, -webkit-box-shadow 0.2s linear;
    transition: background 0.2s linear, border 0.2s linear, box-shadow 0.2s linear;
    transition: background 0.2s linear, border 0.2s linear, box-shadow 0.2s linear, -webkit-box-shadow 0.2s linear;
  }
  .rs-theme-high-contrast .rs-checkbox-wrapper .rs-checkbox-inner:before {
    -webkit-transition: none;
    transition: none;
  }
  label:hover .rs-checkbox-wrapper .rs-checkbox-inner:before {
    border-color: #3498ff;
    border-color: var(--rs-checkbox-checked-bg);
  }
  .rs-checkbox-disabled:not(.rs-checkbox-checked):not(.rs-checkbox-indeterminate) .rs-checkbox-wrapper .rs-checkbox-inner:before {
    background-color: #f7f7fa;
    background-color: var(--rs-checkbox-disabled-bg);
    border-color: #f7f7fa;
    border-color: var(--rs-checkbox-disabled-bg);
  }
  .rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner:before,
  .rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner:before {
    background-color: #3498ff;
    background-color: var(--rs-checkbox-checked-bg);
    border-color: #3498ff;
    border-color: var(--rs-checkbox-checked-bg);
  }
  .rs-checkbox-disabled.rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner:before,
  .rs-checkbox-disabled.rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner:before {
    opacity: 0.3;
  }
  .rs-theme-high-contrast .rs-checkbox-disabled.rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner:before,
  .rs-theme-high-contrast .rs-checkbox-disabled.rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner:before {
    opacity: 0.5;
  }
  .rs-checkbox-group {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .rs-checkbox-group > .rs-checkbox {
    margin-left: -10px;
  }
  .rs-checkbox-group-inline {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .rs-picker-check-menu-items {
    overflow-y: auto;
  }
  .rs-picker-check-menu-items ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .rs-picker-check-menu-items .rs-picker-menu-group:not(:first-child) {
    border-top: 1px solid #e5e5ea;
    border-top: 1px solid var(--rs-border-primary);
    margin-top: 6px;
    padding-top: 6px;
  }
  .rs-picker-check-menu-items .rs-picker-menu-group-title {
    color: #272c36;
    color: var(--rs-text-heading);
    cursor: pointer;
    padding: 8px 32px 8px 12px;
    position: relative;
  }
  .rs-picker-check-menu-items .rs-picker-menu-group-title .rs-picker-menu-group-caret {
    color: #8e8e93;
    color: var(--rs-text-secondary);
    display: inline-block;
    margin-left: 2px;
    padding: 3px;
    position: absolute;
    right: 12px;
    top: 8px;
  }
  .rs-picker-check-menu-items .rs-picker-menu-group.folded .rs-picker-menu-group-caret {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .rs-picker-check-menu-items .rs-check-item.rs-checkbox-checked .rs-checkbox-checker > label {
    font-weight: 700;
  }
  .rs-picker-check-menu-items.rs-picker-check-menu-grouped .rs-check-item .rs-checkbox-checker > label {
    padding-left: 52px;
  }
  .rs-picker-check-menu-items.rs-picker-check-menu-grouped .rs-check-item .rs-checkbox-checker .rs-checkbox-wrapper {
    left: 26px;
  }
  .rs-check-tree.rs-check-tree-virtualized {
    overflow: hidden;
  }
  .rs-check-tree .rs-check-tree-node-label .rs-check-item .rs-checkbox-checker > label {
    margin: 0;
    padding: 1px 0 1px 42px;
    position: relative;
    text-align: left;
  }
  .rs-check-tree .rs-check-tree-node-label .rs-check-item .rs-checkbox-checker .rs-checkbox-wrapper {
    left: 20px;
  }
  .rs-check-tree .rs-check-tree-node-label .rs-check-item .rs-checkbox-checker .rs-check-tree-node-label-content {
    padding: 6px 8px;
    width: auto;
  }
  .rs-check-tree .rs-check-tree-node-label:focus-visible .rs-check-tree-node-label-content {
    outline: 3px solid rgb(from #3498ff r g b/25%);
    outline: 3px solid var(--rs-color-focus-ring);
  }
  .rs-theme-high-contrast .rs-check-tree .rs-check-tree-node-label:focus-visible .rs-check-tree-node-label-content {
    outline-offset: 2px;
  }
  .rs-check-tree-without-children .rs-check-item .rs-checkbox-checker > label {
    padding-left: 32px;
  }
  .rs-check-tree-without-children .rs-check-item .rs-checkbox-checker > label:before {
    margin-left: -34px;
    width: 28px;
  }
  .rs-check-tree-without-children .rs-check-item .rs-checkbox-checker .rs-checkbox-wrapper {
    left: 0;
  }
  .rs-check-tree-node {
    font-size: 14px;
    line-height: 22px;
    position: relative;
  }
  .rs-check-tree-node .rs-check-item {
    display: inline-block;
  }
  .rs-check-tree-node .rs-check-item-focus,
  .rs-check-tree-node .rs-check-item:focus,
  .rs-check-tree-node .rs-check-item:hover {
    background-color: transparent !important;
  }
  .rs-check-tree-node .rs-check-item-focus .rs-check-tree-node-label-content,
  .rs-check-tree-node .rs-check-item:focus .rs-check-tree-node-label-content,
  .rs-check-tree-node .rs-check-item:hover .rs-check-tree-node-label-content {
    background-color: rgb(from #cce9ff r g b/50%);
    background-color: var(--rs-listbox-option-hover-bg);
    border-radius: 6px;
    color: #1675e0;
    color: var(--rs-listbox-option-hover-text);
  }
  .rs-picker-popup .rs-check-tree-node .rs-check-item {
    display: block;
  }
  .rs-check-tree-node:focus .rs-check-item .rs-checkbox-checker > label {
    background-color: #f2faff;
    background-color: var(--rs-state-hover-bg);
    color: #575757;
    color: var(--rs-text-primary);
  }
  .rs-check-tree-node .rs-check-item.rs-checkbox-checked .rs-checkbox-checker > label {
    font-weight: 700;
  }
  .rs-check-tree-node-all-uncheckable .rs-check-item .rs-checkbox-checker > label {
    padding-left: 22px;
  }
  .rs-check-tree-node-all-uncheckable .rs-check-item .rs-checkbox-checker > label:before {
    left: 0;
    margin-left: 0;
    width: 14px;
  }
  .rs-check-tree-node-expand-icon-wrapper {
    cursor: pointer;
    display: inline-block;
    position: absolute;
    z-index: 1;
  }
  .rs-check-tree-node-expand-icon-wrapper > .rs-check-tree-node-expand-icon {
    display: inline-block;
    font-size: 16px;
    height: 36px;
    line-height: 22px;
    margin-top: 6px;
    padding: 8px 8px 8px 0;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transform-origin: 1.5px 14px;
    transform-origin: 1.5px 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .rs-check-tree-node-expand-icon-wrapper > .rs-check-tree-node-expanded,
  .rs-check-tree-open > .rs-check-tree-node .rs-check-tree-node-expand-icon-wrapper > .rs-check-tree-node-expand-icon {
    margin-left: 0;
    margin-top: 0;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  .rs-check-tree-node-expand-icon-wrapper > .rs-check-tree-node-custom-icon,
  .rs-check-tree-node-expand-icon-wrapper > .rs-check-tree-node-loading-icon {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 14px;
    height: 36px;
    width: 14px;
  }
  .rs-check-tree-node-children > .rs-check-tree-group {
    display: none;
    position: relative;
  }
  .rs-check-tree-open.rs-check-tree-node-children > .rs-check-tree-group {
    display: block;
  }
  .rs-check-tree-indent-line {
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    height: 100%;
    margin-left: -13px;
    position: absolute;
    top: 0;
    width: 1px;
  }
  .rs-picker-popup.rs-check-tree-menu {
    padding-top: 12px;
  }
  .rs-check-tree {
    padding-right: 12px;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    height: 100%;
    max-height: 360px;
    overflow-y: auto;
  }
  .rs-check-tree-node > .rs-check-tree-node-label .rs-check-tree-node-label-content {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: top;
    white-space: nowrap;
    width: 100%;
  }
  [dir="rtl"] .rs-check-tree-node-expand-icon-wrapper {
    padding-right: inherit;
    right: 0;
  }
  .rs-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-box-flex: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex: auto;
    flex: auto;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .rs-container-has-sidebar {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .rs-content {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
  }
  .rs-form-control-label {
    margin-bottom: 4px;
  }
  .rs-form-plaintext .rs-form-control-label {
    color: #8e8e93;
    color: var(--rs-text-secondary);
  }
  .rs-picker-toolbar {
    border-top: 1px solid #e5e5ea;
    border-top: 1px solid var(--rs-border-primary);
    padding: 12px;
  }
  .rs-picker-date-inline {
    height: 299px;
  }
  .rs-picker-date-inline .rs-calendar {
    height: 286px;
  }
  .rs-picker-date .rs-input-group-addon {
    color: #8e8e93;
    color: var(--rs-text-secondary);
  }
  .rs-picker-date .rs-input-group-addon .rs-btn-close {
    padding: 0;
  }
  .rs-picker-date.rs-picker-subtle .rs-input-group {
    border-color: transparent;
  }
  .rs-picker-date > .rs-input-group.rs-input-group-inside .rs-input {
    padding-right: 0;
  }
  .rs-picker-date-predefined {
    border-right: 1px solid #e5e5ea;
    border-right: 1px solid var(--rs-border-primary);
    height: 325px;
    padding: 4px 0;
  }
  .rs-picker-date-predefined .rs-btn {
    display: block;
  }
  .rs-picker-popup .rs-picker-toolbar {
    max-width: 100%;
  }
  .rs-picker-popup .rs-picker-toolbar-ranges {
    max-width: 400px;
  }
  .rs-picker-popup .rs-calendar {
    display: block;
    margin: 0 auto;
    min-width: 264px;
  }
  .rs-picker-popup .rs-calendar-show-week-numbers {
    min-width: 278px;
  }
  .rs-picker-popup .rs-calendar-show-week-numbers .rs-calendar-body {
    padding-left: 12px;
    padding-right: 12px;
  }
  .rs-picker-popup .rs-calendar-header {
    width: 100%;
  }
  .rs-picker-popup .rs-calendar-body {
    padding-left: 15px;
    padding-right: 15px;
  }
  .rs-picker-popup .rs-calendar-table {
    width: unset;
  }
  .rs-picker-popup .rs-calendar-month-dropdown-cell:focus-visible .rs-calendar-month-dropdown-cell-content {
    outline: 3px solid rgb(from #3498ff r g b/25%);
    outline: 3px solid var(--rs-color-focus-ring);
  }
  .rs-picker-popup .rs-calendar-table-cell:focus-visible {
    outline: none;
  }
  .rs-picker-popup .rs-calendar-table-cell:focus-visible .rs-calendar-table-cell-content {
    outline: 3px solid rgb(from #3498ff r g b/25%);
    outline: 3px solid var(--rs-color-focus-ring);
  }
  .rs-picker-popup .rs-calendar .rs-calendar-month-dropdown-cell-content,
  .rs-picker-popup .rs-calendar .rs-calendar-table-cell-content {
    height: 30px;
    width: 30px;
  }
  .rs-picker-popup .rs-calendar .rs-calendar-month-dropdown-cell-content:hover {
    background-color: rgb(from #cce9ff r g b/50%);
    background-color: var(--rs-listbox-option-hover-bg);
    color: #1675e0;
    color: var(--rs-listbox-option-hover-text);
  }
  .rs-theme-high-contrast .rs-picker-popup .rs-calendar .rs-calendar-month-dropdown-cell-content:hover {
    color: #1675e0;
    color: var(--rs-listbox-option-hover-text);
    outline: 3px solid rgb(from #3498ff r g b/25%);
    outline: 3px solid var(--rs-color-focus-ring);
    outline-offset: -3px;
    outline-width: 2px;
    text-decoration: underline;
  }
  .rs-theme-high-contrast .rs-theme-high-contrast .rs-picker-popup .rs-calendar .rs-calendar-month-dropdown-cell-content:hover {
    outline-offset: 2px;
  }
  .rs-picker-popup .rs-calendar .rs-calendar-month-dropdown-cell-active .rs-calendar-month-dropdown-cell-content:hover {
    background-color: #1675e0;
    background-color: var(--rs-calendar-cell-selected-hover-bg);
    color: #fff;
    color: var(--rs-calendar-date-selected-text);
  }
  .rs-theme-high-contrast .rs-picker-popup .rs-calendar .rs-calendar-month-dropdown-cell-active .rs-calendar-month-dropdown-cell-content:hover {
    color: #fff;
    color: var(--rs-calendar-date-selected-text);
  }
  .rs-picker-popup .rs-calendar .rs-calendar-table-header-row .rs-calendar-table-cell-content {
    height: 24px;
    padding-top: 0;
  }
  .rs-picker-popup .rs-calendar .rs-calendar-table-cell-content {
    display: inline-block;
    padding-left: 0;
    padding-right: 0;
  }
  .rs-picker-popup .rs-calendar .rs-calendar-table-cell:hover .rs-calendar-table-cell-content {
    background-color: rgb(from #cce9ff r g b/50%);
    background-color: var(--rs-listbox-option-hover-bg);
    color: #1675e0;
    color: var(--rs-listbox-option-hover-text);
  }
  .rs-theme-high-contrast .rs-picker-popup .rs-calendar .rs-calendar-table-cell:hover .rs-calendar-table-cell-content {
    color: #1675e0;
    color: var(--rs-listbox-option-hover-text);
    outline: 3px solid rgb(from #3498ff r g b/25%);
    outline: 3px solid var(--rs-color-focus-ring);
    outline-offset: -3px;
    outline-width: 2px;
    text-decoration: underline;
  }
  .rs-theme-high-contrast .rs-theme-high-contrast .rs-picker-popup .rs-calendar .rs-calendar-table-cell:hover .rs-calendar-table-cell-content {
    outline-offset: 2px;
  }
  .rs-picker-popup .rs-calendar .rs-calendar-table-cell-selected:hover .rs-calendar-table-cell-content {
    background-color: #1675e0;
    background-color: var(--rs-calendar-cell-selected-hover-bg);
    color: #fff;
    color: var(--rs-calendar-date-selected-text);
  }
  .rs-theme-high-contrast .rs-picker-popup .rs-calendar .rs-calendar-table-cell-selected:hover .rs-calendar-table-cell-content {
    color: #fff;
    color: var(--rs-calendar-date-selected-text);
  }
  .rs-picker-popup .rs-calendar .rs-calendar-month-dropdown-scroll {
    height: 227px;
  }
  .rs-picker-popup .rs-calendar .rs-calendar-time-dropdown-column > ul {
    height: 214px;
    padding-bottom: 184px;
  }
  .rs-picker-daterange .rs-input-group-addon {
    color: #8e8e93;
    color: var(--rs-text-secondary);
  }
  .rs-picker-daterange .rs-input-group-addon .rs-btn-close {
    padding: 0;
  }
  .rs-picker-daterange.rs-picker-subtle .rs-input-group {
    border-color: transparent;
  }
  .rs-picker-daterange > .rs-input-group.rs-input-group-inside .rs-input {
    padding-right: 0;
  }
  .rs-picker-daterange-menu .rs-calendar {
    display: inline-block;
    height: 274px;
    padding-bottom: 12px;
  }
  .rs-picker-daterange-menu .rs-calendar:first-child {
    border-right: 1px solid #e5e5ea;
    border-right: 1px solid var(--rs-border-primary);
  }
  .rs-picker-daterange-menu .rs-calendar-header {
    text-align: center;
    width: 100%;
  }
  .rs-picker-daterange-menu .rs-calendar-header-month-toolbar {
    float: none;
  }
  .rs-picker-daterange-menu .rs-calendar-month-dropdown {
    z-index: 1;
  }
  .rs-picker-daterange-menu .rs-calendar-month-dropdown-list {
    width: 185px;
  }
  .rs-picker-daterange-menu .rs-picker-daterange-panel-show-one-calendar .rs-picker-toolbar {
    max-width: 255px;
  }
  .rs-picker-daterange-menu .rs-picker-daterange-panel-show-one-calendar .rs-picker-toolbar-ranges {
    width: 190px;
  }
  .rs-picker-daterange-menu .rs-picker-daterange-calendar-single .rs-calendar {
    border: 0;
    display: block;
    margin: auto;
  }
  .rs-picker-daterange-header {
    border-bottom: 1px solid #e5e5ea;
    border-bottom: 1px solid var(--rs-border-primary);
    font-size: 14px;
    line-height: 1.42857143;
    padding: 8px 12px;
  }
  .rs-picker-daterange-calendar-group {
    height: 274px;
    min-width: 492px;
  }
  .rs-picker-daterange-predefined {
    border-right: 1px solid #e5e5ea;
    border-right: 1px solid var(--rs-border-primary);
    height: 366px;
    padding: 4px 0;
  }
  .rs-picker-daterange-predefined .rs-btn {
    display: block;
  }
  .rs-divider {
    background-color: #e5e5ea;
    background-color: var(--rs-divider-border);
  }
  .rs-divider-vertical {
    display: inline-block;
    height: 1em;
    margin: 0 12px;
    vertical-align: middle;
    width: 1px;
  }
  .rs-divider-horizontal {
    height: 1px;
    margin: 24px 0;
  }
  .rs-divider-horizontal.rs-divider-with-text {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: transparent;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    margin: 30px 0;
  }
  .rs-divider-horizontal.rs-divider-with-text:after,
  .rs-divider-horizontal.rs-divider-with-text:before {
    -webkit-box-flex: 1;
    border-top: 1px solid #e5e5ea;
    border-top: 1px solid var(--rs-divider-border);
    content: "";
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
  }
  .rs-divider-inner-text {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding: 0 12px;
  }
  @-webkit-keyframes shakeHead {
    0%,
    to {
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
    }
    25%,
    75% {
      -webkit-transform: translate3d(-10px, 0, 0);
      transform: translate3d(-10px, 0, 0);
    }
    50% {
      -webkit-transform: translate3d(10px, 0, 0);
      transform: translate3d(10px, 0, 0);
    }
  }
  @keyframes shakeHead {
    0%,
    to {
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
    }
    25%,
    75% {
      -webkit-transform: translate3d(-10px, 0, 0);
      transform: translate3d(-10px, 0, 0);
    }
    50% {
      -webkit-transform: translate3d(10px, 0, 0);
      transform: translate3d(10px, 0, 0);
    }
  }
  .rs-drawer-wrapper {
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1050;
  }
  .rs-drawer {
    -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
    -webkit-box-shadow: var(--rs-drawer-shadow);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
    box-shadow: var(--rs-drawer-shadow);
    display: none;
    outline: 0;
    position: fixed;
    z-index: 1050;
  }
  .rs-drawer,
  .rs-drawer-open.rs-drawer-has-backdrop {
    overflow: hidden;
  }
  .rs-drawer-left,
  .rs-drawer-right {
    height: 100%;
    top: 0;
  }
  .rs-drawer-left.rs-drawer-lg,
  .rs-drawer-right.rs-drawer-lg {
    width: 968px;
  }
  .rs-drawer-left.rs-drawer-md,
  .rs-drawer-right.rs-drawer-md {
    width: 800px;
  }
  .rs-drawer-left.rs-drawer-sm,
  .rs-drawer-right.rs-drawer-sm {
    width: 600px;
  }
  .rs-drawer-left.rs-drawer-xs,
  .rs-drawer-right.rs-drawer-xs {
    width: 400px;
  }
  .rs-drawer-bottom,
  .rs-drawer-top {
    width: 100%;
  }
  .rs-drawer-bottom.rs-drawer-lg,
  .rs-drawer-top.rs-drawer-lg {
    height: 568px;
  }
  .rs-drawer-bottom.rs-drawer-md,
  .rs-drawer-top.rs-drawer-md {
    height: 480px;
  }
  .rs-drawer-bottom.rs-drawer-sm,
  .rs-drawer-top.rs-drawer-sm {
    height: 400px;
  }
  .rs-drawer-bottom.rs-drawer-xs,
  .rs-drawer-top.rs-drawer-xs {
    height: 290px;
  }
  .rs-drawer-full.rs-drawer-bottom,
  .rs-drawer-full.rs-drawer-bottom .rs-drawer-content,
  .rs-drawer-full.rs-drawer-top,
  .rs-drawer-full.rs-drawer-top .rs-drawer-content {
    height: 100%;
  }
  .rs-drawer-full.rs-drawer-left,
  .rs-drawer-full.rs-drawer-right {
    width: 100%;
  }
  .rs-drawer-right {
    right: 0;
  }
  .rs-drawer-left {
    left: 0;
  }
  .rs-drawer-top {
    top: 0;
  }
  .rs-drawer-bottom {
    bottom: 0;
  }
  .rs-drawer-open .rs-drawer {
    overflow: visible;
  }
  .rs-drawer-dialog {
    height: 100%;
    position: relative;
    width: 100%;
  }
  .rs-drawer-shake .rs-drawer-dialog {
    -webkit-animation: shakeHead 0.3s linear;
    animation: shakeHead 0.3s linear;
  }
  .rs-drawer-content {
    background-color: #fff;
    background-color: var(--rs-bg-overlay);
    height: 100%;
    outline: 0;
    position: absolute;
    width: 100%;
  }
  .rs-theme-high-contrast .rs-drawer-content {
    background-color: var(--rs-drawer-bg);
  }
  .rs-drawer-backdrop {
    background-color: rgb(from #272c36 r g b/30%);
    background-color: var(--rs-bg-backdrop);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1050;
  }
  .rs-drawer-backdrop.rs-anim-fade {
    opacity: 0;
    -webkit-transition: opacity 0.3s ease-in;
    transition: opacity 0.3s ease-in;
  }
  .rs-drawer-backdrop.rs-anim-in {
    opacity: 1;
  }
  .rs-drawer-header {
    border-bottom: 1px solid #e5e5ea;
    border-bottom: 1px solid var(--rs-border-primary);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 20px 40px 20px 60px;
    position: relative;
  }
  .rs-drawer-header:after,
  .rs-drawer-header:before {
    content: " ";
    display: table;
  }
  .rs-drawer-header:after {
    clear: both;
  }
  .rs-drawer-header .rs-drawer-header-close {
    left: 15px;
    position: absolute;
    top: 23px;
  }
  .rs-drawer-title {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    -ms-flex-negative: 1;
    color: #272c36;
    color: var(--rs-text-heading);
    flex-grow: 1;
    flex-shrink: 1;
    font-size: 18px;
    font-weight: 400;
    line-height: 36px;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .rs-drawer-actions {
    -ms-flex-negative: 0;
    border-top: none;
    flex-shrink: 0;
    margin-left: auto;
    text-align: right;
  }
  .rs-drawer-actions:after,
  .rs-drawer-actions:before {
    content: " ";
    display: table;
  }
  .rs-drawer-actions:after {
    clear: both;
  }
  .rs-drawer-title ~ .rs-drawer-actions {
    margin-left: 10px;
  }
  .rs-drawer-actions .rs-btn + .rs-btn {
    margin-bottom: 0;
    margin-left: 10px;
  }
  .rs-drawer-actions .rs-btn-group .rs-btn + .rs-btn {
    margin-left: -1px;
  }
  .rs-drawer-actions .rs-btn-block + .rs-btn-block {
    margin-left: 0;
  }
  .rs-drawer-body {
    height: 100%;
    overflow: auto;
    padding: 30px 60px;
    position: relative;
  }
  .rs-drawer-header + .rs-drawer-body {
    height: calc(100% - 76px);
  }
  .rs-drawer-body-close {
    left: 15px;
    position: absolute;
    top: 25px;
  }
  .rs-drawer-header ~ .rs-drawer-body .rs-drawer-body-close {
    display: none;
  }
  .rs-drawer-scrollbar-measure {
    height: 50px;
    overflow: scroll;
    position: absolute;
    top: -9999px;
    width: 50px;
  }
  .rs-drawer-footer {
    border-top: none;
    margin: 0 20px 20px;
    text-align: right;
  }
  .rs-drawer-footer:after,
  .rs-drawer-footer:before {
    content: " ";
    display: table;
  }
  .rs-drawer-footer:after {
    clear: both;
  }
  .rs-drawer-footer .rs-btn + .rs-btn {
    margin-bottom: 0;
    margin-left: 10px;
  }
  .rs-drawer-footer .rs-btn-group .rs-btn + .rs-btn {
    margin-left: -1px;
  }
  .rs-drawer-footer .rs-btn-block + .rs-btn-block {
    margin-left: 0;
  }
  .rs-dropdown-toggle-caret {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 12px;
    height: 20px;
    margin-left: 2px;
    position: absolute;
    right: 12px;
    top: 8px;
  }
  .rs-dropdown {
    display: inline-block;
    position: relative;
    vertical-align: middle;
  }
  .rs-dropdown .rs-btn > .rs-dropdown-toggle-icon {
    margin-right: 6px;
  }
  .rs-dropdown .rs-btn > .rs-dropdown-toggle-caret {
    margin-left: 4px;
  }
  .rs-dropdown-disabled .rs-dropdown-toggle.rs-btn {
    -webkit-box-shadow: none;
    box-shadow: none;
    cursor: not-allowed !important;
  }
  .rs-dropdown-disabled .rs-dropdown-toggle.rs-btn.rs-btn-subtle,
  .rs-dropdown-disabled .rs-dropdown-toggle.rs-btn.rs-btn-subtle:active,
  .rs-dropdown-disabled .rs-dropdown-toggle.rs-btn.rs-btn-subtle:focus {
    background: none;
    color: #c5c6c7;
    color: var(--rs-btn-subtle-disabled-text);
  }
  .rs-dropdown-disabled .rs-dropdown-toggle.rs-btn.rs-btn-primary,
  .rs-dropdown-disabled .rs-dropdown-toggle.rs-btn.rs-btn-primary:active,
  .rs-dropdown-disabled .rs-dropdown-toggle.rs-btn.rs-btn-primary:focus {
    background-color: #3498ff;
    background-color: var(--rs-btn-primary-bg);
    opacity: 0.3;
  }
  .rs-dropdown-disabled .rs-dropdown-toggle.rs-btn.rs-btn-link,
  .rs-dropdown-disabled .rs-dropdown-toggle.rs-btn.rs-btn-link:active,
  .rs-dropdown-disabled .rs-dropdown-toggle.rs-btn.rs-btn-link:focus {
    opacity: 0.3;
    text-decoration: none;
  }
  .rs-dropdown-disabled .rs-dropdown-toggle.rs-btn.rs-btn-ghost,
  .rs-dropdown-disabled .rs-dropdown-toggle.rs-btn.rs-btn-ghost:active,
  .rs-dropdown-disabled .rs-dropdown-toggle.rs-btn.rs-btn-ghost:focus {
    opacity: 0.3;
  }
  .rs-dropdown-disabled .rs-dropdown-toggle.rs-btn .rs-ripple-pond {
    display: none !important;
  }
  .rs-dropdown-toggle.rs-btn-lg {
    padding-right: 36px !important;
  }
  .rs-dropdown-toggle.rs-btn-lg .rs-dropdown-toggle-caret {
    font-size: 14px;
    right: 16px;
    top: 10px;
  }
  .rs-dropdown-toggle.rs-btn-sm {
    padding-right: 30px !important;
  }
  .rs-dropdown-toggle.rs-btn-sm .rs-dropdown-toggle-caret {
    font-size: 10px;
    right: 10px;
    top: 5px;
  }
  .rs-dropdown-toggle.rs-btn-xs {
    padding-right: 28px !important;
  }
  .rs-dropdown-toggle.rs-btn-xs .rs-dropdown-toggle-caret {
    font-size: 8px;
    right: 8px;
    top: 2px;
  }
  .rs-dropdown-toggle,
  .rs-dropdown-toggle.rs-btn {
    display: inline-block;
    padding-right: 32px;
    position: relative;
    z-index: 5;
  }
  .rs-dropdown-toggle.rs-dropdown-toggle-no-caret {
    padding-right: 12px;
  }
  .rs-dropdown-menu {
    background-color: #fff;
    background-color: var(--rs-bg-overlay);
    border-radius: 6px;
    font-size: 14px;
    list-style: none;
    margin: 0;
    outline: 0;
    padding: 6px 0;
    text-align: left;
  }
  .rs-dropdown-menu:focus-visible {
    outline: 3px solid rgb(from #3498ff r g b/25%);
    outline: 3px solid var(--rs-color-focus-ring);
  }
  .rs-theme-high-contrast .rs-dropdown-menu:focus-visible {
    outline-offset: 2px;
  }
  .rs-dropdown .rs-dropdown-menu {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.06), 0 4px 4px rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: var(--rs-dropdown-shadow);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.06), 0 4px 4px rgba(0, 0, 0, 0.12);
    box-shadow: var(--rs-dropdown-shadow);
    float: left;
    outline: none;
    position: absolute;
    z-index: 6;
  }
  .rs-theme-high-contrast .rs-dropdown .rs-dropdown-menu {
    border: 1px solid #e5e5ea;
    border: 1px solid var(--rs-border-primary);
  }
  .rs-dropdown-menu[hidden] {
    display: none;
  }
  .rs-dropdown-item {
    clear: both;
    color: #575757;
    color: var(--rs-text-primary);
    cursor: pointer;
    display: block;
    font-weight: 400;
    line-height: 1.42857143;
    padding: 8px 12px;
    white-space: nowrap;
  }
  .rs-dropdown-item,
  .rs-dropdown-item:active,
  .rs-dropdown-item:focus,
  .rs-dropdown-item:hover {
    text-decoration: none;
  }
  .rs-dropdown-item > .rs-icon {
    margin-right: 6px;
    text-align: center;
    width: 14px;
  }
  .rs-dropdown-item.rs-dropdown-item-active {
    font-weight: 700;
    outline: 0;
  }
  .rs-dropdown-item.rs-dropdown-item-active,
  .rs-dropdown-item.rs-dropdown-item-active:focus,
  .rs-dropdown-item.rs-dropdown-item-active:hover {
    background-color: #f2faff;
    background-color: var(--rs-dropdown-item-bg-active);
    color: #1675e0;
    color: var(--rs-dropdown-item-text-active);
  }
  .rs-dropdown-item.rs-dropdown-item-focus,
  .rs-dropdown-item:focus-visible {
    background-color: rgb(from #cce9ff r g b/50%);
    background-color: var(--rs-menuitem-active-bg);
    color: #1675e0;
    color: var(--rs-menuitem-active-text);
  }
  .rs-theme-high-contrast .rs-dropdown-item.rs-dropdown-item-focus,
  .rs-theme-high-contrast .rs-dropdown-item:focus-visible {
    -webkit-box-shadow: inset 0 0 0 2px #fff;
    box-shadow: inset 0 0 0 2px #fff;
    text-decoration: underline;
  }
  .rs-dropdown-item:focus {
    outline: 0;
  }
  .rs-dropdown-item-disabled {
    color: #c5c6c7;
    color: var(--rs-text-disabled);
    cursor: not-allowed;
    pointer-events: none;
  }
  .rs-dropdown-item-disabled:focus,
  .rs-dropdown-item-disabled:hover {
    background-color: transparent;
    background-image: none;
    text-decoration: none;
  }
  .rs-dropdown-item-divider {
    background-color: #e5e5ea;
    background-color: var(--rs-dropdown-divider);
    height: 1px;
    margin: 6px 0;
    overflow: hidden;
  }
  .rs-dropdown-item-submenu {
    position: relative;
  }
  .rs-dropdown-item-submenu:hover > .rs-dropdown-item-toggle {
    background-color: rgb(from #cce9ff r g b/50%);
    background-color: var(--rs-menuitem-active-bg);
    color: #1675e0;
    color: var(--rs-menuitem-active-text);
  }
  .rs-theme-high-contrast .rs-dropdown-item-submenu:hover > .rs-dropdown-item-toggle {
    -webkit-box-shadow: inset 0 0 0 2px #fff;
    box-shadow: inset 0 0 0 2px #fff;
    text-decoration: underline;
  }
  .rs-dropdown-item-submenu.rs-dropdown-item {
    position: relative;
  }
  .rs-dropdown-item-submenu.rs-dropdown-item > .rs-dropdown-item-toggle .rs-dropdown-menu-toggle-icon {
    margin: 0;
    position: absolute;
    top: 11px;
  }
  .rs-dropdown-item-submenu.rs-dropdown-item > .rs-dropdown-menu {
    position: absolute;
    top: -6px;
  }
  .rs-dropdown-item.rs-dropdown-item-submenu {
    padding: 0;
  }
  .rs-dropdown-item.rs-dropdown-item-submenu .rs-dropdown-item-toggle {
    padding: 8px 27px 8px 12px;
  }
  .rs-dropdown-item.rs-dropdown-item-submenu .rs-dropdown-menu-toggle-icon {
    right: 9px;
  }
  .rs-dropdown-item-submenu .rs-dropdown-menu {
    left: 100%;
  }
  .rs-dropdown-item-submenu .rs-dropdown-menu[data-direction="start"] {
    left: unset;
    right: 100%;
  }
  .rs-dropdown-item-submenu.rs-dropdown-item-open > .rs-dropdown-menu {
    -webkit-animation-name: slideUpIn;
    animation-name: slideUpIn;
    -webkit-animation-play-state: running;
    animation-play-state: running;
    display: table;
  }
  .rs-dropdown-menu > .rs-dropdown-menu-item-focus {
    background-color: #f2faff;
    background-color: var(--rs-dropdown-item-bg-active);
    outline: 0;
  }
  .rs-dropdown-menu > .rs-dropdown-menu-item-focus:focus,
  .rs-dropdown-menu > .rs-dropdown-menu-item-focus:hover {
    background-color: rgb(from #cce9ff r g b/50%);
    background-color: var(--rs-menuitem-active-bg);
    color: #1675e0;
    color: var(--rs-menuitem-active-text);
  }
  .rs-theme-high-contrast .rs-dropdown-menu > .rs-dropdown-menu-item-focus:focus,
  .rs-theme-high-contrast .rs-dropdown-menu > .rs-dropdown-menu-item-focus:hover {
    -webkit-box-shadow: inset 0 0 0 2px #fff;
    box-shadow: inset 0 0 0 2px #fff;
    text-decoration: underline;
  }
  .rs-dropdown-open > .rs-dropdown-menu {
    -webkit-animation-play-state: running;
    animation-play-state: running;
  }
  .rs-dropdown-open.rs-dropdown-placement-top-end > .rs-dropdown-menu,
  .rs-dropdown-open.rs-dropdown-placement-top-start > .rs-dropdown-menu {
    bottom: 100%;
  }
  .rs-dropdown-open.rs-dropdown-placement-left-end > .rs-dropdown-menu,
  .rs-dropdown-open.rs-dropdown-placement-left-start > .rs-dropdown-menu {
    right: 100%;
  }
  .rs-dropdown-open.rs-dropdown-placement-right-end > .rs-dropdown-menu,
  .rs-dropdown-open.rs-dropdown-placement-right-start > .rs-dropdown-menu {
    left: 100%;
  }
  .rs-dropdown-open.rs-dropdown-placement-bottom-end > .rs-dropdown-menu,
  .rs-dropdown-open.rs-dropdown-placement-bottom-start > .rs-dropdown-menu {
    top: 100%;
  }
  .rs-dropdown-open.rs-dropdown-placement-bottom-start > .rs-dropdown-menu,
  .rs-dropdown-open.rs-dropdown-placement-top-start > .rs-dropdown-menu {
    left: 0;
  }
  .rs-dropdown-open.rs-dropdown-placement-bottom-end > .rs-dropdown-menu,
  .rs-dropdown-open.rs-dropdown-placement-top-end > .rs-dropdown-menu {
    right: 0;
  }
  .rs-dropdown-open.rs-dropdown-placement-left-start > .rs-dropdown-menu,
  .rs-dropdown-open.rs-dropdown-placement-right-start > .rs-dropdown-menu {
    top: 0;
  }
  .rs-dropdown-open.rs-dropdown-placement-left-end > .rs-dropdown-menu,
  .rs-dropdown-open.rs-dropdown-placement-right-end > .rs-dropdown-menu {
    bottom: 0;
  }
  .rs-dropdown-open > a {
    outline: 0;
  }
  .rs-dropdown-header {
    border-bottom: 1px solid #e5e5ea;
    border-bottom: 1px solid var(--rs-border-primary);
    color: #a6a6a6;
    color: var(--rs-dropdown-header-text);
    display: block;
    line-height: 1.42857143;
    padding: 8px 12px;
    white-space: nowrap;
  }
  @-webkit-keyframes slideUpIn {
    0% {
      -webkit-transform: scaleY(0.8);
      transform: scaleY(0.8);
      -webkit-transform-origin: 0 0;
      transform-origin: 0 0;
    }
    to {
      -webkit-transform: scaleY(1);
      transform: scaleY(1);
      -webkit-transform-origin: 0 0;
      transform-origin: 0 0;
    }
  }
  @keyframes slideUpIn {
    0% {
      -webkit-transform: scaleY(0.8);
      transform: scaleY(0.8);
      -webkit-transform-origin: 0 0;
      transform-origin: 0 0;
    }
    to {
      -webkit-transform: scaleY(1);
      transform: scaleY(1);
      -webkit-transform-origin: 0 0;
      transform-origin: 0 0;
    }
  }
  .rs-flex-box-grid {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }
  .rs-flex-box-grid-item {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  .rs-flex-box-grid-top {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .rs-flex-box-grid-middle {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .rs-flex-box-grid-bottom {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
  .rs-flex-box-grid-start {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .rs-flex-box-grid-center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .rs-flex-box-grid-end {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  .rs-flex-box-grid-space-between {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .rs-flex-box-grid-space-around {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  .rs-flex-box-grid-item-1 {
    display: block;
    min-height: 1px;
    position: relative;
    width: 4.16666667%;
  }
  .rs-flex-box-grid-item-order-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .rs-flex-box-grid-item-2 {
    display: block;
    min-height: 1px;
    position: relative;
    width: 8.33333333%;
  }
  .rs-flex-box-grid-item-order-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .rs-flex-box-grid-item-3 {
    display: block;
    min-height: 1px;
    position: relative;
    width: 12.5%;
  }
  .rs-flex-box-grid-item-order-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .rs-flex-box-grid-item-4 {
    display: block;
    min-height: 1px;
    position: relative;
    width: 16.66666667%;
  }
  .rs-flex-box-grid-item-order-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }
  .rs-flex-box-grid-item-5 {
    display: block;
    min-height: 1px;
    position: relative;
    width: 20.83333333%;
  }
  .rs-flex-box-grid-item-order-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }
  .rs-flex-box-grid-item-6 {
    display: block;
    min-height: 1px;
    position: relative;
    width: 25%;
  }
  .rs-flex-box-grid-item-order-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }
  .rs-flex-box-grid-item-7 {
    display: block;
    min-height: 1px;
    position: relative;
    width: 29.16666667%;
  }
  .rs-flex-box-grid-item-order-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }
  .rs-flex-box-grid-item-8 {
    display: block;
    min-height: 1px;
    position: relative;
    width: 33.33333333%;
  }
  .rs-flex-box-grid-item-order-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }
  .rs-flex-box-grid-item-9 {
    display: block;
    min-height: 1px;
    position: relative;
    width: 37.5%;
  }
  .rs-flex-box-grid-item-order-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }
  .rs-flex-box-grid-item-10 {
    display: block;
    min-height: 1px;
    position: relative;
    width: 41.66666667%;
  }
  .rs-flex-box-grid-item-order-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }
  .rs-flex-box-grid-item-11 {
    display: block;
    min-height: 1px;
    position: relative;
    width: 45.83333333%;
  }
  .rs-flex-box-grid-item-order-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }
  .rs-flex-box-grid-item-12 {
    display: block;
    min-height: 1px;
    position: relative;
    width: 50%;
  }
  .rs-flex-box-grid-item-order-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }
  .rs-flex-box-grid-item-13 {
    display: block;
    min-height: 1px;
    position: relative;
    width: 54.16666667%;
  }
  .rs-flex-box-grid-item-order-13 {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }
  .rs-flex-box-grid-item-14 {
    display: block;
    min-height: 1px;
    position: relative;
    width: 58.33333333%;
  }
  .rs-flex-box-grid-item-order-14 {
    -webkit-box-ordinal-group: 15;
    -ms-flex-order: 14;
    order: 14;
  }
  .rs-flex-box-grid-item-15 {
    display: block;
    min-height: 1px;
    position: relative;
    width: 62.5%;
  }
  .rs-flex-box-grid-item-order-15 {
    -webkit-box-ordinal-group: 16;
    -ms-flex-order: 15;
    order: 15;
  }
  .rs-flex-box-grid-item-16 {
    display: block;
    min-height: 1px;
    position: relative;
    width: 66.66666667%;
  }
  .rs-flex-box-grid-item-order-16 {
    -webkit-box-ordinal-group: 17;
    -ms-flex-order: 16;
    order: 16;
  }
  .rs-flex-box-grid-item-17 {
    display: block;
    min-height: 1px;
    position: relative;
    width: 70.83333333%;
  }
  .rs-flex-box-grid-item-order-17 {
    -webkit-box-ordinal-group: 18;
    -ms-flex-order: 17;
    order: 17;
  }
  .rs-flex-box-grid-item-18 {
    display: block;
    min-height: 1px;
    position: relative;
    width: 75%;
  }
  .rs-flex-box-grid-item-order-18 {
    -webkit-box-ordinal-group: 19;
    -ms-flex-order: 18;
    order: 18;
  }
  .rs-flex-box-grid-item-19 {
    display: block;
    min-height: 1px;
    position: relative;
    width: 79.16666667%;
  }
  .rs-flex-box-grid-item-order-19 {
    -webkit-box-ordinal-group: 20;
    -ms-flex-order: 19;
    order: 19;
  }
  .rs-flex-box-grid-item-20 {
    display: block;
    min-height: 1px;
    position: relative;
    width: 83.33333333%;
  }
  .rs-flex-box-grid-item-order-20 {
    -webkit-box-ordinal-group: 21;
    -ms-flex-order: 20;
    order: 20;
  }
  .rs-flex-box-grid-item-21 {
    display: block;
    min-height: 1px;
    position: relative;
    width: 87.5%;
  }
  .rs-flex-box-grid-item-order-21 {
    -webkit-box-ordinal-group: 22;
    -ms-flex-order: 21;
    order: 21;
  }
  .rs-flex-box-grid-item-22 {
    display: block;
    min-height: 1px;
    position: relative;
    width: 91.66666667%;
  }
  .rs-flex-box-grid-item-order-22 {
    -webkit-box-ordinal-group: 23;
    -ms-flex-order: 22;
    order: 22;
  }
  .rs-flex-box-grid-item-23 {
    display: block;
    min-height: 1px;
    position: relative;
    width: 95.83333333%;
  }
  .rs-flex-box-grid-item-order-23 {
    -webkit-box-ordinal-group: 24;
    -ms-flex-order: 23;
    order: 23;
  }
  .rs-flex-box-grid-item-24 {
    display: block;
    min-height: 1px;
    position: relative;
    width: 100%;
  }
  .rs-flex-box-grid-item-order-24 {
    -webkit-box-ordinal-group: 25;
    -ms-flex-order: 24;
    order: 24;
  }
  .rs-footer {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  .rs-form-inline > *,
  .rs-form-inline > .rs-btn {
    margin-bottom: 24px;
    margin-right: 20px;
    vertical-align: top;
  }
  .rs-form-inline .rs-form-control-label {
    display: inline-block;
    margin-bottom: auto;
    margin-right: 12px;
    margin-top: 8px;
    vertical-align: top;
  }
  .rs-form-fluid .rs-form-control-wrapper,
  .rs-form-fluid .rs-form-control-wrapper > .rs-input,
  .rs-form-fluid .rs-form-control-wrapper > .rs-input-number,
  .rs-form-fluid.rs-form-vertical .rs-form-group .rs-input-group {
    width: 100%;
  }
  .rs-form-control-wrapper {
    position: relative;
  }
  .rs-form-control-wrapper > .rs-input,
  .rs-form-control-wrapper > .rs-input-number {
    width: 300px;
  }
  .rs-form-control-wrapper.read-only {
    pointer-events: none;
    -ms-touch-action: none;
    touch-action: none;
  }
  .rs-form-vertical .rs-form-group .rs-input-group {
    width: 300px;
  }
  .rs-form-vertical .rs-form-group .rs-form-control-wrapper {
    display: inline-block;
    max-width: 100%;
  }
  .rs-form-horizontal .rs-form-group .rs-form-control-wrapper {
    float: left;
  }
  .rs-form-horizontal .rs-form-group .rs-form-control-wrapper + .rs-form-help-text {
    clear: both;
  }
  .rs-form-horizontal .rs-form-group .rs-form-control-wrapper + .rs-form-help-text:not(.rs-form-help-text-tooltip) {
    margin-left: 182px;
  }
  .rs-form-inline .rs-form-group .rs-form-control-wrapper {
    display: inline-block;
  }
  .rs-form-inline .rs-form-group .rs-sr-only + .rs-form-control-wrapper {
    margin-left: 0;
  }
  .rs-form:not(.rs-form-inline) .rs-form-group:not(:last-child) {
    margin-bottom: 24px;
  }
  .rs-form-group .rs-input {
    display: inline-block;
  }
  .rs-form-group textarea.rs-input {
    vertical-align: bottom;
  }
  .rs-form-vertical .rs-form-group .rs-form-control-label {
    display: block;
  }
  .rs-form-vertical .rs-form-group .rs-form-help-text:not(.rs-form-help-text-tooltip) {
    padding-top: 4px;
  }
  .rs-form-horizontal .rs-form-group:after,
  .rs-form-horizontal .rs-form-group:before {
    content: " ";
    display: table;
  }
  .rs-form-horizontal .rs-form-group:after {
    clear: both;
  }
  .rs-form-horizontal .rs-form-group .rs-form-control-label {
    display: inline-block;
    float: left;
    font-size: 14px;
    line-height: 1.42857143;
    margin-right: 12px;
    min-height: 20px;
    padding: 8px 0;
    text-align: right;
    width: 170px;
  }
  .rs-form-horizontal .rs-form-group .rs-btn-toolbar {
    padding-left: 182px;
  }
  .rs-form-inline .rs-form-group {
    display: inline-block;
  }
  @-webkit-keyframes errorMessageSlideUpIn {
    0% {
      -webkit-transform: translate3d(0, 2px, 0);
      transform: translate3d(0, 2px, 0);
      visibility: visible;
    }
    to {
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
    }
  }
  @keyframes errorMessageSlideUpIn {
    0% {
      -webkit-transform: translate3d(0, 2px, 0);
      transform: translate3d(0, 2px, 0);
      visibility: visible;
    }
    to {
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
    }
  }
  @-webkit-keyframes errorMessageSlideDownIn {
    0% {
      -webkit-transform: translate3d(0, -2px, 0);
      transform: translate3d(0, -2px, 0);
      visibility: visible;
    }
    to {
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
    }
  }
  @keyframes errorMessageSlideDownIn {
    0% {
      -webkit-transform: translate3d(0, -2px, 0);
      transform: translate3d(0, -2px, 0);
      visibility: visible;
    }
    to {
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
    }
  }
  @-webkit-keyframes errorMessageSlideLeftIn {
    0% {
      -webkit-transform: translate3d(-2px, 0, 0);
      transform: translate3d(-2px, 0, 0);
      visibility: visible;
    }
    to {
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
    }
  }
  @keyframes errorMessageSlideLeftIn {
    0% {
      -webkit-transform: translate3d(-2px, 0, 0);
      transform: translate3d(-2px, 0, 0);
      visibility: visible;
    }
    to {
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
    }
  }
  @-webkit-keyframes errorMessageSlideRightIn {
    0% {
      -webkit-transform: translate3d(2px, 0, 0);
      transform: translate3d(2px, 0, 0);
      visibility: visible;
    }
    to {
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
    }
  }
  @keyframes errorMessageSlideRightIn {
    0% {
      -webkit-transform: translate3d(2px, 0, 0);
      transform: translate3d(2px, 0, 0);
      visibility: visible;
    }
    to {
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
    }
  }
  .rs-form-error-message {
    background-color: #fff;
    background-color: var(--rs-form-errormessage-bg);
    border: 1px solid #e5e5ea;
    border: 1px solid var(--rs-form-errormessage-border);
    border-radius: 6px;
    color: #f44336;
    color: var(--rs-form-errormessage-text);
    display: none;
    -webkit-filter: drop-shadow(0 0 6px rgba(0, 0, 0, 0.1));
    filter: drop-shadow(0 0 6px rgba(0, 0, 0, 0.1));
    font-size: 12px;
    line-height: 20px;
    padding: 3px 7px;
    position: absolute;
    white-space: nowrap;
    z-index: 5;
  }
  .rs-form-error-message-wrapper {
    position: absolute;
  }
  .rs-form-error-message-show {
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    display: block;
  }
  .rs-form-error-message-arrow:after,
  .rs-form-error-message-arrow:before {
    border-color: transparent;
    border-style: solid;
    content: "";
    position: absolute;
  }
  .rs-form-error-message-placement-bottom-end,
  .rs-form-error-message-placement-bottom-start {
    bottom: 0;
  }
  .rs-form-error-message-placement-bottom-end .rs-form-error-message,
  .rs-form-error-message-placement-bottom-start .rs-form-error-message {
    top: 0;
  }
  .rs-form-error-message-placement-bottom-end .rs-form-error-message-show,
  .rs-form-error-message-placement-bottom-start .rs-form-error-message-show {
    -webkit-animation-name: errorMessageSlideDownIn;
    animation-name: errorMessageSlideDownIn;
  }
  .rs-form-error-message-placement-bottom-end .rs-form-error-message-arrow:after,
  .rs-form-error-message-placement-bottom-end .rs-form-error-message-arrow:before,
  .rs-form-error-message-placement-bottom-start .rs-form-error-message-arrow:after,
  .rs-form-error-message-placement-bottom-start .rs-form-error-message-arrow:before {
    border-bottom-color: #e5e5ea;
    border-bottom-color: var(--rs-form-errormessage-border);
    border-width: 0 6px 6px;
    top: -6px;
  }
  .rs-form-error-message-placement-bottom-end .rs-form-error-message-arrow:after,
  .rs-form-error-message-placement-bottom-start .rs-form-error-message-arrow:after {
    border-bottom-color: #fff;
    border-bottom-color: var(--rs-form-errormessage-bg);
    top: -5px;
  }
  .rs-form-error-message-placement-top-end,
  .rs-form-error-message-placement-top-start {
    top: 0;
  }
  .rs-form-error-message-placement-top-end .rs-form-error-message,
  .rs-form-error-message-placement-top-start .rs-form-error-message {
    bottom: 0;
  }
  .rs-form-error-message-placement-top-end .rs-form-error-message-show,
  .rs-form-error-message-placement-top-start .rs-form-error-message-show {
    -webkit-animation-name: errorMessageSlideUpIn;
    animation-name: errorMessageSlideUpIn;
  }
  .rs-form-error-message-placement-top-end .rs-form-error-message-arrow:after,
  .rs-form-error-message-placement-top-end .rs-form-error-message-arrow:before,
  .rs-form-error-message-placement-top-start .rs-form-error-message-arrow:after,
  .rs-form-error-message-placement-top-start .rs-form-error-message-arrow:before {
    border-top-color: #e5e5ea;
    border-top-color: var(--rs-form-errormessage-border);
    border-width: 6px 6px 0;
    bottom: -6px;
  }
  .rs-form-error-message-placement-top-end .rs-form-error-message-arrow:after,
  .rs-form-error-message-placement-top-start .rs-form-error-message-arrow:after {
    border-top-color: #fff;
    border-top-color: var(--rs-form-errormessage-bg);
    bottom: -5px;
  }
  .rs-form-error-message-placement-bottom-start,
  .rs-form-error-message-placement-bottom-start .rs-form-error-message,
  .rs-form-error-message-placement-top-start,
  .rs-form-error-message-placement-top-start .rs-form-error-message {
    left: 0;
  }
  .rs-form-error-message-placement-bottom-start .rs-form-error-message-arrow:after,
  .rs-form-error-message-placement-bottom-start .rs-form-error-message-arrow:before,
  .rs-form-error-message-placement-top-start .rs-form-error-message-arrow:after,
  .rs-form-error-message-placement-top-start .rs-form-error-message-arrow:before {
    left: 10px;
  }
  .rs-form-error-message-placement-bottom-end,
  .rs-form-error-message-placement-bottom-end .rs-form-error-message,
  .rs-form-error-message-placement-top-end,
  .rs-form-error-message-placement-top-end .rs-form-error-message {
    right: 0;
  }
  .rs-form-error-message-placement-bottom-end .rs-form-error-message-arrow:after,
  .rs-form-error-message-placement-bottom-end .rs-form-error-message-arrow:before,
  .rs-form-error-message-placement-top-end .rs-form-error-message-arrow:after,
  .rs-form-error-message-placement-top-end .rs-form-error-message-arrow:before {
    right: 10px;
  }
  .rs-form-error-message-placement-left-end,
  .rs-form-error-message-placement-left-start {
    left: 0;
  }
  .rs-form-error-message-placement-left-end .rs-form-error-message,
  .rs-form-error-message-placement-left-start .rs-form-error-message {
    right: 0;
  }
  .rs-form-error-message-placement-left-end .rs-form-error-message-show,
  .rs-form-error-message-placement-left-start .rs-form-error-message-show {
    -webkit-animation-name: errorMessageSlideRightIn;
    animation-name: errorMessageSlideRightIn;
  }
  .rs-form-error-message-placement-left-end .rs-form-error-message-arrow:after,
  .rs-form-error-message-placement-left-end .rs-form-error-message-arrow:before,
  .rs-form-error-message-placement-left-start .rs-form-error-message-arrow:after,
  .rs-form-error-message-placement-left-start .rs-form-error-message-arrow:before {
    border-left-color: #e5e5ea;
    border-left-color: var(--rs-form-errormessage-border);
    border-width: 6px 0 6px 6px;
    right: -6px;
  }
  .rs-form-error-message-placement-left-end .rs-form-error-message-arrow:after,
  .rs-form-error-message-placement-left-start .rs-form-error-message-arrow:after {
    border-left-color: #fff;
    border-left-color: var(--rs-form-errormessage-bg);
    right: -5px;
  }
  .rs-form-error-message-placement-right-end,
  .rs-form-error-message-placement-right-start {
    right: 0;
  }
  .rs-form-error-message-placement-right-end .rs-form-error-message,
  .rs-form-error-message-placement-right-start .rs-form-error-message {
    left: 0;
  }
  .rs-form-error-message-placement-right-end .rs-form-error-message-show,
  .rs-form-error-message-placement-right-start .rs-form-error-message-show {
    -webkit-animation-name: errorMessageSlideLeftIn;
    animation-name: errorMessageSlideLeftIn;
  }
  .rs-form-error-message-placement-right-end .rs-form-error-message-arrow:after,
  .rs-form-error-message-placement-right-end .rs-form-error-message-arrow:before,
  .rs-form-error-message-placement-right-start .rs-form-error-message-arrow:after,
  .rs-form-error-message-placement-right-start .rs-form-error-message-arrow:before {
    border-right-color: #e5e5ea;
    border-right-color: var(--rs-form-errormessage-border);
    border-width: 6px 6px 6px 0;
    left: -6px;
  }
  .rs-form-error-message-placement-right-end .rs-form-error-message-arrow:after,
  .rs-form-error-message-placement-right-start .rs-form-error-message-arrow:after {
    border-right-color: #fff;
    border-right-color: var(--rs-form-errormessage-bg);
    left: -5px;
  }
  .rs-form-error-message-placement-left-start,
  .rs-form-error-message-placement-left-start .rs-form-error-message,
  .rs-form-error-message-placement-right-start,
  .rs-form-error-message-placement-right-start .rs-form-error-message {
    top: 0;
  }
  .rs-form-error-message-placement-left-start .rs-form-error-message-arrow:after,
  .rs-form-error-message-placement-left-start .rs-form-error-message-arrow:before,
  .rs-form-error-message-placement-right-start .rs-form-error-message-arrow:after,
  .rs-form-error-message-placement-right-start .rs-form-error-message-arrow:before {
    top: 4px;
  }
  .rs-form-error-message-placement-left-end,
  .rs-form-error-message-placement-left-end .rs-form-error-message,
  .rs-form-error-message-placement-right-end,
  .rs-form-error-message-placement-right-end .rs-form-error-message {
    bottom: 0;
  }
  .rs-form-error-message-placement-left-end .rs-form-error-message-arrow:after,
  .rs-form-error-message-placement-left-end .rs-form-error-message-arrow:before,
  .rs-form-error-message-placement-right-end .rs-form-error-message-arrow:after,
  .rs-form-error-message-placement-right-end .rs-form-error-message-arrow:before {
    bottom: 4px;
  }
  .rs-grid-container {
    margin-left: auto;
    margin-right: auto;
    padding-left: 5px;
    padding-right: 5px;
  }
  .rs-grid-container:after,
  .rs-grid-container:before {
    content: " ";
    display: table;
  }
  .rs-grid-container:after {
    clear: both;
  }
  @media (min-width: 576px) {
    .rs-grid-container {
      width: 550px;
    }
  }
  @media (min-width: 768px) {
    .rs-grid-container {
      width: 730px;
    }
  }
  @media (min-width: 992px) {
    .rs-grid-container {
      width: 970px;
    }
  }
  @media (min-width: 1200px) {
    .rs-grid-container {
      width: 970px;
    }
  }
  @media (min-width: 1400px) {
    .rs-grid-container {
      width: 970px;
    }
  }
  .rs-grid-container-fluid {
    margin-left: auto;
    margin-right: auto;
    padding-left: 5px;
    padding-right: 5px;
  }
  .rs-grid-container-fluid:after,
  .rs-grid-container-fluid:before {
    content: " ";
    display: table;
  }
  .rs-grid-container-fluid:after {
    clear: both;
  }
  .rs-col-xs-1 {
    width: 4.16666667%;
  }
  .rs-col-xs-pull-1 {
    right: 4.16666667%;
  }
  .rs-col-xs-push-1 {
    left: 4.16666667%;
  }
  .rs-col-xs-offset-1 {
    margin-left: 4.16666667%;
  }
  .rs-col-xs-2 {
    width: 8.33333333%;
  }
  .rs-col-xs-pull-2 {
    right: 8.33333333%;
  }
  .rs-col-xs-push-2 {
    left: 8.33333333%;
  }
  .rs-col-xs-offset-2 {
    margin-left: 8.33333333%;
  }
  .rs-col-xs-3 {
    width: 12.5%;
  }
  .rs-col-xs-pull-3 {
    right: 12.5%;
  }
  .rs-col-xs-push-3 {
    left: 12.5%;
  }
  .rs-col-xs-offset-3 {
    margin-left: 12.5%;
  }
  .rs-col-xs-4 {
    width: 16.66666667%;
  }
  .rs-col-xs-pull-4 {
    right: 16.66666667%;
  }
  .rs-col-xs-push-4 {
    left: 16.66666667%;
  }
  .rs-col-xs-offset-4 {
    margin-left: 16.66666667%;
  }
  .rs-col-xs-5 {
    width: 20.83333333%;
  }
  .rs-col-xs-pull-5 {
    right: 20.83333333%;
  }
  .rs-col-xs-push-5 {
    left: 20.83333333%;
  }
  .rs-col-xs-offset-5 {
    margin-left: 20.83333333%;
  }
  .rs-col-xs-6 {
    width: 25%;
  }
  .rs-col-xs-pull-6 {
    right: 25%;
  }
  .rs-col-xs-push-6 {
    left: 25%;
  }
  .rs-col-xs-offset-6 {
    margin-left: 25%;
  }
  .rs-col-xs-7 {
    width: 29.16666667%;
  }
  .rs-col-xs-pull-7 {
    right: 29.16666667%;
  }
  .rs-col-xs-push-7 {
    left: 29.16666667%;
  }
  .rs-col-xs-offset-7 {
    margin-left: 29.16666667%;
  }
  .rs-col-xs-8 {
    width: 33.33333333%;
  }
  .rs-col-xs-pull-8 {
    right: 33.33333333%;
  }
  .rs-col-xs-push-8 {
    left: 33.33333333%;
  }
  .rs-col-xs-offset-8 {
    margin-left: 33.33333333%;
  }
  .rs-col-xs-9 {
    width: 37.5%;
  }
  .rs-col-xs-pull-9 {
    right: 37.5%;
  }
  .rs-col-xs-push-9 {
    left: 37.5%;
  }
  .rs-col-xs-offset-9 {
    margin-left: 37.5%;
  }
  .rs-col-xs-10 {
    width: 41.66666667%;
  }
  .rs-col-xs-pull-10 {
    right: 41.66666667%;
  }
  .rs-col-xs-push-10 {
    left: 41.66666667%;
  }
  .rs-col-xs-offset-10 {
    margin-left: 41.66666667%;
  }
  .rs-col-xs-11 {
    width: 45.83333333%;
  }
  .rs-col-xs-pull-11 {
    right: 45.83333333%;
  }
  .rs-col-xs-push-11 {
    left: 45.83333333%;
  }
  .rs-col-xs-offset-11 {
    margin-left: 45.83333333%;
  }
  .rs-col-xs-12 {
    width: 50%;
  }
  .rs-col-xs-pull-12 {
    right: 50%;
  }
  .rs-col-xs-push-12 {
    left: 50%;
  }
  .rs-col-xs-offset-12 {
    margin-left: 50%;
  }
  .rs-col-xs-13 {
    width: 54.16666667%;
  }
  .rs-col-xs-pull-13 {
    right: 54.16666667%;
  }
  .rs-col-xs-push-13 {
    left: 54.16666667%;
  }
  .rs-col-xs-offset-13 {
    margin-left: 54.16666667%;
  }
  .rs-col-xs-14 {
    width: 58.33333333%;
  }
  .rs-col-xs-pull-14 {
    right: 58.33333333%;
  }
  .rs-col-xs-push-14 {
    left: 58.33333333%;
  }
  .rs-col-xs-offset-14 {
    margin-left: 58.33333333%;
  }
  .rs-col-xs-15 {
    width: 62.5%;
  }
  .rs-col-xs-pull-15 {
    right: 62.5%;
  }
  .rs-col-xs-push-15 {
    left: 62.5%;
  }
  .rs-col-xs-offset-15 {
    margin-left: 62.5%;
  }
  .rs-col-xs-16 {
    width: 66.66666667%;
  }
  .rs-col-xs-pull-16 {
    right: 66.66666667%;
  }
  .rs-col-xs-push-16 {
    left: 66.66666667%;
  }
  .rs-col-xs-offset-16 {
    margin-left: 66.66666667%;
  }
  .rs-col-xs-17 {
    width: 70.83333333%;
  }
  .rs-col-xs-pull-17 {
    right: 70.83333333%;
  }
  .rs-col-xs-push-17 {
    left: 70.83333333%;
  }
  .rs-col-xs-offset-17 {
    margin-left: 70.83333333%;
  }
  .rs-col-xs-18 {
    width: 75%;
  }
  .rs-col-xs-pull-18 {
    right: 75%;
  }
  .rs-col-xs-push-18 {
    left: 75%;
  }
  .rs-col-xs-offset-18 {
    margin-left: 75%;
  }
  .rs-col-xs-19 {
    width: 79.16666667%;
  }
  .rs-col-xs-pull-19 {
    right: 79.16666667%;
  }
  .rs-col-xs-push-19 {
    left: 79.16666667%;
  }
  .rs-col-xs-offset-19 {
    margin-left: 79.16666667%;
  }
  .rs-col-xs-20 {
    width: 83.33333333%;
  }
  .rs-col-xs-pull-20 {
    right: 83.33333333%;
  }
  .rs-col-xs-push-20 {
    left: 83.33333333%;
  }
  .rs-col-xs-offset-20 {
    margin-left: 83.33333333%;
  }
  .rs-col-xs-21 {
    width: 87.5%;
  }
  .rs-col-xs-pull-21 {
    right: 87.5%;
  }
  .rs-col-xs-push-21 {
    left: 87.5%;
  }
  .rs-col-xs-offset-21 {
    margin-left: 87.5%;
  }
  .rs-col-xs-22 {
    width: 91.66666667%;
  }
  .rs-col-xs-pull-22 {
    right: 91.66666667%;
  }
  .rs-col-xs-push-22 {
    left: 91.66666667%;
  }
  .rs-col-xs-offset-22 {
    margin-left: 91.66666667%;
  }
  .rs-col-xs-23 {
    width: 95.83333333%;
  }
  .rs-col-xs-pull-23 {
    right: 95.83333333%;
  }
  .rs-col-xs-push-23 {
    left: 95.83333333%;
  }
  .rs-col-xs-offset-23 {
    margin-left: 95.83333333%;
  }
  .rs-col-xs-24 {
    width: 100%;
  }
  .rs-col-xs-pull-24 {
    right: 100%;
  }
  .rs-col-xs-push-24 {
    left: 100%;
  }
  .rs-col-xs-offset-24 {
    margin-left: 100%;
  }
  .rs-col-xs-pull-0 {
    right: auto;
  }
  .rs-col-xs-push-0 {
    left: auto;
  }
  @media (min-width: 576px) {
    .rs-col-sm-1 {
      width: 4.16666667%;
    }
    .rs-col-sm-pull-1 {
      right: 4.16666667%;
    }
    .rs-col-sm-push-1 {
      left: 4.16666667%;
    }
    .rs-col-sm-offset-1 {
      margin-left: 4.16666667%;
    }
    .rs-col-sm-2 {
      width: 8.33333333%;
    }
    .rs-col-sm-pull-2 {
      right: 8.33333333%;
    }
    .rs-col-sm-push-2 {
      left: 8.33333333%;
    }
    .rs-col-sm-offset-2 {
      margin-left: 8.33333333%;
    }
    .rs-col-sm-3 {
      width: 12.5%;
    }
    .rs-col-sm-pull-3 {
      right: 12.5%;
    }
    .rs-col-sm-push-3 {
      left: 12.5%;
    }
    .rs-col-sm-offset-3 {
      margin-left: 12.5%;
    }
    .rs-col-sm-4 {
      width: 16.66666667%;
    }
    .rs-col-sm-pull-4 {
      right: 16.66666667%;
    }
    .rs-col-sm-push-4 {
      left: 16.66666667%;
    }
    .rs-col-sm-offset-4 {
      margin-left: 16.66666667%;
    }
    .rs-col-sm-5 {
      width: 20.83333333%;
    }
    .rs-col-sm-pull-5 {
      right: 20.83333333%;
    }
    .rs-col-sm-push-5 {
      left: 20.83333333%;
    }
    .rs-col-sm-offset-5 {
      margin-left: 20.83333333%;
    }
    .rs-col-sm-6 {
      width: 25%;
    }
    .rs-col-sm-pull-6 {
      right: 25%;
    }
    .rs-col-sm-push-6 {
      left: 25%;
    }
    .rs-col-sm-offset-6 {
      margin-left: 25%;
    }
    .rs-col-sm-7 {
      width: 29.16666667%;
    }
    .rs-col-sm-pull-7 {
      right: 29.16666667%;
    }
    .rs-col-sm-push-7 {
      left: 29.16666667%;
    }
    .rs-col-sm-offset-7 {
      margin-left: 29.16666667%;
    }
    .rs-col-sm-8 {
      width: 33.33333333%;
    }
    .rs-col-sm-pull-8 {
      right: 33.33333333%;
    }
    .rs-col-sm-push-8 {
      left: 33.33333333%;
    }
    .rs-col-sm-offset-8 {
      margin-left: 33.33333333%;
    }
    .rs-col-sm-9 {
      width: 37.5%;
    }
    .rs-col-sm-pull-9 {
      right: 37.5%;
    }
    .rs-col-sm-push-9 {
      left: 37.5%;
    }
    .rs-col-sm-offset-9 {
      margin-left: 37.5%;
    }
    .rs-col-sm-10 {
      width: 41.66666667%;
    }
    .rs-col-sm-pull-10 {
      right: 41.66666667%;
    }
    .rs-col-sm-push-10 {
      left: 41.66666667%;
    }
    .rs-col-sm-offset-10 {
      margin-left: 41.66666667%;
    }
    .rs-col-sm-11 {
      width: 45.83333333%;
    }
    .rs-col-sm-pull-11 {
      right: 45.83333333%;
    }
    .rs-col-sm-push-11 {
      left: 45.83333333%;
    }
    .rs-col-sm-offset-11 {
      margin-left: 45.83333333%;
    }
    .rs-col-sm-12 {
      width: 50%;
    }
    .rs-col-sm-pull-12 {
      right: 50%;
    }
    .rs-col-sm-push-12 {
      left: 50%;
    }
    .rs-col-sm-offset-12 {
      margin-left: 50%;
    }
    .rs-col-sm-13 {
      width: 54.16666667%;
    }
    .rs-col-sm-pull-13 {
      right: 54.16666667%;
    }
    .rs-col-sm-push-13 {
      left: 54.16666667%;
    }
    .rs-col-sm-offset-13 {
      margin-left: 54.16666667%;
    }
    .rs-col-sm-14 {
      width: 58.33333333%;
    }
    .rs-col-sm-pull-14 {
      right: 58.33333333%;
    }
    .rs-col-sm-push-14 {
      left: 58.33333333%;
    }
    .rs-col-sm-offset-14 {
      margin-left: 58.33333333%;
    }
    .rs-col-sm-15 {
      width: 62.5%;
    }
    .rs-col-sm-pull-15 {
      right: 62.5%;
    }
    .rs-col-sm-push-15 {
      left: 62.5%;
    }
    .rs-col-sm-offset-15 {
      margin-left: 62.5%;
    }
    .rs-col-sm-16 {
      width: 66.66666667%;
    }
    .rs-col-sm-pull-16 {
      right: 66.66666667%;
    }
    .rs-col-sm-push-16 {
      left: 66.66666667%;
    }
    .rs-col-sm-offset-16 {
      margin-left: 66.66666667%;
    }
    .rs-col-sm-17 {
      width: 70.83333333%;
    }
    .rs-col-sm-pull-17 {
      right: 70.83333333%;
    }
    .rs-col-sm-push-17 {
      left: 70.83333333%;
    }
    .rs-col-sm-offset-17 {
      margin-left: 70.83333333%;
    }
    .rs-col-sm-18 {
      width: 75%;
    }
    .rs-col-sm-pull-18 {
      right: 75%;
    }
    .rs-col-sm-push-18 {
      left: 75%;
    }
    .rs-col-sm-offset-18 {
      margin-left: 75%;
    }
    .rs-col-sm-19 {
      width: 79.16666667%;
    }
    .rs-col-sm-pull-19 {
      right: 79.16666667%;
    }
    .rs-col-sm-push-19 {
      left: 79.16666667%;
    }
    .rs-col-sm-offset-19 {
      margin-left: 79.16666667%;
    }
    .rs-col-sm-20 {
      width: 83.33333333%;
    }
    .rs-col-sm-pull-20 {
      right: 83.33333333%;
    }
    .rs-col-sm-push-20 {
      left: 83.33333333%;
    }
    .rs-col-sm-offset-20 {
      margin-left: 83.33333333%;
    }
    .rs-col-sm-21 {
      width: 87.5%;
    }
    .rs-col-sm-pull-21 {
      right: 87.5%;
    }
    .rs-col-sm-push-21 {
      left: 87.5%;
    }
    .rs-col-sm-offset-21 {
      margin-left: 87.5%;
    }
    .rs-col-sm-22 {
      width: 91.66666667%;
    }
    .rs-col-sm-pull-22 {
      right: 91.66666667%;
    }
    .rs-col-sm-push-22 {
      left: 91.66666667%;
    }
    .rs-col-sm-offset-22 {
      margin-left: 91.66666667%;
    }
    .rs-col-sm-23 {
      width: 95.83333333%;
    }
    .rs-col-sm-pull-23 {
      right: 95.83333333%;
    }
    .rs-col-sm-push-23 {
      left: 95.83333333%;
    }
    .rs-col-sm-offset-23 {
      margin-left: 95.83333333%;
    }
    .rs-col-sm-24 {
      width: 100%;
    }
    .rs-col-sm-pull-24 {
      right: 100%;
    }
    .rs-col-sm-push-24 {
      left: 100%;
    }
    .rs-col-sm-offset-24 {
      margin-left: 100%;
    }
    .rs-col-sm-pull-0 {
      right: auto;
    }
    .rs-col-sm-push-0 {
      left: auto;
    }
  }
  @media (min-width: 768px) {
    .rs-col-md-1 {
      width: 4.16666667%;
    }
    .rs-col-md-pull-1 {
      right: 4.16666667%;
    }
    .rs-col-md-push-1 {
      left: 4.16666667%;
    }
    .rs-col-md-offset-1 {
      margin-left: 4.16666667%;
    }
    .rs-col-md-2 {
      width: 8.33333333%;
    }
    .rs-col-md-pull-2 {
      right: 8.33333333%;
    }
    .rs-col-md-push-2 {
      left: 8.33333333%;
    }
    .rs-col-md-offset-2 {
      margin-left: 8.33333333%;
    }
    .rs-col-md-3 {
      width: 12.5%;
    }
    .rs-col-md-pull-3 {
      right: 12.5%;
    }
    .rs-col-md-push-3 {
      left: 12.5%;
    }
    .rs-col-md-offset-3 {
      margin-left: 12.5%;
    }
    .rs-col-md-4 {
      width: 16.66666667%;
    }
    .rs-col-md-pull-4 {
      right: 16.66666667%;
    }
    .rs-col-md-push-4 {
      left: 16.66666667%;
    }
    .rs-col-md-offset-4 {
      margin-left: 16.66666667%;
    }
    .rs-col-md-5 {
      width: 20.83333333%;
    }
    .rs-col-md-pull-5 {
      right: 20.83333333%;
    }
    .rs-col-md-push-5 {
      left: 20.83333333%;
    }
    .rs-col-md-offset-5 {
      margin-left: 20.83333333%;
    }
    .rs-col-md-6 {
      width: 25%;
    }
    .rs-col-md-pull-6 {
      right: 25%;
    }
    .rs-col-md-push-6 {
      left: 25%;
    }
    .rs-col-md-offset-6 {
      margin-left: 25%;
    }
    .rs-col-md-7 {
      width: 29.16666667%;
    }
    .rs-col-md-pull-7 {
      right: 29.16666667%;
    }
    .rs-col-md-push-7 {
      left: 29.16666667%;
    }
    .rs-col-md-offset-7 {
      margin-left: 29.16666667%;
    }
    .rs-col-md-8 {
      width: 33.33333333%;
    }
    .rs-col-md-pull-8 {
      right: 33.33333333%;
    }
    .rs-col-md-push-8 {
      left: 33.33333333%;
    }
    .rs-col-md-offset-8 {
      margin-left: 33.33333333%;
    }
    .rs-col-md-9 {
      width: 37.5%;
    }
    .rs-col-md-pull-9 {
      right: 37.5%;
    }
    .rs-col-md-push-9 {
      left: 37.5%;
    }
    .rs-col-md-offset-9 {
      margin-left: 37.5%;
    }
    .rs-col-md-10 {
      width: 41.66666667%;
    }
    .rs-col-md-pull-10 {
      right: 41.66666667%;
    }
    .rs-col-md-push-10 {
      left: 41.66666667%;
    }
    .rs-col-md-offset-10 {
      margin-left: 41.66666667%;
    }
    .rs-col-md-11 {
      width: 45.83333333%;
    }
    .rs-col-md-pull-11 {
      right: 45.83333333%;
    }
    .rs-col-md-push-11 {
      left: 45.83333333%;
    }
    .rs-col-md-offset-11 {
      margin-left: 45.83333333%;
    }
    .rs-col-md-12 {
      width: 50%;
    }
    .rs-col-md-pull-12 {
      right: 50%;
    }
    .rs-col-md-push-12 {
      left: 50%;
    }
    .rs-col-md-offset-12 {
      margin-left: 50%;
    }
    .rs-col-md-13 {
      width: 54.16666667%;
    }
    .rs-col-md-pull-13 {
      right: 54.16666667%;
    }
    .rs-col-md-push-13 {
      left: 54.16666667%;
    }
    .rs-col-md-offset-13 {
      margin-left: 54.16666667%;
    }
    .rs-col-md-14 {
      width: 58.33333333%;
    }
    .rs-col-md-pull-14 {
      right: 58.33333333%;
    }
    .rs-col-md-push-14 {
      left: 58.33333333%;
    }
    .rs-col-md-offset-14 {
      margin-left: 58.33333333%;
    }
    .rs-col-md-15 {
      width: 62.5%;
    }
    .rs-col-md-pull-15 {
      right: 62.5%;
    }
    .rs-col-md-push-15 {
      left: 62.5%;
    }
    .rs-col-md-offset-15 {
      margin-left: 62.5%;
    }
    .rs-col-md-16 {
      width: 66.66666667%;
    }
    .rs-col-md-pull-16 {
      right: 66.66666667%;
    }
    .rs-col-md-push-16 {
      left: 66.66666667%;
    }
    .rs-col-md-offset-16 {
      margin-left: 66.66666667%;
    }
    .rs-col-md-17 {
      width: 70.83333333%;
    }
    .rs-col-md-pull-17 {
      right: 70.83333333%;
    }
    .rs-col-md-push-17 {
      left: 70.83333333%;
    }
    .rs-col-md-offset-17 {
      margin-left: 70.83333333%;
    }
    .rs-col-md-18 {
      width: 75%;
    }
    .rs-col-md-pull-18 {
      right: 75%;
    }
    .rs-col-md-push-18 {
      left: 75%;
    }
    .rs-col-md-offset-18 {
      margin-left: 75%;
    }
    .rs-col-md-19 {
      width: 79.16666667%;
    }
    .rs-col-md-pull-19 {
      right: 79.16666667%;
    }
    .rs-col-md-push-19 {
      left: 79.16666667%;
    }
    .rs-col-md-offset-19 {
      margin-left: 79.16666667%;
    }
    .rs-col-md-20 {
      width: 83.33333333%;
    }
    .rs-col-md-pull-20 {
      right: 83.33333333%;
    }
    .rs-col-md-push-20 {
      left: 83.33333333%;
    }
    .rs-col-md-offset-20 {
      margin-left: 83.33333333%;
    }
    .rs-col-md-21 {
      width: 87.5%;
    }
    .rs-col-md-pull-21 {
      right: 87.5%;
    }
    .rs-col-md-push-21 {
      left: 87.5%;
    }
    .rs-col-md-offset-21 {
      margin-left: 87.5%;
    }
    .rs-col-md-22 {
      width: 91.66666667%;
    }
    .rs-col-md-pull-22 {
      right: 91.66666667%;
    }
    .rs-col-md-push-22 {
      left: 91.66666667%;
    }
    .rs-col-md-offset-22 {
      margin-left: 91.66666667%;
    }
    .rs-col-md-23 {
      width: 95.83333333%;
    }
    .rs-col-md-pull-23 {
      right: 95.83333333%;
    }
    .rs-col-md-push-23 {
      left: 95.83333333%;
    }
    .rs-col-md-offset-23 {
      margin-left: 95.83333333%;
    }
    .rs-col-md-24 {
      width: 100%;
    }
    .rs-col-md-pull-24 {
      right: 100%;
    }
    .rs-col-md-push-24 {
      left: 100%;
    }
    .rs-col-md-offset-24 {
      margin-left: 100%;
    }
    .rs-col-md-pull-0 {
      right: auto;
    }
    .rs-col-md-push-0 {
      left: auto;
    }
  }
  @media (min-width: 992px) {
    .rs-col-lg-1 {
      width: 4.16666667%;
    }
    .rs-col-lg-pull-1 {
      right: 4.16666667%;
    }
    .rs-col-lg-push-1 {
      left: 4.16666667%;
    }
    .rs-col-lg-offset-1 {
      margin-left: 4.16666667%;
    }
    .rs-col-lg-2 {
      width: 8.33333333%;
    }
    .rs-col-lg-pull-2 {
      right: 8.33333333%;
    }
    .rs-col-lg-push-2 {
      left: 8.33333333%;
    }
    .rs-col-lg-offset-2 {
      margin-left: 8.33333333%;
    }
    .rs-col-lg-3 {
      width: 12.5%;
    }
    .rs-col-lg-pull-3 {
      right: 12.5%;
    }
    .rs-col-lg-push-3 {
      left: 12.5%;
    }
    .rs-col-lg-offset-3 {
      margin-left: 12.5%;
    }
    .rs-col-lg-4 {
      width: 16.66666667%;
    }
    .rs-col-lg-pull-4 {
      right: 16.66666667%;
    }
    .rs-col-lg-push-4 {
      left: 16.66666667%;
    }
    .rs-col-lg-offset-4 {
      margin-left: 16.66666667%;
    }
    .rs-col-lg-5 {
      width: 20.83333333%;
    }
    .rs-col-lg-pull-5 {
      right: 20.83333333%;
    }
    .rs-col-lg-push-5 {
      left: 20.83333333%;
    }
    .rs-col-lg-offset-5 {
      margin-left: 20.83333333%;
    }
    .rs-col-lg-6 {
      width: 25%;
    }
    .rs-col-lg-pull-6 {
      right: 25%;
    }
    .rs-col-lg-push-6 {
      left: 25%;
    }
    .rs-col-lg-offset-6 {
      margin-left: 25%;
    }
    .rs-col-lg-7 {
      width: 29.16666667%;
    }
    .rs-col-lg-pull-7 {
      right: 29.16666667%;
    }
    .rs-col-lg-push-7 {
      left: 29.16666667%;
    }
    .rs-col-lg-offset-7 {
      margin-left: 29.16666667%;
    }
    .rs-col-lg-8 {
      width: 33.33333333%;
    }
    .rs-col-lg-pull-8 {
      right: 33.33333333%;
    }
    .rs-col-lg-push-8 {
      left: 33.33333333%;
    }
    .rs-col-lg-offset-8 {
      margin-left: 33.33333333%;
    }
    .rs-col-lg-9 {
      width: 37.5%;
    }
    .rs-col-lg-pull-9 {
      right: 37.5%;
    }
    .rs-col-lg-push-9 {
      left: 37.5%;
    }
    .rs-col-lg-offset-9 {
      margin-left: 37.5%;
    }
    .rs-col-lg-10 {
      width: 41.66666667%;
    }
    .rs-col-lg-pull-10 {
      right: 41.66666667%;
    }
    .rs-col-lg-push-10 {
      left: 41.66666667%;
    }
    .rs-col-lg-offset-10 {
      margin-left: 41.66666667%;
    }
    .rs-col-lg-11 {
      width: 45.83333333%;
    }
    .rs-col-lg-pull-11 {
      right: 45.83333333%;
    }
    .rs-col-lg-push-11 {
      left: 45.83333333%;
    }
    .rs-col-lg-offset-11 {
      margin-left: 45.83333333%;
    }
    .rs-col-lg-12 {
      width: 50%;
    }
    .rs-col-lg-pull-12 {
      right: 50%;
    }
    .rs-col-lg-push-12 {
      left: 50%;
    }
    .rs-col-lg-offset-12 {
      margin-left: 50%;
    }
    .rs-col-lg-13 {
      width: 54.16666667%;
    }
    .rs-col-lg-pull-13 {
      right: 54.16666667%;
    }
    .rs-col-lg-push-13 {
      left: 54.16666667%;
    }
    .rs-col-lg-offset-13 {
      margin-left: 54.16666667%;
    }
    .rs-col-lg-14 {
      width: 58.33333333%;
    }
    .rs-col-lg-pull-14 {
      right: 58.33333333%;
    }
    .rs-col-lg-push-14 {
      left: 58.33333333%;
    }
    .rs-col-lg-offset-14 {
      margin-left: 58.33333333%;
    }
    .rs-col-lg-15 {
      width: 62.5%;
    }
    .rs-col-lg-pull-15 {
      right: 62.5%;
    }
    .rs-col-lg-push-15 {
      left: 62.5%;
    }
    .rs-col-lg-offset-15 {
      margin-left: 62.5%;
    }
    .rs-col-lg-16 {
      width: 66.66666667%;
    }
    .rs-col-lg-pull-16 {
      right: 66.66666667%;
    }
    .rs-col-lg-push-16 {
      left: 66.66666667%;
    }
    .rs-col-lg-offset-16 {
      margin-left: 66.66666667%;
    }
    .rs-col-lg-17 {
      width: 70.83333333%;
    }
    .rs-col-lg-pull-17 {
      right: 70.83333333%;
    }
    .rs-col-lg-push-17 {
      left: 70.83333333%;
    }
    .rs-col-lg-offset-17 {
      margin-left: 70.83333333%;
    }
    .rs-col-lg-18 {
      width: 75%;
    }
    .rs-col-lg-pull-18 {
      right: 75%;
    }
    .rs-col-lg-push-18 {
      left: 75%;
    }
    .rs-col-lg-offset-18 {
      margin-left: 75%;
    }
    .rs-col-lg-19 {
      width: 79.16666667%;
    }
    .rs-col-lg-pull-19 {
      right: 79.16666667%;
    }
    .rs-col-lg-push-19 {
      left: 79.16666667%;
    }
    .rs-col-lg-offset-19 {
      margin-left: 79.16666667%;
    }
    .rs-col-lg-20 {
      width: 83.33333333%;
    }
    .rs-col-lg-pull-20 {
      right: 83.33333333%;
    }
    .rs-col-lg-push-20 {
      left: 83.33333333%;
    }
    .rs-col-lg-offset-20 {
      margin-left: 83.33333333%;
    }
    .rs-col-lg-21 {
      width: 87.5%;
    }
    .rs-col-lg-pull-21 {
      right: 87.5%;
    }
    .rs-col-lg-push-21 {
      left: 87.5%;
    }
    .rs-col-lg-offset-21 {
      margin-left: 87.5%;
    }
    .rs-col-lg-22 {
      width: 91.66666667%;
    }
    .rs-col-lg-pull-22 {
      right: 91.66666667%;
    }
    .rs-col-lg-push-22 {
      left: 91.66666667%;
    }
    .rs-col-lg-offset-22 {
      margin-left: 91.66666667%;
    }
    .rs-col-lg-23 {
      width: 95.83333333%;
    }
    .rs-col-lg-pull-23 {
      right: 95.83333333%;
    }
    .rs-col-lg-push-23 {
      left: 95.83333333%;
    }
    .rs-col-lg-offset-23 {
      margin-left: 95.83333333%;
    }
    .rs-col-lg-24 {
      width: 100%;
    }
    .rs-col-lg-pull-24 {
      right: 100%;
    }
    .rs-col-lg-push-24 {
      left: 100%;
    }
    .rs-col-lg-offset-24 {
      margin-left: 100%;
    }
    .rs-col-lg-pull-0 {
      right: auto;
    }
    .rs-col-lg-push-0 {
      left: auto;
    }
  }
  @media (min-width: 1200px) {
    .rs-col-xl-1 {
      width: 4.16666667%;
    }
    .rs-col-xl-pull-1 {
      right: 4.16666667%;
    }
    .rs-col-xl-push-1 {
      left: 4.16666667%;
    }
    .rs-col-xl-offset-1 {
      margin-left: 4.16666667%;
    }
    .rs-col-xl-2 {
      width: 8.33333333%;
    }
    .rs-col-xl-pull-2 {
      right: 8.33333333%;
    }
    .rs-col-xl-push-2 {
      left: 8.33333333%;
    }
    .rs-col-xl-offset-2 {
      margin-left: 8.33333333%;
    }
    .rs-col-xl-3 {
      width: 12.5%;
    }
    .rs-col-xl-pull-3 {
      right: 12.5%;
    }
    .rs-col-xl-push-3 {
      left: 12.5%;
    }
    .rs-col-xl-offset-3 {
      margin-left: 12.5%;
    }
    .rs-col-xl-4 {
      width: 16.66666667%;
    }
    .rs-col-xl-pull-4 {
      right: 16.66666667%;
    }
    .rs-col-xl-push-4 {
      left: 16.66666667%;
    }
    .rs-col-xl-offset-4 {
      margin-left: 16.66666667%;
    }
    .rs-col-xl-5 {
      width: 20.83333333%;
    }
    .rs-col-xl-pull-5 {
      right: 20.83333333%;
    }
    .rs-col-xl-push-5 {
      left: 20.83333333%;
    }
    .rs-col-xl-offset-5 {
      margin-left: 20.83333333%;
    }
    .rs-col-xl-6 {
      width: 25%;
    }
    .rs-col-xl-pull-6 {
      right: 25%;
    }
    .rs-col-xl-push-6 {
      left: 25%;
    }
    .rs-col-xl-offset-6 {
      margin-left: 25%;
    }
    .rs-col-xl-7 {
      width: 29.16666667%;
    }
    .rs-col-xl-pull-7 {
      right: 29.16666667%;
    }
    .rs-col-xl-push-7 {
      left: 29.16666667%;
    }
    .rs-col-xl-offset-7 {
      margin-left: 29.16666667%;
    }
    .rs-col-xl-8 {
      width: 33.33333333%;
    }
    .rs-col-xl-pull-8 {
      right: 33.33333333%;
    }
    .rs-col-xl-push-8 {
      left: 33.33333333%;
    }
    .rs-col-xl-offset-8 {
      margin-left: 33.33333333%;
    }
    .rs-col-xl-9 {
      width: 37.5%;
    }
    .rs-col-xl-pull-9 {
      right: 37.5%;
    }
    .rs-col-xl-push-9 {
      left: 37.5%;
    }
    .rs-col-xl-offset-9 {
      margin-left: 37.5%;
    }
    .rs-col-xl-10 {
      width: 41.66666667%;
    }
    .rs-col-xl-pull-10 {
      right: 41.66666667%;
    }
    .rs-col-xl-push-10 {
      left: 41.66666667%;
    }
    .rs-col-xl-offset-10 {
      margin-left: 41.66666667%;
    }
    .rs-col-xl-11 {
      width: 45.83333333%;
    }
    .rs-col-xl-pull-11 {
      right: 45.83333333%;
    }
    .rs-col-xl-push-11 {
      left: 45.83333333%;
    }
    .rs-col-xl-offset-11 {
      margin-left: 45.83333333%;
    }
    .rs-col-xl-12 {
      width: 50%;
    }
    .rs-col-xl-pull-12 {
      right: 50%;
    }
    .rs-col-xl-push-12 {
      left: 50%;
    }
    .rs-col-xl-offset-12 {
      margin-left: 50%;
    }
    .rs-col-xl-13 {
      width: 54.16666667%;
    }
    .rs-col-xl-pull-13 {
      right: 54.16666667%;
    }
    .rs-col-xl-push-13 {
      left: 54.16666667%;
    }
    .rs-col-xl-offset-13 {
      margin-left: 54.16666667%;
    }
    .rs-col-xl-14 {
      width: 58.33333333%;
    }
    .rs-col-xl-pull-14 {
      right: 58.33333333%;
    }
    .rs-col-xl-push-14 {
      left: 58.33333333%;
    }
    .rs-col-xl-offset-14 {
      margin-left: 58.33333333%;
    }
    .rs-col-xl-15 {
      width: 62.5%;
    }
    .rs-col-xl-pull-15 {
      right: 62.5%;
    }
    .rs-col-xl-push-15 {
      left: 62.5%;
    }
    .rs-col-xl-offset-15 {
      margin-left: 62.5%;
    }
    .rs-col-xl-16 {
      width: 66.66666667%;
    }
    .rs-col-xl-pull-16 {
      right: 66.66666667%;
    }
    .rs-col-xl-push-16 {
      left: 66.66666667%;
    }
    .rs-col-xl-offset-16 {
      margin-left: 66.66666667%;
    }
    .rs-col-xl-17 {
      width: 70.83333333%;
    }
    .rs-col-xl-pull-17 {
      right: 70.83333333%;
    }
    .rs-col-xl-push-17 {
      left: 70.83333333%;
    }
    .rs-col-xl-offset-17 {
      margin-left: 70.83333333%;
    }
    .rs-col-xl-18 {
      width: 75%;
    }
    .rs-col-xl-pull-18 {
      right: 75%;
    }
    .rs-col-xl-push-18 {
      left: 75%;
    }
    .rs-col-xl-offset-18 {
      margin-left: 75%;
    }
    .rs-col-xl-19 {
      width: 79.16666667%;
    }
    .rs-col-xl-pull-19 {
      right: 79.16666667%;
    }
    .rs-col-xl-push-19 {
      left: 79.16666667%;
    }
    .rs-col-xl-offset-19 {
      margin-left: 79.16666667%;
    }
    .rs-col-xl-20 {
      width: 83.33333333%;
    }
    .rs-col-xl-pull-20 {
      right: 83.33333333%;
    }
    .rs-col-xl-push-20 {
      left: 83.33333333%;
    }
    .rs-col-xl-offset-20 {
      margin-left: 83.33333333%;
    }
    .rs-col-xl-21 {
      width: 87.5%;
    }
    .rs-col-xl-pull-21 {
      right: 87.5%;
    }
    .rs-col-xl-push-21 {
      left: 87.5%;
    }
    .rs-col-xl-offset-21 {
      margin-left: 87.5%;
    }
    .rs-col-xl-22 {
      width: 91.66666667%;
    }
    .rs-col-xl-pull-22 {
      right: 91.66666667%;
    }
    .rs-col-xl-push-22 {
      left: 91.66666667%;
    }
    .rs-col-xl-offset-22 {
      margin-left: 91.66666667%;
    }
    .rs-col-xl-23 {
      width: 95.83333333%;
    }
    .rs-col-xl-pull-23 {
      right: 95.83333333%;
    }
    .rs-col-xl-push-23 {
      left: 95.83333333%;
    }
    .rs-col-xl-offset-23 {
      margin-left: 95.83333333%;
    }
    .rs-col-xl-24 {
      width: 100%;
    }
    .rs-col-xl-pull-24 {
      right: 100%;
    }
    .rs-col-xl-push-24 {
      left: 100%;
    }
    .rs-col-xl-offset-24 {
      margin-left: 100%;
    }
    .rs-col-xl-pull-0 {
      right: auto;
    }
    .rs-col-xl-push-0 {
      left: auto;
    }
  }
  @media (min-width: 1400px) {
    .rs-col-xxl-1 {
      width: 4.16666667%;
    }
    .rs-col-xxl-pull-1 {
      right: 4.16666667%;
    }
    .rs-col-xxl-push-1 {
      left: 4.16666667%;
    }
    .rs-col-xxl-offset-1 {
      margin-left: 4.16666667%;
    }
    .rs-col-xxl-2 {
      width: 8.33333333%;
    }
    .rs-col-xxl-pull-2 {
      right: 8.33333333%;
    }
    .rs-col-xxl-push-2 {
      left: 8.33333333%;
    }
    .rs-col-xxl-offset-2 {
      margin-left: 8.33333333%;
    }
    .rs-col-xxl-3 {
      width: 12.5%;
    }
    .rs-col-xxl-pull-3 {
      right: 12.5%;
    }
    .rs-col-xxl-push-3 {
      left: 12.5%;
    }
    .rs-col-xxl-offset-3 {
      margin-left: 12.5%;
    }
    .rs-col-xxl-4 {
      width: 16.66666667%;
    }
    .rs-col-xxl-pull-4 {
      right: 16.66666667%;
    }
    .rs-col-xxl-push-4 {
      left: 16.66666667%;
    }
    .rs-col-xxl-offset-4 {
      margin-left: 16.66666667%;
    }
    .rs-col-xxl-5 {
      width: 20.83333333%;
    }
    .rs-col-xxl-pull-5 {
      right: 20.83333333%;
    }
    .rs-col-xxl-push-5 {
      left: 20.83333333%;
    }
    .rs-col-xxl-offset-5 {
      margin-left: 20.83333333%;
    }
    .rs-col-xxl-6 {
      width: 25%;
    }
    .rs-col-xxl-pull-6 {
      right: 25%;
    }
    .rs-col-xxl-push-6 {
      left: 25%;
    }
    .rs-col-xxl-offset-6 {
      margin-left: 25%;
    }
    .rs-col-xxl-7 {
      width: 29.16666667%;
    }
    .rs-col-xxl-pull-7 {
      right: 29.16666667%;
    }
    .rs-col-xxl-push-7 {
      left: 29.16666667%;
    }
    .rs-col-xxl-offset-7 {
      margin-left: 29.16666667%;
    }
    .rs-col-xxl-8 {
      width: 33.33333333%;
    }
    .rs-col-xxl-pull-8 {
      right: 33.33333333%;
    }
    .rs-col-xxl-push-8 {
      left: 33.33333333%;
    }
    .rs-col-xxl-offset-8 {
      margin-left: 33.33333333%;
    }
    .rs-col-xxl-9 {
      width: 37.5%;
    }
    .rs-col-xxl-pull-9 {
      right: 37.5%;
    }
    .rs-col-xxl-push-9 {
      left: 37.5%;
    }
    .rs-col-xxl-offset-9 {
      margin-left: 37.5%;
    }
    .rs-col-xxl-10 {
      width: 41.66666667%;
    }
    .rs-col-xxl-pull-10 {
      right: 41.66666667%;
    }
    .rs-col-xxl-push-10 {
      left: 41.66666667%;
    }
    .rs-col-xxl-offset-10 {
      margin-left: 41.66666667%;
    }
    .rs-col-xxl-11 {
      width: 45.83333333%;
    }
    .rs-col-xxl-pull-11 {
      right: 45.83333333%;
    }
    .rs-col-xxl-push-11 {
      left: 45.83333333%;
    }
    .rs-col-xxl-offset-11 {
      margin-left: 45.83333333%;
    }
    .rs-col-xxl-12 {
      width: 50%;
    }
    .rs-col-xxl-pull-12 {
      right: 50%;
    }
    .rs-col-xxl-push-12 {
      left: 50%;
    }
    .rs-col-xxl-offset-12 {
      margin-left: 50%;
    }
    .rs-col-xxl-13 {
      width: 54.16666667%;
    }
    .rs-col-xxl-pull-13 {
      right: 54.16666667%;
    }
    .rs-col-xxl-push-13 {
      left: 54.16666667%;
    }
    .rs-col-xxl-offset-13 {
      margin-left: 54.16666667%;
    }
    .rs-col-xxl-14 {
      width: 58.33333333%;
    }
    .rs-col-xxl-pull-14 {
      right: 58.33333333%;
    }
    .rs-col-xxl-push-14 {
      left: 58.33333333%;
    }
    .rs-col-xxl-offset-14 {
      margin-left: 58.33333333%;
    }
    .rs-col-xxl-15 {
      width: 62.5%;
    }
    .rs-col-xxl-pull-15 {
      right: 62.5%;
    }
    .rs-col-xxl-push-15 {
      left: 62.5%;
    }
    .rs-col-xxl-offset-15 {
      margin-left: 62.5%;
    }
    .rs-col-xxl-16 {
      width: 66.66666667%;
    }
    .rs-col-xxl-pull-16 {
      right: 66.66666667%;
    }
    .rs-col-xxl-push-16 {
      left: 66.66666667%;
    }
    .rs-col-xxl-offset-16 {
      margin-left: 66.66666667%;
    }
    .rs-col-xxl-17 {
      width: 70.83333333%;
    }
    .rs-col-xxl-pull-17 {
      right: 70.83333333%;
    }
    .rs-col-xxl-push-17 {
      left: 70.83333333%;
    }
    .rs-col-xxl-offset-17 {
      margin-left: 70.83333333%;
    }
    .rs-col-xxl-18 {
      width: 75%;
    }
    .rs-col-xxl-pull-18 {
      right: 75%;
    }
    .rs-col-xxl-push-18 {
      left: 75%;
    }
    .rs-col-xxl-offset-18 {
      margin-left: 75%;
    }
    .rs-col-xxl-19 {
      width: 79.16666667%;
    }
    .rs-col-xxl-pull-19 {
      right: 79.16666667%;
    }
    .rs-col-xxl-push-19 {
      left: 79.16666667%;
    }
    .rs-col-xxl-offset-19 {
      margin-left: 79.16666667%;
    }
    .rs-col-xxl-20 {
      width: 83.33333333%;
    }
    .rs-col-xxl-pull-20 {
      right: 83.33333333%;
    }
    .rs-col-xxl-push-20 {
      left: 83.33333333%;
    }
    .rs-col-xxl-offset-20 {
      margin-left: 83.33333333%;
    }
    .rs-col-xxl-21 {
      width: 87.5%;
    }
    .rs-col-xxl-pull-21 {
      right: 87.5%;
    }
    .rs-col-xxl-push-21 {
      left: 87.5%;
    }
    .rs-col-xxl-offset-21 {
      margin-left: 87.5%;
    }
    .rs-col-xxl-22 {
      width: 91.66666667%;
    }
    .rs-col-xxl-pull-22 {
      right: 91.66666667%;
    }
    .rs-col-xxl-push-22 {
      left: 91.66666667%;
    }
    .rs-col-xxl-offset-22 {
      margin-left: 91.66666667%;
    }
    .rs-col-xxl-23 {
      width: 95.83333333%;
    }
    .rs-col-xxl-pull-23 {
      right: 95.83333333%;
    }
    .rs-col-xxl-push-23 {
      left: 95.83333333%;
    }
    .rs-col-xxl-offset-23 {
      margin-left: 95.83333333%;
    }
    .rs-col-xxl-24 {
      width: 100%;
    }
    .rs-col-xxl-pull-24 {
      right: 100%;
    }
    .rs-col-xxl-push-24 {
      left: 100%;
    }
    .rs-col-xxl-offset-24 {
      margin-left: 100%;
    }
    .rs-col-xxl-pull-0 {
      right: auto;
    }
    .rs-col-xxl-push-0 {
      left: auto;
    }
  }
  @media (max-width: 575px) {
    .rs-hidden-xs {
      display: none;
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    .rs-hidden-sm {
      display: none;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .rs-hidden-md {
      display: none;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .rs-hidden-lg {
      display: none;
    }
  }
  @media (min-width: 1200px) and (max-width: 1399px) {
    .rs-hidden-xl {
      display: none;
    }
  }
  @media (min-width: 1400px) {
    .rs-hidden-xxl {
      display: none;
    }
  }
  .rs-row {
    margin-left: -5px;
    margin-right: -5px;
  }
  .rs-row:after,
  .rs-row:before {
    content: " ";
    display: table;
  }
  .rs-row:after {
    clear: both;
  }
  .rs-col {
    float: left;
    min-height: 1px;
    padding-left: 5px;
    padding-right: 5px;
    position: relative;
  }
  .rs-header {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  .rs-form-help-text {
    color: #8e8e93;
    color: var(--rs-text-secondary);
    display: block;
    font-size: 12px;
    line-height: 1.66666667;
    min-height: 20px;
  }
  .rs-form-help-text-tooltip {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    margin-left: 10px;
    margin-top: 8px;
  }
  .rs-btn-icon > .rs-icon {
    vertical-align: bottom;
  }
  .rs-btn-icon-with-text > .rs-icon {
    background-color: #f2f2f5;
    background-color: var(--rs-iconbtn-addon);
    display: block;
    position: absolute;
    top: 0;
    -webkit-transition: color 0.15s ease-out, background-color 0.15s ease-out;
    transition: color 0.15s ease-out, background-color 0.15s ease-out;
  }
  .rs-btn-icon-with-text:focus > .rs-icon,
  .rs-btn-icon-with-text:hover > .rs-icon {
    background-color: #d9d9d9;
    background-color: var(--rs-iconbtn-activated-addon);
  }
  .rs-btn-icon-with-text.rs-btn-active > .rs-icon,
  .rs-btn-icon-with-text:active > .rs-icon {
    background-color: #c5c6c7;
    background-color: var(--rs-iconbtn-pressed-addon);
  }
  .rs-btn-icon-with-text.rs-btn-disabled > .rs-icon,
  .rs-btn-icon-with-text:disabled > .rs-icon {
    background-color: #f2f2f5;
    background-color: var(--rs-iconbtn-addon);
  }
  .rs-theme-high-contrast .rs-btn-icon-with-text.rs-btn-disabled,
  .rs-theme-high-contrast .rs-btn-icon-with-text:disabled {
    opacity: 0.5;
  }
  .rs-btn-icon-with-text.rs-btn-primary > .rs-icon {
    background-color: #2589f5;
    background-color: var(--rs-iconbtn-primary-addon);
  }
  .rs-btn-icon-with-text.rs-btn-primary:focus > .rs-icon,
  .rs-btn-icon-with-text.rs-btn-primary:hover > .rs-icon {
    background-color: #1675e0;
    background-color: var(--rs-iconbtn-primary-activated-addon);
  }
  .rs-btn-icon-with-text.rs-btn-primary.rs-btn-active > .rs-icon,
  .rs-btn-icon-with-text.rs-btn-primary:active > .rs-icon {
    background-color: #0a5dc2;
    background-color: var(--rs-iconbtn-primary-pressed-addon);
  }
  .rs-btn-icon-with-text.rs-btn-primary.rs-btn-disabled > .rs-icon,
  .rs-btn-icon-with-text.rs-btn-primary:disabled > .rs-icon {
    background-color: #2589f5;
    background-color: var(--rs-iconbtn-primary-addon);
  }
  .rs-theme-high-contrast .rs-btn-icon-with-text.rs-btn-primary.rs-btn-disabled,
  .rs-theme-high-contrast .rs-btn-icon-with-text.rs-btn-primary:disabled {
    opacity: 0.5;
  }
  .rs-btn-icon-circle {
    border-radius: 50% !important;
  }
  .rs-input {
    background-color: #fff;
    background-color: var(--rs-input-bg);
    background-image: none;
    border: 1px solid #e5e5ea;
    border: 1px solid var(--rs-border-primary);
    border-radius: 6px;
    color: #575757;
    color: var(--rs-text-primary);
    display: block;
    font-size: 14px;
    line-height: 1.42857143;
    padding: 7px 11px;
    -webkit-transition: border-color 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out;
    width: 100%;
  }
  @media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
    .rs-input {
      height: 36px;
    }
  }
  .rs-theme-high-contrast .rs-input {
    -webkit-transition: none;
    transition: none;
  }
  .rs-input::-webkit-input-placeholder {
    color: #8e8e93;
    color: var(--rs-text-secondary);
  }
  .rs-input::-moz-placeholder {
    color: #8e8e93;
    color: var(--rs-text-secondary);
  }
  .rs-input::-ms-input-placeholder {
    color: #8e8e93;
    color: var(--rs-text-secondary);
  }
  .rs-input::placeholder {
    color: #8e8e93;
    color: var(--rs-text-secondary);
  }
  .rs-input:focus,
  .rs-input:hover:not(:disabled) {
    border-color: #3498ff;
    border-color: var(--rs-input-focus-border);
  }
  .rs-input:focus {
    outline: 3px solid rgb(from #3498ff r g b/25%);
    outline: 3px solid var(--rs-color-focus-ring);
  }
  .rs-theme-high-contrast .rs-input:focus {
    outline-offset: 2px;
  }
  .rs-input:disabled {
    background-color: #f7f7fa;
    background-color: var(--rs-input-disabled-bg);
    color: #c5c6c7;
    color: var(--rs-text-disabled);
  }
  textarea.rs-input {
    overflow: auto;
    resize: vertical;
    height: auto;
  }
  .rs-input-xs {
    font-size: 12px;
    line-height: 1.66666667;
    padding: 1px 11px;
  }
  @media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
    .rs-input-xs {
      height: 24px;
    }
  }
  textarea.rs-input-xs {
    height: auto;
  }
  .rs-input-lg {
    font-size: 16px;
    line-height: 1.375;
    padding: 9px 11px;
  }
  @media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
    .rs-input-lg {
      height: 42px;
    }
  }
  textarea.rs-input-lg {
    height: auto;
  }
  .rs-input-sm {
    font-size: 14px;
    line-height: 1.42857143;
    padding: 4px 11px;
  }
  @media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
    .rs-input-sm {
      height: 30px;
    }
  }
  textarea.rs-input-sm {
    height: auto;
  }
  .rs-input-group {
    border: 1px solid #e5e5ea;
    border: 1px solid var(--rs-border-primary);
    border-radius: 6px;
    color: #575757;
    color: var(--rs-text-primary);
    cursor: text;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -webkit-transition: border-color 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out;
    width: 100%;
  }
  .rs-theme-high-contrast .rs-input-group {
    -webkit-transition: none;
    transition: none;
  }
  .rs-input-group:focus-within,
  .rs-input-group:not(.rs-input-group-disabled).rs-input-group-focus,
  .rs-input-group:not(.rs-input-group-disabled):hover {
    border-color: #3498ff;
    border-color: var(--rs-input-focus-border);
  }
  .rs-input-group:focus-within,
  .rs-input-group:not(.rs-input-group-disabled).rs-input-group-focus {
    outline: 3px solid rgb(from #3498ff r g b/25%);
    outline: 3px solid var(--rs-color-focus-ring);
  }
  .rs-theme-high-contrast .rs-input-group:focus-within,
  .rs-theme-high-contrast .rs-input-group:not(.rs-input-group-disabled).rs-input-group-focus {
    outline-offset: 2px;
  }
  .rs-input-group .rs-input-group {
    border-color: transparent !important;
    outline: none !important;
  }
  .rs-input-group .rs-input-group-addon,
  .rs-input-group .rs-input-group-btn,
  .rs-input-group .rs-input-number,
  .rs-input-group .rs-picker-date .rs-picker-toggle,
  .rs-input-group:not(.rs-input-group-inside) .rs-input {
    border: none;
    border-radius: 0;
    outline: none;
  }
  .rs-input-group:not(.rs-input-group-inside) .rs-input-number:not(:last-child) .rs-input-number-btn-group-vertical {
    border-radius: 0;
  }
  .rs-input-group:not(.rs-input-group-inside) .rs-input-number:not(:last-child) .rs-input-number-touchspin-up {
    border-top-right-radius: 0;
  }
  .rs-input-group:not(.rs-input-group-inside) .rs-input-number:not(:last-child) .rs-input-number-touchspin-down {
    border-bottom-right-radius: 0;
  }
  .rs-input-group:not(.rs-input-group-inside) .rs-input-number:not(:first-child) .rs-input {
    border-radius: 0 !important;
  }
  .rs-input-group:not(.rs-input-group-inside) .rs-auto-complete:first-child .rs-input,
  .rs-input-group:not(.rs-input-group-inside) .rs-form-control-wrapper:first-child > .rs-input,
  .rs-input-group:not(.rs-input-group-inside) .rs-picker-date:first-child .rs-picker-toggle,
  .rs-input-group:not(.rs-input-group-inside) > :first-child {
    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px;
  }
  .rs-input-group:not(.rs-input-group-inside) .rs-auto-complete:last-child .rs-input,
  .rs-input-group:not(.rs-input-group-inside) .rs-form-control-wrapper:last-child > .rs-input,
  .rs-input-group:not(.rs-input-group-inside) .rs-picker-date:last-child .rs-picker-toggle,
  .rs-input-group:not(.rs-input-group-inside) > :last-child {
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;
  }
  .rs-input-group > .rs-auto-complete,
  .rs-input-group > .rs-form-control-wrapper,
  .rs-input-group > .rs-input {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
  }
  .rs-input-group .rs-form-control-wrapper > .rs-input {
    width: 100%;
  }
  .rs-input-group > .rs-auto-complete,
  .rs-input-group > .rs-input {
    position: relative;
  }
  .rs-input-group .rs-form-control-wrapper ~ .rs-input-group-addon,
  .rs-input-group input.rs-input ~ .rs-input-group-addon {
    border-left: none;
    left: auto;
    right: 0;
  }
  .rs-input-group .rs-form-control-wrapper ~ .rs-input-group-addon:not(:last-child),
  .rs-input-group input.rs-input ~ .rs-input-group-addon:not(:last-child) {
    border-right: 0;
  }
  .rs-input-group.rs-input-group-inside {
    background-color: #fff;
    background-color: var(--rs-input-bg);
    width: 100%;
  }
  .rs-input-group.rs-input-group-inside .rs-input {
    border: none;
    display: block;
    outline: none;
    padding-right: 36px;
    width: 100%;
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-addon,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-btn {
    background-color: transparent;
    border: none;
    border-radius: 0 6px 6px 0;
    color: #575757;
    color: var(--rs-btn-subtle-text);
    color: inherit;
    height: 34px;
    padding: 7px 11px;
    position: absolute;
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-btn:focus,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn:hover {
    background-color: #e5e5ea;
    background-color: var(--rs-btn-subtle-hover-bg);
    color: #575757;
    color: var(--rs-btn-subtle-hover-text);
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-active,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn:active {
    background-color: #e5e5ea;
    background-color: var(--rs-btn-subtle-active-bg);
    color: #272c36;
    color: var(--rs-btn-subtle-active-text);
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-disabled,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn:disabled {
    background: none;
    color: #c5c6c7;
    color: var(--rs-btn-subtle-disabled-text);
  }
  .rs-theme-high-contrast .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-disabled,
  .rs-theme-high-contrast .rs-input-group.rs-input-group-inside .rs-input-group-btn:disabled {
    opacity: 0.5;
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-btn > .rs-icon {
    font-size: 14px;
    line-height: 1.42857143;
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-btn:focus {
    outline: none;
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-btn:first-child {
    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px;
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-btn:last-child {
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-addon {
    background: none;
    border: none;
    padding: 10px 12px;
    top: 0;
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-addon.rs-input-group-btn {
    padding: 8px 13px;
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-addon ~ .rs-auto-complete > input.rs-input,
  .rs-input-group.rs-input-group-inside .rs-input-group-addon ~ .rs-form-control-wrapper > input.rs-input,
  .rs-input-group.rs-input-group-inside .rs-input-group-addon ~ input.rs-input,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn ~ .rs-auto-complete > input.rs-input,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn ~ .rs-form-control-wrapper > input.rs-input,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn ~ input.rs-input {
    padding-left: 0;
    padding-right: 12px;
  }
  .rs-input-group.rs-input-group-inside .rs-auto-complete ~ .rs-input-group-addon,
  .rs-input-group.rs-input-group-inside .rs-form-control-wrapper ~ .rs-input-group-addon,
  .rs-input-group.rs-input-group-inside .rs-form-control-wrapper ~ .rs-input-group-btn,
  .rs-input-group.rs-input-group-inside input.rs-input ~ .rs-input-group-btn {
    left: inherit;
    right: 0;
  }
  .rs-input-group.rs-input-group-inside .rs-auto-complete ~ .rs-input-group-btn,
  .rs-input-group.rs-input-group-inside .rs-form-control-wrapper ~ .rs-input-group-btn {
    left: inherit;
    right: 1px;
  }
  .rs-input-group-disabled {
    background-color: #f7f7fa;
    background-color: var(--rs-input-disabled-bg);
    color: #c5c6c7;
    color: var(--rs-text-disabled);
    cursor: not-allowed;
  }
  .rs-input-group-disabled .rs-input,
  .rs-input-group-disabled .rs-input-group-addon,
  .rs-input-group-disabled .rs-input-group-btn {
    color: inherit;
  }
  .rs-input-group-lg > .rs-input {
    font-size: 16px;
    line-height: 1.375;
    padding: 9px 11px;
  }
  @media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
    .rs-input-group-lg > .rs-input {
      height: 42px;
    }
  }
  textarea.rs-input-group-lg > .rs-input {
    height: auto;
  }
  .rs-input-group-lg.rs-input-group-inside > .rs-input {
    padding-right: 0;
  }
  .rs-input-group-lg.rs-input-group > .rs-input,
  .rs-input-group-lg.rs-input-group > .rs-input-group-addon {
    height: 40px;
  }
  .rs-input-group-lg.rs-input-group > .rs-input-group-addon > .rs-icon {
    font-size: inherit;
  }
  .rs-input-group-lg.rs-input-group:not(.rs-input-group-inside) > .rs-input-group-addon {
    padding-bottom: 8px;
    padding-top: 8px;
  }
  .rs-input-group-md > .rs-input {
    font-size: 14px;
    line-height: 1.42857143;
    padding: 7px 11px;
  }
  @media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
    .rs-input-group-md > .rs-input {
      height: 36px;
    }
  }
  textarea.rs-input-group-md > .rs-input {
    height: auto;
  }
  .rs-input-group-md.rs-input-group-inside > .rs-input {
    padding-right: 0;
  }
  .rs-input-group-md.rs-input-group > .rs-input,
  .rs-input-group-md.rs-input-group > .rs-input-group-addon {
    height: 34px;
  }
  .rs-input-group-md.rs-input-group > .rs-input-group-addon > .rs-icon {
    font-size: inherit;
  }
  .rs-input-group-md.rs-input-group:not(.rs-input-group-inside) > .rs-input-group-addon {
    padding-bottom: 6px;
    padding-top: 6px;
  }
  .rs-input-group-sm > .rs-input {
    font-size: 14px;
    line-height: 1.42857143;
    padding: 4px 11px;
  }
  @media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
    .rs-input-group-sm > .rs-input {
      height: 30px;
    }
  }
  textarea.rs-input-group-sm > .rs-input {
    height: auto;
  }
  .rs-input-group-sm.rs-input-group-inside > .rs-input {
    padding-right: 0;
  }
  .rs-input-group-sm.rs-input-group > .rs-input,
  .rs-input-group-sm.rs-input-group > .rs-input-group-addon {
    height: 40px;
  }
  .rs-input-group-sm.rs-input-group > .rs-input-group-addon > .rs-icon {
    font-size: inherit;
  }
  .rs-input-group-sm.rs-input-group:not(.rs-input-group-inside) > .rs-input-group-addon {
    padding-bottom: 3px;
    padding-top: 3px;
  }
  .rs-input-group-xs > .rs-input {
    font-size: 12px;
    line-height: 1.66666667;
    padding: 1px 11px;
  }
  @media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
    .rs-input-group-xs > .rs-input {
      height: 24px;
    }
  }
  textarea.rs-input-group-xs > .rs-input {
    height: auto;
  }
  .rs-input-group-xs.rs-input-group-inside > .rs-input {
    padding-right: 0;
  }
  .rs-input-group-xs.rs-input-group > .rs-input,
  .rs-input-group-xs.rs-input-group > .rs-input-group-addon {
    height: 22px;
  }
  .rs-input-group-xs.rs-input-group > .rs-input-group-addon > .rs-icon {
    font-size: inherit;
  }
  .rs-input-group.rs-input-group-lg > .rs-input-group-addon {
    font-size: 16px;
    line-height: 1.375;
    min-width: 44px;
    padding: 9px 15px;
  }
  @media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
    .rs-input-group.rs-input-group-lg > .rs-input-group-addon {
      height: 42px;
    }
  }
  textarea.rs-input-group.rs-input-group-lg > .rs-input-group-addon {
    height: auto;
  }
  .rs-input-group.rs-input-group-md > .rs-input-group-addon {
    font-size: 14px;
    line-height: 1.42857143;
    min-width: 36px;
    padding: 7px 11px;
  }
  @media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
    .rs-input-group.rs-input-group-md > .rs-input-group-addon {
      height: 36px;
    }
  }
  textarea.rs-input-group.rs-input-group-md > .rs-input-group-addon {
    height: auto;
  }
  .rs-input-group.rs-input-group-sm > .rs-input-group-addon {
    font-size: 14px;
    line-height: 1.42857143;
    min-width: 32px;
    padding: 4px 9px;
  }
  @media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
    .rs-input-group.rs-input-group-sm > .rs-input-group-addon {
      height: 30px;
    }
  }
  textarea.rs-input-group.rs-input-group-sm > .rs-input-group-addon {
    height: auto;
  }
  .rs-input-group.rs-input-group-xs > .rs-input-group-addon {
    font-size: 12px;
    line-height: 1.66666667;
    min-width: 28px;
    padding: 1px 7px;
  }
  @media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
    .rs-input-group.rs-input-group-xs > .rs-input-group-addon {
      height: 24px;
    }
  }
  textarea.rs-input-group.rs-input-group-xs > .rs-input-group-addon {
    height: auto;
  }
  .rs-input-group-inside.rs-input-group-lg > .rs-input-group-btn {
    border-radius: 0 6px 6px 0;
    font-size: 16px;
    height: 40px;
    line-height: 1.375;
    padding: 9px 15px;
  }
  @media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
    .rs-input-group-inside.rs-input-group-lg > .rs-input-group-btn {
      height: 42px;
    }
  }
  textarea.rs-input-group-inside.rs-input-group-lg > .rs-input-group-btn {
    height: auto;
  }
  .rs-input-group-inside.rs-input-group-lg > .rs-input-group-btn > .rs-icon {
    font-size: 16px;
    line-height: 1.375;
  }
  .rs-input-group-inside.rs-input-group-md > .rs-input-group-btn {
    border-radius: 0 6px 6px 0;
    font-size: 14px;
    height: 34px;
    line-height: 1.42857143;
    padding: 7px 11px;
  }
  @media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
    .rs-input-group-inside.rs-input-group-md > .rs-input-group-btn {
      height: 36px;
    }
  }
  textarea.rs-input-group-inside.rs-input-group-md > .rs-input-group-btn {
    height: auto;
  }
  .rs-input-group-inside.rs-input-group-md > .rs-input-group-btn > .rs-icon {
    font-size: 14px;
    line-height: 1.42857143;
  }
  .rs-input-group-inside.rs-input-group-sm > .rs-input-group-btn {
    border-radius: 0 6px 6px 0;
    font-size: 14px;
    height: 28px;
    line-height: 1.42857143;
    padding: 4px 9px;
  }
  @media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
    .rs-input-group-inside.rs-input-group-sm > .rs-input-group-btn {
      height: 30px;
    }
  }
  textarea.rs-input-group-inside.rs-input-group-sm > .rs-input-group-btn {
    height: auto;
  }
  .rs-input-group-inside.rs-input-group-sm > .rs-input-group-btn > .rs-icon {
    font-size: 12px;
    line-height: 1.66666667;
  }
  .rs-input-group-inside.rs-input-group-xs > .rs-input-group-btn {
    border-radius: 0 6px 6px 0;
    font-size: 12px;
    height: 22px;
    line-height: 1.66666667;
    padding: 1px 7px;
  }
  @media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
    .rs-input-group-inside.rs-input-group-xs > .rs-input-group-btn {
      height: 24px;
    }
  }
  textarea.rs-input-group-inside.rs-input-group-xs > .rs-input-group-btn {
    height: auto;
  }
  .rs-input-group-inside.rs-input-group-xs > .rs-input-group-btn > .rs-icon {
    font-size: 12px;
    line-height: 1.66666667;
  }
  .rs-input-group-addon:not(:first-child):not(:last-child),
  .rs-input-group-btn:not(:first-child):not(:last-child),
  .rs-input-group:not(.rs-input-group-inside) .rs-input:not(:first-child):not(:last-child) {
    border-radius: 0;
  }
  .rs-input-group-addon {
    -webkit-box-flex: 0;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #f7f7fa;
    background-color: var(--rs-btn-default-bg);
    border: 1px solid #e5e5ea;
    border: 1px solid var(--rs-border-primary);
    border-radius: 6px;
    color: #575757;
    color: var(--rs-text-primary);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    padding: 8px 12px;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
  }
  .rs-input-group-addon.rs-input-sm {
    border-radius: 6px;
    font-size: 12px;
    padding: 5px 10px;
  }
  .rs-input-group-addon.rs-input-xs {
    border-radius: 6px;
    font-size: 12px;
    padding: 8px;
  }
  .rs-input-group-addon.rs-input-lg {
    border-radius: 6px;
    font-size: 16px;
    padding: 10px 16px;
  }
  .rs-input-group-addon input[type="checkbox"],
  .rs-input-group-addon input[type="radio"] {
    margin-top: 0;
  }
  .rs-input-group-btn {
    border-radius: 0;
    line-height: 1;
    position: relative;
    white-space: nowrap;
  }
  .rs-input-group-btn > .rs-btn {
    position: relative;
  }
  .rs-input-group-btn > .rs-btn + .rs-btn {
    margin-left: -1px;
  }
  .rs-input-group-btn:first-child > .rs-btn,
  .rs-input-group-btn:first-child > .rs-btn-group {
    border-right: none;
    margin-right: -1px;
  }
  .rs-input-group-btn:last-child > .rs-btn,
  .rs-input-group-btn:last-child > .rs-btn-group {
    margin-left: -1px;
  }
  .rs-input-number {
    background-color: #fff;
    background-color: var(--rs-input-bg);
  }
  .rs-input-number-btn-group-vertical {
    -webkit-box-flex: 0;
    display: block;
    -ms-flex: 0 0 20px;
    flex: 0 0 20px;
    position: relative;
    width: 20px;
  }
  .rs-input-number-btn-group-vertical > .rs-btn {
    border: none;
    display: block;
    float: none;
    max-width: 100%;
    position: relative;
    width: 100%;
  }
  .rs-input-number-touchspin-down,
  .rs-input-number-touchspin-up {
    border-radius: 0;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    height: 17px;
    padding: 0;
    position: relative;
  }
  .rs-input-group-lg .rs-input-number-touchspin-down,
  .rs-input-group-lg .rs-input-number-touchspin-down > .rs-icon,
  .rs-input-group-lg .rs-input-number-touchspin-up,
  .rs-input-group-lg .rs-input-number-touchspin-up > .rs-icon {
    height: 20px;
  }
  .rs-input-group-sm .rs-input-number-touchspin-down,
  .rs-input-group-sm .rs-input-number-touchspin-down > .rs-icon,
  .rs-input-group-sm .rs-input-number-touchspin-up,
  .rs-input-group-sm .rs-input-number-touchspin-up > .rs-icon {
    height: 14px;
  }
  .rs-input-group-xs .rs-input-number-touchspin-down,
  .rs-input-group-xs .rs-input-number-touchspin-down > .rs-icon,
  .rs-input-group-xs .rs-input-number-touchspin-up,
  .rs-input-group-xs .rs-input-number-touchspin-up > .rs-icon {
    height: 11px;
  }
  .rs-input-number-touchspin-down > .rs-icon,
  .rs-input-number-touchspin-up > .rs-icon {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 12px;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    height: 17px;
  }
  .rs-input-number-btn-group-vertical:last-child .rs-input-number-touchspin-up {
    border-top-right-radius: 6px;
  }
  .rs-input-number-btn-group-vertical:last-child .rs-input-number-touchspin-down {
    border-bottom-right-radius: 6px;
  }
  .rs-input-number > .rs-input {
    border-bottom-left-radius: 6px !important;
    border-top-left-radius: 6px !important;
  }
  .rs-input-number > .rs-input:disabled + .rs-input-number-btn-group-vertical .rs-input-number-touchspin-down,
  .rs-input-number > .rs-input:disabled + .rs-input-number-btn-group-vertical .rs-input-number-touchspin-up {
    background-color: #f7f7fa;
    background-color: var(--rs-input-disabled-bg);
  }
  .rs-input-number input[type="number"] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
  }
  .rs-input-number input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  .rs-picker-input {
    background-color: #fff;
    background-color: var(--rs-input-bg);
    border: 1px solid #e5e5ea;
    border: 1px solid var(--rs-border-primary);
    border-radius: 6px;
    position: relative;
    -webkit-transition: border-color 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out;
  }
  .rs-picker-input .rs-picker-toggle {
    background: transparent !important;
    border: none !important;
    cursor: text;
    height: 34px;
  }
  .rs-picker-input .rs-picker-toggle-caret,
  .rs-picker-input .rs-picker-toggle-clean {
    top: 7px;
  }
  .rs-picker-input .rs-picker-toggle .rs-ripple-pond {
    display: none;
  }
  .rs-picker-input:not(.rs-picker-disabled) .rs-picker-toggle {
    position: absolute !important;
  }
  .rs-picker-input .rs-picker-toggle.rs-btn-lg {
    height: 40px;
  }
  .rs-picker-input .rs-picker-toggle.rs-btn-md {
    height: 34px;
  }
  .rs-picker-input .rs-picker-toggle.rs-btn-sm {
    height: 28px;
  }
  .rs-picker-input .rs-picker-toggle.rs-btn-xs {
    height: 22px;
  }
  .rs-picker-textbox {
    margin-right: 32px;
    min-height: 34px;
  }
  .rs-picker-toggle.rs-btn-lg ~ .rs-picker-textbox {
    min-height: 40px;
  }
  .rs-picker-toggle.rs-btn-sm ~ .rs-picker-textbox {
    min-height: 28px;
  }
  .rs-picker-toggle.rs-btn-xs ~ .rs-picker-textbox {
    min-height: 22px;
  }
  .rs-picker-cleanable .rs-picker-textbox {
    margin-right: 44px;
  }
  .rs-picker-search {
    border: none;
    width: 100%;
  }
  .rs-picker-search-input {
    background: none;
    border: none;
    font-size: 14px;
    line-height: 1.42857143;
    outline: none;
    padding: 7px 0 7px 11px;
    position: relative;
    width: 100%;
  }
  @media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
    .rs-picker-search-input {
      height: 36px;
    }
  }
  textarea.rs-picker-search-input {
    height: auto;
  }
  .rs-picker-tag .rs-picker-search-input {
    font-size: 14px;
    line-height: 1.42857143;
    padding: 2px 12px 2px 11px;
  }
  .rs-picker-tag .rs-picker-search-input > input {
    background: none;
    border: none;
    outline: none;
    width: 100%;
  }
  .rs-picker-focused .rs-picker-search-input {
    z-index: 6;
  }
  .rs-list {
    -webkit-box-shadow: 0 1px 0 #e5e5ea, 0 -1px 0 #e5e5ea;
    -webkit-box-shadow: 0 1px 0 var(--rs-list-border), 0 -1px 0 var(--rs-list-border);
    box-shadow: 0 1px 0 #e5e5ea, 0 -1px 0 #e5e5ea;
    box-shadow: 0 1px 0 var(--rs-list-border), 0 -1px 0 var(--rs-list-border);
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
  }
  .rs-list-bordered {
    border-radius: 6px;
    -webkit-box-shadow: 0 0 0 1px #e5e5ea;
    -webkit-box-shadow: 0 0 0 1px var(--rs-list-border);
    box-shadow: 0 0 0 1px #e5e5ea;
    box-shadow: 0 0 0 1px var(--rs-list-border);
  }
  .rs-list-hover .rs-list-item:hover {
    background-color: #f2faff;
    background-color: var(--rs-list-hover-bg);
  }
  .rs-list-sortable .rs-list-item {
    cursor: move;
    cursor: -webkit-grab;
    cursor: grab;
  }
  .rs-list-sortable .rs-list-item-disabled,
  .rs-list-sortable .rs-list-item-disabled:active {
    cursor: not-allowed;
  }
  .rs-list-item-bordered {
    padding-left: 20px;
    padding-right: 20px;
  }
  .rs-list-item-lg {
    padding-bottom: 20px;
    padding-top: 20px;
  }
  .rs-list-item-md {
    padding-bottom: 13px;
    padding-top: 13px;
  }
  .rs-list-item-sm {
    padding-bottom: 8px;
    padding-top: 8px;
  }
  .rs-list-item {
    position: relative;
    -webkit-box-shadow: 0 -1px 0 #e5e5ea, 0 1px 0 #e5e5ea;
    -webkit-box-shadow: 0 -1px 0 var(--rs-list-border), 0 1px 0 var(--rs-list-border);
    box-shadow: 0 -1px 0 #e5e5ea, 0 1px 0 #e5e5ea;
    box-shadow: 0 -1px 0 var(--rs-list-border), 0 1px 0 var(--rs-list-border);
  }
  .rs-list-item,
  .rs-list-item-helper {
    background-color: #fff;
    background-color: var(--rs-list-bg);
  }
  .rs-list-item-helper {
    border: none;
    border-radius: 6px;
    -webkit-box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
    cursor: move;
    left: 0;
    position: absolute;
    top: 0;
    width: calc(100% - 2px);
    z-index: 99;
  }
  .rs-drawer-open .rs-list-item-helper,
  .rs-modal-open .rs-list-item-helper {
    z-index: 1149;
  }
  .rs-list-item-holder {
    background-color: rgb(from #f2faff r g b/50%);
    background-color: var(--rs-list-placeholder-bg);
    visibility: hidden;
  }
  .rs-list-item-holder:after {
    border: 1px dashed #3498ff;
    border: 1px dashed var(--rs-list-placeholder-border);
    content: "";
    height: calc(100% - 2px);
    left: 1px;
    position: absolute;
    top: 1px;
    visibility: visible;
    width: calc(100% - 2px);
  }
  .rs-loader {
    display: inline-block;
  }
  .rs-loader:after,
  .rs-loader:before {
    content: " ";
    display: table;
  }
  .rs-loader:after {
    clear: both;
  }
  .rs-loader .rs-loader-spin,
  .rs-loader .rs-loader-spin:after,
  .rs-loader .rs-loader-spin:before {
    height: 18px;
    width: 18px;
  }
  .rs-loader .rs-loader-content {
    font-size: 12px;
    line-height: 1.5;
  }
  .rs-loader .rs-loader-vertical .rs-loader .rs-loader {
    height: 48px;
  }
  .rs-loader .rs-loader-vertical .rs-loader .rs-loader-content {
    line-height: 1.66666667;
  }
  .rs-loader-spin {
    display: inline-block;
    float: left;
    position: relative;
  }
  .rs-loader-spin:after,
  .rs-loader-spin:before {
    border-radius: 50%;
    content: "";
    display: block;
    left: 0;
    position: absolute;
    right: 0;
  }
  .rs-loader-spin:before {
    border: 3px solid rgb(from #f7f7fa r g b/80%);
    border: 3px solid var(--rs-loader-ring);
  }
  .rs-loader-spin:after {
    -webkit-animation: loaderSpin 0.6s linear infinite;
    animation: loaderSpin 0.6s linear infinite;
    border: 3px solid transparent;
    border-top-color: #a6a6a6;
    border-color: var(--rs-loader-rotor) transparent transparent;
  }
  .rs-loader-content {
    display: inline-block;
    float: left;
  }
  .rs-loader-spin + .rs-loader-content {
    margin-left: 12px;
  }
  .rs-loader-xs .rs-loader-spin + .rs-loader-content {
    margin-left: 10px;
  }
  .rs-loader-backdrop {
    background: rgb(from #fff r g b/90%);
    background: var(--rs-loader-backdrop);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .rs-loader-vertical {
    display: inline-block;
    width: auto;
  }
  .rs-loader-vertical .rs-loader {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .rs-loader-vertical .rs-loader-spin {
    margin: 0 auto;
  }
  .rs-loader-vertical .rs-loader-content {
    line-height: 1.66666667;
    text-align: center;
  }
  .rs-loader-vertical .rs-loader-content,
  .rs-loader-vertical .rs-loader-spin {
    display: block;
    float: none;
  }
  .rs-loader-vertical .rs-loader-spin + .rs-loader-content {
    margin-left: 0;
    margin-top: 10px;
  }
  .rs-loader-inverse .rs-loader-content {
    color: #f7f7fa;
    color: var(--rs-text-inverse);
  }
  .rs-loader-inverse .rs-loader-backdrop {
    background: rgb(from #272c36 r g b/83%);
    background: var(--rs-loader-backdrop-inverse);
  }
  .rs-loader-inverse .rs-loader-spin:before {
    border-color: rgb(from #f7f7fa r g b/30%);
    border-color: var(--rs-loader-ring-inverse);
  }
  .rs-loader-inverse .rs-loader-spin:after {
    border-top-color: #fff;
    border-top-color: var(--rs-loader-rotor-inverse);
  }
  .rs-loader-speed-fast .rs-loader-spin:after {
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
  }
  .rs-loader-speed-normal .rs-loader-spin:after {
    -webkit-animation-duration: 0.6s;
    animation-duration: 0.6s;
  }
  .rs-loader-speed-slow .rs-loader-spin:after {
    -webkit-animation-duration: 0.8s;
    animation-duration: 0.8s;
  }
  .rs-loader-backdrop-wrapper,
  .rs-loader-center {
    bottom: 0;
    height: 100%;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }
  .rs-loader-backdrop-wrapper .rs-loader,
  .rs-loader-center .rs-loader {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
  }
  .rs-loader-backdrop-wrapper:not(.rs-loader-vertical) .rs-loader,
  .rs-loader-center:not(.rs-loader-vertical) .rs-loader {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .rs-loader-backdrop-wrapper.rs-loader-vertical .rs-loader,
  .rs-loader-center.rs-loader-vertical .rs-loader {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
  }
  .rs-loader-backdrop-wrapper .rs-loader-content,
  .rs-loader-center .rs-loader-content {
    z-index: 1;
  }
  .rs-loader-lg .rs-loader-spin,
  .rs-loader-lg .rs-loader-spin:after,
  .rs-loader-lg .rs-loader-spin:before {
    height: 64px;
    width: 64px;
  }
  .rs-loader-lg .rs-loader-content {
    font-size: 16px;
    line-height: 4;
  }
  .rs-loader-lg .rs-loader-vertical .rs-loader-lg .rs-loader {
    height: 94px;
  }
  .rs-loader-lg .rs-loader-vertical .rs-loader-lg .rs-loader-content {
    line-height: 1.25;
  }
  .rs-loader-md .rs-loader-spin,
  .rs-loader-md .rs-loader-spin:after,
  .rs-loader-md .rs-loader-spin:before {
    height: 36px;
    width: 36px;
  }
  .rs-loader-md .rs-loader-content {
    font-size: 14px;
    line-height: 2.57142857;
  }
  .rs-loader-md .rs-loader-vertical .rs-loader-md .rs-loader {
    height: 66px;
  }
  .rs-loader-md .rs-loader-vertical .rs-loader-md .rs-loader-content {
    line-height: 1.42857143;
  }
  .rs-loader-sm .rs-loader-spin,
  .rs-loader-sm .rs-loader-spin:after,
  .rs-loader-sm .rs-loader-spin:before {
    height: 18px;
    width: 18px;
  }
  .rs-loader-sm .rs-loader-content {
    font-size: 14px;
    line-height: 1.28571429;
  }
  .rs-loader-sm .rs-loader-vertical .rs-loader-sm .rs-loader {
    height: 48px;
  }
  .rs-loader-sm .rs-loader-vertical .rs-loader-sm .rs-loader-content {
    line-height: 1.42857143;
  }
  .rs-loader-xs .rs-loader-spin,
  .rs-loader-xs .rs-loader-spin:after,
  .rs-loader-xs .rs-loader-spin:before {
    height: 16px;
    width: 16px;
  }
  .rs-loader-xs .rs-loader-content {
    font-size: 12px;
    line-height: 1.33333333;
  }
  .rs-loader-xs .rs-loader-vertical .rs-loader-xs .rs-loader {
    height: 46px;
  }
  .rs-loader-xs .rs-loader-vertical .rs-loader-xs .rs-loader-content {
    line-height: 1.66666667;
  }
  .rs-loader-wrapper {
    display: inline-block;
    line-height: 0;
    width: auto;
  }
  @-webkit-keyframes loaderSpin {
    0% {
      -webkit-transform: rotate(0);
      transform: rotate(0);
    }
    to {
      -webkit-transform: rotate(1turn);
      transform: rotate(1turn);
    }
  }
  @keyframes loaderSpin {
    0% {
      -webkit-transform: rotate(0);
      transform: rotate(0);
    }
    to {
      -webkit-transform: rotate(1turn);
      transform: rotate(1turn);
    }
  }
  @-webkit-keyframes notificationMoveIn {
    0% {
      opacity: 0;
      -webkit-transform: scaleY(0.8);
      transform: scaleY(0.8);
      -webkit-transform-origin: 0 0;
      transform-origin: 0 0;
    }
    to {
      opacity: 1;
      -webkit-transform: scaleY(1);
      transform: scaleY(1);
      -webkit-transform-origin: 0 0;
      transform-origin: 0 0;
    }
  }
  @keyframes notificationMoveIn {
    0% {
      opacity: 0;
      -webkit-transform: scaleY(0.8);
      transform: scaleY(0.8);
      -webkit-transform-origin: 0 0;
      transform-origin: 0 0;
    }
    to {
      opacity: 1;
      -webkit-transform: scaleY(1);
      transform: scaleY(1);
      -webkit-transform-origin: 0 0;
      transform-origin: 0 0;
    }
  }
  @-webkit-keyframes notificationMoveInLeft {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
    to {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
  @keyframes notificationMoveInLeft {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
    to {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
  @-webkit-keyframes notificationMoveInRight {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(100%, 0, 0);
      transform: translate3d(100%, 0, 0);
    }
    to {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
  @keyframes notificationMoveInRight {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(100%, 0, 0);
      transform: translate3d(100%, 0, 0);
    }
    to {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
  @-webkit-keyframes notificationMoveOut {
    0% {
      max-height: 100px;
      opacity: 1;
      -webkit-transform: scaleY(1);
      transform: scaleY(1);
      -webkit-transform-origin: 0 0;
      transform-origin: 0 0;
    }
    to {
      max-height: 0;
      opacity: 0;
      overflow: hidden;
      -webkit-transform: scaleY(0.8);
      transform: scaleY(0.8);
      -webkit-transform-origin: 0 0;
      transform-origin: 0 0;
    }
  }
  @keyframes notificationMoveOut {
    0% {
      max-height: 100px;
      opacity: 1;
      -webkit-transform: scaleY(1);
      transform: scaleY(1);
      -webkit-transform-origin: 0 0;
      transform-origin: 0 0;
    }
    to {
      max-height: 0;
      opacity: 0;
      overflow: hidden;
      -webkit-transform: scaleY(0.8);
      transform: scaleY(0.8);
      -webkit-transform-origin: 0 0;
      transform-origin: 0 0;
    }
  }
  .rs-message {
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    border-radius: 6px;
    font-size: 14px;
    line-height: 1.42857143;
    overflow: hidden;
    position: relative;
  }
  .rs-message-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 20px 28px 20px 20px;
  }
  .rs-message-centered .rs-message-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 20px;
    text-align: center;
  }
  .rs-message-centered .rs-message-icon {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .rs-message-centered .rs-message-icon .rs-icon {
    font-size: 40px !important;
  }
  .rs-message-centered.rs-message-bordered {
    border-left-width: 1px !important;
    border-top-width: 4px !important;
  }
  .rs-message-icon {
    -ms-flex-item-align: center;
    align-self: center;
    font-size: 0;
    margin-right: 10px;
  }
  .rs-message-icon .rs-icon {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 16px;
  }
  .rs-message-content {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }
  .rs-message-header {
    color: #272c36;
    color: var(--rs-text-heading);
    font-weight: 700;
    line-height: 1.71428571;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
  .rs-message-header + .rs-message-body {
    margin-top: 4px;
  }
  .rs-message-body {
    color: #575757;
    color: var(--rs-text-primary);
  }
  .rs-message-body ol,
  .rs-message-body ul {
    -webkit-padding-start: 16px;
    margin: 0;
    padding: 0;
    padding-inline-start: 16px;
  }
  .rs-message.rs-message-has-title .rs-message-icon {
    -ms-flex-item-align: start;
    align-self: flex-start;
  }
  .rs-message.rs-message-has-title .rs-message-icon .rs-icon {
    font-size: 24px;
    line-height: 1;
  }
  .rs-message:not(.rs-message-hiding) + .rs-message {
    margin-top: 10px;
  }
  .rs-message:not(.rs-message-hiding) + .rs-message.rs-message-hiding:last-child {
    margin-top: 0;
    -webkit-transition: margin-top 0.1s linear 0.3s;
    transition: margin-top 0.1s linear 0.3s;
  }
  .rs-message.rs-message-hiding {
    -webkit-animation: notificationMoveOut 0.3s ease-in forwards;
    animation: notificationMoveOut 0.3s ease-in forwards;
  }
  .rs-theme-dark .rs-message .rs-btn-link,
  .rs-theme-dark .rs-message a {
    color: inherit;
    text-decoration: underline;
  }
  .rs-message-full {
    border-radius: 0;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1090;
  }
  .rs-message-success {
    background-color: #fff;
  }
  .rs-message-success.rs-message-bordered {
    border: 1px solid #4caf50;
    border: 1px solid var(--rs-message-success-border);
    border-left-width: 4px;
  }
  .rs-message-success.rs-message-bordered .rs-message-icon {
    border: 4px solid #a5e0a4;
    border: 4px solid var(--rs-message-success-icon-border);
    border-radius: 50%;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
  }
  .rs-message-success .rs-message-container {
    background-color: rgb(from #c8f0c7 r g b/60%);
    background-color: var(--rs-message-success-bg);
  }
  .rs-message-success .rs-message-header {
    color: #272c36;
    color: var(--rs-message-success-header);
  }
  .rs-message-success .rs-message-body {
    color: #575757;
    color: var(--rs-message-success-text);
  }
  .rs-message-success .rs-btn-close {
    font-size: 12px;
    padding: 8px;
    position: absolute;
    right: 0;
    top: 0;
  }
  .rs-message-success .rs-btn-close,
  .rs-message-success .rs-message-icon > .rs-icon {
    color: #4caf50;
    color: var(--rs-message-success-icon);
  }
  .rs-theme-high-contrast .rs-message-success {
    border: 1px solid #4caf50;
    border: 1px solid var(--rs-message-success-border);
  }
  .rs-message-info {
    background-color: #fff;
  }
  .rs-message-info.rs-message-bordered {
    border: 1px solid #2196f3;
    border: 1px solid var(--rs-message-info-border);
    border-left-width: 4px;
  }
  .rs-message-info.rs-message-bordered .rs-message-icon {
    border: 4px solid #9bd4fa;
    border: 4px solid var(--rs-message-info-icon-border);
    border-radius: 50%;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
  }
  .rs-message-info .rs-message-container {
    background-color: rgb(from #c5e7fc r g b/60%);
    background-color: var(--rs-message-info-bg);
  }
  .rs-message-info .rs-message-header {
    color: #272c36;
    color: var(--rs-message-info-header);
  }
  .rs-message-info .rs-message-body {
    color: #575757;
    color: var(--rs-message-info-text);
  }
  .rs-message-info .rs-btn-close {
    font-size: 12px;
    padding: 8px;
    position: absolute;
    right: 0;
    top: 0;
  }
  .rs-message-info .rs-btn-close,
  .rs-message-info .rs-message-icon > .rs-icon {
    color: #2196f3;
    color: var(--rs-message-info-icon);
  }
  .rs-theme-high-contrast .rs-message-info {
    border: 1px solid #2196f3;
    border: 1px solid var(--rs-message-info-border);
  }
  .rs-message-warning {
    background-color: #fff;
  }
  .rs-message-warning.rs-message-bordered {
    border: 1px solid #ffb300;
    border: 1px solid var(--rs-message-warning-border);
    border-left-width: 4px;
  }
  .rs-message-warning.rs-message-bordered .rs-message-icon {
    border: 4px solid #ffd991;
    border: 4px solid var(--rs-message-warning-icon-border);
    border-radius: 50%;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
  }
  .rs-message-warning .rs-message-container {
    background-color: rgb(from #ffe9c2 r g b/60%);
    background-color: var(--rs-message-warning-bg);
  }
  .rs-message-warning .rs-message-header {
    color: #272c36;
    color: var(--rs-message-warning-header);
  }
  .rs-message-warning .rs-message-body {
    color: #575757;
    color: var(--rs-message-warning-text);
  }
  .rs-message-warning .rs-btn-close {
    font-size: 12px;
    padding: 8px;
    position: absolute;
    right: 0;
    top: 0;
  }
  .rs-message-warning .rs-btn-close,
  .rs-message-warning .rs-message-icon > .rs-icon {
    color: #ffb300;
    color: var(--rs-message-warning-icon);
  }
  .rs-theme-high-contrast .rs-message-warning {
    border: 1px solid #ffb300;
    border: 1px solid var(--rs-message-warning-border);
  }
  .rs-message-error {
    background-color: #fff;
  }
  .rs-message-error.rs-message-bordered {
    border: 1px solid #f44336;
    border: 1px solid var(--rs-message-error-border);
    border-left-width: 4px;
  }
  .rs-message-error.rs-message-bordered .rs-message-icon {
    border: 4px solid #faa9a7;
    border: 4px solid var(--rs-message-error-icon-border);
    border-radius: 50%;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
  }
  .rs-message-error .rs-message-container {
    background-color: rgb(from #fccfcf r g b/60%);
    background-color: var(--rs-message-error-bg);
  }
  .rs-message-error .rs-message-header {
    color: #272c36;
    color: var(--rs-message-error-header);
  }
  .rs-message-error .rs-message-body {
    color: #575757;
    color: var(--rs-message-error-text);
  }
  .rs-message-error .rs-btn-close {
    font-size: 12px;
    padding: 8px;
    position: absolute;
    right: 0;
    top: 0;
  }
  .rs-message-error .rs-btn-close,
  .rs-message-error .rs-message-icon > .rs-icon {
    color: #f44336;
    color: var(--rs-message-error-icon);
  }
  .rs-theme-high-contrast .rs-message-error {
    border: 1px solid #f44336;
    border: 1px solid var(--rs-message-error-border);
  }
  .rs-modal-backdrop {
    background-color: rgb(from #272c36 r g b/30%);
    background-color: var(--rs-bg-backdrop);
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 1049;
  }
  .rs-modal-backdrop.rs-anim-fade {
    opacity: 0;
    -webkit-transition: opacity 0.3s ease-in;
    transition: opacity 0.3s ease-in;
  }
  .rs-modal-backdrop.rs-anim-in {
    opacity: 1;
  }
  .rs-modal-open {
    overflow: hidden;
  }
  .rs-modal-wrapper {
    height: 100%;
    left: 0;
    overflow: auto;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1050;
  }
  .rs-modal {
    display: none;
    margin: 30px auto 0;
    outline: 0;
    overflow: visible;
    position: relative;
    width: auto;
    z-index: 1050;
  }
  .rs-modal-lg {
    width: 968px;
  }
  .rs-modal-lg,
  .rs-modal-md {
    max-width: calc(100% - 10px);
  }
  .rs-modal-md {
    width: 800px;
  }
  .rs-modal-sm {
    width: 600px;
  }
  .rs-modal-sm,
  .rs-modal-xs {
    max-width: calc(100% - 10px);
  }
  .rs-modal-xs {
    width: 400px;
  }
  .rs-modal-full {
    height: 100%;
    margin: 0;
  }
  .rs-modal-full .rs-modal-content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    border-radius: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%;
    position: absolute;
    width: 100%;
  }
  .rs-modal-full .rs-modal-content .rs-modal-header {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  .rs-modal-full .rs-modal-content .rs-modal-body {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    overflow: auto;
  }
  .rs-modal-full .rs-modal-content .rs-modal-footer {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  .rs-modal-shake .rs-modal-dialog {
    -webkit-animation: shakeHead 0.3s linear;
    animation: shakeHead 0.3s linear;
  }
  .rs-modal-content {
    background-color: #fff;
    background-color: var(--rs-bg-overlay);
    border: none;
    border: var(--rs-modal-border, none);
    border-radius: 6px;
    -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
    -webkit-box-shadow: var(--rs-modal-shadow);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
    box-shadow: var(--rs-modal-shadow);
    outline: 0;
    padding: 20px;
    position: relative;
  }
  .rs-modal-header {
    padding-right: 20px;
  }
  .rs-modal-header:after,
  .rs-modal-header:before {
    content: " ";
    display: table;
  }
  .rs-modal-header:after {
    clear: both;
  }
  .rs-modal-header .rs-modal-header-close {
    color: #8e8e93;
    color: var(--rs-text-secondary);
    font-size: 12px;
    padding: 0;
    position: absolute;
    right: 20px;
    top: 20px;
  }
  .rs-modal-header .rs-modal-header-close:hover {
    color: #575757;
    color: var(--rs-text-primary);
  }
  .rs-modal-title {
    color: #272c36;
    color: var(--rs-text-heading);
    display: block;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.11111111;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
  .rs-modal-body {
    margin-top: 20px;
    padding-bottom: 20px;
    position: relative;
  }
  .rs-modal-footer {
    border-top: none;
    text-align: right;
  }
  .rs-modal-footer:after,
  .rs-modal-footer:before {
    content: " ";
    display: table;
  }
  .rs-modal-footer:after {
    clear: both;
  }
  .rs-modal-footer .rs-btn + .rs-btn {
    margin-bottom: 0;
    margin-left: 10px;
  }
  .rs-modal-footer .rs-btn-group .rs-btn + .rs-btn {
    margin-left: -1px;
  }
  .rs-modal-footer .rs-btn-block + .rs-btn-block {
    margin-left: 0;
  }
  .rs-modal-scrollbar-measure {
    height: 50px;
    overflow: scroll;
    position: absolute;
    top: -9999px;
    width: 50px;
  }
  .rs-picker-cascader-menu .rs-check-item .rs-checkbox-checker > label {
    padding-right: 26px;
  }
  .rs-picker-cascader-menu .rs-check-item.rs-check-item-focus .rs-checkbox-checker > label,
  .rs-picker-cascader-menu .rs-check-item.rs-checkbox-checked .rs-checkbox-checker > label {
    font-weight: 700;
  }
  .rs-picker-cascader-menu-column.rs-picker-cascader-menu-column-uncheckable .rs-check-item .rs-checkbox-checker > label {
    padding-left: 12px;
  }
  .rs-picker-multi-cascader-menu .rs-picker-cascader-col {
    padding: 0;
  }
  .rs-picker-multi-cascader-menu .rs-picker-cascader-row {
    padding-bottom: 0;
    padding-left: 0;
    padding-top: 0;
  }
  .rs-nav {
    outline: 0;
    position: relative;
  }
  .rs-nav .rs-dropdown > .rs-dropdown-toggle {
    height: 36px;
    vertical-align: bottom;
  }
  .rs-nav-default .rs-nav-item,
  .rs-nav-tabs .rs-nav-item {
    overflow: hidden;
    position: relative;
    -webkit-transition: color 0.15s ease-out, background-color 0.15s ease-out;
    transition: color 0.15s ease-out, background-color 0.15s ease-out;
  }
  @media not all and (min-resolution: 0.001dpcm) {
    .rs-nav-default .rs-nav-item,
    .rs-nav-tabs .rs-nav-item {
      -webkit-mask-image: -webkit-radial-gradient(#fff, #000);
    }
  }
  .rs-nav-item {
    color: #575757;
    color: var(--rs-navs-text);
    cursor: pointer;
    outline: none;
    overflow: hidden;
    padding: 8px 12px;
    text-decoration: none;
    text-overflow: ellipsis;
    -webkit-transition: color 0.15s ease-out, background-color 0.15s ease-out;
    transition: color 0.15s ease-out, background-color 0.15s ease-out;
    white-space: nowrap;
  }
  @media not all and (min-resolution: 0.001dpcm) {
    .rs-nav-item {
      -webkit-mask-image: -webkit-radial-gradient(#fff, #000);
    }
  }
  .rs-nav-item:active,
  .rs-nav-item:focus,
  .rs-nav-item:hover {
    text-decoration: none;
  }
  .rs-theme-high-contrast .rs-nav-item {
    -webkit-transition: none;
    transition: none;
  }
  .rs-nav-item.rs-nav-item-focus,
  .rs-nav-item:focus,
  .rs-nav-item:hover {
    color: #575757;
    color: var(--rs-navs-text-hover);
  }
  .rs-theme-high-contrast .rs-nav-item.rs-nav-item-focus,
  .rs-theme-high-contrast .rs-nav-item:focus,
  .rs-theme-high-contrast .rs-nav-item:hover {
    text-decoration: underline;
  }
  .rs-nav-item:focus-visible {
    outline: 3px solid rgb(from #3498ff r g b/25%);
    outline: 3px solid var(--rs-color-focus-ring);
    outline-offset: 0;
    outline-width: 2px;
    z-index: 2;
  }
  .rs-theme-high-contrast .rs-nav-item:focus-visible {
    outline-offset: 2px;
  }
  .rs-nav-item:active {
    color: #272c36;
    color: var(--rs-navs-text-active);
  }
  .rs-theme-high-contrast .rs-nav-item:active {
    text-decoration: underline;
  }
  .rs-nav-item.rs-nav-item-disabled {
    color: #c5c6c7;
    color: var(--rs-text-disabled);
    cursor: not-allowed;
    pointer-events: none;
  }
  .rs-nav-item.rs-nav-item-active {
    color: #1675e0;
    color: var(--rs-navs-selected);
    position: relative;
    z-index: 1;
  }
  .rs-theme-high-contrast .rs-nav-item.rs-nav-item-active {
    text-decoration: underline;
  }
  .rs-nav-item-icon {
    margin-right: 6px;
  }
  .rs-nav-item-caret {
    font-size: 16px;
    margin-left: 6px;
    vertical-align: text-bottom;
  }
  .rs-nav-horizontal {
    white-space: nowrap;
  }
  .rs-nav-horizontal > .rs-nav-item {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    vertical-align: top;
  }
  .rs-nav-horizontal .rs-nav-bar {
    bottom: 0;
    position: absolute;
    width: 100%;
  }
  .rs-nav-reversed.rs-nav-horizontal .rs-nav-bar {
    bottom: auto;
    top: 0;
  }
  .rs-nav-vertical > .rs-nav-item {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .rs-nav-vertical > .rs-dropdown {
    width: 100%;
  }
  .rs-nav-vertical > .rs-dropdown > .rs-dropdown-toggle {
    text-align: left;
    width: 100%;
    z-index: 0;
  }
  .rs-nav-vertical .rs-nav-bar {
    bottom: 0;
    display: block;
    position: absolute;
    right: 0;
    top: 0;
  }
  .rs-nav-reversed.rs-nav-vertical .rs-nav-bar {
    right: auto;
  }
  .rs-nav-default .rs-nav-item {
    border-radius: 6px;
  }
  .rs-nav-default .rs-nav-item:focus,
  .rs-nav-default .rs-nav-item:hover,
  .rs-nav-tabs .rs-nav-item:focus,
  .rs-nav-tabs .rs-nav-item:hover {
    background: #e5e5ea;
    background: var(--rs-navs-bg-hover);
  }
  .rs-nav-tabs .rs-nav-item.rs-nav-item-active {
    background-color: #fff;
    background-color: var(--rs-bg-card);
    border: 1px solid #d9d9d9;
    border: 1px solid var(--rs-navs-tab-border);
    z-index: 1;
  }
  .rs-nav-tabs.rs-nav-horizontal > .rs-dropdown .rs-dropdown-toggle,
  .rs-nav-tabs.rs-nav-horizontal > .rs-nav-item {
    border-radius: 6px 6px 0 0;
  }
  .rs-nav-reversed.rs-nav-tabs.rs-nav-horizontal > .rs-dropdown .rs-dropdown-toggle,
  .rs-nav-reversed.rs-nav-tabs.rs-nav-horizontal > .rs-nav-item {
    border-radius: 0 0 6px 6px;
  }
  .rs-nav-tabs.rs-nav-horizontal .rs-nav-bar {
    border-top: 1px solid #d9d9d9;
    border-top: 1px solid var(--rs-navs-tab-border);
  }
  .rs-nav-tabs.rs-nav-horizontal .rs-nav-item.rs-nav-item-active {
    border-bottom-width: 0;
  }
  .rs-nav-reversed.rs-nav-tabs.rs-nav-horizontal .rs-nav-item.rs-nav-item-active {
    border-bottom-width: 1px;
    border-top-width: 0;
  }
  .rs-nav-tabs.rs-nav-vertical > .rs-dropdown .rs-dropdown-toggle,
  .rs-nav-tabs.rs-nav-vertical > .rs-nav-item {
    border-radius: 6px 0 0 6px;
  }
  .rs-nav-reversed.rs-nav-tabs.rs-nav-vertical > .rs-dropdown .rs-dropdown-toggle,
  .rs-nav-reversed.rs-nav-tabs.rs-nav-vertical > .rs-nav-item {
    border-radius: 0 6px 6px 0;
  }
  .rs-nav-tabs.rs-nav-vertical .rs-nav-bar {
    background: #d9d9d9;
    background: var(--rs-navs-tab-border);
    width: 1px;
  }
  .rs-nav-tabs.rs-nav-vertical .rs-nav-item.rs-nav-item-active {
    border-right-width: 0;
  }
  .rs-nav-reversed.rs-nav-tabs.rs-nav-vertical .rs-nav-item.rs-nav-item-active {
    border-left-width: 0;
    border-right-width: 1px;
  }
  .rs-nav-subtle .rs-nav-item {
    position: relative;
  }
  .rs-nav-subtle .rs-nav-item:focus,
  .rs-nav-subtle .rs-nav-item:hover {
    color: #1675e0;
    color: var(--rs-navs-selected);
  }
  .rs-nav-subtle .rs-nav-item.rs-nav-item-active:before {
    background-color: #1675e0;
    background-color: var(--rs-navs-selected);
    content: "";
    display: block;
    position: absolute;
    z-index: 1;
  }
  .rs-nav-subtle.rs-nav-horizontal .rs-nav-bar {
    border-top: 2px solid #f7f7fa;
    border-top: 2px solid var(--rs-navs-subtle-border);
  }
  .rs-nav-subtle.rs-nav-horizontal .rs-nav-item.rs-nav-item-active:before {
    bottom: 0;
    height: 2px;
    left: 0;
    right: 0;
  }
  .rs-nav-reversed.rs-nav-subtle.rs-nav-horizontal .rs-nav-item.rs-nav-item-active:before {
    bottom: auto;
    top: 0;
  }
  .rs-nav-subtle.rs-nav-vertical .rs-nav-bar {
    background: #f7f7fa;
    background: var(--rs-navs-subtle-border);
    width: 2px;
  }
  .rs-nav-subtle.rs-nav-vertical .rs-nav-item.rs-nav-item-active:before {
    bottom: 0;
    right: 0;
    top: 0;
    width: 2px;
  }
  .rs-nav-reversed.rs-nav-subtle.rs-nav-vertical .rs-nav-item.rs-nav-item-active:before {
    left: 0;
    right: auto;
  }
  .rs-nav-justified {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .rs-nav-justified > .rs-dropdown,
  .rs-nav-justified > .rs-nav-item {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 1%;
    flex: 1 1 1%;
  }
  .rs-nav-justified > .rs-dropdown .rs-dropdown-toggle {
    text-align: left;
    width: 100%;
  }
  .rs-navbar:after,
  .rs-navbar:before {
    content: " ";
    display: table;
  }
  .rs-navbar:after {
    clear: both;
  }
  .rs-navbar-brand,
  .rs-navbar-header {
    color: inherit;
    display: inline-block;
    float: left;
    height: 56px;
  }
  .rs-navbar-header a {
    color: inherit;
  }
  .rs-navbar-brand {
    padding: 18px 20px;
  }
  .rs-navbar-nav {
    float: left;
  }
  .rs-navbar-nav.rs-navbar-right {
    float: right;
  }
  .rs-navbar-nav:focus .rs-dropdown .rs-dropdown-toggle:focus-visible,
  .rs-navbar-nav:focus .rs-dropdown.rs-dropdown-focus .rs-dropdown-toggle,
  .rs-navbar-nav:focus .rs-navbar-item.rs-navbar-item-focus,
  .rs-navbar-nav:focus .rs-navbar-item:focus-visible {
    outline: 3px solid rgb(from #3498ff r g b/25%);
    outline: 3px solid var(--rs-color-focus-ring);
    z-index: 1;
  }
  .rs-theme-high-contrast .rs-navbar-nav:focus .rs-dropdown .rs-dropdown-toggle:focus-visible,
  .rs-theme-high-contrast .rs-navbar-nav:focus .rs-dropdown.rs-dropdown-focus .rs-dropdown-toggle,
  .rs-theme-high-contrast .rs-navbar-nav:focus .rs-navbar-item.rs-navbar-item-focus,
  .rs-theme-high-contrast .rs-navbar-nav:focus .rs-navbar-item:focus-visible {
    outline-offset: 2px;
  }
  .rs-theme-high-contrast .rs-navbar-nav:focus .rs-dropdown .rs-dropdown-toggle:focus-visible,
  .rs-theme-high-contrast .rs-navbar-nav:focus .rs-dropdown.rs-dropdown-focus .rs-dropdown-toggle,
  .rs-theme-high-contrast .rs-navbar-nav:focus .rs-navbar-item.rs-navbar-item-focus,
  .rs-theme-high-contrast .rs-navbar-nav:focus .rs-navbar-item:focus-visible {
    outline: 3px solid rgb(from #3498ff r g b/25%);
    outline: 3px solid var(--rs-color-focus-ring);
    outline-offset: -3px;
    outline-width: 2px;
  }
  .rs-theme-high-contrast .rs-theme-high-contrast .rs-navbar-nav:focus .rs-dropdown .rs-dropdown-toggle:focus-visible,
  .rs-theme-high-contrast .rs-theme-high-contrast .rs-navbar-nav:focus .rs-dropdown.rs-dropdown-focus .rs-dropdown-toggle,
  .rs-theme-high-contrast .rs-theme-high-contrast .rs-navbar-nav:focus .rs-navbar-item.rs-navbar-item-focus,
  .rs-theme-high-contrast .rs-theme-high-contrast .rs-navbar-nav:focus .rs-navbar-item:focus-visible {
    outline-offset: 2px;
  }
  .rs-navbar-item,
  .rs-navbar-nav > .rs-dropdown .rs-dropdown-toggle {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: transparent;
    border-radius: 0;
    color: inherit;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    float: left;
    height: 56px;
    overflow: hidden;
    padding: 18px 16px;
    position: relative;
  }
  .rs-navbar-item-icon {
    font-size: 16px;
    margin-right: 5px;
  }
  .rs-navbar-item-caret {
    font-size: 16px;
    margin-left: 6px;
  }
  .rs-navbar-brand,
  .rs-navbar-brand:active,
  .rs-navbar-brand:focus,
  .rs-navbar-brand:hover,
  .rs-navbar-item,
  .rs-navbar-item:active,
  .rs-navbar-item:focus,
  .rs-navbar-item:hover,
  .rs-navbar-nav > .rs-dropdown .rs-dropdown-toggle,
  .rs-navbar-nav > .rs-dropdown-item,
  .rs-navbar-nav > .rs-dropdown-item:active,
  .rs-navbar-nav > .rs-dropdown-item:focus,
  .rs-navbar-nav > .rs-dropdown-item:hover {
    text-decoration: none;
  }
  .rs-navbar-brand:focus-visible,
  .rs-navbar-item:focus-visible,
  .rs-navbar-nav > .rs-dropdown .rs-dropdown-toggle:focus,
  .rs-navbar-nav > .rs-dropdown .rs-dropdown-toggle:focus-visible,
  .rs-navbar-nav > .rs-dropdown-item:focus-visible {
    outline: 3px solid rgb(from #3498ff r g b/25%);
    outline: 3px solid var(--rs-color-focus-ring);
    outline-offset: -3px;
    z-index: 2;
  }
  .rs-theme-high-contrast .rs-navbar-brand:focus-visible,
  .rs-theme-high-contrast .rs-navbar-item:focus-visible,
  .rs-theme-high-contrast .rs-navbar-nav > .rs-dropdown-item:focus-visible {
    outline-offset: 2px;
  }
  .rs-theme-high-contrast .rs-navbar-brand:focus-visible,
  .rs-theme-high-contrast .rs-navbar-item:focus-visible,
  .rs-theme-high-contrast .rs-navbar-nav > .rs-dropdown-item:focus-visible {
    outline: 3px solid rgb(from #3498ff r g b/25%);
    outline: 3px solid var(--rs-color-focus-ring);
    outline-offset: -3px;
    outline-width: 2px;
  }
  .rs-theme-high-contrast .rs-theme-high-contrast .rs-navbar-brand:focus-visible,
  .rs-theme-high-contrast .rs-theme-high-contrast .rs-navbar-item:focus-visible,
  .rs-theme-high-contrast .rs-theme-high-contrast .rs-navbar-nav > .rs-dropdown-item:focus-visible {
    outline-offset: 2px;
  }
  .rs-navbar-item,
  .rs-navbar-nav > .rs-dropdown,
  .rs-navbar-nav > .rs-dropdown .rs-dropdown-toggle {
    margin: 0 !important;
  }
  .rs-theme-high-contrast .rs-navbar-item.rs-navbar-item-active {
    text-decoration: underline;
  }
  .rs-navbar-nav > .rs-dropdown .rs-dropdown-toggle {
    padding-right: 36px;
  }
  .rs-theme-high-contrast .rs-navbar-nav > .rs-dropdown .rs-dropdown-toggle {
    border: none;
  }
  .rs-theme-high-contrast .rs-navbar-nav > .rs-dropdown .rs-dropdown-toggle:focus-visible {
    outline: 3px solid rgb(from #3498ff r g b/25%);
    outline: 3px solid var(--rs-color-focus-ring);
    outline-offset: -3px;
    outline-width: 2px;
  }
  .rs-theme-high-contrast .rs-theme-high-contrast .rs-navbar-nav > .rs-dropdown .rs-dropdown-toggle:focus-visible {
    outline-offset: 2px;
  }
  .rs-navbar-nav > .rs-dropdown .rs-dropdown-toggle .rs-dropdown-toggle-caret {
    right: 16px;
    top: 18px;
  }
  .rs-navbar .rs-dropdown-item:hover {
    background-color: rgb(from #cce9ff r g b/50%);
    background-color: var(--rs-menuitem-active-bg);
    color: #1675e0;
    color: var(--rs-menuitem-active-text);
  }
  .rs-theme-high-contrast .rs-navbar .rs-dropdown-item:hover {
    -webkit-box-shadow: inset 0 0 0 2px #fff;
    box-shadow: inset 0 0 0 2px #fff;
    text-decoration: underline;
  }
  .rs-navbar-default {
    background-color: #f7f7fa;
    background-color: var(--rs-navbar-default-bg);
    color: #575757;
    color: var(--rs-navbar-default-text);
  }
  .rs-navbar-default .rs-dropdown .rs-dropdown-toggle:hover,
  .rs-navbar-default .rs-dropdown.rs-dropdown-open .rs-dropdown-toggle,
  .rs-navbar-default .rs-navbar-item:hover {
    background-color: #e5e5ea;
    background-color: var(--rs-navbar-default-hover-bg);
    color: #575757;
    color: var(--rs-navbar-default-hover-text);
  }
  .rs-navbar-default .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle,
  .rs-navbar-default .rs-navbar-item.rs-navbar-item-active {
    color: #1675e0;
    color: var(--rs-navbar-default-selected-text);
  }
  .rs-navbar-inverse {
    background-color: #3498ff;
    background-color: var(--rs-navbar-inverse-bg);
    color: #fff;
    color: var(--rs-navbar-inverse-text);
  }
  .rs-navbar-inverse .rs-dropdown .rs-dropdown-toggle:focus,
  .rs-navbar-inverse .rs-dropdown .rs-dropdown-toggle:hover,
  .rs-navbar-inverse .rs-navbar-item:focus,
  .rs-navbar-inverse .rs-navbar-item:hover {
    background-color: #2589f5;
    background-color: var(--rs-navbar-inverse-hover-bg);
    color: #fff;
    color: var(--rs-navbar-inverse-hover-text);
  }
  .rs-navbar-inverse .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle,
  .rs-navbar-inverse .rs-navbar-item.rs-navbar-item-active {
    background-color: #1675e0;
    background-color: var(--rs-navbar-inverse-selected-bg);
  }
  .rs-theme-high-contrast .rs-navbar-inverse .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle,
  .rs-theme-high-contrast .rs-navbar-inverse .rs-navbar-item.rs-navbar-item-active {
    color: var(--rs-navbar-inverse-selected-text);
  }
  .rs-navbar-subtle {
    background-color: transparent;
    color: #575757;
    color: var(--rs-navbar-subtle-text);
  }
  .rs-navbar-subtle .rs-dropdown .rs-dropdown-toggle:focus,
  .rs-navbar-subtle .rs-dropdown .rs-dropdown-toggle:hover,
  .rs-navbar-subtle .rs-navbar-item:focus,
  .rs-navbar-subtle .rs-navbar-item:hover {
    background-color: #f7f7fa;
    background-color: var(--rs-navbar-subtle-hover-bg);
    color: #575757;
    color: var(--rs-navbar-subtle-hover-text);
  }
  .rs-navbar-subtle .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle,
  .rs-navbar-subtle .rs-navbar-item.rs-navbar-item-active {
    color: #1675e0;
    color: var(--rs-navbar-subtle-selected-text);
  }
  .rs-notification {
    -webkit-box-flex: 0;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    background: #fff;
    background: var(--rs-bg-overlay);
    border-radius: 6px;
    -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
    display: inline-block;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    overflow: hidden;
    pointer-events: auto;
    position: relative;
  }
  .rs-notification:not(.rs-toast-fade-exited) ~ .rs-notification {
    margin-top: 10px;
  }
  .rs-theme-high-contrast .rs-notification {
    border: 1px solid #e5e5ea;
    border: 1px solid var(--rs-border-primary);
  }
  .rs-notification-content {
    max-width: 400px;
    padding: 20px;
  }
  .rs-notification-title {
    color: #272c36;
    color: var(--rs-text-heading);
    font-size: 16px;
    line-height: 1.5;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
  .rs-notification-title > p {
    margin: 0;
  }
  .rs-notification-title .rs-icon {
    font-size: 24px;
    line-height: 1;
    margin-right: 10px;
    width: 24px;
  }
  .rs-notification-title-with-icon {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
  .rs-notification-description {
    color: #575757;
    color: var(--rs-text-primary);
  }
  .rs-notification-title + .rs-notification-description {
    margin-top: 8px;
  }
  .rs-notification.rs-notification-hiding {
    -webkit-animation: notificationMoveOut 0.3s ease-in forwards;
    animation: notificationMoveOut 0.3s ease-in forwards;
  }
  .rs-notification.rs-notification-hiding ~ .rs-notification {
    margin-top: 0;
  }
  .rs-notification .rs-btn-close {
    font-size: 12px;
    padding: 8px;
    position: absolute;
    right: 0;
    top: 0;
  }
  .rs-notification-success .rs-notification-content .rs-notification-title + .rs-notification-description {
    margin-left: 34px;
  }
  .rs-notification-success .rs-notification-content .rs-icon {
    color: #4caf50;
    color: var(--rs-state-success);
  }
  .rs-notification-success .rs-notification-content .rs-icon:before {
    vertical-align: middle;
  }
  .rs-notification-info .rs-notification-content .rs-notification-title + .rs-notification-description {
    margin-left: 34px;
  }
  .rs-notification-info .rs-notification-content .rs-icon {
    color: #2196f3;
    color: var(--rs-state-info);
  }
  .rs-notification-info .rs-notification-content .rs-icon:before {
    vertical-align: middle;
  }
  .rs-notification-warning .rs-notification-content .rs-notification-title + .rs-notification-description {
    margin-left: 34px;
  }
  .rs-notification-warning .rs-notification-content .rs-icon {
    color: #ffb300;
    color: var(--rs-state-warning);
  }
  .rs-notification-warning .rs-notification-content .rs-icon:before {
    vertical-align: middle;
  }
  .rs-notification-error .rs-notification-content .rs-notification-title + .rs-notification-description {
    margin-left: 34px;
  }
  .rs-notification-error .rs-notification-content .rs-icon {
    color: #f44336;
    color: var(--rs-state-error);
  }
  .rs-notification-error .rs-notification-content .rs-icon:before {
    vertical-align: middle;
  }
  .rs-pagination-group {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .rs-pagination-group-lg {
    font-size: 16px;
  }
  .rs-pagination-group-md,
  .rs-pagination-group-sm {
    font-size: 14px;
  }
  .rs-pagination-group-xs {
    font-size: 12px;
  }
  .rs-pagination-group-grow {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }
  .rs-pagination-group .rs-pagination {
    vertical-align: middle;
  }
  .rs-pagination-group-limit + .rs-pagination-group-total {
    margin-left: 18px;
  }
  .rs-pagination-group-skip {
    display: inline-block;
    margin-left: 10px;
    vertical-align: middle;
  }
  .rs-pagination-group-skip .rs-input {
    display: inline-block;
    margin: 0 5px;
    width: 46px;
  }
  .rs-pagination {
    display: inline-block;
    margin-bottom: 0;
    padding-left: 0;
  }
  .rs-pagination-lg .rs-pagination-btn {
    font-size: 16px;
    line-height: 22px;
    min-width: 42px;
    padding: 10px 16px;
  }
  .rs-btn-ghost.rs-pagination-lg .rs-pagination-btn {
    padding: 9px 15px;
  }
  .rs-btn-icon.rs-pagination-lg .rs-pagination-btn {
    line-height: 20px;
    padding: 11px;
  }
  .rs-btn-icon.rs-pagination-lg .rs-pagination-btn > .rs-icon {
    font-size: 20px;
  }
  .rs-btn-icon-with-text.rs-pagination-lg .rs-pagination-btn {
    line-height: 22px;
  }
  .rs-btn-icon-with-text.rs-pagination-lg .rs-pagination-btn > .rs-icon {
    height: 42px;
    padding: 11px;
    width: 42px;
  }
  .rs-btn-icon-with-text.rs-pagination-lg .rs-pagination-btn.rs-btn-icon-placement-left {
    padding: 10px 16px 10px 58px;
  }
  .rs-btn-icon-with-text.rs-pagination-lg .rs-pagination-btn.rs-btn-icon-placement-left > .rs-icon {
    border-right: none;
    border-right: var(--rs-btn-default-border, none);
    left: 0;
  }
  .rs-btn-icon-with-text.rs-pagination-lg .rs-pagination-btn.rs-btn-icon-placement-right {
    padding: 10px 58px 10px 16px;
  }
  .rs-btn-icon-with-text.rs-pagination-lg .rs-pagination-btn.rs-btn-icon-placement-right > .rs-icon {
    border-left: none;
    border-left: var(--rs-btn-default-border, none);
    right: 0;
  }
  .rs-pagination-lg .rs-pagination-btn .rs-pagination-symbol {
    height: 22px;
  }
  .rs-pagination-lg .rs-pagination-btn-active {
    padding: 9px 15px;
  }
  .rs-pagination-md .rs-pagination-btn {
    font-size: 14px;
    line-height: 20px;
    min-width: 36px;
    padding: 8px 12px;
  }
  .rs-btn-ghost.rs-pagination-md .rs-pagination-btn {
    padding: 7px 11px;
  }
  .rs-btn-icon.rs-pagination-md .rs-pagination-btn {
    line-height: 16px;
    padding: 10px;
  }
  .rs-btn-icon.rs-pagination-md .rs-pagination-btn > .rs-icon {
    font-size: 16px;
  }
  .rs-btn-icon-with-text.rs-pagination-md .rs-pagination-btn {
    line-height: 20px;
  }
  .rs-btn-icon-with-text.rs-pagination-md .rs-pagination-btn > .rs-icon {
    height: 36px;
    padding: 10px;
    width: 36px;
  }
  .rs-btn-icon-with-text.rs-pagination-md .rs-pagination-btn.rs-btn-icon-placement-left {
    padding: 8px 12px 8px 48px;
  }
  .rs-btn-icon-with-text.rs-pagination-md .rs-pagination-btn.rs-btn-icon-placement-left > .rs-icon {
    border-right: none;
    border-right: var(--rs-btn-default-border, none);
    left: 0;
  }
  .rs-btn-icon-with-text.rs-pagination-md .rs-pagination-btn.rs-btn-icon-placement-right {
    padding: 8px 48px 8px 12px;
  }
  .rs-btn-icon-with-text.rs-pagination-md .rs-pagination-btn.rs-btn-icon-placement-right > .rs-icon {
    border-left: none;
    border-left: var(--rs-btn-default-border, none);
    right: 0;
  }
  .rs-pagination-md .rs-pagination-btn-active {
    padding: 7px 11px;
  }
  .rs-pagination-sm .rs-pagination-btn {
    font-size: 14px;
    line-height: 20px;
    min-width: 30px;
    padding: 5px 10px;
  }
  .rs-btn-ghost.rs-pagination-sm .rs-pagination-btn {
    padding: 4px 9px;
  }
  .rs-btn-icon.rs-pagination-sm .rs-pagination-btn {
    line-height: 16px;
    padding: 7px;
  }
  .rs-btn-icon.rs-pagination-sm .rs-pagination-btn > .rs-icon {
    font-size: 16px;
  }
  .rs-btn-icon-with-text.rs-pagination-sm .rs-pagination-btn {
    line-height: 20px;
  }
  .rs-btn-icon-with-text.rs-pagination-sm .rs-pagination-btn > .rs-icon {
    height: 30px;
    padding: 7px;
    width: 30px;
  }
  .rs-btn-icon-with-text.rs-pagination-sm .rs-pagination-btn.rs-btn-icon-placement-left {
    padding: 5px 10px 5px 40px;
  }
  .rs-btn-icon-with-text.rs-pagination-sm .rs-pagination-btn.rs-btn-icon-placement-left > .rs-icon {
    border-right: none;
    border-right: var(--rs-btn-default-border, none);
    left: 0;
  }
  .rs-btn-icon-with-text.rs-pagination-sm .rs-pagination-btn.rs-btn-icon-placement-right {
    padding: 5px 40px 5px 10px;
  }
  .rs-btn-icon-with-text.rs-pagination-sm .rs-pagination-btn.rs-btn-icon-placement-right > .rs-icon {
    border-left: none;
    border-left: var(--rs-btn-default-border, none);
    right: 0;
  }
  .rs-pagination-sm .rs-pagination-btn-active {
    padding: 4px 9px;
  }
  .rs-pagination-xs .rs-pagination-btn {
    font-size: 12px;
    line-height: 20px;
    min-width: 24px;
    padding: 2px 8px;
  }
  .rs-btn-ghost.rs-pagination-xs .rs-pagination-btn {
    padding: 1px 7px;
  }
  .rs-btn-icon.rs-pagination-xs .rs-pagination-btn {
    line-height: 12px;
    padding: 6px;
  }
  .rs-btn-icon.rs-pagination-xs .rs-pagination-btn > .rs-icon {
    font-size: 12px;
  }
  .rs-btn-icon-with-text.rs-pagination-xs .rs-pagination-btn {
    line-height: 20px;
  }
  .rs-btn-icon-with-text.rs-pagination-xs .rs-pagination-btn > .rs-icon {
    height: 24px;
    padding: 6px;
    width: 24px;
  }
  .rs-btn-icon-with-text.rs-pagination-xs .rs-pagination-btn.rs-btn-icon-placement-left {
    padding: 2px 8px 2px 32px;
  }
  .rs-btn-icon-with-text.rs-pagination-xs .rs-pagination-btn.rs-btn-icon-placement-left > .rs-icon {
    border-right: none;
    border-right: var(--rs-btn-default-border, none);
    left: 0;
  }
  .rs-btn-icon-with-text.rs-pagination-xs .rs-pagination-btn.rs-btn-icon-placement-right {
    padding: 2px 32px 2px 8px;
  }
  .rs-btn-icon-with-text.rs-pagination-xs .rs-pagination-btn.rs-btn-icon-placement-right > .rs-icon {
    border-left: none;
    border-left: var(--rs-btn-default-border, none);
    right: 0;
  }
  .rs-pagination-xs .rs-pagination-btn-active {
    padding: 1px 7px;
  }
  .rs-pagination-btn {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    align-items: center;
    background-color: #f7f7fa;
    background-color: var(--rs-btn-default-bg);
    background-color: transparent;
    border: none;
    border-radius: 6px;
    color: var(--rs-btn-default-text);
    color: #575757;
    color: var(--rs-btn-subtle-text);
    cursor: pointer;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    float: left;
    font-size: 14px;
    font-weight: 400;
    justify-content: center;
    line-height: 20px;
    margin: 0 2px;
    overflow: hidden;
    padding: 5px 10px;
    position: relative;
    text-align: center;
    text-decoration: none;
    -webkit-transition: color 0.15s ease-out, background-color 0.15s ease-out;
    transition: color 0.15s ease-out, background-color 0.15s ease-out;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;
  }
  .rs-btn-ghost.rs-pagination-btn {
    padding: 7px 11px;
  }
  .rs-btn-icon.rs-pagination-btn {
    padding: 10px;
  }
  .rs-btn-icon-with-text.rs-pagination-btn > .rs-icon {
    height: 36px;
    padding: 10px;
    width: 36px;
  }
  .rs-btn-icon-with-text.rs-pagination-btn.rs-btn-icon-placement-left {
    padding: 8px 12px 8px 48px;
  }
  .rs-btn-icon-with-text.rs-pagination-btn.rs-btn-icon-placement-right {
    padding: 8px 48px 8px 12px;
  }
  .rs-pagination-btn:focus-visible {
    outline: 3px solid rgb(from #3498ff r g b/25%);
    outline: 3px solid var(--rs-color-focus-ring);
  }
  .rs-theme-high-contrast .rs-pagination-btn:focus-visible {
    outline-offset: 2px;
  }
  .rs-pagination-btn:focus,
  .rs-pagination-btn:hover {
    background-color: var(--rs-btn-default-hover-bg);
    color: var(--rs-btn-default-hover-text);
    text-decoration: none;
  }
  .rs-pagination-btn.rs-btn-active,
  .rs-pagination-btn:active {
    background-color: #d9d9d9;
    background-color: var(--rs-btn-default-active-bg);
    color: var(--rs-btn-default-active-text);
  }
  .rs-pagination-btn.rs-btn-disabled,
  .rs-pagination-btn:disabled {
    background-color: #f7f7fa;
    background-color: var(--rs-btn-default-disabled-bg);
    color: var(--rs-btn-default-disabled-text);
    cursor: not-allowed;
  }
  @media not all and (min-resolution: 0.001dpcm) {
    .rs-pagination-btn {
      -webkit-mask-image: -webkit-radial-gradient(#fff, #000);
    }
  }
  .rs-pagination-btn:focus,
  .rs-pagination-btn:hover {
    background-color: #e5e5ea;
    background-color: var(--rs-btn-subtle-hover-bg);
    color: #575757;
    color: var(--rs-btn-subtle-hover-text);
  }
  .rs-pagination-btn.rs-btn-active,
  .rs-pagination-btn:active {
    background-color: #e5e5ea;
    background-color: var(--rs-btn-subtle-active-bg);
    color: #272c36;
    color: var(--rs-btn-subtle-active-text);
  }
  .rs-pagination-btn.rs-btn-disabled,
  .rs-pagination-btn:disabled {
    background: none;
    color: #c5c6c7;
    color: var(--rs-btn-subtle-disabled-text);
  }
  .rs-theme-high-contrast .rs-pagination-btn.rs-btn-disabled,
  .rs-theme-high-contrast .rs-pagination-btn:disabled {
    opacity: 0.5;
  }
  .rs-btn-ghost.rs-pagination-btn {
    padding: 4px 9px;
  }
  .rs-btn-icon.rs-pagination-btn {
    line-height: 16px;
    padding: 7px;
  }
  .rs-btn-icon.rs-pagination-btn > .rs-icon {
    font-size: 16px;
  }
  .rs-btn-icon-with-text.rs-pagination-btn {
    line-height: 20px;
  }
  .rs-btn-icon-with-text.rs-pagination-btn > .rs-icon {
    height: 30px;
    padding: 7px;
    width: 30px;
  }
  .rs-btn-icon-with-text.rs-pagination-btn.rs-btn-icon-placement-left {
    padding: 5px 10px 5px 40px;
  }
  .rs-btn-icon-with-text.rs-pagination-btn.rs-btn-icon-placement-left > .rs-icon {
    border-right: none;
    border-right: var(--rs-btn-default-border, none);
    left: 0;
  }
  .rs-btn-icon-with-text.rs-pagination-btn.rs-btn-icon-placement-right {
    padding: 5px 40px 5px 10px;
  }
  .rs-btn-icon-with-text.rs-pagination-btn.rs-btn-icon-placement-right > .rs-icon {
    border-left: none;
    border-left: var(--rs-btn-default-border, none);
    right: 0;
  }
  .rs-pagination-btn .rs-pagination-symbol {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 20px;
  }
  .rs-theme-high-contrast .rs-pagination-btn {
    color: var(--rs-pagination-item-text);
    -webkit-transition: none;
    transition: none;
  }
  .rs-theme-high-contrast .rs-pagination-btn:focus-visible,
  .rs-theme-high-contrast .rs-pagination-btn:hover {
    color: var(--rs-pagination-item-current-text);
    text-decoration: underline;
  }
  .rs-pagination-btn.rs-pagination-btn-active {
    background-color: transparent;
    border: 1px solid #1675e0;
    border: 1px solid var(--rs-btn-ghost-border);
    color: #1675e0;
    color: var(--rs-btn-ghost-text);
  }
  .rs-pagination-btn.rs-pagination-btn-active:focus,
  .rs-pagination-btn.rs-pagination-btn-active:hover {
    background-color: transparent;
    border-color: #0a5dc2;
    border-color: var(--rs-btn-ghost-hover-border);
    -webkit-box-shadow: 0 0 0 1px #0a5dc2;
    -webkit-box-shadow: 0 0 0 1px var(--rs-btn-ghost-hover-border);
    box-shadow: 0 0 0 1px #0a5dc2;
    box-shadow: 0 0 0 1px var(--rs-btn-ghost-hover-border);
    color: #0a5dc2;
    color: var(--rs-btn-ghost-hover-text);
  }
  .rs-pagination-btn.rs-pagination-btn-active.rs-btn-active,
  .rs-pagination-btn.rs-pagination-btn-active:active {
    background-color: transparent;
    border-color: #004299;
    border-color: var(--rs-btn-ghost-active-border);
    color: #004299;
    color: var(--rs-btn-ghost-active-text);
  }
  .rs-pagination-btn.rs-pagination-btn-active.rs-btn-disabled,
  .rs-pagination-btn.rs-pagination-btn-active:disabled {
    background-color: transparent;
    border-color: #1675e0;
    border-color: var(--rs-btn-ghost-border);
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #1675e0;
    color: var(--rs-btn-ghost-text);
    opacity: 0.3;
  }
  .rs-theme-high-contrast .rs-pagination-btn.rs-pagination-btn-active.rs-btn-disabled,
  .rs-theme-high-contrast .rs-pagination-btn.rs-pagination-btn-active:disabled {
    opacity: 0.5;
  }
  .rs-theme-high-contrast .rs-pagination-btn.rs-pagination-btn-active {
    color: var(--rs-pagination-item-current-text);
    text-decoration: underline;
  }
  [dir="rtl"] .rs-pagination-btn [class*="rs-icon-page"] {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  .rs-panel {
    border-radius: 6px;
    overflow: hidden;
  }
  .rs-panel .rs-anim-collapse {
    display: none;
  }
  .rs-panel .rs-anim-collapse.rs-anim-in {
    display: block;
  }
  .rs-panel-bordered {
    border: 1px solid #e5e5ea;
    border: 1px solid var(--rs-border-primary);
  }
  .rs-panel-shaded {
    -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
    -webkit-box-shadow: var(--rs-panel-shadow);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
    box-shadow: var(--rs-panel-shadow);
  }
  .rs-panel-body,
  .rs-panel-header {
    padding: 20px;
  }
  .rs-panel-body-fill {
    padding: 0 !important;
  }
  .rs-panel-header {
    color: #272c36;
    color: var(--rs-text-heading);
    font-size: 16px;
    font-weight: inherit;
    line-height: 1.25;
  }
  .rs-panel-btn {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: none;
    border: none;
    border-radius: 6px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 16px;
    outline: 2px solid transparent;
    padding: 10px;
    width: 100%;
  }
  .rs-panel-btn:active,
  .rs-panel-btn:focus,
  .rs-panel-btn:hover {
    background-color: #e5e5ea;
    background-color: var(--rs-btn-subtle-hover-bg);
    color: #575757;
    color: var(--rs-btn-subtle-hover-text);
    outline: 2px solid transparent;
  }
  .rs-panel-btn-icon {
    -webkit-transition: -webkit-transform 0.2s ease 0s;
    transition: -webkit-transform 0.2s ease 0s;
    transition: transform 0.2s ease 0s;
    transition: transform 0.2s ease 0s, -webkit-transform 0.2s ease 0s;
  }
  .rs-panel-btn[disabled] {
    background-color: var(--rs-btn-subtle-disabled-bg);
    color: #c5c6c7;
    color: var(--rs-text-disabled);
    cursor: not-allowed;
  }
  .rs-panel-title {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
    margin: 0;
    text-align: left;
  }
  .rs-panel-title a {
    color: inherit;
  }
  .rs-panel-title a:active,
  .rs-panel-title a:focus,
  .rs-panel-title a:hover {
    text-decoration: none;
  }
  .rs-panel-header + .rs-panel-body,
  .rs-panel-header + .rs-panel-collapse .rs-panel-body {
    padding-top: 0;
  }
  .rs-panel-collapsible > .rs-panel-header {
    cursor: pointer;
    padding: 10px;
    position: relative;
    -webkit-transition: background-color 0.3s linear, border-radius 0.3s linear;
    transition: background-color 0.3s linear, border-radius 0.3s linear;
  }
  .rs-panel-collapsible > .rs-panel-header > .rs-icon {
    color: #8e8e93;
    color: var(--rs-text-secondary);
    position: absolute;
    right: 20px;
    top: 24px;
    -webkit-transition: -webkit-transform 0.3s linear;
    transition: -webkit-transform 0.3s linear;
    transition: transform 0.3s linear;
    transition: transform 0.3s linear, -webkit-transform 0.3s linear;
  }
  .rs-panel-group {
    border-radius: 6px;
    overflow: hidden;
  }
  .rs-panel-group-bordered {
    border: 1px solid #e5e5ea;
    border: 1px solid var(--rs-border-primary);
  }
  .rs-panel-group > .rs-panel {
    border: none;
  }
  .rs-panel-group > .rs-panel + .rs-panel {
    position: relative;
  }
  .rs-panel-group > .rs-panel + .rs-panel:before {
    border-top: 1px solid #e5e5ea;
    border-top: 1px solid var(--rs-border-primary);
    content: "";
    left: 20px;
    position: absolute;
    right: 20px;
    top: 0;
  }
  .rs-placeholder {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .rs-placeholder-paragraph {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .rs-placeholder-paragraph-graph {
    -webkit-box-flex: 0;
    background-color: #f2f2f5;
    background-color: var(--rs-placeholder);
    border-radius: 2px;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    height: 60px;
    margin-right: 20px;
    width: 60px;
  }
  .rs-placeholder-paragraph-graph-circle {
    border-radius: 50%;
  }
  .rs-placeholder-paragraph-graph-image {
    height: 60px;
    overflow: hidden;
    position: relative;
    width: 80px;
  }
  .rs-placeholder-paragraph-graph-image:after {
    border-bottom: 36px solid #fff;
    border-bottom: 36px solid var(--rs-bg-card);
    border-left: 20px solid transparent;
    border-right: 30px solid transparent;
    bottom: 10px;
    content: "";
    height: 0;
    left: 10px;
    position: absolute;
    width: 0;
  }
  .rs-placeholder-paragraph-graph-image:before {
    border-bottom: 22px solid #fff;
    border-bottom: 22px solid var(--rs-bg-card);
    border-left: 16px solid transparent;
    border-right: 16px solid transparent;
    bottom: 10px;
    content: "";
    height: 0;
    left: 40px;
    position: absolute;
    width: 0;
  }
  .rs-placeholder-paragraph-graph-image .rs-placeholder-paragraph-graph-inner {
    background: #fff;
    background: var(--rs-bg-card);
    border-radius: 50%;
    height: 12px;
    position: absolute;
    right: 18px;
    top: 10px;
    width: 12px;
  }
  .rs-placeholder-paragraph-rows {
    width: 100%;
  }
  .rs-placeholder-paragraph-rows > p {
    background-color: #f2f2f5;
    background-color: var(--rs-placeholder);
  }
  .rs-placeholder-grid-col {
    -webkit-box-flex: 1;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1;
    flex: 1;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .rs-placeholder-grid-col > p {
    background-color: #f2f2f5;
    background-color: var(--rs-placeholder);
  }
  .rs-placeholder-grid-col:first-child {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .rs-placeholder-graph {
    background-color: #f2f2f5;
    background-color: var(--rs-placeholder);
    display: inline-block;
    width: 100%;
  }
  .rs-placeholder-active .rs-placeholder-grid-col > p,
  .rs-placeholder-active .rs-placeholder-paragraph-graph-image,
  .rs-placeholder-active .rs-placeholder-paragraph-rows > p,
  .rs-placeholder-active.rs-placeholder-graph {
    -webkit-animation: placeholder-active 1.5s ease infinite;
    animation: placeholder-active 1.5s ease infinite;
    background: linear-gradient(-45deg, #f2f2f5 25%, #e5e5ea 37%, #f2f2f5 63%);
    background: linear-gradient(-45deg, var(--rs-placeholder) 25%, var(--rs-placeholder-active) 37%, var(--rs-placeholder) 63%);
    background-size: 400% 100% !important;
  }
  @-webkit-keyframes placeholder-active {
    0% {
      background-position: 100% 50%;
    }
    to {
      background-position: 0 50%;
    }
  }
  @keyframes placeholder-active {
    0% {
      background-position: 100% 50%;
    }
    to {
      background-position: 0 50%;
    }
  }
  .rs-popover {
    background-clip: padding-box;
    background-color: #fff;
    background-color: var(--rs-bg-overlay);
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: var(--rs-popover-shadow);
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.12);
    box-shadow: var(--rs-popover-shadow);
    display: none;
    -webkit-filter: drop-shadow(0 1px 8px rgba(0, 0, 0, 0.12));
    -webkit-filter: drop-shadow(var(--rs-popover-shadow));
    filter: drop-shadow(0 1px 8px rgba(0, 0, 0, 0.12));
    filter: drop-shadow(var(--rs-popover-shadow));
    font-size: 14px;
    left: 0;
    opacity: 0;
    padding: 12px;
    position: absolute;
    top: 0;
    z-index: 1060;
  }
  .rs-popover.rs-anim-fade {
    -webkit-transition: opacity 0.1s linear, -webkit-transform 0.1s ease-out;
    transition: opacity 0.1s linear, -webkit-transform 0.1s ease-out;
    transition: opacity 0.1s linear, transform 0.1s ease-out;
    transition: opacity 0.1s linear, transform 0.1s ease-out, -webkit-transform 0.1s ease-out;
  }
  .rs-popover.rs-anim-in {
    opacity: 1;
    -webkit-transition: opacity 0.15s linear, -webkit-transform 0.15s ease-in;
    transition: opacity 0.15s linear, -webkit-transform 0.15s ease-in;
    transition: opacity 0.15s linear, transform 0.15s ease-in;
    transition: opacity 0.15s linear, transform 0.15s ease-in, -webkit-transform 0.15s ease-in;
  }
  .rs-theme-high-contrast .rs-popover {
    border: 1px solid #e5e5ea;
    border: 1px solid var(--rs-border-primary);
  }
  .rs-popover > .rs-popover-arrow {
    border-width: 6px;
  }
  .rs-popover > .rs-popover-arrow,
  .rs-popover > .rs-popover-arrow:after,
  .rs-popover > .rs-popover-arrow:before {
    border-color: transparent;
    border-style: solid;
    display: block;
    height: 0;
    position: absolute;
    width: 0;
  }
  .rs-popover > .rs-popover-arrow:after,
  .rs-popover > .rs-popover-arrow:before {
    border-width: 6px;
    content: "";
  }
  .rs-popover > .rs-popover-arrow:before {
    display: none;
  }
  .rs-theme-high-contrast .rs-popover > .rs-popover-arrow:before {
    display: block;
  }
  .rs-popover-title {
    font-size: 14px;
    line-height: 1.42857143;
    margin: 0;
  }
  .rs-popover-content {
    font-size: 12px;
    line-height: 1.66666667;
  }
  .rs-popover-title ~ .rs-popover-content {
    margin-top: 8px;
  }
  .rs-popover[class*="placement-top"] {
    margin-top: -8px;
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  .rs-popover[class*="placement-top"].rs-anim-in {
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);
  }
  .rs-popover[class*="placement-top"] > .rs-popover-arrow {
    bottom: -6px;
    margin-left: -6px;
  }
  .rs-popover[class*="placement-top"] > .rs-popover-arrow:after {
    border-top-color: #fff;
    border-top-color: var(--rs-bg-overlay);
    border-width: 6px 6px 0;
    bottom: -6px;
    margin-left: -6px;
  }
  @media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
    .rs-popover[class*="placement-top"] > .rs-popover-arrow:after {
      bottom: -7px;
    }
  }
  @supports (-ms-ime-align: auto) {
    .rs-popover[class*="placement-top"] > .rs-popover-arrow:after {
      bottom: -7px;
    }
  }
  .rs-theme-high-contrast .rs-popover[class*="placement-top"] > .rs-popover-arrow:before {
    border-top-color: #e5e5ea;
    border-top-color: var(--rs-border-primary);
    border-width: 7px 7px 0;
    bottom: -7px;
    margin-left: -7px;
  }
  @media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
    .rs-theme-high-contrast .rs-popover[class*="placement-top"] > .rs-popover-arrow:before {
      bottom: -8px;
    }
  }
  @supports (-ms-ime-align: auto) {
    .rs-theme-high-contrast .rs-popover[class*="placement-top"] > .rs-popover-arrow:before {
      bottom: -8px;
    }
  }
  .rs-popover[class*="placement-right"] {
    margin-left: 8px;
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  .rs-popover[class*="placement-right"].rs-anim-in {
    -webkit-transform: translate(2px);
    transform: translate(2px);
  }
  .rs-popover[class*="placement-right"] > .rs-popover-arrow {
    left: -6px;
    margin-top: -6px;
  }
  .rs-popover[class*="placement-right"] > .rs-popover-arrow:after {
    border-right-color: #fff;
    border-right-color: var(--rs-bg-overlay);
    border-width: 6px 6px 6px 0;
    left: -6px;
    margin-top: -6px;
  }
  @media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
    .rs-popover[class*="placement-right"] > .rs-popover-arrow:after {
      left: -7px;
    }
  }
  @supports (-ms-ime-align: auto) {
    .rs-popover[class*="placement-right"] > .rs-popover-arrow:after {
      left: -7px;
    }
  }
  .rs-theme-high-contrast .rs-popover[class*="placement-right"] > .rs-popover-arrow:before {
    border-right-color: #e5e5ea;
    border-right-color: var(--rs-border-primary);
    border-width: 7px 7px 7px 0;
    left: -7px;
    margin-top: -7px;
  }
  @media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
    .rs-theme-high-contrast .rs-popover[class*="placement-right"] > .rs-popover-arrow:before {
      left: -8px;
    }
  }
  @supports (-ms-ime-align: auto) {
    .rs-theme-high-contrast .rs-popover[class*="placement-right"] > .rs-popover-arrow:before {
      left: -8px;
    }
  }
  .rs-popover[class*="placement-left"] {
    margin-left: -8px;
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  .rs-popover[class*="placement-left"].rs-anim-in {
    -webkit-transform: translate(-2px);
    transform: translate(-2px);
  }
  .rs-popover[class*="placement-left"] > .rs-popover-arrow {
    margin-top: -6px;
    right: -6px;
  }
  .rs-popover[class*="placement-left"] > .rs-popover-arrow:after {
    border-left-color: #fff;
    border-left-color: var(--rs-bg-overlay);
    border-width: 6px 0 6px 6px;
    margin-top: -6px;
    right: -6px;
  }
  @media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
    .rs-popover[class*="placement-left"] > .rs-popover-arrow:after {
      right: -7px;
    }
  }
  @supports (-ms-ime-align: auto) {
    .rs-popover[class*="placement-left"] > .rs-popover-arrow:after {
      right: -7px;
    }
  }
  .rs-theme-high-contrast .rs-popover[class*="placement-left"] > .rs-popover-arrow:before {
    border-left-color: #e5e5ea;
    border-left-color: var(--rs-border-primary);
    border-width: 7px 0 7px 7px;
    margin-top: -7px;
    right: -7px;
  }
  @media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
    .rs-theme-high-contrast .rs-popover[class*="placement-left"] > .rs-popover-arrow:before {
      right: -8px;
    }
  }
  @supports (-ms-ime-align: auto) {
    .rs-theme-high-contrast .rs-popover[class*="placement-left"] > .rs-popover-arrow:before {
      right: -8px;
    }
  }
  .rs-popover[class*="placement-bottom"] {
    margin-top: 8px;
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  .rs-popover[class*="placement-bottom"].rs-anim-in {
    -webkit-transform: translateY(2px);
    transform: translateY(2px);
  }
  .rs-popover[class*="placement-bottom"] > .rs-popover-arrow {
    margin-left: -6px;
    top: -6px;
  }
  .rs-popover[class*="placement-bottom"] > .rs-popover-arrow:after {
    border-bottom-color: #fff;
    border-bottom-color: var(--rs-bg-overlay);
    border-width: 0 6px 6px;
    margin-left: -6px;
    top: -6px;
  }
  @media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
    .rs-popover[class*="placement-bottom"] > .rs-popover-arrow:after {
      top: -7px;
    }
  }
  @supports (-ms-ime-align: auto) {
    .rs-popover[class*="placement-bottom"] > .rs-popover-arrow:after {
      top: -7px;
    }
  }
  .rs-theme-high-contrast .rs-popover[class*="placement-bottom"] > .rs-popover-arrow:before {
    border-bottom-color: #e5e5ea;
    border-bottom-color: var(--rs-border-primary);
    border-width: 0 7px 7px;
    margin-left: -7px;
    top: -7px;
  }
  @media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
    .rs-theme-high-contrast .rs-popover[class*="placement-bottom"] > .rs-popover-arrow:before {
      top: -8px;
    }
  }
  @supports (-ms-ime-align: auto) {
    .rs-theme-high-contrast .rs-popover[class*="placement-bottom"] > .rs-popover-arrow:before {
      top: -8px;
    }
  }
  .rs-popover.placement-bottom > .rs-popover-arrow,
  .rs-popover.placement-top > .rs-popover-arrow {
    left: 50%;
  }
  .rs-popover.placement-bottom-end > .rs-popover-arrow,
  .rs-popover.placement-top-end > .rs-popover-arrow {
    right: 4px;
  }
  .rs-popover.placement-left > .rs-popover-arrow,
  .rs-popover.placement-right > .rs-popover-arrow {
    top: 50%;
  }
  .rs-popover.placement-left-end > .rs-popover-arrow,
  .rs-popover.placement-right-end > .rs-popover-arrow {
    bottom: 4px;
  }
  .rs-popover-full {
    padding: 0;
  }
  .rs-popover-full .rs-popover-content {
    margin-top: 0;
  }
  .rs-popover.placement-bottom-start.rs-popover-full > .rs-popover-arrow,
  .rs-popover.placement-top-start.rs-popover-full > .rs-popover-arrow {
    margin-left: 6px;
  }
  .rs-popover.placement-left-start.rs-popover-full > .rs-popover-arrow,
  .rs-popover.placement-right-start.rs-popover-full > .rs-popover-arrow {
    margin-top: 6px;
  }
  @-webkit-keyframes progress-active {
    0% {
      opacity: 0.1;
      width: 0;
    }
    20% {
      opacity: 0.5;
      width: 0;
    }
    to {
      opacity: 0;
      width: 100%;
    }
  }
  @keyframes progress-active {
    0% {
      opacity: 0.1;
      width: 0;
    }
    20% {
      opacity: 0.5;
      width: 0;
    }
    to {
      opacity: 0;
      width: 100%;
    }
  }
  @-webkit-keyframes progress-active-vertical {
    0% {
      height: 0;
      opacity: 0.1;
    }
    20% {
      height: 0;
      opacity: 0.5;
    }
    to {
      height: 100%;
      opacity: 0;
    }
  }
  @keyframes progress-active-vertical {
    0% {
      height: 0;
      opacity: 0.1;
    }
    20% {
      height: 0;
      opacity: 0.5;
    }
    to {
      height: 100%;
      opacity: 0;
    }
  }
  .rs-progress-info {
    font-size: 0;
  }
  .rs-progress,
  .rs-progress-icon-fail,
  .rs-progress-icon-success,
  .rs-progress-info-status {
    font-size: 16px;
    line-height: 1.25;
  }
  .rs-progress-circle {
    font-size: 0;
    position: relative;
  }
  .rs-progress-circle-info {
    bottom: 0;
    color: #8e8e93;
    color: var(--rs-text-secondary);
    font-size: 16px;
    height: 36px;
    left: 0;
    line-height: 2.25;
    margin: auto;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
  }
  .rs-progress-circle .rs-progress-trail {
    stroke: #e5e5ea;
    stroke: var(--rs-progress-bg);
  }
  .rs-progress-circle .rs-progress-stroke {
    stroke: #3498ff;
    stroke: var(--rs-progress-bar);
    -webkit-transition: stroke-dashoffset 0.3s ease, stroke-dasharray 0.2s ease-in-out, stroke 0.3s;
    transition: stroke-dashoffset 0.3s ease, stroke-dasharray 0.2s ease-in-out, stroke 0.3s;
  }
  .rs-progress-circle-success .rs-progress-circle-info {
    color: #4caf50;
    color: var(--rs-progress-bar-success);
  }
  .rs-progress-circle-success .rs-progress-stroke {
    stroke: #4caf50;
    stroke: var(--rs-progress-bar-success);
  }
  .rs-progress-circle-fail .rs-progress-circle-info {
    color: #f44336;
    color: var(--rs-progress-bar-fail);
  }
  .rs-progress-circle-fail .rs-progress-stroke {
    stroke: #f44336;
    stroke: var(--rs-progress-bar-fail);
  }
  .rs-progress-line {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 8px 12px;
    width: 100%;
  }
  .rs-progress-line-active .rs-progress-line-bg {
    position: relative;
  }
  .rs-progress-line-active .rs-progress-line-bg:before {
    -webkit-animation: progress-active 2s cubic-bezier(0.23, 1, 0.32, 1) infinite;
    animation: progress-active 2s cubic-bezier(0.23, 1, 0.32, 1) infinite;
    background-color: #fff;
    border-radius: 10px;
    bottom: 0;
    content: "";
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .rs-progress-line .rs-progress-info {
    -webkit-box-flex: 0;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 0 0 50px;
    flex: 0 0 50px;
    height: 20px;
    padding-left: 12px;
    width: 50px;
  }
  .rs-progress-line-outer {
    -webkit-box-flex: 1;
    border-radius: 5px;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    overflow: hidden;
    vertical-align: middle;
  }
  .rs-progress-line-inner {
    background: #e5e5ea;
    background: var(--rs-progress-bg);
    border-radius: 5px;
  }
  .rs-progress-line-bg {
    background-color: #3498ff;
    background-color: var(--rs-progress-bar);
    border-radius: 5px;
    -webkit-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
    -webkit-transition-property: width, background-color;
    transition-property: width, background-color;
  }
  .rs-progress-line-bg,
  .rs-progress-line-inner {
    height: 10px;
  }
  .rs-progress-line-success {
    color: #4caf50;
    color: var(--rs-progress-bar-success);
  }
  .rs-progress-line-success .rs-progress-line-bg {
    background-color: #4caf50;
    background-color: var(--rs-progress-bar-success);
  }
  .rs-progress-line-fail {
    color: #f44336;
    color: var(--rs-progress-bar-fail);
  }
  .rs-progress-line-fail .rs-progress-line-bg {
    background-color: #f44336;
    background-color: var(--rs-progress-bar-fail);
  }
  .rs-progress-line.rs-progress-line-vertical {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    width: auto;
  }
  .rs-progress-line.rs-progress-line-vertical .rs-progress-line-outer {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: auto;
  }
  .rs-progress-line.rs-progress-line-vertical .rs-progress-line-inner {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    height: 100%;
    min-height: 100px;
    position: relative;
    width: 10px;
  }
  .rs-progress-line.rs-progress-line-vertical .rs-progress-info {
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
    padding-left: 0;
    width: auto;
  }
  .rs-progress-line.rs-progress-line-vertical .rs-progress-line-bg {
    bottom: 0;
    position: absolute;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    -webkit-transition-property: height, background-color;
    transition-property: height, background-color;
    width: 100%;
  }
  .rs-progress-line.rs-progress-line-vertical.rs-progress-line-active .rs-progress-line-bg:before {
    -webkit-animation-name: progress-active-vertical;
    animation-name: progress-active-vertical;
  }
  .rs-progress-icon-fail,
  .rs-progress-icon-success {
    display: inline-block;
  }
  .rs-progress-icon-fail svg,
  .rs-progress-icon-success svg {
    stroke: currentColor;
    stroke-width: 0.5px;
    stroke-linecap: round;
  }
  .rs-progress-line .rs-progress-icon-fail,
  .rs-progress-line .rs-progress-icon-success {
    font-size: 14px;
    line-height: 0;
  }
  .rs-progress-circle .rs-progress-icon-fail,
  .rs-progress-circle .rs-progress-icon-success {
    font-size: 36px;
    line-height: 0;
  }
  .rs-radio {
    display: inline-block;
    position: relative;
    vertical-align: middle;
  }
  .rs-radio-disabled label {
    cursor: not-allowed;
  }
  .rs-radio label {
    cursor: pointer;
    font-weight: 400;
    line-height: 1.14285714;
    margin-bottom: 0;
  }
  .rs-radio-inner:before {
    border-color: #3498ff;
    border-color: var(--rs-checkbox-checked-bg);
  }
  .rs-radio.rs-radio-disabled label {
    cursor: not-allowed;
  }
  .rs-radio-disabled > .rs-radio-checker > label {
    color: #c5c6c7;
    color: var(--rs-text-disabled);
  }
  .rs-radio-inline {
    display: inline-block;
    font-weight: 400;
    margin-bottom: 0;
    margin-right: 10px;
    margin-top: 0;
    position: relative;
    vertical-align: middle;
  }
  .rs-plaintext .rs-radio-inline:first-child {
    margin-left: 0;
  }
  .rs-radio [type="radio"]:focus ~ .rs-radio-inner:before {
    -webkit-box-shadow: 0 0 0 3px rgb(from #3498ff r g b/25%);
    -webkit-box-shadow: var(--rs-state-focus-shadow);
    box-shadow: 0 0 0 3px rgb(from #3498ff r g b/25%);
    box-shadow: var(--rs-state-focus-shadow);
  }
  .rs-radio-checker {
    line-height: 1;
    min-height: 36px;
    padding-bottom: 10px;
    padding-left: 36px;
    padding-top: 10px;
    position: relative;
  }
  .rs-radio-wrapper {
    display: inline-block;
    height: 16px;
    left: 10px;
    position: absolute;
    top: 10px;
    width: 16px;
  }
  .rs-radio-wrapper [type="radio"] {
    cursor: pointer;
    height: 16px;
    opacity: 0;
    position: absolute;
    width: 16px;
    z-index: 1;
  }
  .rs-radio-wrapper .rs-radio-inner:after,
  .rs-radio-wrapper .rs-radio-inner:before,
  .rs-radio-wrapper:after,
  .rs-radio-wrapper:before {
    content: "";
    display: block;
    left: 0;
    position: absolute;
    top: 0;
  }
  .rs-radio-wrapper .rs-radio-inner:before,
  .rs-radio-wrapper:before {
    height: 16px;
    width: 16px;
  }
  .rs-radio-wrapper:before {
    background-color: transparent;
    border: 1px solid #3498ff;
    border: 1px solid var(--rs-radio-checked-bg);
    border-radius: 50%;
    opacity: 0.7;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: opacity 0.2s linear, -webkit-transform 0.2s linear;
    transition: opacity 0.2s linear, -webkit-transform 0.2s linear;
    transition: transform 0.2s linear, opacity 0.2s linear;
    transition: transform 0.2s linear, opacity 0.2s linear, -webkit-transform 0.2s linear;
    visibility: hidden;
  }
  .rs-theme-high-contrast .rs-radio-wrapper:before {
    -webkit-transition: none;
    transition: none;
  }
  .rs-radio-checked .rs-radio-wrapper:before {
    opacity: 0;
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    visibility: visible;
  }
  .rs-radio-wrapper:after {
    bottom: -10px;
    left: -10px;
    right: -10px;
    top: -10px;
  }
  .rs-radio-inner:before {
    background-color: transparent;
    border: 1px solid #d9d9d9;
    border: 1px solid var(--rs-radio-border);
    border-radius: 50%;
    -webkit-transition: background 0.2s linear, border 0.2s linear, -webkit-box-shadow 0.2s linear;
    transition: background 0.2s linear, border 0.2s linear, -webkit-box-shadow 0.2s linear;
    transition: background 0.2s linear, border 0.2s linear, box-shadow 0.2s linear;
    transition: background 0.2s linear, border 0.2s linear, box-shadow 0.2s linear, -webkit-box-shadow 0.2s linear;
  }
  .rs-theme-high-contrast .rs-radio-inner:before {
    -webkit-transition: none;
    transition: none;
  }
  .rs-radio:hover .rs-radio-inner:before {
    border-color: #3498ff;
    border-color: var(--rs-radio-checked-bg);
  }
  .rs-radio.rs-radio-disabled .rs-radio-inner:before {
    background-color: #f7f7fa;
    background-color: var(--rs-radio-disabled-bg);
    border-color: #f7f7fa;
    border-color: var(--rs-radio-disabled-bg);
  }
  .rs-radio.rs-radio-checked .rs-radio-inner:before {
    background-color: #3498ff;
    background-color: var(--rs-radio-checked-bg);
    border-color: #3498ff;
    border-color: var(--rs-radio-checked-bg);
  }
  .rs-radio.rs-radio-disabled.rs-radio-checked .rs-radio-inner:before {
    opacity: 0.3;
  }
  .rs-theme-high-contrast .rs-radio.rs-radio-disabled.rs-radio-checked .rs-radio-inner:before {
    opacity: 0.5;
  }
  .rs-radio-inner:after {
    background-color: #fff;
    background-color: var(--rs-radio-marker);
    border-radius: 50%;
    height: 6px;
    margin-left: 5px;
    margin-top: 5px;
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transition: opacity 0.2s ease-in, -webkit-transform 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46);
    transition: opacity 0.2s ease-in, -webkit-transform 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46);
    transition: opacity 0.2s ease-in, transform 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46);
    transition: opacity 0.2s ease-in, transform 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46), -webkit-transform 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46);
    width: 6px;
  }
  .rs-theme-high-contrast .rs-radio-inner:after {
    -webkit-transition: none;
    transition: none;
  }
  .rs-radio-checked .rs-radio-inner:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  .rs-radio-group {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .rs-radio-group > .rs-radio {
    margin-left: -10px;
  }
  .rs-radio-group-inline {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .rs-radio-group-picker {
    border: 1px solid #e5e5ea;
    border: 1px solid var(--rs-border-primary);
    border-radius: 6px;
    color: #575757;
    color: var(--rs-text-primary);
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
  }
  .rs-radio-group-picker .rs-radio-inline {
    margin: 0;
    padding: 0 10px;
  }
  .rs-radio-group-picker .rs-radio-inline:first-child {
    padding-left: 12px;
  }
  .rs-radio-group-picker .rs-radio-inline:last-child {
    padding-right: 12px;
  }
  .rs-radio-group-picker .rs-radio-checker {
    min-height: auto;
    padding: 0;
  }
  .rs-radio-group-picker .rs-radio-wrapper {
    display: none;
  }
  .rs-radio-group-picker .rs-radio-checker > label {
    background: none;
    border-bottom: 2px solid transparent;
    border-radius: 0;
    color: #8e8e93;
    color: var(--rs-text-secondary);
    display: inline-block;
    font-size: 14px;
    line-height: 1.42857143;
    padding: 7px 0 5px;
    -webkit-transition: color 0.3s linear;
    transition: color 0.3s linear;
    white-space: nowrap;
  }
  .rs-theme-high-contrast .rs-radio-group-picker .rs-radio-checker > label {
    -webkit-transition: none;
    transition: none;
  }
  .rs-radio-group-picker .rs-radio-checker > label:active,
  .rs-radio-group-picker .rs-radio-checker > label:hover {
    background: none;
    color: #1675e0;
    color: var(--rs-text-active);
  }
  .rs-radio-group-picker .rs-radio-checked .rs-radio-checker > label {
    border-color: #1675e0;
    border-color: var(--rs-text-active);
    color: #1675e0;
    color: var(--rs-text-active);
  }
  .rs-radio-group-picker .rs-radio-disabled .rs-radio-checker {
    cursor: not-allowed;
    opacity: 0.3;
  }
  .rs-radio-group-picker .rs-radio-disabled:not(.rs-radio-checked) .rs-radio-checker > label {
    color: #8e8e93;
    color: var(--rs-text-secondary);
  }
  .rs-radio-tile {
    border: 2px solid #d9d9d9;
    border: 2px solid var(--rs-radio-tile-border);
    border-radius: 6px;
    cursor: pointer;
    overflow: hidden;
    padding: 10px;
    position: relative;
  }
  .rs-radio-tile-label {
    font-weight: 700;
  }
  .rs-radio-tile-content {
    color: #8e8e93;
    color: var(--rs-text-secondary);
  }
  .rs-radio-tile-mark {
    background: #3498ff;
    background: var(--rs-radio-tile-checked-color);
    border-bottom-left-radius: 50%;
    height: 48px;
    opacity: 0;
    position: absolute;
    right: -24px;
    top: -24px;
    width: 48px;
    z-index: 3;
  }
  .rs-radio-tile-mark-icon {
    color: #fff;
    color: var(--rs-radio-tile-checked-mark-color);
    font-size: 16px;
    left: 7px;
    position: absolute;
    top: 25px;
  }
  .rs-radio-tile-checked .rs-radio-tile-mark {
    opacity: 1;
  }
  .rs-radio-tile-checked.rs-radio-tile-disabled {
    border-color: #cce9ff;
    border-color: var(--rs-radio-tile-checked-disabled-color);
  }
  .rs-radio-tile-checked.rs-radio-tile-disabled .rs-radio-tile-mark {
    background-color: #cce9ff;
    background-color: var(--rs-radio-tile-checked-disabled-color);
  }
  .rs-radio-tile-checked,
  .rs-radio-tile:hover:not(.rs-radio-tile-disabled) {
    border: 2px solid #3498ff;
    border: 2px solid var(--rs-radio-tile-checked-color);
  }
  .rs-radio-tile-disabled,
  .rs-radio-tile-disabled .rs-radio-tile-content {
    color: #c5c6c7;
    color: var(--rs-text-disabled);
    cursor: not-allowed;
  }
  .rs-radio-tile input {
    height: 0;
    opacity: 0;
    position: absolute;
    width: 0;
  }
  .rs-radio-tile-icon {
    font-size: 32px;
    font-size: var(--rs-radio-tile-icon-size);
  }
  .rs-picker-select-menu-items {
    overflow-y: auto;
  }
  .rs-picker-select-menu-items ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .rs-picker-select-menu-items .rs-picker-menu-group:not(:first-child) {
    border-top: 1px solid #e5e5ea;
    border-top: 1px solid var(--rs-border-primary);
    margin-top: 6px;
    padding-top: 6px;
  }
  .rs-picker-select-menu-items .rs-picker-menu-group-title {
    color: #272c36;
    color: var(--rs-text-heading);
    cursor: pointer;
    padding: 8px 32px 8px 12px;
    position: relative;
  }
  .rs-picker-select-menu-items .rs-picker-menu-group-title .rs-picker-menu-group-caret {
    color: #8e8e93;
    color: var(--rs-text-secondary);
    display: inline-block;
    margin-left: 2px;
    padding: 3px;
    position: absolute;
    right: 12px;
    top: 8px;
  }
  .rs-picker-select-menu-items .rs-picker-menu-group.folded .rs-picker-menu-group-caret {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .rs-picker-select-menu-items .rs-picker-menu-group ~ [role="option"] > .rs-picker-select-menu-item {
    padding-left: 26px;
  }
  .rs-picker-select-menu-item {
    clear: both;
    color: #575757;
    color: var(--rs-text-primary);
    cursor: pointer;
    display: block;
    font-weight: 400;
    line-height: 1.42857143;
    padding: 8px 12px;
    text-decoration: none;
    width: 100%;
  }
  .rs-picker-select-menu-item.rs-picker-select-menu-item-focus,
  .rs-picker-select-menu-item:focus,
  .rs-picker-select-menu-item:hover {
    background-color: rgb(from #cce9ff r g b/50%);
    background-color: var(--rs-listbox-option-hover-bg);
    color: #1675e0;
    color: var(--rs-listbox-option-hover-text);
  }
  .rs-theme-high-contrast .rs-picker-select-menu-item.rs-picker-select-menu-item-focus,
  .rs-theme-high-contrast .rs-picker-select-menu-item:focus,
  .rs-theme-high-contrast .rs-picker-select-menu-item:hover {
    color: #1675e0;
    color: var(--rs-listbox-option-hover-text);
    outline: 3px solid rgb(from #3498ff r g b/25%);
    outline: 3px solid var(--rs-color-focus-ring);
    outline-offset: -3px;
    outline-width: 2px;
    text-decoration: underline;
  }
  .rs-theme-high-contrast .rs-theme-high-contrast .rs-picker-select-menu-item.rs-picker-select-menu-item-focus,
  .rs-theme-high-contrast .rs-theme-high-contrast .rs-picker-select-menu-item:focus,
  .rs-theme-high-contrast .rs-theme-high-contrast .rs-picker-select-menu-item:hover {
    outline-offset: 2px;
  }
  .rs-picker-select-menu-item.rs-picker-select-menu-item-active {
    background-color: #f2faff;
    background-color: var(--rs-listbox-option-selected-bg);
    color: #1675e0;
    color: var(--rs-listbox-option-selected-text);
    font-weight: 700;
  }
  .rs-picker-select-menu-item.rs-picker-select-menu-item-disabled {
    background: none;
    color: #c5c6c7;
    color: var(--rs-listbox-option-disabled-text);
    cursor: not-allowed;
  }
  .grouped .rs-picker-select-menu-item,
  .rs-picker-select-menu-group-children .rs-picker-select-menu-item {
    padding-left: 26px;
  }
  .rs-sidebar-collapse {
    min-width: 0;
    -webkit-transition: width 0.15s ease-in, -webkit-box-flex 0.15s ease-in;
    transition: width 0.15s ease-in, -webkit-box-flex 0.15s ease-in;
    transition: flex 0.15s ease-in, width 0.15s ease-in;
    transition: flex 0.15s ease-in, width 0.15s ease-in, -webkit-box-flex 0.15s ease-in, -ms-flex 0.15s ease-in;
  }
  .rs-sidenav {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-transition: width 0.15s ease-in;
    transition: width 0.15s ease-in;
    width: 56px;
  }
  .rs-sidenav-nav {
    list-style: none;
    padding: 0;
  }
  .rs-sidenav-nav .rs-dropdown-item,
  .rs-sidenav-nav .rs-dropdown-item-toggle,
  .rs-sidenav-nav .rs-dropdown-toggle,
  .rs-sidenav-nav .rs-sidenav-item {
    border-radius: 0;
    color: inherit;
    padding: 15px 20px;
    -webkit-transition: color 0.15s ease-out, background-color 0.15s ease-out;
    transition: color 0.15s ease-out, background-color 0.15s ease-out;
  }
  .rs-theme-high-contrast .rs-sidenav-nav .rs-dropdown-item,
  .rs-theme-high-contrast .rs-sidenav-nav .rs-dropdown-item-toggle,
  .rs-theme-high-contrast .rs-sidenav-nav .rs-dropdown-toggle,
  .rs-theme-high-contrast .rs-sidenav-nav .rs-sidenav-item {
    -webkit-transition: none;
    transition: none;
  }
  .rs-sidenav-nav > .rs-dropdown,
  .rs-sidenav-nav > .rs-sidenav-item {
    margin: 0 !important;
  }
  .rs-sidenav-nav > .rs-dropdown .rs-dropdown-item-toggle {
    background: none;
    border-width: 0;
    display: block;
    padding-right: 40px;
    position: relative;
    text-align: start;
    width: 100%;
  }
  .rs-sidenav-collapse-in .rs-sidenav-nav > .rs-dropdown .rs-dropdown-item-toggle {
    padding-left: 56px;
  }
  .rs-sidenav-nav > .rs-dropdown .rs-dropdown-item-toggle:focus {
    outline: 0;
  }
  .rs-sidenav-nav > .rs-dropdown .rs-dropdown-item-toggle:focus-visible {
    outline: 3px solid rgb(from #3498ff r g b/25%);
    outline: 3px solid var(--rs-color-focus-ring);
    outline-offset: -3px;
    z-index: 1;
  }
  .rs-theme-high-contrast .rs-sidenav-nav > .rs-dropdown .rs-dropdown-item-toggle:focus-visible {
    outline-offset: 2px;
  }
  .rs-theme-high-contrast .rs-sidenav-nav > .rs-dropdown .rs-dropdown-item-toggle:focus-visible {
    outline: 3px solid rgb(from #3498ff r g b/25%);
    outline: 3px solid var(--rs-color-focus-ring);
    outline-offset: -3px;
    outline-width: 2px;
  }
  .rs-theme-high-contrast .rs-theme-high-contrast .rs-sidenav-nav > .rs-dropdown .rs-dropdown-item-toggle:focus-visible {
    outline-offset: 2px;
  }
  .rs-sidenav-nav > .rs-dropdown .rs-dropdown-item-toggle-icon {
    font-size: 16px;
    position: absolute;
    right: 20px;
    top: 11px;
  }
  .rs-sidenav-nav > .rs-dropdown .rs-dropdown-item-expand-icon {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
  }
  .rs-sidenav-nav > .rs-dropdown .rs-dropdown-item-collapse-icon {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .rs-theme-high-contrast .rs-sidenav-nav .rs-dropdown-menu,
  .rs-theme-high-contrast .rs-sidenav-nav .rs-dropdown-toggle {
    border: none;
  }
  .rs-sidenav-nav .rs-sidenav-item:focus-visible {
    outline: 3px solid rgb(from #3498ff r g b/25%);
    outline: 3px solid var(--rs-color-focus-ring);
    outline-offset: -3px;
    z-index: 1;
  }
  .rs-theme-high-contrast .rs-sidenav-nav .rs-sidenav-item:focus-visible {
    outline-offset: 2px;
  }
  .rs-theme-high-contrast .rs-sidenav-nav .rs-sidenav-item:focus-visible {
    outline: 3px solid rgb(from #3498ff r g b/25%);
    outline: 3px solid var(--rs-color-focus-ring);
    outline-offset: -3px;
    outline-width: 2px;
  }
  .rs-theme-high-contrast .rs-theme-high-contrast .rs-sidenav-nav .rs-sidenav-item:focus-visible {
    outline-offset: 2px;
  }
  .rs-sidenav-nav > .rs-dropdown .rs-dropdown-item-active,
  .rs-sidenav-nav > .rs-dropdown .rs-dropdown-menu-item-focus {
    background: transparent;
    font-weight: 400;
  }
  .rs-sidenav-nav > .rs-dropdown .rs-dropdown-menu {
    -webkit-transition: height 0.15s ease-in;
    transition: height 0.15s ease-in;
  }
  .rs-sidenav-nav > .rs-dropdown .rs-dropdown-toggle {
    height: auto;
    padding-right: 40px;
  }
  .rs-sidenav-nav > .rs-dropdown .rs-dropdown-toggle .rs-dropdown-toggle-caret {
    font-size: 16px;
    right: 20px;
    top: 15px;
  }
  .rs-sidenav-nav > .rs-dropdown .rs-dropdown-toggle .rs-dropdown-toggle-caret[aria-label="angle-right"] {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .rs-sidenav-nav > .rs-dropdown .rs-dropdown-toggle,
  .rs-sidenav-nav > .rs-sidenav-item {
    font-size: 16px;
    line-height: 1.25;
    padding-left: 56px;
    white-space: normal;
    width: 100%;
  }
  .rs-sidenav-nav > .rs-dropdown .rs-dropdown-toggle:focus-visible,
  .rs-sidenav-nav > .rs-sidenav-item:focus-visible {
    outline: 3px solid rgb(from #3498ff r g b/25%);
    outline: 3px solid var(--rs-color-focus-ring);
    outline-offset: -3px;
  }
  .rs-theme-high-contrast .rs-sidenav-nav > .rs-dropdown .rs-dropdown-toggle:focus-visible,
  .rs-theme-high-contrast .rs-sidenav-nav > .rs-sidenav-item:focus-visible {
    outline-offset: 2px;
  }
  .rs-sidenav-nav > .rs-dropdown .rs-dropdown-toggle > .rs-icon:not(.rs-dropdown-toggle-caret),
  .rs-sidenav-nav > .rs-sidenav-item > .rs-icon:not(.rs-dropdown-toggle-caret) {
    font-size: 16px;
    height: 16px;
    left: 20px;
    line-height: 1.25;
    margin-right: 20px;
    position: absolute;
    top: 15px;
  }
  .rs-sidenav-nav .rs-dropdown-toggle:focus-visible,
  .rs-sidenav-nav > .rs-dropdown-focus .rs-dropdown-toggle {
    outline: 3px solid rgb(from #3498ff r g b/25%);
    outline: 3px solid var(--rs-color-focus-ring);
    z-index: 2;
  }
  .rs-theme-high-contrast .rs-sidenav-nav .rs-dropdown-toggle:focus-visible,
  .rs-theme-high-contrast .rs-sidenav-nav > .rs-dropdown-focus .rs-dropdown-toggle {
    outline-offset: 2px;
  }
  .rs-theme-high-contrast .rs-sidenav-nav .rs-dropdown-toggle:focus-visible,
  .rs-theme-high-contrast .rs-sidenav-nav > .rs-dropdown-focus .rs-dropdown-toggle {
    outline: 3px solid rgb(from #3498ff r g b/25%);
    outline: 3px solid var(--rs-color-focus-ring);
    outline-offset: -3px;
    outline-width: 2px;
  }
  .rs-theme-high-contrast .rs-theme-high-contrast .rs-sidenav-nav .rs-dropdown-toggle:focus-visible,
  .rs-theme-high-contrast .rs-theme-high-contrast .rs-sidenav-nav > .rs-dropdown-focus .rs-dropdown-toggle {
    outline-offset: 2px;
  }
  .rs-sidenav-item {
    display: block;
    outline: 0;
    overflow: hidden;
    position: relative;
  }
  .rs-sidenav-item,
  .rs-sidenav-item:focus,
  .rs-sidenav-item:hover {
    text-decoration: none;
  }
  .rs-sidenav-item.rs-sidenav-item-focus,
  .rs-sidenav-item:focus-visible {
    outline: 3px solid rgb(from #3498ff r g b/25%);
    outline: 3px solid var(--rs-color-focus-ring);
    outline-offset: -3px;
    outline-width: 2px;
    z-index: 2;
  }
  .rs-theme-high-contrast .rs-sidenav-item.rs-sidenav-item-focus,
  .rs-theme-high-contrast .rs-sidenav-item:focus-visible {
    outline-offset: 2px;
  }
  .rs-sidenav-toggle {
    border-top: 1px solid;
    height: 50px;
    margin-top: auto;
  }
  .rs-sidenav-toggle-button {
    border: none;
    border-radius: 0;
    color: inherit;
    float: right;
    height: 100%;
    width: 56px;
  }
  .rs-sidenav-collapse-in {
    width: 100%;
  }
  .rs-sidenav-collapse-in .rs-sidenav-item {
    border-radius: 0;
  }
  .rs-sidenav-collapse-in .rs-dropdown {
    -webkit-box-flex: 1;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .rs-sidenav-collapse-in .rs-dropdown-item-focus,
  .rs-sidenav-collapse-in .rs-dropdown-item:focus-visible {
    background: none;
    outline: 3px solid rgb(from #3498ff r g b/25%);
    outline: 3px solid var(--rs-color-focus-ring);
    outline-offset: -3px;
    z-index: 2;
  }
  .rs-theme-high-contrast .rs-sidenav-collapse-in .rs-dropdown-item-focus,
  .rs-theme-high-contrast .rs-sidenav-collapse-in .rs-dropdown-item:focus-visible {
    outline-offset: 2px;
  }
  .rs-theme-high-contrast .rs-sidenav-collapse-in .rs-dropdown-item-focus,
  .rs-theme-high-contrast .rs-sidenav-collapse-in .rs-dropdown-item:focus-visible {
    outline: 3px solid rgb(from #3498ff r g b/25%);
    outline: 3px solid var(--rs-color-focus-ring);
    outline-offset: -3px;
    outline-width: 2px;
  }
  .rs-theme-high-contrast .rs-theme-high-contrast .rs-sidenav-collapse-in .rs-dropdown-item-focus,
  .rs-theme-high-contrast .rs-theme-high-contrast .rs-sidenav-collapse-in .rs-dropdown-item:focus-visible {
    outline-offset: 2px;
  }
  .rs-sidenav-collapse-in .rs-dropdown-item-focus.rs-dropdown-item-submenu,
  .rs-sidenav-collapse-in .rs-dropdown-item:focus-visible.rs-dropdown-item-submenu {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .rs-sidenav-collapse-in .rs-dropdown-item-focus.rs-dropdown-item-submenu > .rs-dropdown-item-toggle,
  .rs-sidenav-collapse-in .rs-dropdown-item:focus-visible.rs-dropdown-item-submenu > .rs-dropdown-item-toggle {
    outline: 3px solid rgb(from #3498ff r g b/25%);
    outline: 3px solid var(--rs-color-focus-ring);
    outline-offset: -3px;
    z-index: 2;
  }
  .rs-theme-high-contrast .rs-sidenav-collapse-in .rs-dropdown-item-focus.rs-dropdown-item-submenu > .rs-dropdown-item-toggle,
  .rs-theme-high-contrast .rs-sidenav-collapse-in .rs-dropdown-item:focus-visible.rs-dropdown-item-submenu > .rs-dropdown-item-toggle {
    outline-offset: 2px;
  }
  .rs-theme-high-contrast .rs-sidenav-collapse-in .rs-dropdown-item-focus.rs-dropdown-item-submenu > .rs-dropdown-item-toggle,
  .rs-theme-high-contrast .rs-sidenav-collapse-in .rs-dropdown-item:focus-visible.rs-dropdown-item-submenu > .rs-dropdown-item-toggle {
    outline: 3px solid rgb(from #3498ff r g b/25%);
    outline: 3px solid var(--rs-color-focus-ring);
    outline-offset: -3px;
    outline-width: 2px;
  }
  .rs-theme-high-contrast .rs-theme-high-contrast .rs-sidenav-collapse-in .rs-dropdown-item-focus.rs-dropdown-item-submenu > .rs-dropdown-item-toggle,
  .rs-theme-high-contrast
    .rs-theme-high-contrast
    .rs-sidenav-collapse-in
    .rs-dropdown-item:focus-visible.rs-dropdown-item-submenu
    > .rs-dropdown-item-toggle {
    outline-offset: 2px;
  }
  .rs-sidenav-collapse-in .rs-dropdown-item-submenu > .rs-dropdown-item-toggle,
  .rs-sidenav-collapse-in .rs-dropdown-item:not(.rs-dropdown-item-submenu) {
    overflow: hidden;
    position: relative;
  }
  @media not all and (min-resolution: 0.001dpcm) {
    .rs-sidenav-collapse-in .rs-dropdown-item-submenu > .rs-dropdown-item-toggle,
    .rs-sidenav-collapse-in .rs-dropdown-item:not(.rs-dropdown-item-submenu) {
      -webkit-mask-image: -webkit-radial-gradient(#fff, #000);
    }
  }
  .rs-sidenav-collapse-in .rs-dropdown .rs-dropdown-item-toggle > .rs-dropdown-item-toggle-icon:before,
  .rs-sidenav-collapse-in .rs-dropdown .rs-dropdown-toggle-caret:before,
  .rs-sidenav-collapse-in .rs-dropdown-item-disabled .rs-sidenav-collapse-in .rs-dropdown-item:after {
    display: none;
  }
  .rs-sidenav-collapse-in .rs-dropdown .rs-dropdown-item-toggle > .rs-dropdown-item-toggle-icon,
  .rs-sidenav-collapse-in .rs-dropdown .rs-dropdown-toggle-caret {
    -webkit-transition: -webkit-transform 0.3s linear;
    transition: -webkit-transform 0.3s linear;
    transition: transform 0.3s linear;
    transition: transform 0.3s linear, -webkit-transform 0.3s linear;
  }
  .rs-sidenav-collapse-in .rs-dropdown.rs-dropdown-expand .rs-dropdown-toggle-caret {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  .rs-sidenav-collapse-in .rs-dropdown .rs-dropdown-menu {
    -webkit-animation-name: none;
    animation-name: none;
    background-color: transparent;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    display: block;
    float: none;
    padding: 0;
    position: static;
  }
  .rs-sidenav-collapse-in .rs-dropdown .rs-dropdown-item-submenu {
    padding: 0;
  }
  .rs-sidenav-collapse-in .rs-dropdown .rs-dropdown-item-submenu:hover {
    background-color: transparent;
  }
  .rs-sidenav-collapse-in .rs-dropdown-item:not(.rs-dropdown-item-submenu),
  .rs-sidenav-collapse-in .rs-dropdown-menu .rs-dropdown-menu-toggle {
    white-space: normal;
    width: 100%;
  }
  .rs-sidenav-collapse-in .rs-dropdown-menu {
    overflow: hidden;
  }
  .rs-sidenav-collapse-in .rs-dropdown-item-submenu.rs-dropdown-item-collapse > .rs-dropdown-menu.rs-dropdown-menu-collapse-out,
  .rs-sidenav-collapse-in .rs-dropdown.rs-dropdown-collapse > .rs-dropdown-menu.rs-dropdown-menu-collapse-out {
    display: none;
  }
  .rs-sidenav-collapse-in .rs-dropdown-item-submenu.rs-dropdown-menu-collapse-in > .rs-dropdown-menu.rs-dropdown-menu-collapse-in,
  .rs-sidenav-collapse-in .rs-dropdown.rs-dropdown-menu-collapse-in > .rs-dropdown-menu.dropdown-menu-collapse-in {
    display: block;
  }
  .rs-sidenav-collapse-in .rs-dropdown-item-submenu > .rs-dropdown-item-menu-icon {
    line-height: 1.42857143;
    padding-bottom: 11px;
    padding-top: 11px;
    position: absolute;
    width: 14px;
    z-index: 1;
  }
  .rs-sidenav-collapse-in .rs-dropdown-item,
  .rs-sidenav-collapse-in .rs-dropdown-item-submenu > .rs-dropdown-item-toggle {
    padding: 11px 20px 11px 56px;
  }
  .rs-sidenav-collapse-in .rs-dropdown-item.rs-dropdown-item-with-icon > .rs-dropdown-item-content > .rs-dropdown-item-menu-icon {
    left: 56px;
  }
  .rs-sidenav-collapse-in .rs-dropdown-item.rs-dropdown-item-with-icon > .rs-dropdown-item-content > .rs-dropdown-menu-toggle {
    padding-left: 76px;
  }
  .rs-sidenav-collapse-in .rs-dropdown-item .rs-dropdown-item:not(.rs-dropdown-item-submenu),
  .rs-sidenav-collapse-in .rs-dropdown-item-submenu > .rs-dropdown-menu .rs-dropdown-item-toggle {
    padding-left: 65px;
  }
  .rs-sidenav-collapse-in
    .rs-dropdown-item-submenu
    > .rs-dropdown-menu
    > .rs-dropdown-item.rs-dropdown-item-with-icon
    > .rs-dropdown-item-content
    > .rs-dropdown-item-menu-icon {
    left: 65px;
  }
  .rs-sidenav-collapse-in
    .rs-dropdown-item-submenu
    > .rs-dropdown-menu
    > .rs-dropdown-item.rs-dropdown-item-with-icon
    > .rs-dropdown-item-content
    > .rs-dropdown-menu-toggle {
    padding-left: 85px;
  }
  .rs-sidenav-collapse-out .rs-dropdown .rs-dropdown-toggle,
  .rs-sidenav-collapse-out .rs-sidenav-item {
    height: 50px;
    padding-left: 56px;
    padding-right: 0;
  }
  .rs-sidenav-collapse-out .rs-dropdown .rs-dropdown-toggle:focus-visible,
  .rs-sidenav-collapse-out .rs-sidenav-item:focus-visible {
    outline: 3px solid rgb(from #3498ff r g b/25%);
    outline: 3px solid var(--rs-color-focus-ring);
  }
  .rs-theme-high-contrast .rs-sidenav-collapse-out .rs-dropdown .rs-dropdown-toggle:focus-visible,
  .rs-theme-high-contrast .rs-sidenav-collapse-out .rs-sidenav-item:focus-visible {
    outline-offset: 2px;
  }
  .rs-theme-high-contrast .rs-sidenav-collapse-out .rs-dropdown .rs-dropdown-toggle:focus-visible,
  .rs-theme-high-contrast .rs-sidenav-collapse-out .rs-sidenav-item:focus-visible {
    outline: 3px solid rgb(from #3498ff r g b/25%);
    outline: 3px solid var(--rs-color-focus-ring);
    outline-offset: -3px;
    outline-width: 2px;
  }
  .rs-theme-high-contrast .rs-theme-high-contrast .rs-sidenav-collapse-out .rs-dropdown .rs-dropdown-toggle:focus-visible,
  .rs-theme-high-contrast .rs-theme-high-contrast .rs-sidenav-collapse-out .rs-sidenav-item:focus-visible {
    outline-offset: 2px;
  }
  .rs-sidenav-collapse-out .rs-dropdown-item {
    padding: 8px 27px 8px 12px;
  }
  .rs-sidenav-collapse-out .rs-dropdown-item-submenu {
    padding: 0;
  }
  .rs-sidenav-collapse-out .rs-dropdown-toggle-caret {
    display: none;
  }
  .rs-sidenav-collapse-out .rs-dropdown-toggle > span,
  .rs-sidenav-collapse-out .rs-sidenav-item-text {
    -webkit-animation: sideNavFoldedText 0.15s ease-in forwards;
    animation: sideNavFoldedText 0.15s ease-in forwards;
  }
  .rs-sidenav-collapsing.rs-sidenav-collapse-in .rs-dropdown .rs-dropdown-toggle,
  .rs-sidenav-collapsing.rs-sidenav-collapse-in .rs-dropdown-item,
  .rs-sidenav-collapsing.rs-sidenav-collapse-in .rs-dropdown-item-submenu > .rs-dropdown-menu-toggle,
  .rs-sidenav-collapsing.rs-sidenav-collapse-in .rs-sidenav-item,
  .rs-sidenav-collapsing.rs-sidenav-collapse-out .rs-dropdown .rs-dropdown-toggle,
  .rs-sidenav-collapsing.rs-sidenav-collapse-out .rs-dropdown-item,
  .rs-sidenav-collapsing.rs-sidenav-collapse-out .rs-dropdown-item-submenu > .rs-dropdown-menu-toggle,
  .rs-sidenav-collapsing.rs-sidenav-collapse-out .rs-sidenav-item {
    text-overflow: clip;
    white-space: nowrap;
  }
  .rs-sidenav-collapsing.rs-sidenav-collapse-in .rs-dropdown-toggle-caret {
    display: none;
  }
  .rs-sidenav-collapsing.rs-sidenav-collapse-out .rs-dropdown .rs-dropdown-menu {
    padding-left: 0;
  }
  .rs-sidenav-collapsing.rs-sidenav-collapse-out .rs-dropdown-item {
    -webkit-animation: sideNavFoldedText 0.15s ease-in;
    animation: sideNavFoldedText 0.15s ease-in;
  }
  .rs-sidenav-default {
    color: #575757;
    color: var(--rs-sidenav-default-text);
  }
  .rs-sidenav-default,
  .rs-sidenav-default .rs-dropdown-toggle,
  .rs-sidenav-default .rs-sidenav-item,
  .rs-sidenav-default .rs-sidenav-toggle-button {
    background-color: #f7f7fa;
    background-color: var(--rs-sidenav-default-bg);
  }
  .rs-sidenav-default .rs-dropdown .rs-dropdown-toggle:hover,
  .rs-sidenav-default .rs-dropdown.rs-dropdown-open .rs-dropdown-toggle,
  .rs-sidenav-default .rs-sidenav-item:hover,
  .rs-sidenav-default .rs-sidenav-toggle-button:hover {
    background-color: #e5e5ea;
    background-color: var(--rs-sidenav-default-hover-bg);
    color: #575757;
    color: var(--rs-sidenav-default-hover-text);
  }
  .rs-sidenav-default .rs-dropdown-item.rs-dropdown-item-active,
  .rs-sidenav-default .rs-dropdown.rs-dropdown-selected-within .rs-sidenav-item-icon,
  .rs-sidenav-default .rs-sidenav-item.rs-sidenav-item-active {
    color: #1675e0;
    color: var(--rs-sidenav-default-selected-text);
  }
  .rs-sidenav-default .rs-sidenav-toggle {
    border-top-color: #e5e5ea;
    border-top-color: var(--rs-sidenav-default-footer-border);
  }
  .rs-sidenav-default.rs-sidenav-collapse-out .rs-dropdown-item.rs-dropdown-item-active {
    background-color: rgb(from #cce9ff r g b/50%);
    background-color: var(--rs-menuitem-active-bg);
    color: #1675e0;
    color: var(--rs-menuitem-active-text);
  }
  .rs-theme-high-contrast .rs-sidenav-default.rs-sidenav-collapse-out .rs-dropdown-item.rs-dropdown-item-active {
    -webkit-box-shadow: inset 0 0 0 2px #fff;
    box-shadow: inset 0 0 0 2px #fff;
    text-decoration: underline;
  }
  .rs-sidenav-default.rs-sidenav-collapse-in .rs-dropdown-item > .rs-dropdown-item-toggle,
  .rs-sidenav-default.rs-sidenav-collapse-in .rs-dropdown-item > .rs-dropdown-menu-toggle,
  .rs-sidenav-default.rs-sidenav-collapsing .rs-dropdown-item > .rs-dropdown-item-toggle,
  .rs-sidenav-default.rs-sidenav-collapsing .rs-dropdown-item > .rs-dropdown-menu-toggle {
    color: #575757;
    color: var(--rs-sidenav-default-text);
  }
  .rs-sidenav-default.rs-sidenav-collapse-in .rs-dropdown-item > .rs-dropdown-item-toggle.rs-dropdown-item-focus,
  .rs-sidenav-default.rs-sidenav-collapse-in .rs-dropdown-item > .rs-dropdown-item-toggle:focus,
  .rs-sidenav-default.rs-sidenav-collapse-in .rs-dropdown-item > .rs-dropdown-item-toggle:hover,
  .rs-sidenav-default.rs-sidenav-collapse-in .rs-dropdown-item > .rs-dropdown-menu-toggle.rs-dropdown-item-focus,
  .rs-sidenav-default.rs-sidenav-collapse-in .rs-dropdown-item > .rs-dropdown-menu-toggle:focus,
  .rs-sidenav-default.rs-sidenav-collapse-in .rs-dropdown-item > .rs-dropdown-menu-toggle:hover,
  .rs-sidenav-default.rs-sidenav-collapsing .rs-dropdown-item > .rs-dropdown-item-toggle.rs-dropdown-item-focus,
  .rs-sidenav-default.rs-sidenav-collapsing .rs-dropdown-item > .rs-dropdown-item-toggle:focus,
  .rs-sidenav-default.rs-sidenav-collapsing .rs-dropdown-item > .rs-dropdown-item-toggle:hover,
  .rs-sidenav-default.rs-sidenav-collapsing .rs-dropdown-item > .rs-dropdown-menu-toggle.rs-dropdown-item-focus,
  .rs-sidenav-default.rs-sidenav-collapsing .rs-dropdown-item > .rs-dropdown-menu-toggle:focus,
  .rs-sidenav-default.rs-sidenav-collapsing .rs-dropdown-item > .rs-dropdown-menu-toggle:hover {
    background-color: #e5e5ea;
    background-color: var(--rs-sidenav-default-hover-bg);
    color: #575757;
    color: var(--rs-sidenav-default-hover-text);
  }
  .rs-sidenav-default.rs-sidenav-collapse-in .rs-dropdown-menu > .rs-dropdown-item.rs-dropdown-item-active,
  .rs-sidenav-default.rs-sidenav-collapse-in .rs-sidenav-item.rs-sidenav-item-active,
  .rs-sidenav-default.rs-sidenav-collapsing .rs-dropdown-menu > .rs-dropdown-item.rs-dropdown-item-active,
  .rs-sidenav-default.rs-sidenav-collapsing .rs-sidenav-item.rs-sidenav-item-active {
    color: #1675e0;
    color: var(--rs-sidenav-default-selected-text);
  }
  .rs-theme-high-contrast .rs-sidenav-default.rs-sidenav-collapse-in .rs-dropdown-menu > .rs-dropdown-item.rs-dropdown-item-active,
  .rs-theme-high-contrast .rs-sidenav-default.rs-sidenav-collapse-in .rs-sidenav-item.rs-sidenav-item-active,
  .rs-theme-high-contrast .rs-sidenav-default.rs-sidenav-collapsing .rs-dropdown-menu > .rs-dropdown-item.rs-dropdown-item-active,
  .rs-theme-high-contrast .rs-sidenav-default.rs-sidenav-collapsing .rs-sidenav-item.rs-sidenav-item-active {
    text-decoration: underline;
  }
  .rs-sidenav-default.rs-sidenav-collapse-in .rs-dropdown.rs-dropdown-selected-within .rs-sidenav-item-icon,
  .rs-sidenav-default.rs-sidenav-collapse-out .rs-dropdown.rs-dropdown-selected-within .rs-sidenav-item-icon,
  .rs-sidenav-default.rs-sidenav-collapsing .rs-dropdown.rs-dropdown-selected-within .rs-sidenav-item-icon {
    color: #1675e0;
    color: var(--rs-sidenav-default-selected-text);
  }
  .rs-sidenav-inverse {
    color: #fff;
    color: var(--rs-sidenav-inverse-text);
  }
  .rs-sidenav-inverse,
  .rs-sidenav-inverse .rs-dropdown .rs-dropdown-toggle,
  .rs-sidenav-inverse .rs-dropdown-item-submenu:hover > .rs-dropdown-item-toggle,
  .rs-sidenav-inverse .rs-sidenav-item,
  .rs-sidenav-inverse .rs-sidenav-toggle-button {
    background-color: #3498ff;
    background-color: var(--rs-sidenav-inverse-bg);
  }
  .rs-sidenav-inverse .rs-dropdown .rs-dropdown-toggle:focus,
  .rs-sidenav-inverse .rs-dropdown .rs-dropdown-toggle:hover,
  .rs-sidenav-inverse .rs-dropdown-item-submenu:hover > .rs-dropdown-item-toggle:focus,
  .rs-sidenav-inverse .rs-dropdown-item-submenu:hover > .rs-dropdown-item-toggle:hover,
  .rs-sidenav-inverse .rs-sidenav-item:focus,
  .rs-sidenav-inverse .rs-sidenav-item:hover,
  .rs-sidenav-inverse .rs-sidenav-toggle-button:focus,
  .rs-sidenav-inverse .rs-sidenav-toggle-button:hover {
    background-color: #2589f5;
    background-color: var(--rs-sidenav-inverse-hover-bg);
  }
  .rs-sidenav-inverse .rs-dropdown-item,
  .rs-sidenav-inverse .rs-dropdown-item > .rs-dropdown-item-toggle,
  .rs-sidenav-inverse .rs-dropdown-item > .rs-dropdown-menu-toggle,
  .rs-sidenav-inverse .rs-sidenav-toggle-button {
    color: #fff;
    color: var(--rs-sidenav-inverse-text);
  }
  .rs-sidenav-inverse .rs-dropdown-item.rs-dropdown-item-focus,
  .rs-sidenav-inverse .rs-dropdown-item:focus,
  .rs-sidenav-inverse .rs-dropdown-item:hover,
  .rs-sidenav-inverse .rs-dropdown-item > .rs-dropdown-item-toggle.rs-dropdown-item-focus,
  .rs-sidenav-inverse .rs-dropdown-item > .rs-dropdown-item-toggle:focus,
  .rs-sidenav-inverse .rs-dropdown-item > .rs-dropdown-item-toggle:hover,
  .rs-sidenav-inverse .rs-dropdown-item > .rs-dropdown-menu-toggle.rs-dropdown-item-focus,
  .rs-sidenav-inverse .rs-dropdown-item > .rs-dropdown-menu-toggle:focus,
  .rs-sidenav-inverse .rs-dropdown-item > .rs-dropdown-menu-toggle:hover,
  .rs-sidenav-inverse .rs-sidenav-toggle-button.rs-dropdown-item-focus,
  .rs-sidenav-inverse .rs-sidenav-toggle-button:focus,
  .rs-sidenav-inverse .rs-sidenav-toggle-button:hover {
    background-color: #2589f5;
    background-color: var(--rs-sidenav-inverse-hover-bg);
    color: #fff;
    color: var(--rs-sidenav-inverse-text);
  }
  .rs-theme-high-contrast .rs-sidenav-inverse .rs-dropdown.rs-dropdown-selected-within .rs-dropdown-toggle,
  .rs-theme-high-contrast .rs-sidenav-inverse .rs-sidenav-item.rs-sidenav-item-active {
    color: var(--rs-sidenav-inverse-selected-text);
  }
  .rs-sidenav-inverse .rs-dropdown .rs-dropdown-toggle,
  .rs-sidenav-inverse .rs-dropdown .rs-dropdown-toggle.rs-dropdown-item-focus,
  .rs-sidenav-inverse .rs-dropdown .rs-dropdown-toggle:focus,
  .rs-sidenav-inverse .rs-dropdown .rs-dropdown-toggle:hover,
  .rs-sidenav-inverse .rs-dropdown.rs-dropdown-selected-within .rs-dropdown-toggle,
  .rs-sidenav-inverse .rs-dropdown.rs-dropdown-selected-within .rs-dropdown-toggle.rs-dropdown-item-focus,
  .rs-sidenav-inverse .rs-dropdown.rs-dropdown-selected-within .rs-dropdown-toggle:focus,
  .rs-sidenav-inverse .rs-dropdown.rs-dropdown-selected-within .rs-dropdown-toggle:hover,
  .rs-sidenav-inverse .rs-sidenav-item,
  .rs-sidenav-inverse .rs-sidenav-item.rs-dropdown-item-focus,
  .rs-sidenav-inverse .rs-sidenav-item:focus,
  .rs-sidenav-inverse .rs-sidenav-item:hover {
    color: #fff;
    color: var(--rs-sidenav-inverse-text);
  }
  .rs-sidenav-inverse .rs-dropdown-item.rs-dropdown-item-active,
  .rs-sidenav-inverse .rs-sidenav-item.rs-sidenav-item-active {
    background-color: #1675e0;
    background-color: var(--rs-sidenav-inverse-selected-bg);
  }
  .rs-sidenav-inverse .rs-sidenav-toggle {
    border-top-color: #2589f5;
    border-top-color: var(--rs-sidenav-inverse-footer-border);
  }
  .rs-sidenav-subtle {
    background-color: transparent;
  }
  .rs-sidenav-subtle .rs-dropdown-item,
  .rs-sidenav-subtle .rs-dropdown-toggle,
  .rs-sidenav-subtle .rs-sidenav-item,
  .rs-sidenav-subtle .rs-sidenav-toggle-button {
    background-color: transparent;
    color: #575757;
    color: var(--rs-sidenav-subtle-text);
  }
  .rs-sidenav-subtle .rs-dropdown-item:focus-visible,
  .rs-sidenav-subtle .rs-dropdown-item:hover,
  .rs-sidenav-subtle .rs-dropdown-toggle:focus-visible,
  .rs-sidenav-subtle .rs-dropdown-toggle:hover,
  .rs-sidenav-subtle .rs-sidenav-item:focus-visible,
  .rs-sidenav-subtle .rs-sidenav-item:hover,
  .rs-sidenav-subtle .rs-sidenav-toggle-button:focus-visible,
  .rs-sidenav-subtle .rs-sidenav-toggle-button:hover {
    background-color: #f7f7fa;
    background-color: var(--rs-sidenav-subtle-hover-bg);
    color: #575757;
    color: var(--rs-sidenav-subtle-hover-text);
  }
  .rs-sidenav-subtle .rs-dropdown-item.rs-dropdown-item-active,
  .rs-sidenav-subtle .rs-dropdown.rs-dropdown-selected-within .rs-sidenav-item-icon,
  .rs-sidenav-subtle .rs-sidenav-item.rs-sidenav-item-active {
    color: #1675e0;
    color: var(--rs-sidenav-subtle-selected-text);
  }
  .rs-sidenav-subtle .rs-dropdown-open > .rs-dropdown-toggle.btn {
    background-color: transparent;
  }
  .rs-sidenav-subtle .rs-dropdown-open > .rs-dropdown-toggle.btn:focus,
  .rs-sidenav-subtle .rs-dropdown-open > .rs-dropdown-toggle.btn:hover {
    background-color: #f7f7fa;
    background-color: var(--rs-sidenav-subtle-hover-bg);
  }
  .rs-sidenav-subtle .rs-sidenav-toggle {
    border-top-color: #e5e5ea;
    border-top-color: var(--rs-sidenav-subtle-footer-border);
  }
  .rs-sidenav-subtle.rs-sidenav-collapse-in .rs-dropdown-item > .rs-dropdown-item-toggle,
  .rs-sidenav-subtle.rs-sidenav-collapse-in .rs-dropdown-item > .rs-dropdown-menu-toggle,
  .rs-sidenav-subtle.rs-sidenav-collapsing .rs-dropdown-item > .rs-dropdown-item-toggle,
  .rs-sidenav-subtle.rs-sidenav-collapsing .rs-dropdown-item > .rs-dropdown-menu-toggle {
    color: #575757;
    color: var(--rs-sidenav-subtle-text);
  }
  .rs-sidenav-subtle.rs-sidenav-collapse-in .rs-dropdown-item > .rs-dropdown-item-toggle:focus-visible,
  .rs-sidenav-subtle.rs-sidenav-collapse-in .rs-dropdown-item > .rs-dropdown-item-toggle:hover,
  .rs-sidenav-subtle.rs-sidenav-collapse-in .rs-dropdown-item > .rs-dropdown-menu-toggle:focus-visible,
  .rs-sidenav-subtle.rs-sidenav-collapse-in .rs-dropdown-item > .rs-dropdown-menu-toggle:hover,
  .rs-sidenav-subtle.rs-sidenav-collapsing .rs-dropdown-item > .rs-dropdown-item-toggle:focus-visible,
  .rs-sidenav-subtle.rs-sidenav-collapsing .rs-dropdown-item > .rs-dropdown-item-toggle:hover,
  .rs-sidenav-subtle.rs-sidenav-collapsing .rs-dropdown-item > .rs-dropdown-menu-toggle:focus-visible,
  .rs-sidenav-subtle.rs-sidenav-collapsing .rs-dropdown-item > .rs-dropdown-menu-toggle:hover {
    background-color: #f7f7fa;
    background-color: var(--rs-sidenav-subtle-hover-bg);
    color: #575757;
    color: var(--rs-sidenav-subtle-hover-text);
  }
  .rs-sidenav-subtle.rs-sidenav-collapse-in .rs-dropdown-menu > .rs-dropdown-item.rs-dropdown-item-active,
  .rs-sidenav-subtle.rs-sidenav-collapsing .rs-dropdown-menu > .rs-dropdown-item.rs-dropdown-item-active {
    color: #1675e0;
    color: var(--rs-sidenav-subtle-selected-text);
  }
  .rs-sidenav-subtle.rs-sidenav-collapse-in .rs-dropdown.rs-dropdown-selected-within .rs-dropdown-toggle:hover,
  .rs-sidenav-subtle.rs-sidenav-collapse-out .rs-dropdown.rs-dropdown-selected-within .rs-dropdown-toggle:hover,
  .rs-sidenav-subtle.rs-sidenav-collapsing .rs-dropdown.rs-dropdown-selected-within .rs-dropdown-toggle:hover {
    background-color: #f7f7fa;
    background-color: var(--rs-sidenav-subtle-hover-bg);
  }
  .rs-sidenav-subtle.rs-sidenav-collapse-in .rs-dropdown.rs-dropdown-selected-within .rs-dropdown-toggle-icon,
  .rs-sidenav-subtle.rs-sidenav-collapse-out .rs-dropdown.rs-dropdown-selected-within .rs-dropdown-toggle-icon,
  .rs-sidenav-subtle.rs-sidenav-collapsing .rs-dropdown.rs-dropdown-selected-within .rs-dropdown-toggle-icon {
    color: #1675e0;
    color: var(--rs-sidenav-subtle-selected-text);
  }
  @-webkit-keyframes sideNavFoldedText {
    0% {
      max-width: 200px;
      opacity: 0.8;
    }
    to {
      max-width: 0;
      opacity: 0;
    }
  }
  @keyframes sideNavFoldedText {
    0% {
      max-width: 200px;
      opacity: 0.8;
    }
    to {
      max-width: 0;
      opacity: 0;
    }
  }
  .rs-slider {
    position: relative;
  }
  .rs-slider .rs-tooltip {
    display: none;
  }
  .rs-slider .rs-tooltip.rs-tooltip-placement-top .rs-tooltip:after {
    left: 0;
    margin: auto;
    right: 0;
  }
  .rs-slider-disabled {
    cursor: not-allowed;
    opacity: 0.3;
  }
  .rs-slider-disabled .rs-slider-bar,
  .rs-slider-disabled .rs-slider-handle:before {
    cursor: not-allowed;
  }
  .rs-slider-with-mark:not(.rs-slider-vertical) {
    margin-bottom: 29px;
  }
  .rs-slider-bar {
    background-color: #f2f2f5;
    background-color: var(--rs-slider-bar);
    border-radius: 3px;
    cursor: pointer;
    height: 6px;
    -webkit-transition: background-color 0.3s ease-in-out;
    transition: background-color 0.3s ease-in-out;
  }
  .rs-slider:hover .rs-slider-bar {
    background-color: #e5e5ea;
    background-color: var(--rs-slider-hover-bar);
  }
  .rs-slider-vertical .rs-slider-bar {
    height: 100%;
    width: 6px;
  }
  .rs-slider-handle {
    outline: none;
    position: absolute;
    top: -50%;
  }
  .rs-slider-handle:before {
    background-color: #fff;
    background-color: var(--rs-slider-thumb-bg);
    border: 2px solid #3498ff;
    border: 2px solid var(--rs-slider-thumb-border);
    border-radius: 50%;
    content: "";
    cursor: pointer;
    height: 12px;
    margin-left: -6px;
    position: absolute;
    -webkit-transition: background-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
    transition: box-shadow 0.15s ease-in-out, background-color 0.15s ease-in-out, transform 0.15s ease-in-out;
    transition: box-shadow 0.15s ease-in-out, background-color 0.15s ease-in-out, transform 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out,
      -webkit-transform 0.15s ease-in-out;
    width: 12px;
  }
  .rs-slider-handle:focus:before,
  .rs-slider-handle:hover:before {
    -webkit-box-shadow: 0 0 0 8px rgb(from #a6a6a6 r g b/25%);
    -webkit-box-shadow: var(--rs-slider-thumb-hover-shadow);
    box-shadow: 0 0 0 8px rgb(from #a6a6a6 r g b/25%);
    box-shadow: var(--rs-slider-thumb-hover-shadow);
  }
  .rs-slider-handle.active:before,
  .rs-slider-handle:active:before {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  .rs-slider-vertical .rs-slider-handle {
    top: unset;
  }
  .rs-slider-vertical .rs-slider-handle:before {
    left: 3px;
    margin-top: -6px;
  }
  .rs-slider-handle.active .rs-tooltip,
  .rs-slider-handle:hover .rs-tooltip {
    display: block;
    opacity: 1;
    top: -30px;
  }
  .rs-slider-vertical .rs-slider-handle.active .rs-tooltip,
  .rs-slider-vertical .rs-slider-handle:hover .rs-tooltip {
    margin-left: 3px;
    top: -33px;
  }
  .rs-slider-mark {
    left: -2px;
    position: absolute;
    top: 15px;
    white-space: nowrap;
  }
  .rs-slider-mark-content {
    margin-left: -50%;
  }
  .rs-slider-mark-last {
    left: auto;
    right: -2px;
  }
  .rs-slider-mark-last .rs-slider-mark-content {
    margin-left: 50%;
  }
  .rs-slider-graduator {
    width: 100%;
  }
  .rs-slider-graduator li,
  .rs-slider-graduator ol {
    list-style: none;
  }
  .rs-slider-graduator > ol {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-left: 0;
    width: 100%;
  }
  .rs-slider-graduator > ol > li {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 1%;
    flex: 1 1 1%;
    position: relative;
  }
  .rs-slider-graduator > ol > li:before,
  .rs-slider-graduator > ol > li:last-child:after {
    background-color: #fff;
    background-color: var(--rs-slider-thumb-bg);
    border: 2px solid #f2f2f5;
    border: 2px solid var(--rs-slider-bar);
    border-radius: 50%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    content: "";
    display: block;
    height: 8px;
    margin-left: -4px;
    position: absolute;
    top: -1px;
    width: 8px;
  }
  .rs-slider-vertical .rs-slider-graduator > ol > li:before,
  .rs-slider-vertical .rs-slider-graduator > ol > li:last-child:after {
    bottom: -4px;
    margin-left: -1px;
    top: unset;
  }
  .rs-slider-graduator > ol > li:last-child:after {
    right: -4px;
  }
  .rs-slider-vertical .rs-slider-graduator > ol > li:last-child:after {
    bottom: unset;
    left: 0;
    top: -4px;
  }
  .rs-slider-graduator > ol > li.rs-slider-pass:before {
    border-color: #3498ff;
    border-color: var(--rs-slider-progress);
  }
  .rs-slider-graduator > ol > li.rs-slider-active:before {
    visibility: hidden;
  }
  .rs-slider-vertical .rs-slider-graduator {
    display: block;
    height: 100%;
  }
  .rs-slider-vertical .rs-slider-graduator > ol {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    height: 100%;
    padding: 0;
    width: 6px;
  }
  .rs-slider-vertical .rs-slider-graduator > ol > li {
    display: block;
    padding: 0;
  }
  .rs-slider-progress-bar {
    background-color: #3498ff;
    background-color: var(--rs-slider-progress);
    border-radius: 3px 0 0 3px;
    height: 6px;
    position: absolute;
  }
  .rs-slider-vertical .rs-slider-progress-bar {
    border-radius: 0 0 3px 3px;
    width: 6px;
  }
  .rs-slider-vertical {
    height: 100%;
  }
  .rs-slider-vertical .rs-slider-mark {
    bottom: -8px;
    left: 15px;
    top: unset;
  }
  .rs-slider-vertical .rs-slider-mark-content {
    margin-left: auto;
  }
  .rs-slider-vertical .rs-slider-mark-last {
    bottom: unset;
    top: -8px;
  }
  .rs-steps {
    min-height: 30px;
  }
  .rs-steps-item {
    overflow: hidden;
    padding-left: 40px;
    position: relative;
  }
  .rs-steps-item-icon-wrapper {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border: 1px solid #8e8e93;
    border: 1px solid var(--rs-steps-border);
    border-radius: 50%;
    color: #8e8e93;
    color: var(--rs-text-secondary);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 16px;
    height: 30px;
    left: 0;
    line-height: 1.75;
    position: absolute;
    text-align: center;
    top: 0;
    width: 30px;
  }
  .rs-steps-item-status-finish .rs-steps-item-icon-wrapper {
    border-color: #3498ff;
    border-color: var(--rs-steps-state-finish);
    color: #3498ff;
    color: var(--rs-steps-state-finish);
  }
  .rs-steps-item-status-error .rs-steps-item-icon-wrapper {
    border-color: #f44336;
    border-color: var(--rs-steps-state-error);
    color: #f44336;
    color: var(--rs-steps-state-error);
  }
  .rs-steps-item-status-process .rs-steps-item-icon-wrapper {
    background-color: #3498ff;
    background-color: var(--rs-steps-state-process);
    border-color: #3498ff;
    border-color: var(--rs-steps-state-process);
    color: #f7f7fa;
    color: var(--rs-text-inverse);
  }
  .rs-steps-item-status-process .rs-steps-item-icon-wrapper .rs-icon {
    color: #3498ff;
    color: var(--rs-steps-icon-state-process);
  }
  .rs-steps-item-icon-wrapper.rs-steps-item-custom-icon {
    background: none;
    border: none;
  }
  .rs-steps-item-icon-wrapper > .rs-steps-item-icon {
    display: block;
    position: relative;
    text-align: center;
    width: 100%;
  }
  .rs-steps-item-icon-wrapper > .rs-steps-item-icon .rs-icon {
    font-size: 14px;
    vertical-align: middle;
  }
  .rs-steps-item-content {
    color: #8e8e93;
    color: var(--rs-text-secondary);
    display: inline-block;
    position: relative;
    width: 100%;
  }
  .rs-steps-item-status-process .rs-steps-item-content {
    color: #575757;
    color: var(--rs-text-primary);
  }
  .rs-steps-item-status-error .rs-steps-item-content {
    color: #f44336;
    color: var(--rs-text-error);
  }
  .rs-steps-item-title {
    display: inline-block;
    font-size: 16px;
    line-height: 1.875;
    min-height: 16px;
    padding-right: 10px;
    position: relative;
  }
  .rs-steps-item-title:empty {
    padding-right: 0;
  }
  .rs-steps-item-description {
    margin-top: 12px;
  }
  .rs-steps-item-tail,
  .rs-steps-item-title:after {
    position: absolute;
  }
  .rs-steps-item-status-process .rs-steps-item-tail,
  .rs-steps-item-status-process .rs-steps-item-title:after,
  .rs-steps-item-tail,
  .rs-steps-item-title:after {
    border-color: #8e8e93;
    border-color: var(--rs-steps-border);
  }
  .rs-steps-item-status-finish .rs-steps-item-tail,
  .rs-steps-item-status-finish .rs-steps-item-title:after {
    border-color: #3498ff;
    border-color: var(--rs-steps-state-finish);
  }
  .rs-steps-next-error .rs-steps-item-tail,
  .rs-steps-next-error .rs-steps-item-title:after {
    border-color: #f44336;
    border-color: var(--rs-steps-border-state-error);
  }
  .rs-steps-horizontal {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
  }
  .rs-steps-horizontal .rs-steps-item {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    -ms-flex-negative: 1;
    flex-grow: 1;
    flex-shrink: 1;
    min-height: 30px;
  }
  .rs-steps-horizontal .rs-steps-item:last-child {
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    -ms-flex-negative: 0;
    flex-grow: 0;
    flex-shrink: 0;
  }
  .rs-steps-horizontal .rs-steps-item:last-child .rs-steps-item-title:after {
    display: none;
  }
  .rs-steps-horizontal .rs-steps-item:not(:first-child) {
    padding-left: 50px;
  }
  .rs-steps-horizontal .rs-steps-item:not(:first-child) .rs-steps-item-icon-wrapper {
    left: 10px;
  }
  .rs-steps-horizontal .rs-steps-item-content {
    height: 30px;
  }
  .rs-steps-horizontal .rs-steps-item-title:after {
    border-top-style: solid;
    border-top-width: 1px;
    content: "";
    left: 100%;
    top: 15px;
    width: 9999px;
  }
  .rs-steps-vertical .rs-steps-item {
    padding-bottom: 20px;
  }
  .rs-steps-vertical .rs-steps-item:not(:first-child) {
    margin-top: 10px;
  }
  .rs-steps-vertical .rs-steps-item:last-child .rs-steps-item-tail {
    display: none;
  }
  .rs-steps-vertical .rs-steps-item-tail {
    border-left-style: solid;
    border-left-width: 1px;
    bottom: 0;
    left: 15px;
    top: 40px;
  }
  .rs-steps-small,
  .rs-steps-small.rs-steps-horizontal .rs-steps-item {
    min-height: 24px;
  }
  .rs-steps-small.rs-steps-horizontal .rs-steps-item:not(:first-child) {
    padding-left: 44px;
  }
  .rs-steps-small.rs-steps-vertical .rs-steps-item-tail {
    left: 12px;
  }
  .rs-steps-small.rs-steps-horizontal .rs-steps-item-title:after {
    top: 12px;
  }
  .rs-steps-small .rs-steps-item {
    padding-left: 34px;
  }
  .rs-steps-small .rs-steps-item-icon-wrapper {
    font-size: 14px;
    height: 24px;
    line-height: 1.57142857;
    width: 24px;
  }
  .rs-steps-small .rs-steps-item-title {
    font-size: 14px;
    line-height: 1.71428571;
  }
  .rs-steps-small .rs-steps-item-description {
    font-size: 12px;
    line-height: 1.66666667;
    margin-top: 12px;
  }
  .rs-table {
    overflow: hidden;
    position: relative;
  }
  .rs-table-column-resizing {
    cursor: ew-resize;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .rs-table-row {
    height: 36px;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .rs-table-row.virtualized {
    pointer-events: none;
  }
  @media not all and (min-resolution: 0.001dpcm) {
    .rs-table-row.virtualized {
      visibility: hidden;
    }
  }
  .rs-table-row-expanded {
    border-top: 1px solid #f2f2f5;
    border-top: 1px solid var(--rs-border-secondary);
    bottom: 0;
    height: 46px;
    padding: 10px;
    position: absolute;
    width: 100%;
    z-index: 4;
  }
  .rs-table-hover .rs-table-row:hover .rs-table-cell,
  .rs-table-hover .rs-table-row:hover .rs-table-cell-group,
  .rs-table-row-expanded,
  .rs-table-row-header,
  .rs-table-row-header .rs-table-cell {
    background-color: #fff;
    background-color: var(--rs-bg-card);
  }
  .rs-table-row-header .rs-table-cell-group-shadow {
    -webkit-box-shadow: none;
    box-shadow: none;
    z-index: 5;
  }
  .rs-table-hover .rs-table-row:hover .rs-table-cell,
  .rs-table-hover .rs-table-row:hover .rs-table-cell-group,
  .rs-table-hover .rs-table-row:not(.rs-table-row-header):hover {
    background-color: #f2faff;
    background-color: var(--rs-state-hover-bg);
  }
  .rs-table-hover .rs-table-row-header:hover .rs-table-cell,
  .rs-table-hover .rs-table-row-header:hover .rs-table-cell-group {
    background-color: #fff;
    background-color: var(--rs-bg-card);
  }
  .rs-table-header-row-wrapper {
    color: #8e8e93;
    color: var(--rs-text-secondary);
    font-size: 12px;
    position: relative;
    z-index: 2;
  }
  .rs-table-affix-header {
    visibility: hidden;
    z-index: 3;
  }
  .rs-table-affix-header.fixed {
    visibility: visible;
  }
  .rs-table-body-row-wrapper {
    overflow: hidden;
    position: relative;
    z-index: 0;
  }
  .rs-table-body-info {
    line-height: 40px;
    margin-top: -20px;
    position: absolute;
    text-align: center;
    top: 50%;
    width: 100%;
  }
  .rs-table-body-info .icon {
    margin: 0 10px;
  }
  .rs-table-body-info-wheel-area,
  .rs-table-body-wheel-area {
    width: 100%;
  }
  .rs-table-loader-wrapper {
    background-color: rgb(from #fff r g b/90%);
    background-color: var(--rs-loader-backdrop);
    height: 100%;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    -webkit-transition: opacity 0.2s ease-in;
    transition: opacity 0.2s ease-in;
    width: 100%;
  }
  @media not all and (min-resolution: 0.001dpcm) {
    .rs-table-loader-wrapper {
      visibility: hidden;
    }
  }
  .rs-table-loader {
    line-height: 40px;
    margin-top: -20px;
    position: absolute;
    text-align: center;
    top: 50%;
    width: 100%;
  }
  .rs-table-loader-icon {
    display: inline-block;
    margin-right: 12px;
    padding-top: 3px;
    position: relative;
  }
  .rs-table-loader-icon,
  .rs-table-loader-icon:after,
  .rs-table-loader-icon:before {
    height: 18px;
    width: 18px;
  }
  .rs-table-loader-icon:after,
  .rs-table-loader-icon:before {
    border-radius: 50%;
    content: "";
    display: block;
    left: 0;
    position: absolute;
    right: 0;
  }
  .rs-table-loader-icon:before {
    border: 3px solid rgb(from #f7f7fa r g b/80%);
    border: 3px solid var(--rs-loader-ring);
  }
  .rs-table-loader-icon:after {
    -webkit-animation: loaderSpin 0.6s linear infinite;
    animation: loaderSpin 0.6s linear infinite;
    border: 3px solid transparent;
    border-top-color: #a6a6a6;
    border-color: var(--rs-loader-rotor) transparent transparent;
  }
  .rs-table-loading .rs-table-loader-wrapper {
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
    z-index: 1;
  }
  .rs-table-cell {
    background-color: #fff;
    background-color: var(--rs-bg-card);
    border-bottom: 1px solid #f2f2f5;
    border-bottom: 1px solid var(--rs-border-secondary);
    display: block;
    height: 36px;
    overflow: hidden;
    position: absolute;
    white-space: normal;
  }
  .rs-table-cell.first {
    border-left-width: 0;
  }
  .rs-table-cell-wrap1 {
    display: table;
  }
  .rs-table-cell-wrap2 {
    display: table-row;
  }
  .rs-table-cell-wrap3 {
    display: table-cell;
    vertical-align: middle;
  }
  .rs-table-cell-content {
    overflow: hidden;
    padding: 13px 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
  .rs-table-cell-header .rs-table-cell-content {
    line-height: 1.66666667;
    padding: 10px;
  }
  .rs-table-cell-header-sort-wrapper {
    cursor: pointer;
    display: inline-block;
    margin-left: 5px;
  }
  .rs-table-cell-header-icon-sort {
    color: #3498ff;
    color: var(--rs-table-sort);
    font-size: 16px;
    line-height: 1.66666667;
  }
  .rs-table-cell-rowspan {
    border-bottom: 1px solid #f2f2f5 !important;
    border-bottom: 1px solid var(--rs-border-secondary) !important;
  }
  .rs-table-cell-full-text:hover {
    -webkit-box-shadow: inset 0 0 2px #3498ff;
    -webkit-box-shadow: inset var(--rs-primary-500) 0 0 2px;
    box-shadow: inset 0 0 2px #3498ff;
    box-shadow: inset var(--rs-primary-500) 0 0 2px;
    width: auto !important;
    z-index: 1 !important;
  }
  .rs-table-cell-full-text:hover .rs-table-cell-content {
    width: auto !important;
  }
  .rs-table-cell-header-sortable .rs-table-cell-content {
    cursor: pointer;
  }
  .rs-table-column-resize-spanner {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    cursor: ew-resize !important;
    height: 36px;
    outline: none;
    position: absolute;
    width: 3px;
    z-index: 3;
  }
  .rs-table-column-resize-spanner:after,
  .rs-table-column-resize-spanner:before {
    bottom: 0;
    content: " ";
    display: none;
    height: 0;
    margin: auto;
    position: absolute;
    top: 0;
    width: 0;
  }
  .rs-table-column-resize-spanner:before {
    border-color: transparent #3498ff transparent transparent;
    border-color: transparent var(--rs-table-resize) transparent transparent;
    border-style: dashed solid dashed dashed;
    border-width: 3px;
    right: 4px;
  }
  .rs-table-column-resize-spanner:after {
    border-color: transparent transparent transparent #3498ff;
    border-color: transparent transparent transparent var(--rs-table-resize);
    border-style: dashed dashed dashed solid;
    border-width: 3px;
    left: 4px;
  }
  .rs-table:not(.rs-table-column-resizing) .rs-table-cell-header .rs-table-cell:hover ~ .rs-table-column-resize-spanner,
  .rs-table:not(.rs-table-column-resizing) .rs-table-column-resize-spanner:hover {
    background-color: #3498ff;
    background-color: var(--rs-table-resize);
  }
  .rs-table:not(.rs-table-column-resizing) .rs-table-cell-header .rs-table-cell:hover ~ .rs-table-column-resize-spanner:after,
  .rs-table:not(.rs-table-column-resizing) .rs-table-cell-header .rs-table-cell:hover ~ .rs-table-column-resize-spanner:before,
  .rs-table:not(.rs-table-column-resizing) .rs-table-column-resize-spanner:hover:after,
  .rs-table:not(.rs-table-column-resizing) .rs-table-column-resize-spanner:hover:before {
    display: block;
  }
  .rs-table-cell-group-fixed-left,
  .rs-table-cell-group-fixed-right {
    background-color: #fff;
    background-color: var(--rs-bg-card);
    position: absolute;
    z-index: 4;
  }
  .rs-table-cell-group-left-shadow,
  .rs-table-cell-group-shadow {
    -webkit-box-shadow: 3px 0 5px rgba(9, 9, 9, 0.08);
    -webkit-box-shadow: 3px 0 5px var(--rs-table-shadow);
    box-shadow: 3px 0 5px rgba(9, 9, 9, 0.08);
    box-shadow: 3px 0 5px var(--rs-table-shadow);
  }
  .rs-table-cell-group-right-shadow {
    -webkit-box-shadow: -3px 0 5px rgba(9, 9, 9, 0.08);
    -webkit-box-shadow: -3px 0 5px var(--rs-table-shadow);
    box-shadow: -3px 0 5px rgba(9, 9, 9, 0.08);
    box-shadow: -3px 0 5px var(--rs-table-shadow);
  }
  .rs-table-mouse-area {
    display: none;
    left: -1px;
    top: 0;
    width: 1px;
    z-index: 6;
  }
  .rs-table-mouse-area,
  .rs-table-mouse-area > span {
    background-color: #3498ff;
    background-color: var(--rs-table-resize);
    position: absolute;
  }
  .rs-table-mouse-area > span {
    cursor: ew-resize !important;
    height: 36px;
    left: -1.5px;
    outline: none;
    width: 3px;
    z-index: 3;
  }
  .rs-table-word-wrap .rs-table-cell-content {
    white-space: normal;
  }
  .rs-table-bordered {
    border: 1px solid #f2f2f5;
    border: 1px solid var(--rs-border-secondary);
  }
  .rs-table-cell-bordered .rs-table-cell {
    border-right: 1px solid #f2f2f5;
    border-right: 1px solid var(--rs-border-secondary);
  }
  .rs-table-column-group {
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }
  .rs-table-column-group-header {
    border-bottom: 1px solid #f2f2f5;
    border-bottom: 1px solid var(--rs-border-secondary);
    position: absolute;
    width: 100%;
  }
  .rs-table-column-group-header-content {
    display: table-cell;
    padding: 10px;
  }
  .rs-table-column-group-cell {
    border-right: 1px solid #f2f2f5;
    border-right: 1px solid var(--rs-border-secondary);
    position: absolute;
  }
  .rs-table-cell-expand-wrapper {
    cursor: pointer;
    display: inline-block;
    margin-right: 10px;
  }
  .rs-table-cell-expand-icon {
    cursor: pointer;
    font-size: 16px;
    outline: none;
  }
  .rs-table-scrollbar {
    background-color: #e5e5ea;
    background-color: var(--rs-table-scrollbar-track);
    opacity: 0.6;
    position: absolute;
    -webkit-transition: background 0.1s linear, -webkit-transform 0.1s linear;
    transition: background 0.1s linear, -webkit-transform 0.1s linear;
    transition: background 0.1s linear, transform 0.1s linear;
    transition: background 0.1s linear, transform 0.1s linear, -webkit-transform 0.1s linear;
    z-index: 1;
  }
  .rs-table-scrollbar-hide {
    display: none;
  }
  .rs-table-scrollbar-handle {
    background-color: #575757;
    background-color: var(--rs-table-scrollbar-thumb);
    border-radius: 4px;
    position: absolute;
  }
  .rs-table-scrollbar-pressed {
    z-index: 1;
  }
  .rs-table-scrollbar-pressed .rs-table-scrollbar-handle {
    background-color: #272c36;
    background-color: var(--rs-table-scrollbar-thumb-active);
  }
  .rs-table-scrollbar-horizontal {
    bottom: 0;
    height: 10px;
    width: 100%;
  }
  .rs-table-scrollbar-horizontal.fixed {
    position: fixed;
  }
  .rs-table-scrollbar-horizontal .rs-table-scrollbar-handle {
    height: 8px;
    left: 0;
    top: 1px;
  }
  .rs-table-scrollbar-horizontal:hover,
  .rs-table-scrollbar-pressed.rs-table-scrollbar-horizontal:hover {
    -webkit-transform: scaleY(1.3);
    transform: scaleY(1.3);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
  }
  .rs-table-scrollbar-vertical {
    background-color: rgb(from #e5e5ea r g b/40%);
    background-color: var(--rs-table-scrollbar-vertical-track);
    bottom: 10px;
    right: 0;
    top: 0;
    width: 10px;
  }
  .rs-table-scrollbar-vertical .rs-table-scrollbar-handle {
    left: 1px;
    min-height: 20px;
    top: 0;
    width: 8px;
  }
  .rs-table-scrollbar-pressed.rs-table-scrollbar-vertical:hover,
  .rs-table-scrollbar-vertical:hover {
    -webkit-transform: scaleX(1.3);
    transform: scaleX(1.3);
    -webkit-transform-origin: right center;
    transform-origin: right center;
  }
  .rs-tag {
    background-color: #f7f7fa;
    background-color: var(--rs-tag-bg);
    border-radius: 6px;
    color: #575757;
    color: var(--rs-text-primary);
    display: inline-block;
    line-height: 20px;
  }
  .rs-tag-closable {
    position: relative;
  }
  .rs-tag-icon-close {
    position: absolute;
    right: 0;
    top: 0;
  }
  .rs-tag-lg {
    font-size: 14px;
    padding: 5px 10px;
  }
  .rs-tag-lg .rs-tag-icon-close {
    padding: 9px 8px;
  }
  .rs-tag-closable.rs-tag-lg {
    padding-right: 27px;
  }
  .rs-tag-md {
    font-size: 12px;
    padding: 2px 8px;
  }
  .rs-tag-md .rs-tag-icon-close {
    padding: 6px 8px;
  }
  .rs-tag-closable.rs-tag-md {
    padding-right: 25px;
  }
  .rs-tag-sm {
    font-size: 12px;
    padding: 0 8px;
  }
  .rs-tag-sm .rs-tag-icon-close {
    padding: 4px 5px;
  }
  .rs-tag-closable.rs-tag-sm {
    padding-right: 25px;
  }
  .rs-tag + .rs-tag {
    margin-left: 10px;
  }
  .rs-tag-red {
    background-color: #f44336;
    background-color: var(--rs-red-500);
    color: #fff;
  }
  .rs-tag-orange {
    background-color: #fa8900;
    background-color: var(--rs-orange-500);
    color: #fff;
  }
  .rs-tag-yellow {
    background-color: #ffb300;
    background-color: var(--rs-yellow-500);
    color: #fff;
  }
  .rs-tag-green {
    background-color: #4caf50;
    background-color: var(--rs-green-500);
    color: #fff;
  }
  .rs-tag-cyan {
    background-color: #00bcd4;
    background-color: var(--rs-cyan-500);
    color: #fff;
  }
  .rs-tag-blue {
    background-color: #2196f3;
    background-color: var(--rs-blue-500);
    color: #fff;
  }
  .rs-tag-violet {
    background-color: #673ab7;
    background-color: var(--rs-violet-500);
    color: #fff;
  }
  .rs-tag-group {
    margin: -10px 0 0 -10px;
  }
  .rs-tag-group > {
    margin-left: 10px;
    margin-top: 10px;
  }
  .rs-tabs {
    -webkit-box-direction: normal;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 10px;
  }
  .rs-tabs,
  .rs-tabs-reversed {
    -webkit-box-orient: vertical;
  }
  .rs-tabs-reversed {
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .rs-tabs-vertical {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .rs-tabs-vertical .rs-tabs-content {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .rs-tabs .rs-nav-item {
    background: none;
  }
  .rs-tabs .rs-nav-vertical {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .rs-picker-tag .rs-picker-toggle {
    cursor: text;
    left: 0;
  }
  .rs-picker-tag.rs-picker-disabled {
    cursor: not-allowed;
  }
  .rs-picker-tag.rs-picker-disabled .rs-picker-toggle {
    position: absolute;
  }
  .rs-picker-tag .rs-tag {
    max-width: calc(100% - 6px);
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: top;
    white-space: nowrap;
  }
  .rs-picker-tag .rs-picker-search-input,
  .rs-picker-tag .rs-tag {
    margin-top: 5px;
  }
  .rs-picker-tag.rs-picker-has-value .rs-picker-search-input {
    padding-left: 6px;
  }
  .rs-picker-tag .rs-tag,
  .rs-picker-tag .rs-tag + .rs-tag {
    margin-left: 6px;
  }
  .rs-picker-tag .rs-picker-search,
  .rs-picker-tag .rs-picker-search-input,
  .rs-picker-tag .rs-picker-search-input > input {
    display: inline-block;
    min-width: 14px;
    width: auto;
  }
  .rs-picker-tag .rs-picker-search-input > input {
    padding: 0;
  }
  .rs-picker-tag .rs-picker-textbox {
    padding-bottom: 5px;
    position: relative;
    z-index: 6;
  }
  .rs-picker-tag-list {
    display: contents;
  }
  .rs-plaintext .rs-tag {
    margin-bottom: 6px;
    margin-left: 6px;
  }
  .rs-picker-disabled-options .rs-picker-toggle {
    padding-right: 32px !important;
  }
  .rs-picker-disabled-options .rs-picker-textbox {
    margin-right: 32px !important;
  }
  .rs-picker-disabled-options .rs-picker-clean {
    right: 10px !important;
  }
  .rs-timeline {
    direction: ltr;
    list-style: none;
    margin-bottom: 0;
    padding: 0;
  }
  .rs-timeline-item {
    position: relative;
    text-align: left;
  }
  .rs-timeline-item:not(:last-child) .rs-timeline-item-content {
    padding-bottom: 18px;
  }
  .rs-timeline-item-dot {
    position: absolute;
    top: 5px;
  }
  .rs-timeline-item-dot:before {
    background-color: #d9d9d9;
    background-color: var(--rs-timeline-indicator-bg);
    border-radius: 50%;
    content: "";
    display: block;
    height: 10px;
    width: 10px;
  }
  .rs-timeline-item-custom-dot {
    text-align: center;
  }
  .rs-timeline-item-custom-dot:before {
    display: none;
  }
  .rs-timeline-item-active .rs-timeline-item-dot:before {
    background-color: #3498ff;
    background-color: var(--rs-timeline-indicator-active-bg);
  }
  .rs-timeline-item-tail {
    background-color: #d9d9d9;
    background-color: var(--rs-timeline-indicator-bg);
    bottom: 0;
    position: absolute;
    top: 0;
    width: 2px;
  }
  .rs-timeline-item:first-child .rs-timeline-item-tail {
    top: 5px;
  }
  .rs-timeline-item:last-child .rs-timeline-item-tail {
    bottom: auto;
    height: 15px;
  }
  .rs-timeline-endless .rs-timeline-item:last-child .rs-timeline-item-tail {
    height: auto;
    min-height: 38px;
  }
  .rs-timeline-item:only-child .rs-timeline-item-tail {
    display: none;
  }
  .rs-timeline-align-left .rs-timeline-item-dot {
    left: 0;
  }
  .rs-timeline-align-left .rs-timeline-item-tail {
    left: 4px;
  }
  .rs-timeline-align-left .rs-timeline-item {
    padding-left: 22px;
  }
  .rs-timeline-align-right .rs-timeline-item-dot {
    right: 0;
  }
  .rs-timeline-align-right .rs-timeline-item-tail {
    right: 4px;
  }
  .rs-timeline-align-right .rs-timeline-item {
    padding-right: 22px;
  }
  .rs-timeline-align-right .rs-timeline-item-content {
    text-align: right;
  }
  .rs-timeline-align-alternate .rs-timeline-item-dot {
    left: calc(50% - 5px);
  }
  .rs-timeline-align-alternate .rs-timeline-item-tail {
    left: calc(50% - 1px);
  }
  .rs-timeline-align-alternate .rs-timeline-item-content {
    width: 50%;
  }
  .rs-timeline-align-alternate .rs-timeline-item:nth-child(2n) .rs-timeline-item-content,
  .rs-timeline-align-alternate .rs-timeline-item:nth-child(odd) {
    text-align: right;
  }
  .rs-timeline-align-alternate .rs-timeline-item:nth-child(odd) .rs-timeline-item-content {
    display: inline-block;
    text-align: left;
  }
  .rs-timeline-with-time .rs-timeline-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-left: 0;
  }
  .rs-timeline-with-time .rs-timeline-item-dot {
    left: calc(50% - 5px);
  }
  .rs-timeline-with-time .rs-timeline-item-tail {
    left: calc(50% - 1px);
  }
  .rs-timeline-with-time .rs-timeline-item-content,
  .rs-timeline-with-time .rs-timeline-item-time {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 50%;
    flex: 1 0 50%;
  }
  @media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
    .rs-timeline-with-time .rs-timeline-item-content,
    .rs-timeline-with-time .rs-timeline-item-time {
      max-width: 50%;
    }
  }
  .rs-timeline-align-alternate .rs-timeline-item:nth-child(2n),
  .rs-timeline-with-time.rs-timeline-align-right .rs-timeline-item {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  .rs-timeline-with-time.rs-timeline-align-right .rs-timeline-item {
    padding-right: 0;
  }
  .rs-timeline-with-time.rs-timeline-align-left .rs-timeline-item-time {
    text-align: right;
  }
  .rs-timeline-with-time.rs-timeline-align-right .rs-timeline-item-time {
    text-align: left;
  }
  .rs-timeline-align-alternate .rs-timeline-item:nth-child(2n) .rs-timeline-item-content,
  .rs-timeline-align-alternate .rs-timeline-item:nth-child(odd) .rs-timeline-item-time,
  .rs-timeline-with-time.rs-timeline-align-left .rs-timeline-item-time,
  .rs-timeline-with-time.rs-timeline-align-right .rs-timeline-item-content {
    padding-right: 17px;
  }
  .rs-timeline-align-alternate .rs-timeline-item:nth-child(2n) .rs-timeline-item-time,
  .rs-timeline-align-alternate .rs-timeline-item:nth-child(odd) .rs-timeline-item-content,
  .rs-timeline-with-time.rs-timeline-align-left .rs-timeline-item-content,
  .rs-timeline-with-time.rs-timeline-align-right .rs-timeline-item-time {
    padding-left: 17px;
  }
  .rs-toggle {
    position: relative;
  }
  .rs-toggle .rs-toggle-loader {
    height: 18px;
    left: 3px;
    top: 3px;
    width: 18px;
  }
  .rs-toggle .rs-toggle-loader .rs-loader-spin,
  .rs-toggle .rs-toggle-loader .rs-loader-spin:after,
  .rs-toggle .rs-toggle-loader .rs-loader-spin:before {
    height: 18px;
    width: 18px;
  }
  .rs-toggle .rs-toggle-presentation {
    border-radius: 12px;
    height: 24px;
    min-width: 44px;
  }
  .rs-toggle .rs-toggle-presentation:after {
    border-radius: 9px;
    height: 18px;
    left: 3px;
    top: 3px;
    width: 18px;
  }
  .rs-toggle .rs-toggle-presentation:active:after {
    width: 21.6px;
  }
  .rs-toggle .rs-toggle-inner {
    font-size: 12px;
    height: 24px;
    line-height: 2;
    margin-left: 24px;
    margin-right: 8px;
  }
  .rs-toggle .rs-toggle-inner .rs-icon {
    font-size: 12px;
  }
  .rs-toggle.rs-toggle-checked .rs-toggle-presentation:after {
    left: 100%;
    margin-left: -21px;
  }
  .rs-toggle.rs-toggle-checked .rs-toggle-presentation:active:after {
    margin-left: -24.6px;
  }
  .rs-toggle.rs-toggle-checked .rs-toggle-presentation .rs-toggle-inner {
    margin-left: 8px;
    margin-right: 24px;
  }
  .rs-toggle.rs-toggle-checked .rs-toggle-loader {
    left: 100%;
    margin-left: -21px;
  }
  .rs-toggle-input {
    bottom: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .rs-toggle-presentation {
    background-color: #d9d9d9;
    background-color: var(--rs-toggle-bg);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #fff;
    color: var(--rs-toggle-thumb);
    cursor: pointer;
    display: inline-block;
    position: relative;
    -webkit-transition: background-color 0.15s ease-out, width 0.15s ease-out;
    transition: background-color 0.15s ease-out, width 0.15s ease-out;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
  }
  .rs-theme-high-contrast .rs-toggle-presentation {
    -webkit-box-shadow: inset 0 0 0 1px #fff;
    -webkit-box-shadow: inset 0 0 0 1px var(--rs-toggle-thumb);
    box-shadow: inset 0 0 0 1px #fff;
    box-shadow: inset 0 0 0 1px var(--rs-toggle-thumb);
    -webkit-transition: none;
    transition: none;
  }
  .rs-toggle-input:focus-visible + .rs-toggle-presentation {
    outline: 3px solid rgb(from #3498ff r g b/25%);
    outline: 3px solid var(--rs-color-focus-ring);
  }
  .rs-theme-high-contrast .rs-toggle-input:focus-visible + .rs-toggle-presentation {
    outline-offset: 2px;
  }
  .rs-theme-high-contrast .rs-toggle-input:focus-visible + .rs-toggle-presentation {
    -webkit-box-shadow: inset 0 0 0 1px #fff, 0 0 0 3px rgb(from #3498ff r g b/25%);
    -webkit-box-shadow: inset 0 0 0 1px var(--rs-toggle-thumb), var(--rs-state-focus-shadow);
    box-shadow: inset 0 0 0 1px #fff, 0 0 0 3px rgb(from #3498ff r g b/25%);
    box-shadow: inset 0 0 0 1px var(--rs-toggle-thumb), var(--rs-state-focus-shadow);
  }
  .rs-toggle-presentation:hover {
    background-color: #c5c6c7;
    background-color: var(--rs-toggle-hover-bg);
  }
  .rs-toggle-presentation:after {
    background-color: currentColor;
    content: "";
    cursor: pointer;
    position: absolute;
    -webkit-transition: left 0.15s ease-out, margin-left 0.15s ease-out, width 0.15s ease-out;
    transition: left 0.15s ease-out, margin-left 0.15s ease-out, width 0.15s ease-out;
  }
  .rs-theme-high-contrast .rs-toggle-presentation:after {
    -webkit-transition: none;
    transition: none;
  }
  .rs-toggle-disabled .rs-toggle-presentation {
    background-color: #f7f7fa;
    background-color: var(--rs-toggle-disabled-bg);
    -webkit-box-shadow: inset 0 0 0 1px #fff;
    -webkit-box-shadow: inset 0 0 0 1px var(--rs-toggle-disabled-thumb);
    box-shadow: inset 0 0 0 1px #fff;
    box-shadow: inset 0 0 0 1px var(--rs-toggle-disabled-thumb);
    color: #fff;
    color: var(--rs-toggle-disabled-thumb);
    cursor: not-allowed;
  }
  .rs-toggle-checked .rs-toggle-presentation {
    background-color: #3498ff;
    background-color: var(--rs-toggle-checked-bg);
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #fff;
    color: var(--rs-toggle-checked-thumb);
  }
  .rs-toggle-checked .rs-toggle-presentation:hover {
    background-color: #2589f5;
    background-color: var(--rs-toggle-checked-hover-bg);
  }
  .rs-toggle-disabled.rs-toggle-checked .rs-toggle-presentation {
    background-color: #cce9ff;
    background-color: var(--rs-toggle-checked-disabled-bg);
    color: #fff;
    color: var(--rs-toggle-checked-disabled-thumb);
  }
  .rs-toggle-loading .rs-toggle-presentation:after {
    display: none;
  }
  .rs-toggle-inner {
    display: block;
    -webkit-transition: margin 0.15s ease-out;
    transition: margin 0.15s ease-out;
  }
  .rs-theme-high-contrast .rs-toggle-inner {
    -webkit-transition: none;
    transition: none;
  }
  .rs-toggle-loader {
    position: absolute;
    -webkit-transition: left 0.15s ease-out, margin-left 0.15s ease-out, width 0.15s ease-out;
    transition: left 0.15s ease-out, margin-left 0.15s ease-out, width 0.15s ease-out;
  }
  .rs-toggle-loader .rs-loader-spin:before {
    border-color: rgb(from #f7f7fa r g b/30%);
    border-color: var(--rs-toggle-loader-ring);
  }
  .rs-toggle-loader .rs-loader-spin:after {
    border-top-color: #fff;
    border-top-color: var(--rs-toggle-loader-rotor);
  }
  .rs-toggle-sm .rs-toggle-loader {
    height: 10px;
    left: 2px;
    top: 2px;
    width: 10px;
  }
  .rs-toggle-sm .rs-toggle-loader .rs-loader-spin,
  .rs-toggle-sm .rs-toggle-loader .rs-loader-spin:after,
  .rs-toggle-sm .rs-toggle-loader .rs-loader-spin:before {
    height: 10px;
    width: 10px;
  }
  .rs-toggle-sm .rs-toggle-loader .rs-loader-spin:after,
  .rs-toggle-sm .rs-toggle-loader .rs-loader-spin:before {
    border-width: 2px;
  }
  .rs-toggle-sm .rs-toggle-presentation {
    border-radius: 7px;
    height: 14px;
    min-width: 26px;
  }
  .rs-toggle-sm .rs-toggle-presentation:after {
    border-radius: 5px;
    height: 10px;
    left: 2px;
    top: 2px;
    width: 10px;
  }
  .rs-toggle-sm .rs-toggle-presentation:active:after {
    width: 12px;
  }
  .rs-toggle-sm .rs-toggle-inner {
    font-size: 10px;
    height: 14px;
    line-height: 1.4;
    margin-left: 14px;
    margin-right: 6px;
  }
  .rs-toggle-sm .rs-toggle-inner .rs-icon {
    font-size: 10px;
  }
  .rs-toggle-sm.rs-toggle-checked .rs-toggle-presentation:after {
    left: 100%;
    margin-left: -12px;
  }
  .rs-toggle-sm.rs-toggle-checked .rs-toggle-presentation:active:after {
    margin-left: -14px;
  }
  .rs-toggle-sm.rs-toggle-checked .rs-toggle-presentation .rs-toggle-inner {
    margin-left: 6px;
    margin-right: 14px;
  }
  .rs-toggle-sm.rs-toggle-checked .rs-toggle-loader {
    left: 100%;
    margin-left: -12px;
  }
  .rs-toggle-md .rs-toggle-loader {
    height: 18px;
    left: 3px;
    top: 3px;
    width: 18px;
  }
  .rs-toggle-md .rs-toggle-loader .rs-loader-spin,
  .rs-toggle-md .rs-toggle-loader .rs-loader-spin:after,
  .rs-toggle-md .rs-toggle-loader .rs-loader-spin:before {
    height: 18px;
    width: 18px;
  }
  .rs-toggle-md .rs-toggle-presentation {
    border-radius: 12px;
    height: 24px;
    min-width: 44px;
  }
  .rs-toggle-md .rs-toggle-presentation:after {
    border-radius: 9px;
    height: 18px;
    left: 3px;
    top: 3px;
    width: 18px;
  }
  .rs-toggle-md .rs-toggle-presentation:active:after {
    width: 21.6px;
  }
  .rs-toggle-md .rs-toggle-inner {
    font-size: 12px;
    height: 24px;
    line-height: 2;
    margin-left: 24px;
    margin-right: 8px;
  }
  .rs-toggle-md .rs-toggle-inner .rs-icon {
    font-size: 12px;
  }
  .rs-toggle-md.rs-toggle-checked .rs-toggle-presentation:after {
    left: 100%;
    margin-left: -21px;
  }
  .rs-toggle-md.rs-toggle-checked .rs-toggle-presentation:active:after {
    margin-left: -24.6px;
  }
  .rs-toggle-md.rs-toggle-checked .rs-toggle-presentation .rs-toggle-inner {
    margin-left: 8px;
    margin-right: 24px;
  }
  .rs-toggle-md.rs-toggle-checked .rs-toggle-loader {
    left: 100%;
    margin-left: -21px;
  }
  .rs-toggle-lg .rs-toggle-loader {
    height: 22px;
    left: 4px;
    top: 4px;
    width: 22px;
  }
  .rs-toggle-lg .rs-toggle-loader .rs-loader-spin,
  .rs-toggle-lg .rs-toggle-loader .rs-loader-spin:after,
  .rs-toggle-lg .rs-toggle-loader .rs-loader-spin:before {
    height: 22px;
    width: 22px;
  }
  .rs-toggle-lg .rs-toggle-presentation {
    border-radius: 15px;
    height: 30px;
    min-width: 55px;
  }
  .rs-toggle-lg .rs-toggle-presentation:after {
    border-radius: 11px;
    height: 22px;
    left: 4px;
    top: 4px;
    width: 22px;
  }
  .rs-toggle-lg .rs-toggle-presentation:active:after {
    width: 26.4px;
  }
  .rs-toggle-lg .rs-toggle-inner {
    font-size: 14px;
    height: 30px;
    line-height: 2.14285714;
    margin-left: 30px;
    margin-right: 10px;
  }
  .rs-toggle-lg .rs-toggle-inner .rs-icon {
    font-size: 14px;
  }
  .rs-toggle-lg.rs-toggle-checked .rs-toggle-presentation:after {
    left: 100%;
    margin-left: -26px;
  }
  .rs-toggle-lg.rs-toggle-checked .rs-toggle-presentation:active:after {
    margin-left: -30.4px;
  }
  .rs-toggle-lg.rs-toggle-checked .rs-toggle-presentation .rs-toggle-inner {
    margin-left: 10px;
    margin-right: 30px;
  }
  .rs-toggle-lg.rs-toggle-checked .rs-toggle-loader {
    left: 100%;
    margin-left: -26px;
  }
  .rs-tooltip {
    background-color: #272c36;
    background-color: var(--rs-tooltip-bg);
    border-radius: 4px;
    color: #fff;
    color: var(--rs-tooltip-text);
    display: block;
    font-size: 12px;
    line-height: 1.66666667;
    max-width: 250px;
    opacity: 0;
    overflow-wrap: break-word;
    padding: 2px 10px;
    position: absolute;
    z-index: 1070;
  }
  .rs-tooltip.rs-anim-fade {
    -webkit-transition: opacity 0.1s linear, -webkit-transform 0.1s ease-out;
    transition: opacity 0.1s linear, -webkit-transform 0.1s ease-out;
    transition: opacity 0.1s linear, transform 0.1s ease-out;
    transition: opacity 0.1s linear, transform 0.1s ease-out, -webkit-transform 0.1s ease-out;
  }
  .rs-tooltip.rs-anim-in {
    opacity: 1;
    -webkit-transition: opacity 0.15s linear, -webkit-transform 0.15s ease-in;
    transition: opacity 0.15s linear, -webkit-transform 0.15s ease-in;
    transition: opacity 0.15s linear, transform 0.15s ease-in;
    transition: opacity 0.15s linear, transform 0.15s ease-in, -webkit-transform 0.15s ease-in;
  }
  .rs-tooltip-arrow:after,
  .rs-tooltip-arrow:before {
    border-color: transparent;
    border-style: solid;
    content: " ";
    display: block;
    height: 0;
    position: absolute;
    width: 0;
  }
  .rs-tooltip-arrow:before {
    display: none;
  }
  .rs-theme-high-contrast .rs-tooltip {
    border: 1px solid #e5e5ea;
    border: 1px solid var(--rs-border-primary);
  }
  .rs-theme-high-contrast .rs-tooltip-arrow:before {
    display: block;
  }
  .rs-tooltip[class*="placement-top"] {
    margin-top: -8px;
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  .rs-tooltip[class*="placement-top"].rs-anim-in {
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);
  }
  .rs-tooltip[class*="placement-top"]:after {
    border-top-color: #272c36;
    border-top-color: var(--rs-tooltip-bg);
    border-width: 6px 6px 0;
    bottom: -6px;
    margin-left: -6px;
  }
  @media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
    .rs-tooltip[class*="placement-top"]:after {
      bottom: -7px;
    }
  }
  @supports (-ms-ime-align: auto) {
    .rs-tooltip[class*="placement-top"]:after {
      bottom: -7px;
    }
  }
  .rs-theme-high-contrast .rs-tooltip[class*="placement-top"]:before {
    border-top-color: var(--rs-tooltip-border);
    border-width: 7px 7px 0;
    bottom: -7px;
    margin-left: -7px;
  }
  @media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
    .rs-theme-high-contrast .rs-tooltip[class*="placement-top"]:before {
      bottom: -8px;
    }
  }
  @supports (-ms-ime-align: auto) {
    .rs-theme-high-contrast .rs-tooltip[class*="placement-top"]:before {
      bottom: -8px;
    }
  }
  .rs-tooltip[class*="placement-bottom"] {
    margin-top: 8px;
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  .rs-tooltip[class*="placement-bottom"].rs-anim-in {
    -webkit-transform: translateY(2px);
    transform: translateY(2px);
  }
  .rs-tooltip[class*="placement-bottom"]:after {
    border-bottom-color: #272c36;
    border-bottom-color: var(--rs-tooltip-bg);
    border-width: 0 6px 6px;
    margin-left: -6px;
    top: -6px;
  }
  @media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
    .rs-tooltip[class*="placement-bottom"]:after {
      top: -7px;
    }
  }
  @supports (-ms-ime-align: auto) {
    .rs-tooltip[class*="placement-bottom"]:after {
      top: -7px;
    }
  }
  .rs-theme-high-contrast .rs-tooltip[class*="placement-bottom"]:before {
    border-bottom-color: var(--rs-tooltip-border);
    border-width: 0 7px 7px;
    margin-left: -7px;
    top: -7px;
  }
  @media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
    .rs-theme-high-contrast .rs-tooltip[class*="placement-bottom"]:before {
      top: -8px;
    }
  }
  @supports (-ms-ime-align: auto) {
    .rs-theme-high-contrast .rs-tooltip[class*="placement-bottom"]:before {
      top: -8px;
    }
  }
  .rs-tooltip[class*="placement-right"] {
    margin-left: 8px;
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  .rs-tooltip[class*="placement-right"].rs-anim-in {
    -webkit-transform: translate(2px);
    transform: translate(2px);
  }
  .rs-tooltip[class*="placement-right"]:after {
    border-right-color: #272c36;
    border-right-color: var(--rs-tooltip-bg);
    border-width: 6px 6px 6px 0;
    left: -6px;
    margin-top: -6px;
  }
  @media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
    .rs-tooltip[class*="placement-right"]:after {
      left: -7px;
    }
  }
  @supports (-ms-ime-align: auto) {
    .rs-tooltip[class*="placement-right"]:after {
      left: -7px;
    }
  }
  .rs-theme-high-contrast .rs-tooltip[class*="placement-right"]:before {
    border-right-color: var(--rs-tooltip-border);
    border-width: 7px 7px 7px 0;
    left: -7px;
    margin-top: -7px;
  }
  @media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
    .rs-theme-high-contrast .rs-tooltip[class*="placement-right"]:before {
      left: -8px;
    }
  }
  @supports (-ms-ime-align: auto) {
    .rs-theme-high-contrast .rs-tooltip[class*="placement-right"]:before {
      left: -8px;
    }
  }
  .rs-tooltip[class*="placement-left"] {
    margin-left: -8px;
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  .rs-tooltip[class*="placement-left"].rs-anim-in {
    -webkit-transform: translate(-2px);
    transform: translate(-2px);
  }
  .rs-tooltip[class*="placement-left"]:after {
    border-left-color: #272c36;
    border-left-color: var(--rs-tooltip-bg);
    border-width: 6px 0 6px 6px;
    margin-top: -6px;
    right: -6px;
  }
  @media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
    .rs-tooltip[class*="placement-left"]:after {
      right: -7px;
    }
  }
  @supports (-ms-ime-align: auto) {
    .rs-tooltip[class*="placement-left"]:after {
      right: -7px;
    }
  }
  .rs-theme-high-contrast .rs-tooltip[class*="placement-left"]:before {
    border-left-color: var(--rs-tooltip-border);
    border-width: 7px 0 7px 7px;
    margin-top: -7px;
    right: -7px;
  }
  @media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
    .rs-theme-high-contrast .rs-tooltip[class*="placement-left"]:before {
      right: -8px;
    }
  }
  @supports (-ms-ime-align: auto) {
    .rs-theme-high-contrast .rs-tooltip[class*="placement-left"]:before {
      right: -8px;
    }
  }
  .rs-tooltip.placement-bottom:after,
  .rs-tooltip.placement-bottom:before,
  .rs-tooltip.placement-top:after,
  .rs-tooltip.placement-top:before {
    left: 50%;
  }
  .rs-tooltip.placement-bottom-start:after,
  .rs-tooltip.placement-bottom-start:before,
  .rs-tooltip.placement-top-start:after,
  .rs-tooltip.placement-top-start:before {
    left: 10px;
  }
  .rs-tooltip.placement-bottom-end:before,
  .rs-tooltip.placement-top-end:before {
    right: 3px;
  }
  .rs-tooltip.placement-bottom-end:after,
  .rs-tooltip.placement-top-end:after {
    right: 4px;
  }
  .rs-tooltip.placement-left:after,
  .rs-tooltip.placement-left:before,
  .rs-tooltip.placement-right:after,
  .rs-tooltip.placement-right:before {
    top: 50%;
  }
  .rs-tooltip.placement-left-start:after,
  .rs-tooltip.placement-left-start:before,
  .rs-tooltip.placement-right-start:after,
  .rs-tooltip.placement-right-start:before {
    top: 10px;
  }
  .rs-tooltip.placement-left-end:before,
  .rs-tooltip.placement-right-end:before {
    bottom: 3px;
  }
  .rs-tooltip.placement-left-end:after,
  .rs-tooltip.placement-right-end:after {
    bottom: 4px;
  }
  .rs-tree {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    height: 100%;
    max-height: 360px;
    overflow-y: auto;
  }
  .rs-tree.rs-tree-virtualized {
    overflow: hidden;
  }
  .rs-tree-drag-preview {
    background-color: #fff;
    background-color: var(--rs-bg-overlay);
    border-radius: 6px;
    -webkit-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.12);
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.12);
    color: #575757;
    color: var(--rs-text-primary);
    display: inline-block;
    margin: 0;
    padding: 8px 12px;
    position: absolute;
    top: 0;
    z-index: -1;
  }
  .rs-tree-node {
    font-size: 0;
    margin: 0 0 4px;
    position: relative;
    text-align: left;
  }
  .rs-tree-node-label {
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    line-height: 22px;
    margin: 0;
    padding: 1px 1px 1px 16px;
    position: relative;
  }
  .rs-tree-node-label:focus-visible .rs-tree-node-label-content {
    outline: 3px solid rgb(from #3498ff r g b/25%);
    outline: 3px solid var(--rs-color-focus-ring);
  }
  .rs-theme-high-contrast .rs-tree-node-label:focus-visible .rs-tree-node-label-content {
    outline-offset: 2px;
  }
  .rs-tree-node-label-content {
    border-radius: 6px;
    display: inline-block;
    padding: 6px 8px;
  }
  .rs-tree-node-label-content.rs-tree-node-label-content-focus,
  .rs-tree-node-label-content:focus,
  .rs-tree-node-label-content:hover {
    background-color: rgb(from #cce9ff r g b/50%);
    background-color: var(--rs-listbox-option-hover-bg);
    color: #1675e0;
    color: var(--rs-listbox-option-hover-text);
  }
  .rs-theme-high-contrast .rs-tree-node-label-content.rs-tree-node-label-content-focus,
  .rs-theme-high-contrast .rs-tree-node-label-content:focus,
  .rs-theme-high-contrast .rs-tree-node-label-content:hover {
    color: #1675e0;
    color: var(--rs-listbox-option-hover-text);
    outline: 3px solid rgb(from #3498ff r g b/25%);
    outline: 3px solid var(--rs-color-focus-ring);
    outline-offset: -3px;
    outline-width: 2px;
    text-decoration: underline;
  }
  .rs-theme-high-contrast .rs-theme-high-contrast .rs-tree-node-label-content.rs-tree-node-label-content-focus,
  .rs-theme-high-contrast .rs-theme-high-contrast .rs-tree-node-label-content:focus,
  .rs-theme-high-contrast .rs-theme-high-contrast .rs-tree-node-label-content:hover {
    outline-offset: 2px;
  }
  .rs-tree-node-active .rs-tree-node-label-content {
    background-color: #f2faff;
    background-color: var(--rs-listbox-option-selected-bg);
    color: #1675e0;
    color: var(--rs-text-link);
    font-weight: 700;
  }
  .rs-tree-node-drag-over {
    background-color: rgb(from #cce9ff r g b/50%);
    background-color: var(--rs-listbox-option-hover-bg);
  }
  .rs-tree-node-dragging {
    outline: 1px dashed #3498ff;
    outline: 1px dashed var(--rs-primary-500);
  }
  .rs-tree-node-drag-over-top.rs-tree-node-label-content {
    border-top: 2px solid #1675e0 !important;
    border-top: 2px solid var(--rs-text-link) !important;
  }
  .rs-tree-node-drag-over-top.rs-tree-node-label-content:after {
    border-bottom: 3px solid transparent;
    border-left: 6px solid #1675e0;
    border-left: 6px solid var(--rs-text-link);
    border-top: 3px solid transparent;
    content: "";
    height: 0;
    left: 12px;
    position: absolute;
    top: -2px;
    width: 0;
  }
  .rs-tree-node-drag-over-bottom.rs-tree-node-label-content {
    border-bottom: 2px solid #1675e0 !important;
    border-bottom: 2px solid var(--rs-text-link) !important;
  }
  .rs-tree-node-drag-over-bottom.rs-tree-node-label-content:after {
    border-bottom: 3px solid transparent;
    border-left: 6px solid #1675e0;
    border-left: 6px solid var(--rs-text-link);
    border-top: 3px solid transparent;
    bottom: -2px;
    content: "";
    height: 0;
    left: 12px;
    position: absolute;
    width: 0;
  }
  .rs-tree-node-drag-disabled.rs-tree-node-label-content {
    cursor: not-allowed;
  }
  .rs-tree-node-disabled,
  .rs-tree-node-disabled > .rs-tree-node-label > .rs-tree-node-label-content {
    color: #c5c6c7;
    color: var(--rs-listbox-option-disabled-text);
    cursor: not-allowed;
  }
  .rs-tree-node-disabled > .rs-tree-node-label > .rs-tree-node-label-content,
  .rs-tree-node-disabled > .rs-tree-node-label > .rs-tree-node-label-content:hover {
    background-color: transparent;
  }
  .rs-tree-node-disabled.rs-tree-node-active > .rs-tree-node-label > .rs-tree-node-label-content,
  .rs-tree-node-disabled.rs-tree-node-active > .rs-tree-node-label > .rs-tree-node-label-content:hover {
    color: #a6d7ff;
    color: var(--rs-listbox-option-disabled-selected-text);
  }
  .rs-tree-node:not(.rs-tree-node-disabled):focus > .rs-tree-node-label > .rs-tree-node-label-content,
  .rs-tree-node:not(.rs-tree-node-disabled) > .rs-tree-node-label:focus > .rs-tree-node-label-content {
    background-color: #f2faff;
    background-color: var(--rs-listbox-option-selected-bg);
  }
  .rs-tree-node-expand-icon-wrapper {
    cursor: pointer;
    display: inline-block;
    position: absolute;
    z-index: 1;
  }
  .rs-tree-node-expand-icon-wrapper > .rs-tree-node-expand-icon {
    display: inline-block;
    font-size: 16px;
    height: 36px;
    line-height: 22px;
    margin-top: 6px;
    padding: 8px 8px 8px 0;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transform-origin: 1.5px 14px;
    transform-origin: 1.5px 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .rs-tree-node-expand-icon-wrapper > .rs-tree-node-expanded,
  .rs-tree-open > .rs-tree-node .rs-tree-node-expand-icon-wrapper > .rs-tree-node-expand-icon {
    margin-left: 0;
    margin-top: 0;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  .rs-tree-node-expand-icon-wrapper > .rs-tree-node-custom-icon,
  .rs-tree-node-expand-icon-wrapper > .rs-tree-node-loading-icon {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 14px;
    height: 36px;
    width: 14px;
  }
  .rs-tree-node-children > .rs-tree-group {
    display: none;
    position: relative;
  }
  .rs-tree-open.rs-tree-node-children > .rs-tree-group {
    display: block;
  }
  .rs-tree-indent-line {
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    height: 100%;
    margin-left: -13px;
    position: absolute;
    top: 0;
    width: 1px;
  }
  .rs-picker-popup .rs-tree {
    padding-right: 12px;
  }
  .rs-picker-popup .rs-tree.rs-tree-virtualized {
    padding: 0;
  }
  .rs-picker-popup .rs-tree-node {
    margin: 0;
  }
  .rs-picker-popup .rs-tree-node > .rs-tree-node-label > .rs-tree-node-label-content {
    display: block;
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
  [dir="rtl"] .rs-tree-node-expand-icon-wrapper {
    padding-right: inherit;
    right: 0;
  }
  .rs-uploader-trigger input[type="file"] {
    clip: rect(1px 1px 1px 1px);
    position: absolute !important;
  }
  .rs-uploader-file-item-disabled {
    cursor: not-allowed;
    opacity: 0.3;
  }
  .rs-uploader-picture .rs-uploader-file-item-disabled .rs-uploader-file-item-status {
    cursor: not-allowed;
  }
  .rs-uploader-file-item-disabled .rs-uploader-file-item-btn-remove {
    display: none;
  }
  .rs-uploader-file-item-icon-reupload {
    color: #575757;
    color: var(--rs-text-primary);
    cursor: pointer;
    font-size: 12px;
    margin-left: 10px;
  }
  .rs-theme-high-contrast .rs-uploader-file-item:hover .rs-uploader-file-item-title {
    color: var(--rs-uploader-item-hover-text);
    text-decoration: underline;
  }
  .rs-uploader-text .rs-uploader-file-items {
    margin-top: 10px;
  }
  .rs-uploader-text .rs-uploader-file-item {
    padding: 8px 30px 8px 36px;
    position: relative;
    -webkit-transition: background-color 0.3s linear;
    transition: background-color 0.3s linear;
  }
  .rs-uploader-text .rs-uploader-file-item:hover {
    background-color: #f7f7fa;
    background-color: var(--rs-uploader-item-hover-bg);
  }
  .rs-uploader-text .rs-uploader-file-item-icon-wrapper {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    align-items: center;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    height: 20px;
    justify-content: center;
    left: 12px;
    position: absolute;
    top: 8px;
    width: 20px;
  }
  .rs-uploader-text .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon,
  .rs-uploader-text .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon:after,
  .rs-uploader-text .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon:before {
    height: 18px;
    width: 18px;
  }
  .rs-uploader-text .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon:after,
  .rs-uploader-text .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon:before {
    border-radius: 50%;
    content: "";
    display: block;
    left: 0;
    position: absolute;
    right: 0;
  }
  .rs-uploader-text .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon:before {
    border: 3px solid rgb(from #f7f7fa r g b/80%);
    border: 3px solid var(--rs-loader-ring);
  }
  .rs-uploader-text .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon:after {
    -webkit-animation: loaderSpin 0.6s linear infinite;
    animation: loaderSpin 0.6s linear infinite;
    border: 3px solid transparent;
    border-top-color: #a6a6a6;
    border-color: var(--rs-loader-rotor) transparent transparent;
  }
  .rs-uploader-text .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon > svg {
    display: none;
  }
  .rs-uploader-text .rs-uploader-file-item-content {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    color: #575757;
    color: var(--rs-text-primary);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    -webkit-transition: color 0.3s;
    transition: color 0.3s;
  }
  .rs-uploader-text .rs-uploader-file-item-title {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
  .rs-uploader-text .rs-uploader-file-item-size {
    color: #8e8e93;
    color: var(--rs-text-secondary);
    padding: 0 8px;
  }
  .rs-uploader-text .rs-uploader-file-item-size,
  .rs-uploader-text .rs-uploader-file-item-status {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  .rs-uploader-text .rs-uploader-file-item-status > a,
  .rs-uploader-text .rs-uploader-file-item-status > a:active,
  .rs-uploader-text .rs-uploader-file-item-status > a:focus,
  .rs-uploader-text .rs-uploader-file-item-status > a:hover {
    text-decoration: none;
  }
  .rs-uploader-text .rs-uploader-file-item-btn-remove {
    color: #8e8e93;
    color: var(--rs-text-secondary);
    cursor: pointer;
    font-size: 14px;
    padding: 0;
    position: absolute;
    right: 12px;
    top: 12px;
  }
  .rs-uploader-text .rs-uploader-file-item-btn-remove:focus,
  .rs-uploader-text .rs-uploader-file-item-btn-remove:hover {
    text-decoration: none;
  }
  .rs-uploader-text .rs-uploader-file-item-panel {
    position: relative;
  }
  .rs-uploader-text .rs-uploader-file-item-progress {
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%;
  }
  .rs-uploader-text .rs-uploader-file-item-progress-bar {
    background-color: #3498ff;
    background-color: var(--rs-primary-500);
    height: 2px;
    -webkit-transition: width 0.3s ease-in-out;
    transition: width 0.3s ease-in-out;
  }
  .rs-uploader-text .rs-uploader-file-item-has-error .rs-uploader-file-item-content,
  .rs-uploader-text .rs-uploader-file-item-has-error .rs-uploader-file-item-title {
    color: #f44336;
    color: var(--rs-state-error);
  }
  .rs-uploader-picture {
    display: inline-block;
  }
  .rs-uploader-picture:after,
  .rs-uploader-picture:before {
    content: " ";
    display: table;
  }
  .rs-uploader-picture:after {
    clear: both;
  }
  .rs-uploader-picture .rs-uploader-trigger-btn {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    align-items: center;
    background-color: #f7f7fa;
    background-color: var(--rs-btn-default-bg);
    background-color: transparent;
    border: 2px dotted #e5e5ea;
    border: 2px dotted var(--rs-border-primary);
    border-radius: 6px;
    color: var(--rs-btn-default-text);
    color: #575757;
    color: var(--rs-btn-subtle-text);
    cursor: pointer;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    font-size: 14px;
    font-weight: 400;
    height: 66px;
    justify-content: center;
    line-height: 20px;
    margin: 10px 0 0;
    overflow: hidden;
    padding: 0;
    position: relative;
    text-align: center;
    text-decoration: none;
    -webkit-transition: color 0.15s ease-out, background-color 0.15s ease-out;
    transition: color 0.15s ease-out, background-color 0.15s ease-out;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;
    width: 66px;
  }
  .rs-theme-high-contrast .rs-uploader-picture .rs-uploader-trigger-btn {
    -webkit-transition: none;
    transition: none;
  }
  .rs-btn-ghost.rs-uploader-picture .rs-uploader-trigger-btn {
    padding: 7px 11px;
  }
  .rs-btn-icon.rs-uploader-picture .rs-uploader-trigger-btn {
    line-height: 16px;
    padding: 10px;
  }
  .rs-btn-icon.rs-uploader-picture .rs-uploader-trigger-btn > .rs-icon {
    font-size: 16px;
  }
  .rs-btn-icon-with-text.rs-uploader-picture .rs-uploader-trigger-btn {
    line-height: 20px;
  }
  .rs-btn-icon-with-text.rs-uploader-picture .rs-uploader-trigger-btn > .rs-icon {
    height: 36px;
    padding: 10px;
    width: 36px;
  }
  .rs-btn-icon-with-text.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-icon-placement-left {
    padding: 8px 12px 8px 48px;
  }
  .rs-btn-icon-with-text.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-icon-placement-left > .rs-icon {
    border-right: none;
    border-right: var(--rs-btn-default-border, none);
    left: 0;
  }
  .rs-btn-icon-with-text.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-icon-placement-right {
    padding: 8px 48px 8px 12px;
  }
  .rs-btn-icon-with-text.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-icon-placement-right > .rs-icon {
    border-left: none;
    border-left: var(--rs-btn-default-border, none);
    right: 0;
  }
  .rs-uploader-picture .rs-uploader-trigger-btn:focus-visible {
    outline: 3px solid rgb(from #3498ff r g b/25%);
    outline: 3px solid var(--rs-color-focus-ring);
  }
  .rs-theme-high-contrast .rs-uploader-picture .rs-uploader-trigger-btn:focus-visible {
    outline-offset: 2px;
  }
  .rs-uploader-picture .rs-uploader-trigger-btn:focus,
  .rs-uploader-picture .rs-uploader-trigger-btn:hover {
    background-color: var(--rs-btn-default-hover-bg);
    color: var(--rs-btn-default-hover-text);
    text-decoration: none;
  }
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-active,
  .rs-uploader-picture .rs-uploader-trigger-btn:active {
    background-color: #d9d9d9;
    background-color: var(--rs-btn-default-active-bg);
    color: var(--rs-btn-default-active-text);
  }
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-disabled,
  .rs-uploader-picture .rs-uploader-trigger-btn:disabled {
    background-color: #f7f7fa;
    background-color: var(--rs-btn-default-disabled-bg);
    color: var(--rs-btn-default-disabled-text);
    cursor: not-allowed;
  }
  @media not all and (min-resolution: 0.001dpcm) {
    .rs-uploader-picture .rs-uploader-trigger-btn {
      -webkit-mask-image: -webkit-radial-gradient(#fff, #000);
    }
  }
  .rs-uploader-picture .rs-uploader-trigger-btn:focus,
  .rs-uploader-picture .rs-uploader-trigger-btn:hover {
    background-color: #e5e5ea;
    background-color: var(--rs-btn-subtle-hover-bg);
    color: #575757;
    color: var(--rs-btn-subtle-hover-text);
  }
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-active,
  .rs-uploader-picture .rs-uploader-trigger-btn:active {
    background-color: #e5e5ea;
    background-color: var(--rs-btn-subtle-active-bg);
    color: #272c36;
    color: var(--rs-btn-subtle-active-text);
  }
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-disabled,
  .rs-uploader-picture .rs-uploader-trigger-btn:disabled {
    background: none;
    color: #c5c6c7;
    color: var(--rs-btn-subtle-disabled-text);
  }
  .rs-theme-high-contrast .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-disabled,
  .rs-theme-high-contrast .rs-uploader-picture .rs-uploader-trigger-btn:disabled {
    opacity: 0.5;
  }
  .rs-uploader-picture .rs-uploader-trigger.rs-uploader-trigger-disabled .rs-uploader-trigger-btn {
    background-color: transparent !important;
    color: #575757 !important;
    color: var(--rs-btn-subtle-text) !important;
  }
  .rs-uploader-picture .rs-uploader-trigger.rs-uploader-trigger-disabled .rs-uploader-trigger-btn:after {
    display: none;
  }
  .rs-uploader-picture .rs-uploader-file-item,
  .rs-uploader-picture .rs-uploader-trigger {
    float: left;
  }
  .rs-uploader-picture .rs-uploader-file-items {
    display: inline;
  }
  .rs-uploader-picture .rs-uploader-file-item {
    background-color: #d9d9d9;
    background-color: var(--rs-uploader-item-bg);
    border-radius: 6px;
    height: 66px;
    margin-right: 10px;
    margin-top: 10px;
    overflow: hidden;
    position: relative;
    width: 66px;
  }
  .rs-uploader-picture .rs-uploader-file-item-preview {
    height: 66px;
    position: relative;
    text-align: center;
    vertical-align: middle;
    z-index: 1;
  }
  .rs-uploader-picture .rs-uploader-file-item-preview > img {
    height: auto;
    width: 100%;
  }
  @supports ((-o-object-fit: cover) or (object-fit: cover)) {
    .rs-uploader-picture .rs-uploader-file-item-preview > img {
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
    }
  }
  .rs-uploader-picture .rs-uploader-file-item-preview .rs-uploader-file-item-icon {
    font-size: 22px;
    margin: 22px;
  }
  .rs-uploader-picture .rs-uploader-file-item-status {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    align-items: center;
    background-color: rgb(from #fff r g b/80%);
    background-color: var(--rs-uploader-overlay-bg);
    color: #f44336;
    color: var(--rs-state-error);
    cursor: default;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 66px;
    justify-content: center;
    left: 0;
    position: absolute;
    text-align: center;
    top: 0;
    width: 66px;
    z-index: 3;
  }
  .rs-uploader-picture .rs-uploader-file-item-status > span {
    white-space: nowrap;
  }
  .rs-uploader-picture .rs-uploader-file-item-status > a:hover {
    text-decoration: none;
  }
  .rs-uploader-picture .rs-uploader-file-item-icon-wrapper {
    display: none;
  }
  .rs-uploader-picture .rs-uploader-file-item-btn-remove {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    align-items: center;
    background-color: rgb(from #fff r g b/80%);
    background-color: var(--rs-uploader-overlay-bg);
    border-radius: 50%;
    color: #8e8e93;
    color: var(--rs-text-secondary);
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 24px;
    justify-content: center;
    line-height: 24px;
    opacity: 0;
    padding-top: 0;
    position: absolute;
    right: 3px;
    text-align: center;
    top: 3px;
    -webkit-transition: opacity 0.2s;
    transition: opacity 0.2s;
    visibility: hidden;
    width: 24px;
    z-index: 4;
  }
  .rs-uploader-picture .rs-uploader-file-item-btn-remove:focus,
  .rs-uploader-picture .rs-uploader-file-item-btn-remove:hover {
    text-decoration: none;
  }
  .rs-uploader-picture .rs-uploader-file-item:hover .rs-uploader-file-item-btn-remove {
    opacity: 1;
    visibility: visible;
  }
  .rs-uploader-picture .rs-uploader-file-item-icon-loading {
    background-color: rgb(from #fff r g b/80%);
    background-color: var(--rs-uploader-overlay-bg);
    display: block;
    height: 66px;
    left: 0;
    line-height: 66px;
    position: absolute;
    text-align: center;
    top: 0;
    width: 66px;
    z-index: 2;
  }
  .rs-uploader-picture .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon {
    display: inline-block;
    height: 18px;
    position: relative;
    width: 18px;
  }
  .rs-uploader-picture .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon:after,
  .rs-uploader-picture .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon:before {
    border-radius: 50%;
    content: "";
    display: block;
    height: 18px;
    left: 0;
    position: absolute;
    right: 0;
    width: 18px;
  }
  .rs-uploader-picture .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon:before {
    border: 3px solid rgb(from #f7f7fa r g b/80%);
    border: 3px solid var(--rs-loader-ring);
  }
  .rs-uploader-picture .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon:after {
    -webkit-animation: loaderSpin 0.6s linear infinite;
    animation: loaderSpin 0.6s linear infinite;
    border: 3px solid transparent;
    border-top-color: #a6a6a6;
    border-color: var(--rs-loader-rotor) transparent transparent;
  }
  .rs-uploader-picture .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon > svg {
    display: none;
  }
  .rs-uploader-picture .rs-uploader-file-item-icon-reupload {
    display: block;
    margin-left: 0;
  }
  .rs-uploader-picture-text .rs-uploader-file-item {
    border: 1px solid #e5e5ea;
    border: 1px solid var(--rs-border-primary);
    border-radius: 6px;
    height: 50px;
    margin-top: 10px;
    overflow: hidden;
    padding-left: 50px;
    padding-right: 30px;
    position: relative;
    -webkit-transition: background-color 0.3s linear;
    transition: background-color 0.3s linear;
  }
  .rs-uploader-picture-text .rs-uploader-file-item:hover {
    background-color: #f7f7fa;
    background-color: var(--rs-uploader-item-hover-bg);
  }
  .rs-uploader-picture-text .rs-uploader-file-item-icon-wrapper {
    display: none;
  }
  .rs-uploader-picture-text .rs-uploader-file-item-preview {
    background-color: #e5e5ea;
    background-color: var(--rs-border-primary);
    border-radius: 6px 0 0 6px;
    height: 50px;
    left: 0;
    overflow: hidden;
    position: absolute;
    text-align: center;
    top: 0;
    vertical-align: middle;
    width: 50px;
  }
  .rs-uploader-picture-text .rs-uploader-file-item-preview > img {
    height: auto;
    width: 100%;
  }
  @supports ((-o-object-fit: cover) or (object-fit: cover)) {
    .rs-uploader-picture-text .rs-uploader-file-item-preview > img {
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
    }
  }
  .rs-uploader-picture-text .rs-uploader-file-item-preview .rs-uploader-file-item-icon {
    font-size: 22px;
    margin: 12px;
  }
  .rs-uploader-picture-text .rs-uploader-file-item-content {
    padding: 5px 12px;
  }
  .rs-uploader-picture-text .rs-uploader-file-item-content .rs-uploader-file-item-size,
  .rs-uploader-picture-text .rs-uploader-file-item-content .rs-uploader-file-item-title {
    display: block;
  }
  .rs-uploader-picture-text .rs-uploader-file-item-content .rs-uploader-file-item-title {
    color: #575757;
    color: var(--rs-text-primary);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
  .rs-uploader-picture-text .rs-uploader-file-item-content .rs-uploader-file-item-size {
    color: #8e8e93;
    color: var(--rs-text-secondary);
  }
  .rs-uploader-picture-text .rs-uploader-file-item-btn-remove {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    align-items: center;
    color: #8e8e93;
    color: var(--rs-text-secondary);
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 50px;
    justify-content: center;
    padding: 0;
    position: absolute;
    right: 12px;
    top: 0;
  }
  .rs-uploader-picture-text .rs-uploader-file-item-btn-remove:focus,
  .rs-uploader-picture-text .rs-uploader-file-item-btn-remove:hover {
    text-decoration: none;
  }
  .rs-uploader-picture-text .rs-uploader-file-item-progress {
    bottom: 0;
    left: 0;
    padding-left: 50px;
    position: absolute;
    width: 100%;
  }
  .rs-uploader-picture-text .rs-uploader-file-item-progress-bar {
    background-color: #3498ff;
    background-color: var(--rs-primary-500);
    height: 2px;
    -webkit-transition: width 0.3s ease-in-out;
    transition: width 0.3s ease-in-out;
  }
  .rs-uploader-picture-text .rs-uploader-file-item-icon-loading {
    background-color: #e5e5ea;
    background-color: var(--rs-border-primary);
    display: block;
    height: 50px;
    left: 0;
    line-height: 50px;
    position: absolute;
    text-align: center;
    top: 0;
    width: 50px;
    z-index: 1;
  }
  .rs-uploader-picture-text .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon {
    display: inline-block;
    height: 18px;
    position: relative;
    width: 18px;
  }
  .rs-uploader-picture-text .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon:after,
  .rs-uploader-picture-text .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon:before {
    border-radius: 50%;
    content: "";
    display: block;
    height: 18px;
    left: 0;
    position: absolute;
    right: 0;
    width: 18px;
  }
  .rs-uploader-picture-text .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon:before {
    border: 3px solid rgb(from #f7f7fa r g b/80%);
    border: 3px solid var(--rs-loader-ring);
  }
  .rs-uploader-picture-text .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon:after {
    -webkit-animation: loaderSpin 0.6s linear infinite;
    animation: loaderSpin 0.6s linear infinite;
    border: 3px solid transparent;
    border-top-color: #a6a6a6;
    border-color: var(--rs-loader-rotor) transparent transparent;
  }
  .rs-uploader-picture-text .rs-uploader-file-item-has-error .rs-uploader-file-item-size,
  .rs-uploader-picture-text .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon > svg {
    display: none;
  }
  .rs-uploader-picture-text .rs-uploader-file-item-has-error .rs-uploader-file-item-status,
  .rs-uploader-picture-text .rs-uploader-file-item-has-error .rs-uploader-file-item-title {
    color: #f44336;
    color: var(--rs-state-error);
    display: block;
  }
  .rs-uploader-draggable .rs-uploader-trigger-customize .rs-uploader-trigger-btn {
    background: #fff;
    background: var(--rs-uploader-dnd-bg);
    border: 1px dashed #e5e5ea;
    border: 1px dashed var(--rs-uploader-dnd-border);
    display: block;
  }
  .rs-uploader-draggable .rs-uploader-trigger-drag-over .rs-uploader-trigger-btn.rs-uploader-trigger-customize,
  .rs-uploader-draggable .rs-uploader-trigger.rs-uploader-trigger-customize:not(.rs-uploader-trigger-disabled) .rs-uploader-trigger-btn:hover {
    border-color: #3498ff;
    border-color: var(--rs-uploader-dnd-hover-border);
  }
  .rs-uploader-draggable .rs-uploader-trigger-disabled {
    cursor: not-allowed;
  }
  .rs-uploader-draggable .rs-uploader-trigger-disabled .rs-uploader-trigger-btn {
    pointer-events: none;
  }
  .rs-rate {
    color: #2196f3;
    color: var(--rs-rate-symbol-checked);
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
    vertical-align: middle;
  }
  .rs-rate-character {
    cursor: pointer;
    margin-right: 10px;
    outline: none;
    padding: 3px 0;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .rs-rate-character-before {
    height: 100%;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    width: 50%;
  }
  .rs-rate-character-after {
    color: #8e8e93;
    color: var(--rs-rate-symbol);
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
  }
  .rs-theme-high-contrast .rs-rate-character-after > .rs-icon {
    fill: none;
    stroke: currentColor;
  }
  .rs-rate-character-after,
  .rs-rate-character-before {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    z-index: 1;
  }
  .rs-rate-character-after > .rs-icon,
  .rs-rate-character-before > .rs-icon {
    font-size: inherit;
  }
  .rs-rate-character-half .rs-rate-character-before {
    -webkit-filter: none;
    filter: none;
    opacity: 1;
  }
  .rs-rate-character-full .rs-rate-character-after {
    color: inherit;
    -webkit-filter: none;
    filter: none;
  }
  .rs-rate-character-full .rs-rate-character-after > .rs-icon {
    fill: currentColor;
  }
  .rs-rate-character-vertical {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    bottom: 3px;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    height: 50%;
    width: 100%;
  }
  .rs-rate-character .rs-icon {
    height: 1em;
    position: absolute;
    width: 1em;
  }
  .rs-rate:not(.rs-rate-readonly).rs-rate:not(.rs-rate-disabled) .rs-rate-character:hover {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  .rs-rate:not(.rs-rate-readonly).rs-rate:not(.rs-rate-disabled) .rs-rate-character:active {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  .rs-rate-disabled {
    opacity: 0.3;
    outline: none;
  }
  .rs-rate-disabled .rs-rate-character {
    cursor: not-allowed;
  }
  .rs-rate-readonly .rs-rate-character {
    cursor: default;
    pointer-events: none;
  }
  .rs-rate-lg {
    font-size: 36px;
  }
  .rs-rate-lg .rs-rate-character {
    height: 42px;
    width: 36px;
  }
  .rs-rate-md {
    font-size: 30px;
  }
  .rs-rate-md .rs-rate-character {
    height: 36px;
    width: 30px;
  }
  .rs-rate-sm {
    font-size: 24px;
  }
  .rs-rate-sm .rs-rate-character {
    height: 30px;
    width: 24px;
  }
  .rs-rate-xs {
    font-size: 18px;
  }
  .rs-rate-xs .rs-rate-character {
    height: 24px;
    width: 18px;
  }
  .rs-rate-red {
    color: #f44336;
    color: var(--rs-red-500);
  }
  .rs-rate-orange {
    color: #fa8900;
    color: var(--rs-orange-500);
  }
  .rs-rate-yellow {
    color: #ffb300;
    color: var(--rs-yellow-500);
  }
  .rs-rate-green {
    color: #4caf50;
    color: var(--rs-green-500);
  }
  .rs-rate-cyan {
    color: #00bcd4;
    color: var(--rs-cyan-500);
  }
  .rs-rate-blue {
    color: #2196f3;
    color: var(--rs-blue-500);
  }
  .rs-rate-violet {
    color: #673ab7;
    color: var(--rs-violet-500);
  }
  .rs-toast {
    pointer-events: auto;
  }
  .rs-toast-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    pointer-events: none;
    position: fixed;
    z-index: 1080;
  }
  .rs-toast-container .rs-toast:not(.rs-notification) {
    -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
    -webkit-box-shadow: var(--rs-shadow-overlay);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
    box-shadow: var(--rs-shadow-overlay);
  }
  .rs-toast-container .rs-toast-fade-entered,
  .rs-toast-container .rs-toast-fade-exiting {
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }
  .rs-toast-container .rs-toast-fade-entered {
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
    -webkit-animation-timing-function: cubic-bezier(0.99, 0.44, 0.44, 1.35);
    animation-timing-function: cubic-bezier(0.99, 0.44, 0.44, 1.35);
  }
  .rs-toast-container .rs-toast-fade-entering,
  .rs-toast-container .rs-toast-fade-exited {
    opacity: 0;
  }
  .rs-toast-container .rs-toast-fade-exited {
    max-height: 0;
    overflow: hidden;
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
  }
  .rs-toast-container-bottom-center,
  .rs-toast-container-top-center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
  }
  .rs-toast-container-bottom-center .rs-toast-fade-entered,
  .rs-toast-container-top-center .rs-toast-fade-entered {
    -webkit-animation-name: notificationMoveIn;
    animation-name: notificationMoveIn;
  }
  .rs-toast-container-bottom-start,
  .rs-toast-container-top-start {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    left: 24px;
  }
  .rs-toast-container-bottom-start .rs-toast-fade-entered,
  .rs-toast-container-top-start .rs-toast-fade-entered {
    -webkit-animation-name: notificationMoveInLeft;
    animation-name: notificationMoveInLeft;
  }
  .rs-toast-container-bottom-end,
  .rs-toast-container-top-end {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    right: 24px;
  }
  .rs-toast-container-bottom-end .rs-toast-fade-entered,
  .rs-toast-container-top-end .rs-toast-fade-entered {
    -webkit-animation-name: notificationMoveInRight;
    animation-name: notificationMoveInRight;
    margin-left: auto;
  }
  .rs-toast-container-top-center,
  .rs-toast-container-top-end,
  .rs-toast-container-top-start {
    top: 24px;
  }
  .rs-toast-container-bottom-center,
  .rs-toast-container-bottom-end,
  .rs-toast-container-bottom-start {
    bottom: 24px;
  }
  .rs-toast-container .rs-toast-fade-exiting {
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-name: notificationMoveOut;
    animation-name: notificationMoveOut;
    -webkit-animation-timing-function: cubic-bezier(0.64, 0.65, 0.57, 1.13);
    animation-timing-function: cubic-bezier(0.64, 0.65, 0.57, 1.13);
  }
  .rs-stack {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .rs-visually-hidden {
    clip: rect(0 0 0 0);
    border: 0;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
  .rs-ripple {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    display: block;
    position: absolute;
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transition: 0s;
    transition: 0s;
  }
  .rs-ripple-rippling {
    opacity: 0;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: opacity 1s cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 0.55s cubic-bezier(0.4, 0, 0.2, 1);
    transition: opacity 1s cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 0.55s cubic-bezier(0.4, 0, 0.2, 1);
    transition: transform 0.55s cubic-bezier(0.4, 0, 0.2, 1), opacity 1s cubic-bezier(0.4, 0, 0.2, 1);
    transition: transform 0.55s cubic-bezier(0.4, 0, 0.2, 1), opacity 1s cubic-bezier(0.4, 0, 0.2, 1),
      -webkit-transform 0.55s cubic-bezier(0.4, 0, 0.2, 1);
  }
  .rs-ripple-pond {
    content: "";
    display: block;
    height: 100%;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .rs-theme-high-contrast .rs-ripple-pond {
    display: none !important;
  }
  .rs-btn-close {
    background: transparent;
    border: none;
    cursor: pointer;
    line-height: 1;
    outline: none !important;
  }
  .rs-btn-close .rs-icon {
    vertical-align: bottom;
  }
  .rs-btn-close:focus,
  .rs-btn-close:hover {
    color: #f44336;
    color: var(--rs-close-button-hover-color);
  }
  .rs-btn-close:focus svg path,
  .rs-btn-close:hover svg path {
    stroke: #f44336;
    stroke: var(--rs-close-button-hover-color);
    stroke-width: 1;
  }
  .rs-picker-toggle-wrapper {
    display: inline-block;
    max-width: 100%;
    vertical-align: middle;
  }
  .rs-picker-toggle.rs-btn .rs-ripple-pond {
    display: none !important;
  }
  .rs-picker-block {
    display: block;
  }
  .rs-picker-disabled {
    opacity: 0.3;
  }
  .rs-picker-toggle .rs-picker-toggle-placeholder {
    color: #8e8e93;
    color: var(--rs-text-secondary);
  }
  .rs-picker-has-value .rs-btn .rs-picker-toggle-value,
  .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
    color: #1675e0;
    color: var(--rs-picker-value);
  }
  .rs-picker-none {
    color: #8e8e93;
    color: var(--rs-text-secondary);
    cursor: default;
    padding: 6px 12px 12px;
  }
  .rs-picker-countable .rs-picker-toggle-value {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .rs-picker-value-list {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  @media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
    .rs-picker-value-list {
      max-width: 100%;
    }
  }
  .rs-picker-value-count {
    background-color: #3498ff;
    background-color: var(--rs-picker-count-bg);
    border-radius: 10px;
    color: #fff;
    color: var(--rs-picker-count-text);
    line-height: 20px;
    margin: 0 4px;
    padding: 0 8px;
  }
  .rs-picker-value-separator {
    margin: 0 4px 0 0;
  }
  .rs-picker.rs-picker-focused,
  .rs-picker:not(.rs-picker-disabled):hover {
    border-color: #3498ff;
    border-color: var(--rs-input-focus-border);
  }
  .rs-picker-toggle-active,
  .rs-picker.rs-picker-focused {
    -webkit-box-shadow: 0 0 0 3px rgb(from #3498ff r g b/25%);
    -webkit-box-shadow: var(--rs-state-focus-shadow);
    box-shadow: 0 0 0 3px rgb(from #3498ff r g b/25%);
    box-shadow: var(--rs-state-focus-shadow);
  }
  .rs-picker-label.rs-input-group-addon {
    color: #575757;
    color: var(--rs-text-primary);
    padding-right: 4px !important;
  }
  .rs-picker-loader {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 14px;
    width: 14px;
  }
  .rs-picker-error > .rs-input-group {
    border-color: #f44336 !important;
    border-color: var(--rs-state-error) !important;
  }
  .rs-picker-error > .rs-input-group:focus-within {
    outline: 3px solid rgb(from #f44336 r g b/25%) !important;
    outline: var(--rs-state-error-outline) !important;
  }
  .rs-picker-toggle {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    align-items: center;
    background-color: #f7f7fa;
    background-color: var(--rs-btn-default-bg);
    border: none;
    border: var(--rs-btn-default-border, none);
    border-radius: 6px;
    color: #575757;
    color: var(--rs-btn-default-text);
    cursor: pointer;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    font-size: 14px;
    font-weight: 400;
    justify-content: center;
    line-height: 20px;
    margin-bottom: 0;
    padding: 8px 12px;
    position: relative;
    text-align: center;
    text-decoration: none;
    -webkit-transition: color 0.15s ease-out, background-color 0.15s ease-out;
    transition: color 0.15s ease-out, background-color 0.15s ease-out;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
  }
  .rs-theme-high-contrast .rs-picker-toggle {
    -webkit-transition: none;
    transition: none;
  }
  .rs-btn-ghost.rs-picker-toggle {
    padding: 7px 11px;
  }
  .rs-btn-icon.rs-picker-toggle {
    line-height: 16px;
    padding: 10px;
  }
  .rs-btn-icon.rs-picker-toggle > .rs-icon {
    font-size: 16px;
  }
  .rs-btn-icon-with-text.rs-picker-toggle {
    line-height: 20px;
  }
  .rs-btn-icon-with-text.rs-picker-toggle > .rs-icon {
    height: 36px;
    padding: 10px;
    width: 36px;
  }
  .rs-btn-icon-with-text.rs-picker-toggle.rs-btn-icon-placement-left {
    padding: 8px 12px 8px 48px;
  }
  .rs-btn-icon-with-text.rs-picker-toggle.rs-btn-icon-placement-left > .rs-icon {
    border-right: none;
    border-right: var(--rs-btn-default-border, none);
    left: 0;
  }
  .rs-btn-icon-with-text.rs-picker-toggle.rs-btn-icon-placement-right {
    padding: 8px 48px 8px 12px;
  }
  .rs-btn-icon-with-text.rs-picker-toggle.rs-btn-icon-placement-right > .rs-icon {
    border-left: none;
    border-left: var(--rs-btn-default-border, none);
    right: 0;
  }
  .rs-picker-toggle:focus-visible {
    outline: 3px solid rgb(from #3498ff r g b/25%);
    outline: 3px solid var(--rs-color-focus-ring);
  }
  .rs-theme-high-contrast .rs-picker-toggle:focus-visible {
    outline-offset: 2px;
  }
  .rs-picker-toggle:focus,
  .rs-picker-toggle:hover {
    background-color: #e5e5ea;
    background-color: var(--rs-btn-default-hover-bg);
    color: var(--rs-btn-default-hover-text);
    text-decoration: none;
  }
  .rs-picker-toggle.rs-btn-active,
  .rs-picker-toggle:active {
    background-color: #d9d9d9;
    background-color: var(--rs-btn-default-active-bg);
    color: #272c36;
    color: var(--rs-btn-default-active-text);
  }
  .rs-picker-toggle.rs-btn-disabled,
  .rs-picker-toggle:disabled {
    background-color: #f7f7fa;
    background-color: var(--rs-btn-default-disabled-bg);
    color: #c5c6c7;
    color: var(--rs-btn-default-disabled-text);
    cursor: not-allowed;
  }
  .rs-theme-high-contrast .rs-picker-toggle.rs-btn-disabled,
  .rs-theme-high-contrast .rs-picker-toggle:disabled {
    opacity: 0.5;
  }
  @media not all and (min-resolution: 0.001dpcm) {
    .rs-picker-toggle {
      -webkit-mask-image: -webkit-radial-gradient(#fff, #000);
    }
  }
  .rs-picker-default .rs-picker-toggle {
    padding: 7px 11px;
  }
  .rs-picker-toggle-textbox {
    background-color: #fff;
    background-color: var(--rs-input-bg);
    border: 1px solid #0000;
    color: #575757;
    color: var(--rs-text-primary);
    height: 100%;
    left: 0;
    outline: none;
    padding-left: 10px;
    padding-right: 32px;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-textbox {
    padding-left: 14px;
  }
  .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-textbox {
    padding-left: 8px;
  }
  .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-textbox {
    padding-left: 6px;
  }
  .rs-picker-toggle-read-only {
    opacity: 0;
  }
  .rs-picker-default .rs-picker-toggle {
    -webkit-transition: border-color 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out;
  }
  .rs-theme-high-contrast .rs-picker-default .rs-picker-toggle {
    -webkit-transition: none;
    transition: none;
  }
  .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle-active,
  .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:focus,
  .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover {
    border-color: #3498ff;
    border-color: var(--rs-input-focus-border);
  }
  .rs-picker-subtle .rs-picker-toggle {
    background-color: transparent;
    border: none;
    color: #575757;
    color: var(--rs-btn-subtle-text);
    -webkit-transition: none;
    transition: none;
  }
  .rs-picker-subtle .rs-picker-toggle:focus,
  .rs-picker-subtle .rs-picker-toggle:hover {
    background-color: #e5e5ea;
    background-color: var(--rs-btn-subtle-hover-bg);
    color: #575757;
    color: var(--rs-btn-subtle-hover-text);
  }
  .rs-picker-subtle .rs-picker-toggle.rs-btn-active,
  .rs-picker-subtle .rs-picker-toggle:active {
    background-color: #e5e5ea;
    background-color: var(--rs-btn-subtle-active-bg);
    color: #272c36;
    color: var(--rs-btn-subtle-active-text);
  }
  .rs-picker-subtle .rs-picker-toggle.rs-btn-disabled,
  .rs-picker-subtle .rs-picker-toggle:disabled {
    background: none;
    color: #c5c6c7;
    color: var(--rs-btn-subtle-disabled-text);
  }
  .rs-theme-high-contrast .rs-picker-subtle .rs-picker-toggle.rs-btn-disabled,
  .rs-theme-high-contrast .rs-picker-subtle .rs-picker-toggle:disabled {
    opacity: 0.5;
  }
  .rs-picker-subtle .rs-picker-toggle-active {
    background-color: #e5e5ea;
    background-color: var(--rs-btn-subtle-hover-bg);
    color: #575757;
    color: var(--rs-btn-subtle-hover-text);
  }
  .rs-picker-has-value.rs-picker-cleanable .rs-picker-toggle.rs-btn,
  .rs-picker-toggle-wrapper .rs-picker-toggle.rs-btn {
    padding-right: 32px;
  }
  .rs-picker-toggle.rs-btn .rs-picker-toggle-indicator .rs-picker-caret-icon,
  .rs-picker-toggle.rs-btn .rs-picker-toggle-indicator .rs-picker-clean {
    right: 12px;
    top: 8px;
  }
  .rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-indicator .rs-picker-caret-icon,
  .rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-indicator .rs-picker-clean {
    top: 7px;
  }
  .rs-picker-input:not(.rs-picker-tag) .rs-picker-toggle.rs-btn ~ .rs-picker-textbox .rs-picker-search-input {
    font-size: 14px;
    line-height: 1.42857143;
    padding: 7px 12px;
  }
  .rs-picker-tag .rs-picker-toggle.rs-btn ~ .rs-picker-textbox {
    padding-bottom: 5px;
  }
  .rs-picker-tag .rs-picker-toggle.rs-btn ~ .rs-picker-textbox .rs-tag {
    margin-top: 5px;
  }
  .rs-picker-tag .rs-picker-toggle.rs-btn ~ .rs-picker-textbox .rs-picker-search-input {
    font-size: 14px;
    margin-top: 5px;
    padding-left: 12px;
  }
  .rs-picker-tag .rs-picker-toggle.rs-btn ~ .rs-picker-textbox input {
    height: 18px;
  }
  .rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn {
    -webkit-box-shadow: none;
    box-shadow: none;
    padding-bottom: 7px;
    padding-top: 7px;
  }
  .rs-picker-date .rs-picker-toggle.rs-btn,
  .rs-picker-daterange .rs-picker-toggle.rs-btn {
    padding-right: 36px;
  }
  .rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-indicator .rs-picker-caret-icon,
  .rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-indicator .rs-picker-clean,
  .rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-indicator .rs-picker-caret-icon,
  .rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-indicator .rs-picker-clean {
    right: 12px;
    top: 8px;
  }
  .rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-indicator .rs-picker-caret-icon,
  .rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-indicator .rs-picker-clean,
  .rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-indicator .rs-picker-caret-icon,
  .rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-indicator .rs-picker-clean {
    top: 7px;
  }
  .rs-picker-default .rs-picker-toggle.rs-btn {
    padding-bottom: 7px;
    padding-top: 7px;
  }
  .rs-picker-toggle.rs-btn-lg {
    font-size: 16px;
    line-height: 22px;
    padding: 10px 16px;
  }
  .rs-picker-has-value.rs-picker-cleanable .rs-picker-toggle.rs-btn-lg,
  .rs-picker-toggle-wrapper .rs-picker-toggle.rs-btn-lg {
    padding-right: 36px;
  }
  .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-indicator .rs-picker-caret-icon,
  .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-indicator .rs-picker-clean {
    right: 16px;
    top: 10px;
  }
  .rs-picker-default .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-indicator .rs-picker-caret-icon,
  .rs-picker-default .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-indicator .rs-picker-clean {
    top: 9px;
  }
  .rs-picker-default .rs-picker-toggle.rs-btn-lg {
    padding-left: 15px;
  }
  .rs-picker-input:not(.rs-picker-tag) .rs-picker-toggle.rs-btn-lg ~ .rs-picker-textbox .rs-picker-search-input {
    font-size: 16px;
    line-height: 1.375;
    padding: 8px 16px;
  }
  .rs-picker-tag .rs-picker-toggle.rs-btn-lg ~ .rs-picker-textbox {
    padding-bottom: 6px;
  }
  .rs-picker-tag .rs-picker-toggle.rs-btn-lg ~ .rs-picker-textbox .rs-tag {
    margin-top: 6px;
  }
  .rs-picker-tag .rs-picker-toggle.rs-btn-lg ~ .rs-picker-textbox .rs-picker-search-input {
    font-size: 16px;
    margin-top: 6px;
    padding-left: 16px;
  }
  .rs-picker-tag .rs-picker-toggle.rs-btn-lg ~ .rs-picker-textbox input {
    height: 20px;
  }
  .rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-lg {
    -webkit-box-shadow: none;
    box-shadow: none;
    padding-bottom: 8px;
    padding-top: 8px;
  }
  .rs-picker-date .rs-picker-toggle.rs-btn-lg,
  .rs-picker-daterange .rs-picker-toggle.rs-btn-lg {
    padding-right: 42px;
  }
  .rs-picker-date .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-indicator .rs-picker-caret-icon,
  .rs-picker-date .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-indicator .rs-picker-clean,
  .rs-picker-daterange .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-indicator .rs-picker-caret-icon,
  .rs-picker-daterange .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-indicator .rs-picker-clean {
    right: 16px;
    top: 10px;
  }
  .rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-indicator .rs-picker-caret-icon,
  .rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-indicator .rs-picker-clean,
  .rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-indicator .rs-picker-caret-icon,
  .rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-indicator .rs-picker-clean {
    top: 9px;
  }
  .rs-picker-default .rs-picker-toggle.rs-btn-lg {
    padding-bottom: 9px;
    padding-top: 9px;
  }
  .rs-btn-ghost.rs-picker-toggle.rs-btn-lg {
    padding: 9px 15px;
  }
  .rs-btn-icon.rs-picker-toggle.rs-btn-lg {
    line-height: 20px;
    padding: 11px;
  }
  .rs-btn-icon.rs-picker-toggle.rs-btn-lg > .rs-icon {
    font-size: 20px;
  }
  .rs-btn-icon-with-text.rs-picker-toggle.rs-btn-lg {
    line-height: 22px;
  }
  .rs-btn-icon-with-text.rs-picker-toggle.rs-btn-lg > .rs-icon {
    height: 42px;
    padding: 11px;
    width: 42px;
  }
  .rs-btn-icon-with-text.rs-picker-toggle.rs-btn-lg.rs-btn-icon-placement-left {
    padding: 10px 16px 10px 58px;
  }
  .rs-btn-icon-with-text.rs-picker-toggle.rs-btn-lg.rs-btn-icon-placement-left > .rs-icon {
    border-right: none;
    border-right: var(--rs-btn-default-border, none);
    left: 0;
  }
  .rs-btn-icon-with-text.rs-picker-toggle.rs-btn-lg.rs-btn-icon-placement-right {
    padding: 10px 58px 10px 16px;
  }
  .rs-btn-icon-with-text.rs-picker-toggle.rs-btn-lg.rs-btn-icon-placement-right > .rs-icon {
    border-left: none;
    border-left: var(--rs-btn-default-border, none);
    right: 0;
  }
  .rs-picker-toggle.rs-btn-lg .rs-picker-value-count {
    line-height: 22px;
  }
  .rs-picker-has-value.rs-picker-cleanable .rs-picker-toggle.rs-btn-md,
  .rs-picker-toggle-wrapper .rs-picker-toggle.rs-btn-md {
    padding-right: 32px;
  }
  .rs-picker-toggle.rs-btn-md .rs-picker-toggle-indicator .rs-picker-caret-icon,
  .rs-picker-toggle.rs-btn-md .rs-picker-toggle-indicator .rs-picker-clean {
    right: 12px;
    top: 8px;
  }
  .rs-picker-default .rs-picker-toggle.rs-btn-md .rs-picker-toggle-indicator .rs-picker-caret-icon,
  .rs-picker-default .rs-picker-toggle.rs-btn-md .rs-picker-toggle-indicator .rs-picker-clean {
    top: 7px;
  }
  .rs-picker-default .rs-picker-toggle.rs-btn-md {
    padding-left: 11px;
  }
  .rs-picker-input:not(.rs-picker-tag) .rs-picker-toggle.rs-btn-md ~ .rs-picker-textbox .rs-picker-search-input {
    font-size: 14px;
    line-height: 1.42857143;
    padding: 7px 12px;
  }
  .rs-picker-tag .rs-picker-toggle.rs-btn-md ~ .rs-picker-textbox {
    padding-bottom: 5px;
  }
  .rs-picker-tag .rs-picker-toggle.rs-btn-md ~ .rs-picker-textbox .rs-tag {
    margin-top: 5px;
  }
  .rs-picker-tag .rs-picker-toggle.rs-btn-md ~ .rs-picker-textbox .rs-picker-search-input {
    font-size: 14px;
    margin-top: 5px;
    padding-left: 12px;
  }
  .rs-picker-tag .rs-picker-toggle.rs-btn-md ~ .rs-picker-textbox input {
    height: 18px;
  }
  .rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-md {
    -webkit-box-shadow: none;
    box-shadow: none;
    padding-bottom: 7px;
    padding-top: 7px;
  }
  .rs-picker-date .rs-picker-toggle.rs-btn-md,
  .rs-picker-daterange .rs-picker-toggle.rs-btn-md {
    padding-right: 36px;
  }
  .rs-picker-date .rs-picker-toggle.rs-btn-md .rs-picker-toggle-indicator .rs-picker-caret-icon,
  .rs-picker-date .rs-picker-toggle.rs-btn-md .rs-picker-toggle-indicator .rs-picker-clean,
  .rs-picker-daterange .rs-picker-toggle.rs-btn-md .rs-picker-toggle-indicator .rs-picker-caret-icon,
  .rs-picker-daterange .rs-picker-toggle.rs-btn-md .rs-picker-toggle-indicator .rs-picker-clean {
    right: 12px;
    top: 8px;
  }
  .rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn-md .rs-picker-toggle-indicator .rs-picker-caret-icon,
  .rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn-md .rs-picker-toggle-indicator .rs-picker-clean,
  .rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn-md .rs-picker-toggle-indicator .rs-picker-caret-icon,
  .rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn-md .rs-picker-toggle-indicator .rs-picker-clean {
    top: 7px;
  }
  .rs-picker-default .rs-picker-toggle.rs-btn-md {
    padding-bottom: 7px;
    padding-top: 7px;
  }
  .rs-picker-toggle.rs-btn-sm {
    font-size: 14px;
    line-height: 20px;
    padding: 5px 10px;
  }
  .rs-btn-ghost.rs-picker-toggle.rs-btn-sm {
    padding: 4px 9px;
  }
  .rs-btn-icon.rs-picker-toggle.rs-btn-sm {
    line-height: 16px;
    padding: 7px;
  }
  .rs-btn-icon.rs-picker-toggle.rs-btn-sm > .rs-icon {
    font-size: 16px;
  }
  .rs-btn-icon-with-text.rs-picker-toggle.rs-btn-sm {
    line-height: 20px;
  }
  .rs-btn-icon-with-text.rs-picker-toggle.rs-btn-sm > .rs-icon {
    height: 30px;
    padding: 7px;
    width: 30px;
  }
  .rs-btn-icon-with-text.rs-picker-toggle.rs-btn-sm.rs-btn-icon-placement-left {
    padding: 5px 10px 5px 40px;
  }
  .rs-btn-icon-with-text.rs-picker-toggle.rs-btn-sm.rs-btn-icon-placement-left > .rs-icon {
    border-right: none;
    border-right: var(--rs-btn-default-border, none);
    left: 0;
  }
  .rs-btn-icon-with-text.rs-picker-toggle.rs-btn-sm.rs-btn-icon-placement-right {
    padding: 5px 40px 5px 10px;
  }
  .rs-btn-icon-with-text.rs-picker-toggle.rs-btn-sm.rs-btn-icon-placement-right > .rs-icon {
    border-left: none;
    border-left: var(--rs-btn-default-border, none);
    right: 0;
  }
  .rs-picker-has-value.rs-picker-cleanable .rs-picker-toggle.rs-btn-sm,
  .rs-picker-toggle-wrapper .rs-picker-toggle.rs-btn-sm {
    padding-right: 30px;
  }
  .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-indicator .rs-picker-caret-icon,
  .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-indicator .rs-picker-clean {
    right: 10px;
    top: 5px;
  }
  .rs-picker-default .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-indicator .rs-picker-caret-icon,
  .rs-picker-default .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-indicator .rs-picker-clean {
    top: 4px;
  }
  .rs-picker-default .rs-picker-toggle.rs-btn-sm {
    padding-left: 9px;
  }
  .rs-picker-input:not(.rs-picker-tag) .rs-picker-toggle.rs-btn-sm ~ .rs-picker-textbox .rs-picker-search-input {
    font-size: 14px;
    line-height: 1.42857143;
    padding: 4px 10px;
  }
  .rs-picker-tag .rs-picker-toggle.rs-btn-sm ~ .rs-picker-textbox {
    padding-bottom: 2px;
  }
  .rs-picker-tag .rs-picker-toggle.rs-btn-sm ~ .rs-picker-textbox .rs-tag {
    margin-top: 2px;
  }
  .rs-picker-tag .rs-picker-toggle.rs-btn-sm ~ .rs-picker-textbox .rs-picker-search-input {
    font-size: 14px;
    margin-top: 2px;
    padding-left: 10px;
  }
  .rs-picker-tag .rs-picker-toggle.rs-btn-sm ~ .rs-picker-textbox input {
    height: 18px;
  }
  .rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-sm {
    -webkit-box-shadow: none;
    box-shadow: none;
    padding-bottom: 4px;
    padding-top: 4px;
  }
  .rs-picker-date .rs-picker-toggle.rs-btn-sm,
  .rs-picker-daterange .rs-picker-toggle.rs-btn-sm {
    padding-right: 34px;
  }
  .rs-picker-date .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-indicator .rs-picker-caret-icon,
  .rs-picker-date .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-indicator .rs-picker-clean,
  .rs-picker-daterange .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-indicator .rs-picker-caret-icon,
  .rs-picker-daterange .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-indicator .rs-picker-clean {
    right: 10px;
    top: 4px;
  }
  .rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-indicator .rs-picker-caret-icon,
  .rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-indicator .rs-picker-clean,
  .rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-indicator .rs-picker-caret-icon,
  .rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-indicator .rs-picker-clean {
    top: 3px;
  }
  .rs-picker-default .rs-picker-toggle.rs-btn-sm {
    padding-bottom: 4px;
    padding-top: 4px;
  }
  .rs-picker-toggle.rs-btn-xs {
    font-size: 12px;
    line-height: 20px;
    padding: 2px 8px;
  }
  .rs-btn-ghost.rs-picker-toggle.rs-btn-xs {
    padding: 1px 7px;
  }
  .rs-btn-icon.rs-picker-toggle.rs-btn-xs {
    line-height: 12px;
    padding: 6px;
  }
  .rs-btn-icon.rs-picker-toggle.rs-btn-xs > .rs-icon {
    font-size: 12px;
  }
  .rs-btn-icon-with-text.rs-picker-toggle.rs-btn-xs {
    line-height: 20px;
  }
  .rs-btn-icon-with-text.rs-picker-toggle.rs-btn-xs > .rs-icon {
    height: 24px;
    padding: 6px;
    width: 24px;
  }
  .rs-btn-icon-with-text.rs-picker-toggle.rs-btn-xs.rs-btn-icon-placement-left {
    padding: 2px 8px 2px 32px;
  }
  .rs-btn-icon-with-text.rs-picker-toggle.rs-btn-xs.rs-btn-icon-placement-left > .rs-icon {
    border-right: none;
    border-right: var(--rs-btn-default-border, none);
    left: 0;
  }
  .rs-btn-icon-with-text.rs-picker-toggle.rs-btn-xs.rs-btn-icon-placement-right {
    padding: 2px 32px 2px 8px;
  }
  .rs-btn-icon-with-text.rs-picker-toggle.rs-btn-xs.rs-btn-icon-placement-right > .rs-icon {
    border-left: none;
    border-left: var(--rs-btn-default-border, none);
    right: 0;
  }
  .rs-picker-has-value.rs-picker-cleanable .rs-picker-toggle.rs-btn-xs,
  .rs-picker-toggle-wrapper .rs-picker-toggle.rs-btn-xs {
    padding-right: 28px;
  }
  .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-indicator .rs-picker-caret-icon,
  .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-indicator .rs-picker-clean {
    right: 8px;
    top: 2px;
  }
  .rs-picker-default .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-indicator .rs-picker-caret-icon,
  .rs-picker-default .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-indicator .rs-picker-clean {
    top: 1px;
  }
  .rs-picker-default .rs-picker-toggle.rs-btn-xs {
    padding-left: 7px;
  }
  .rs-picker-input:not(.rs-picker-tag) .rs-picker-toggle.rs-btn-xs ~ .rs-picker-textbox .rs-picker-search-input {
    font-size: 12px;
    line-height: 1.66666667;
    padding: 1px 8px;
  }
  .rs-picker-tag .rs-picker-toggle.rs-btn-xs ~ .rs-picker-textbox {
    padding-bottom: -1px;
  }
  .rs-picker-tag .rs-picker-toggle.rs-btn-xs ~ .rs-picker-textbox .rs-tag {
    margin-top: -1px;
  }
  .rs-picker-tag .rs-picker-toggle.rs-btn-xs ~ .rs-picker-textbox .rs-picker-search-input {
    font-size: 12px;
    margin-top: -1px;
    padding-left: 8px;
  }
  .rs-picker-tag .rs-picker-toggle.rs-btn-xs ~ .rs-picker-textbox input {
    height: 18px;
  }
  .rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-xs {
    -webkit-box-shadow: none;
    box-shadow: none;
    padding-bottom: 1px;
    padding-top: 1px;
  }
  .rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-xs ~ .rs-picker-textbox {
    padding-bottom: 1px;
  }
  .rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-xs ~ .rs-picker-textbox .rs-tag {
    margin-top: 1px;
  }
  .rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-xs ~ .rs-picker-textbox .rs-picker-search-input {
    padding-bottom: 1px;
    padding-top: 1px;
  }
  .rs-picker-date .rs-picker-toggle.rs-btn-xs,
  .rs-picker-daterange .rs-picker-toggle.rs-btn-xs {
    padding-right: 30px;
  }
  .rs-picker-date .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-indicator .rs-picker-caret-icon,
  .rs-picker-date .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-indicator .rs-picker-clean,
  .rs-picker-daterange .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-indicator .rs-picker-caret-icon,
  .rs-picker-daterange .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-indicator .rs-picker-clean {
    right: 8px;
    top: 2px;
  }
  .rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-indicator .rs-picker-caret-icon,
  .rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-indicator .rs-picker-clean,
  .rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-indicator .rs-picker-caret-icon,
  .rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-indicator .rs-picker-clean {
    top: 1px;
  }
  .rs-picker-default .rs-picker-toggle.rs-btn-xs {
    padding-bottom: 1px;
    padding-top: 1px;
  }
  .rs-picker-toggle {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
  .rs-picker-default .rs-picker-toggle {
    border: 1px solid #e5e5ea;
    border: 1px solid var(--rs-border-primary);
    color: #575757;
    color: var(--rs-text-primary);
    display: inline-block;
    padding-right: 32px;
    position: relative;
    z-index: 5;
  }
  .rs-picker-disabled .rs-picker-toggle {
    cursor: not-allowed;
  }
  .rs-picker-toggle .rs-picker-default:not(.rs-picker-disabled):focus,
  .rs-picker-toggle .rs-picker-default:not(.rs-picker-disabled):hover {
    border-color: #3498ff;
    border-color: var(--rs-input-focus-border);
  }
  .rs-picker-subtle .rs-picker-toggle {
    display: inline-block;
    padding-right: 32px;
    position: relative;
    z-index: 5;
  }
  .rs-picker-subtle.rs-picker-disabled .rs-picker-toggle {
    cursor: not-allowed;
  }
  .rs-picker-subtle.rs-picker-disabled .rs-picker-toggle:active,
  .rs-picker-subtle.rs-picker-disabled .rs-picker-toggle:focus,
  .rs-picker-subtle.rs-picker-disabled .rs-picker-toggle:hover {
    background: none;
  }
  .rs-picker-subtle.rs-picker-disabled .rs-picker-toggle:active:after,
  .rs-picker-subtle.rs-picker-disabled .rs-picker-toggle:focus:after,
  .rs-picker-subtle.rs-picker-disabled .rs-picker-toggle:hover:after {
    display: none;
  }
  .rs-picker-toggle.rs-btn {
    text-align: left;
    width: 100%;
  }
  .rs-picker-toggle-label {
    color: #575757;
    color: var(--rs-text-primary);
  }
  .rs-picker-toggle-label:after {
    content: ":";
    margin: 0 4px 0 2px;
  }
  .rs-picker-toggle-value {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
  .rs-picker-cleanable.rs-picker-has-value .rs-picker-toggle {
    padding-right: 44px;
  }
  .rs-picker-toggle-indicator .rs-picker-clean {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: inherit;
    color: #8e8e93;
    color: var(--rs-text-secondary);
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 12px;
    height: 20px;
    margin-left: 2px;
    position: absolute;
    right: 30px;
    top: 8px;
    -webkit-transition: color 0.2s linear;
    transition: color 0.2s linear;
  }
  .rs-picker-toggle-indicator .rs-picker-clean.rs-btn-close {
    padding: 4px 0;
  }
  .rs-picker-date .rs-picker-toggle-indicator .rs-picker-clean,
  .rs-picker-daterange .rs-picker-toggle-indicator .rs-picker-clean {
    right: 38px;
  }
  .rs-picker-toggle-indicator .rs-picker-caret-icon {
    color: #8e8e93;
    color: var(--rs-text-secondary);
  }
  .rs-picker-toggle-indicator .rs-picker-caret-icon,
  .rs-picker-toggle-indicator .rs-picker-loader {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 12px;
    height: 20px;
    margin-left: 2px;
    position: absolute;
    right: 12px;
    top: 8px;
  }
  .rs-picker-menu {
    position: absolute;
    text-align: left;
    z-index: 7;
    border-radius: 6px;
    background-color: #fff;
    background-color: var(--rs-bg-overlay);
    -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
    -webkit-box-shadow: var(--rs-shadow-overlay);
            box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
            box-shadow: var(--rs-shadow-overlay);
    overflow: hidden;
    -webkit-transition: none;
    transition: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .rs-picker-popup {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    background-color: #fff;
    background-color: var(--rs-bg-overlay);
    border-radius: 6px;
    -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
    -webkit-box-shadow: var(--rs-shadow-overlay);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
    box-shadow: var(--rs-shadow-overlay);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    overflow: hidden;
    padding: 6px 0;
    position: absolute;
    text-align: left;
    -webkit-transition: none;
    transition: none;
    z-index: 11;
  }
  #borrowing-base-main-container {
    .rs-picker-popup {
      z-index: 101;
    }
  }
  #transaction-summary-main-container {
    + .rs-picker-popup {
      z-index: 101;
    }
  }
  .rs-theme-high-contrast .rs-picker-popup {
    border: 1px solid #e5e5ea;
    border: 1px solid var(--rs-border-primary);
  }
  .rs-drawer-open .rs-picker-popup,
  .rs-modal-open .rs-picker-popup {
    z-index: 1055;
  }
  .rs-picker-popup.rs-picker-inline {
    -webkit-box-shadow: none;
    box-shadow: none;
    position: relative;
  }
  .rs-picker-popup .rs-search-box {
    padding: 6px 12px;
  }
  .rs-picker-default .rs-btn,
  .rs-picker-default .rs-picker-toggle,
  .rs-picker-input .rs-btn,
  .rs-picker-input .rs-picker-toggle {
    background-color: #fff !important;
    background-color: var(--rs-input-bg) !important;
  }
  .rs-picker-default.rs-picker-disabled .rs-btn,
  .rs-picker-default.rs-picker-disabled .rs-picker-toggle,
  .rs-picker-input.rs-picker-disabled .rs-btn,
  .rs-picker-input.rs-picker-disabled .rs-picker-toggle {
    background-color: #f7f7fa !important;
    background-color: var(--rs-input-disabled-bg) !important;
  }
  .rs-check-item {
    display: block;
  }
  .rs-check-item.rs-check-item-focus,
  .rs-check-item:focus,
  .rs-check-item:not(.rs-checkbox-disabled):hover {
    background-color: rgb(from #cce9ff r g b/50%);
    background-color: var(--rs-listbox-option-hover-bg);
    color: #1675e0;
    color: var(--rs-listbox-option-hover-text);
  }
  .rs-theme-high-contrast .rs-check-item.rs-check-item-focus,
  .rs-theme-high-contrast .rs-check-item:focus,
  .rs-theme-high-contrast .rs-check-item:not(.rs-checkbox-disabled):hover {
    color: #1675e0;
    color: var(--rs-listbox-option-hover-text);
    outline: 3px solid rgb(from #3498ff r g b/25%);
    outline: 3px solid var(--rs-color-focus-ring);
    outline-offset: -3px;
    outline-width: 2px;
    text-decoration: underline;
  }
  .rs-theme-high-contrast .rs-theme-high-contrast .rs-check-item.rs-check-item-focus,
  .rs-theme-high-contrast .rs-theme-high-contrast .rs-check-item:focus,
  .rs-theme-high-contrast .rs-theme-high-contrast .rs-check-item:not(.rs-checkbox-disabled):hover {
    outline-offset: 2px;
  }
  .rs-theme-high-contrast .rs-check-item.rs-check-item-focus .rs-check-tree-node-text-wrapper,
  .rs-theme-high-contrast .rs-check-item:focus .rs-check-tree-node-text-wrapper,
  .rs-theme-high-contrast .rs-check-item:not(.rs-checkbox-disabled):hover .rs-check-tree-node-text-wrapper {
    text-decoration: underline;
  }
  .rs-check-item .rs-checkbox-checker {
    padding: 0;
  }
  .rs-check-item .rs-checkbox-checker > label {
    cursor: pointer;
    display: block;
    line-height: 20px;
    padding: 8px 12px 8px 38px;
    position: relative;
  }
  .rs-theme-high-contrast .rs-check-item .rs-checkbox-checker > label {
    -webkit-transition: none;
    transition: none;
  }
  .rs-check-item .rs-checkbox-checker > label:before {
    background-color: #fff;
    background-color: var(--rs-bg-overlay);
  }
  .rs-theme-high-contrast .rs-check-item .rs-checkbox-checker > label:before {
    background: none;
  }
  .rs-checkbox-disabled.rs-check-item .rs-checkbox-checker > label {
    cursor: not-allowed;
  }
  .grouped .rs-check-item .rs-checkbox-checker > label,
  .rs-picker-check-menu-group-children .rs-check-item .rs-checkbox-checker > label {
    padding-left: 52px;
  }
  .rs-check-item .rs-checkbox-checker .rs-checkbox-wrapper {
    left: 12px;
  }
  .grouped .rs-check-item .rs-checkbox-checker .rs-checkbox-wrapper,
  .rs-picker-check-menu-group-children .rs-check-item .rs-checkbox-checker .rs-checkbox-wrapper {
    left: 26px;
  }
  .rs-search-box {
    position: relative;
  }
  .rs-search-box-icon {
    color: #8e8e93;
    color: var(--rs-text-secondary);
  }
  /*# sourceMappingURL=rsuite.min.css.map */
}
