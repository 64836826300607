@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  src: url(./poppins-v20-latin-regular.916d3686.woff2) format("woff2")
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  src: url(./poppins-v20-latin-500.7777133e.woff2) format("woff2")
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  src: url(./poppins-v20-latin-600.d8692086.woff2) format("woff2")
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  src: url(./poppins-v20-latin-700.9a881e2a.woff2) format("woff2")
}