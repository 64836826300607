@font-face {font-family: "wbi";
  src: url('wbi.eot?t=1712108779305'); /* IE9*/
  src: url('wbi.eot?t=1712108779305#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url("wbi.woff2?t=1712108779305") format("woff2"),
  url("wbi.woff?t=1712108779305") format("woff"),
  url('wbi.ttf?t=1712108779305') format('truetype'), /* chrome, firefox, opera, Safari, Android, iOS 4.2+*/
  url('wbi.svg?t=1712108779305#wbi') format('svg'); /* iOS 4.1- */
}

[class^="wbi-"], [class*=" wbi-"] {
  font-family: 'wbi' !important;
  font-style:normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.wbi-cylon:before { content: "\ea01"; }
.wbi-skynet-cloud:before { content: "\ea02"; }
.wbi-skynet-portal:before { content: "\ea03"; }
.wbi-webby:before { content: "\ea04"; }

$wbi-cylon: "\ea01";
$wbi-skynet-cloud: "\ea02";
$wbi-skynet-portal: "\ea03";
$wbi-webby: "\ea04";

