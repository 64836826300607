@import "styles";
@import "themes/fonts";

body, .p-card, .layout-container .layout-sidebar {
  transition: background-color var(--transition-duration);
}

:root {
  --sidebar-border: none;
}

/* primereact overrides */
.p-scrollpanel-bar-y {
  background: var(--primary-200);
}

.p-datatable-table tr .p-button.p-button-icon-only {
  width: 23px;
  height: 23px;
  padding: 0;
  margin: -1px 0 -1px 0;
}

.p-cell-editing input.p-inputtext {
  margin-top: 1.2rem;
}

.p-dropdown-item {
  padding: 1rem;
}

.p-dropdown-item:hover {
  background: var(--gray-100);
}

.p-multiselect-item {
  padding: 1rem;
}

.p-multiselect-item:hover {
  background: var(--gray-100);
}

// call to action banner effect
.banner-container {
  left: 50vw;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 1rem;
  z-index: 999;
}

.banner-call-to-action {
  border: none;
  outline: none;
  cursor: pointer;
  position: relative;
  z-index: 0;
  display: inline-flex;
}

.banner-call-to-action::before {
  position: absolute;
  content: "";
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  left: -2px;
  top: -2px;
  background: linear-gradient(124deg, #ff2400, #e81d1d, #e8b71d, #e3e81d, #1de840, #fff, #fff, #fff, #1ddde8, #2b1de8, #dd00f3, #dd00f3);
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  animation: move 20s linear infinite;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
  border-radius: 4px;
}

.banner-call-to-action::after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #fff;
  left: 0;
  top: 0;
  border-radius: 4px;
}


@keyframes move {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

/* legacy banner */
.env-banner {
  background-color: white;
  border-top: 3px solid var(--surface-400);
  border-bottom: 5px solid var(--surface-400);
  padding: 5px 0px;
}

.inner-banner {
  background-color: var(--surface-400);
  border-top: 3px solid var(--surface-400);
  border-bottom: 3px solid var(--surface-400);
  color: white;
}
