.layout-content > .p-tabmenu.p-component {
  margin: 0 14px;
  margin-bottom: -2px;
  position: relative;
  overflow: visible;
}

.layout-content>.p-tabmenu.p-component:after {
  content: '.';
  display: block;
  color: transparent;
  margin: -2px -14px -16px -14px;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  border-top: 2px solid var(--surface-d);
  height: 16px;
  z-index: 1000;
}

.layout-content>.p-tabmenu .p-tabmenu-nav {
  background-color: transparent;
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  box-sizing: content-box;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.layout-content>.p-tabmenu .p-tabmenu-nav::-webkit-scrollbar {
  display: none;
}

.p-tabmenu.p-component + .p-card.p-component,
.p-tabmenu.p-component + .p-scrollpanel.p-component .p-card.p-component,
.p-tabmenu.p-component + * .p-scrollpanel.p-component .p-card.p-component {
  margin-top: 0px !important;
}

.p-tabmenu.p-component .p-tabmenu-nav .p-tabmenuitem {
  margin-right: 14px;
}