// Usage:
// <div className="gateway-modal-container">
//   <div className={`gateway-modal ${showBaModal ? "show" : ""} ${hideBaModal ? "hide" : ""}`}>
//     <div className={`p-3 gateway-container`}>
//       <Modal />
//     </div>
//   </div>
// </div>
.gateway-modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  .gateway-modal {
    background-color: rgba(10, 10, 10, 0.6);
    height: 1px;
    width: 1px;
    z-index: 1;
    overflow-x: auto;
  }
  .gateway-modal.show {
    animation: openGate .7s forwards;
    .gateway-container {
      animation: gateContents .7s forwards;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .modal {
        animation: gateCard .5s forwards;
      }
    }
  }
  .gateway-modal.hide {
    animation: closeGate .5s forwards;
    .gateway-container {
      animation: gateContents .7s reverse;
      .modal {
        animation: gateCard .5s reverse;
      }
    }
  }
}

@keyframes gateCard {
  0%{
    height: 0;
  }
  50%{
    height: 0;
  }
  100%{
    height: auto;
  }
}

@keyframes gateContents {
  0%{
    transform: scaleX(0);
  }
  100%{
    transform: scaleX(1);
  }
}

@keyframes openGate {
  0% {
    height: 1px;
    width: 1px;
  }
  50% {
    height: 100vh;
    width: 1px;
  }
  100% {
    height: 100vh;
    width: 100vw;
  }
}

@keyframes closeGate {
  0% {
    height: 100vh;
    width: 100vw;
  }
  25% {
    width: 1px;
  }
  50% {
    height: 100vh;
  }
  100% {
    height: 1px;
  }
}