@import '../styles/bulma/bulma.sass';
@import '/node_modules/@fortawesome/fontawesome-free/css/all.css';
@import './login.scss';
@import './footer.scss';
@import './systemmessages.scss';
@import './topdrawer.scss';
@import './transactionSummary.scss';
@import './test.scss';
@import './baModal.scss';
@import './lqrReport.scss';
@import './customCells.scss';
@import "../styles/rsuite/rsuiteNormalize.scss";
@import "/node_modules/primeicons/primeicons.css";
@import "/node_modules/primeflex/primeflex.css";
/* import shared styles */
@import "../webbank-ui";

/* makes container fill available space in div */
.custom-flex-container {
  display: flex;
  flex-direction: column;
  flex: 1 1;
}
/* content inside this container should scroll */
.custom-flex-container.flex-scroll {
  overflow: auto;
  > div {
    display: flex;
    flex-direction: column;
    height: 0px;
  }
}

// fixes textarea in lqr report
#lqr-report {
  .rs-table-cell-wrap {
    flex: 1 1 auto;
  }
}

/* classes bulma doesn't have but should (in bulma syntax) */
.is-absolute {
  position: absolute !important;
}

.is-max-height {
  height: 100% !important;
}

.is-width-full {
  width: 100% !important;
}

.is-green {
  color: green;
}

.is-sticky {
  position: sticky !important;
}

//custom flex
.is-flex-justify-center-align-center {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.is-flex-justify-space-between-align-center {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.is-flex-justify-start-align-center {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
}

.loader-is-info .rs-loader-spin:after {
  border-color: #3e8ed0 transparent transparent !important;
}

.header {
  height: 50px;
  border-bottom: 1px solid #d7d7d7;
  box-shadow: 0px 2px 4px rgba($color: #d0d0d0, $alpha: 1.0);
  z-index: 5;
  position: relative;
}

.prevent-text-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.custom-select-entry:hover {
  cursor: pointer;
  background-color: #3e8ed0;
  color: #fff;
}

.custom-select-contents {
  top: 31px;
  width: 285px;
  right: 0px;
  padding: 0.5rem;
  border: 1px solid rgb(215, 215, 215);
  border-radius: 3px;
  background: rgb(250, 249, 249);
  overflow: auto;
  max-height: 300px;
}

html,
body {
  overflow: hidden;
}



ul.rs-dropdown-menu li a {
  display: flex !important;
}

.navbar {
  min-height: initial;
}

.navbar .navbar-brand {
  min-height: initial;
}

#brand {
  height: 37px;
}

.navbar-brand span {
  line-height: 37px;
  font-size: 30px;
}

.full-table-height {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.rs-table-body-row-wrapper {
  height: calc(100% - 50px) !important;
}

.select-picker {
  max-width: 200px !important;
}

.title-color {
  color: rgb(216, 216, 216);
  background-color: rgb(49, 49, 100);
}

.rs-sidenav-item {
  white-space: nowrap !important;
  padding-left: 0 !important;
}

.rs-nav-item {
  padding-left: 0 !important;
}

.side-nav-icon {
  margin-left: 19px;
  margin-right: 19px;
  min-width: 20px;
  text-align: center;
}

.secondary-text {
  color: #3e8ed0;
  font-weight: bold;
  letter-spacing: .05em;
}

.secondary-text:hover {
  color: #0c5fa3;
  border-bottom: 1px solid #0c5fa3;
}



.info-line-height {
  max-height: 150px;
  max-width: 270px;
}



.cell-item:hover:not(.table-cell-add-button) {
  background-color: #cfdef5;
}

.table-cell-add-button:hover {
  color: white;
  background-color: hsl(207deg, 61%, 53%);
  box-shadow: 0px 2px 4px rgba($color: #d0d0d0, $alpha: 1.0);
  border: unset;
  background-image: unset;

}

.table-cell-add-button {
  color: #dae2ff;
  margin-top: -.5rem;
  width: 20px;
  height: 20px;
  border: double 1px transparent;
  border-radius: 4px;
  background-image: linear-gradient(to top right, rgb(249, 250, 255), #f6f8ff), radial-gradient(circle at top left, #eeeef2, #f3f3f3);
  background-origin: border-box;
  background-clip: padding-box, border-box;
}

// override bulma column widths
.section-column-width {
  max-width: 300px;
  width: 300px;
}

span.rs-panel-title {
  margin-right: 50px;
}

span.faq-search-input-group {
  margin-top: -6px;
}

.faq-q-row {
  margin-top: 20px;
}

p.faq-info-text {
  line-height: 8px;
  margin-top: 8px;
}

.rs-panel-title {
  font-size: 1.4rem;
}

.faq-answer-label {
  font-weight: bold;
}

.faq-answer-container {
  margin: 12px;
}

.faq-section-title,
.topic-section-title {
  margin-top: 15px;
}

.faq-sections-list li,
.topic-sections-list li {
  font-size: 1.2rem;
  margin-bottom: 12px;
}

nav.panel.faq-section-list,
.faq-questions-list {
  margin-top: 20px;
}

a.panel-block.is-active {
  font-weight: bold;
}

.columns.faq-sections-list-item,
.columns.topic-sections-list-item {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 10px;
  margin: 8px;
}

.columns.faq-sections-list-item-header {
  padding: 10px;
  border: none;
  border-radius: 10px;
  margin: 8px;
}

p.faq-select-a-section {
  font-size: 1.4rem;
}

.faq-confirm-delete:hover {
  text-decoration: underline;
}

.faq-confirm-delete {
  color: #1675e0;
}

span.panel-block.is-clickable.is-active {
  font-style: italic;
  font-weight: bold;
}

.title-button {
  height: 25px;
  line-height: 1rem;
  border: 1px solid rgb(179 179 220);
  background: rgb(49, 49, 100);
  box-shadow: 0px 2px 4px rgb(85 85 141);
  color: rgb(179 179 220);
  margin-top: -5px;
  font-family: var(--rs-font-family) !important;
  font-size: 16px !important;
}

.title-button:hover {
  color: rgb(216, 216, 216);
  border-color: rgb(216, 216, 216);
  box-shadow: 0px 2px 4px rgb(85 85 141);
}

.title-button-inverse {
  height: 25px;
  font-size: 1rem;
  line-height: 1rem;
  border: 1px solid #3e8ed0;
  color: rgb(49, 49, 100);
  box-shadow: 0px 2px 4px rgb(85 85 141);
  background-color: rgb(179 179 220);
  margin-top: -5px;
}

.title-button-inverse:hover {
  background-color: rgba(216, 216, 216, .65);
  color: rgb(216, 216, 216);
  box-shadow: 0px 2px 4px rgb(85 85 141);
  border-color: #3e8ed0;
}

.char-counter {
  font-size: .7rem;
  margin-left: 13px;
  margin-top: 3px;
  color: #aaa;
}

.hover-3 {
  --b: 0.1em;
  /* the thickness of the line */
  --c: #1095c1;
  /* the color */

  color: #0000;
  padding-block: var(--b);
  background:
    linear-gradient(var(--c) 50%, #000 0) 0% calc(100% - var(--_p, 0%))/100% 200%,
    linear-gradient(var(--c) 0 0) 0% var(--_p, 0%)/var(--_p, 0%) var(--b) no-repeat;
  -webkit-background-clip: text, padding-box;
  background-clip: text, padding-box;
  transition: .3s var(--_s, 0s) linear, background-size .3s calc(.3s - var(--_s, 0s));
}

.hover-3:hover {
  --_p: 100%;
  --_s: .3s;
}

.custom-button-shadow,
.custom-button-shadow-hover:hover {
  color: #fff;
  text-shadow: 0px 2px 2px #3347d7;
  box-shadow: 0px 2px 2px #3347d7;
  background-color: #fff;
  font-weight: bold;
}

.custom-button-shadow-hover:active {
  color: unset;
  text-shadow: unset;
  box-shadow: unset;
}


// shake effect
@keyframes shake {
  25% {
    transform: rotate(calc(var(--angle) * -1));
  }

  50% {
    transform: rotate(var(--angle));
  }

  100% {
    transform: rotate(0deg);
  }
}

.animate-shake:hover {
  --angle: 5deg;
  animation: shake 0.3s;
}


// animate siren
@keyframes animatesiren {
  0% {
    background-position: 0% 100%;
    background-image: linear-gradient(90deg, transparent, yellow, orange, transparent);
  }

  50% {
    background-position: 100% 0%;
  }

  100% {
    background-position: 0% 100%;
    background-image: linear-gradient(90deg, transparent, yellow, orange, transparent);
  }
}

.animate-siren {
  animation-duration: .5s;
  animation-name: animatesiren;
  animation-iteration-count: infinite;
  background-size: 175% 175%;
  background-clip: text;
  color: transparent;
}

.delete-accident:hover {
  color: cornflowerblue;
}


// animated drop card
$drop-transition-time: .3s;

.drop-card-anchor {
  position: relative;
  background-color: inherit;
  color: inherit;

  & .drop-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    position: absolute;
    top: var(--dropCardTopPosition);
    right: 0;
    height: var(--dropCardButtonHeight);
    width: var(--dropCardButtonWidth);
    min-width: 0px;
    color: inherit;
    line-height: 1rem;
    border: 1px solid var(--dropBorderColor);
    background-color: var(--dropBackgroundColor);
    box-shadow: 0px 2px 4px var(--dropBackgroundColor);
    z-index: 10;
    transition: ease-in $drop-transition-time;

    & .drop-card-content {
      flex: 1;
    }

    & .drop-card-content,
    .drop-card-actions-container {
      min-width: 0px;
      min-height: 0px;
      overflow: auto;
    }
  }

  & .drop-card.active {
    height: var(--dropHeight);
    width: var(--dropWidth);
    max-width: 600px;
    transition: ease-in $drop-transition-time;
    cursor: unset;
  }
}

.button .button-style-overrides .control {
  font-weight: 400;
  margin: unset;
  padding: unset;
  font-size: unset;
  line-height: 1.5rem;
  text-align: left;
}

/* legal */
button.title-button.drop-card-activate-button.legal-dash {
  padding-left: 13px;
  padding-right: 13px;
}

.legal-dash.card {
  width: 150px;
  height: 100px;
}

.p-2.mb-2.cell-item.is-clickable.legal-dash {
  margin-top: 7px;
}

.topic-confirm-delete {
  color: var(--rs-text-link);
}

// page template classes
div.is-page-content {
  height: 100%;

  & div.full-height-div {
    height: 100%;
  }
}

.inline-save-required {
  margin-left: -0.75rem;
  margin-right: -0.75rem;
  margin-top: -1.5rem;
  background-color: white;
  border-radius: 4px;
}

.ban-cursor {
  cursor: not-allowed !important;
}

// assign to parent table container. add var style (--lastRowHeight) to same element as .extend-last-row class
.extend-last-row {
  & .rs-table-row.rs-table-row-header:last-child {
    padding-bottom: 0 !important
  }
  & .rs-table-row:last-child {
    padding-bottom: var(--lastRowHeight) !important;
  }
}

.row-action-button {
  height: 30px;
}

// tabs
.tab {
  cursor: pointer;
  padding: .5rem;

  --c: rgb(49, 49, 100); /* the color */
  --b: .1em;    /* border length*/
  --d: 10px;    /* the cube depth */
  
  --_s: calc(var(--d) + var(--b));
  
  color: var(--c);
  border: solid rgb(56, 80, 183);
  border-width: var(--b) var(--b) var(--_s) var(--_s);
  background:
    conic-gradient(at left var(--d)  bottom var(--d),
      #0000 90deg, rgb(255 255 255 /0.3) 0 225deg,rgb(255 255 255 /0.6) 0) border-box,
    conic-gradient(at left var(--_s) bottom var(--_s),
      #0000 90deg,var(--c) 0) 0 100%/calc(100% - var(--b)) calc(100% - var(--b))  border-box;
  transform: translate(calc(var(--d)/(-1)),var(--d));
  clip-path: 
    polygon(
     var(--d) 0%, 
     var(--d) 0%, 
     100% 0%, 
     100% calc(100% - var(--d)),
     100% calc(100% - var(--d)),
     var(--d) calc(100% - var(--d))
    );
  transition: 0.3s;


}

.tab:hover {
  transform: translate(0,0);
  clip-path: 
    polygon(
     0% var(--d), 
     var(--d) 0%, 
     100% 0%, 
     100% calc(100% - var(--d)), 
     calc(100% - var(--d)) 100%, 
     0% 100%
   );
}

select.select-input-row {
  max-height: inherit;
  font-size: 1rem;
  width: inherit;
  padding-top: 0px;
  padding-bottom: 0px;
}

select.select-input {
  box-shadow: inset 0 0.0625em 0.125em rgba(10, 10, 10, 0.05);
  font-size: 1rem;
  padding-top: 0px;
  padding-bottom: 0px;
  width: 100%;
}

.custom-disabled {
  cursor: not-allowed;
  background-color: hsl(0, 0%, 96%);
  border-color: hsl(0, 0%, 96%);
  box-shadow: none;
  color: hsl(0, 0%, 48%);
}

.no-border-table .rs-table-cell {
  border-bottom:0 !important;
}

.mimic-table-row-hover:hover {
  background-color: #f2faff;
}

// call to action button effect
.btn-call-to-action {
  border: none;
  outline: none;
  cursor: pointer;
  position: relative;
  z-index: 0;
}

.btn-call-to-action::before {
  position: absolute;
  content: "";
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  left: -2px;
  top: -2px;
  background: linear-gradient(
    124deg,
    #ff2400,
    #e81d1d,
    #e8b71d,
    #e3e81d,
    #1de840,
    #fff,
    #fff,
    #fff,
    #1ddde8,
    #2b1de8,
    #dd00f3,
    #dd00f3
  );
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  animation: move 20s linear infinite;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
  border-radius: 4px;
}

.btn-call-to-action::after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: hsl(153, 53%, 53%);
  left: 0;
  top: 0;
  border-radius: 4px;
}

@keyframes move {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

// toggle
.override-toggle {
  height: 20px;
  width: 40px;
  border: 1px solid rgb(219 219 219);
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding-left: 2px;
  padding-right: 2px;
  box-shadow: inset 0 0.0625em 0.125em rgb(10 10 10 / 5%);

}

.override-toggle-on {
  background: #b4d2ff;
  border-color: rgb(0,212,255);
}

.override-latch {
  height: 14px;
  width: 14px;
  border-radius: 7px;
  background-color: #efefef;
  box-shadow: 1px 1px 3px #b2b2b2;
  position: absolute;
  left: 10px;
  transform: translateX(-50%);
  transition: all 300ms;
}

.override-latch-on {
  background: rgb(2,0,36);
  background: linear-gradient(315deg, rgba(2,0,36,1) 0%, rgba(67,118,185,1) 42%, rgba(0,212,255,1) 100%);
  box-shadow: -1px 1px 3px #b2b2b2;
  left: 31px;
}

.override-toggle.toggle-disabled {
  background: #bbc8dd;
  border-color: rgb(128, 181, 192);
  cursor: not-allowed;
  .override-latch-on {
    background: linear-gradient(315deg, rgba(2,0,36,1) 0%, rgb(144, 161, 185) 42%, rgb(203, 244, 252) 100%);

  }
}

.field-entry-row-submit {
  margin-top: 34px;
}

.rs-picker-menu {
  z-index: 99 !important;
}

.rs-picker-menu.rs-picker-check-menu, .rs-picker-menu.rs-picker-select-menu, .rs-picker-date-menu.rs-picker-menu {
  // make rsuite pickers work in modals
  z-index: 101 !important;
}

// fix for dropcard hover inheriting button hover
.active.drop-card.title-button:hover{
  border-color: var(--dropBorderColor);
  background-color: var(--dropBackgroundColor);
}

// custom DatePicker disabled styling
.rs-calendar-table-cell.rs-calendar-table-cell-disabled, .rs-calendar-table-cell.rs-calendar-table-cell-disabled:hover {
  background-color: #eee !important;
  border-radius: 3px !important;
}

.date-picker-container {
  .rs-picker-toggle.rs-btn.rs-btn-default {
    height: 100%;
    padding-top: 9px;
  }
}
.input-style {
  .rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-caret, .rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
    top: 9px;
  }
  .rs-picker-toggle {
    height: 100% !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
}

.button.is-success.is-primary {
  background-color: hsl(171, 100%, 41%) !important;
}

.editable-cells {
  .control {
    margin-top: 0 !important;
  }
}

.text-match-selector {
  border: 1px solid rgb(215, 215, 215);
  border-radius: 3px;
  background: #fff;
  overflow: auto;
  max-height: 300px;
  li {
    padding: 0.5rem;
  }
  li:hover {
    background-color: #7ab9eb;
    color: #efeeee;
    cursor: pointer;
  }
}
.text-match-highlighted {
  background-color: #3e8ed0;
  color: #fff;
  cursor: pointer;
}

.row-align-middle td {
  vertical-align: middle;
  height: 3em;
  border-bottom: 1px solid #d2d2d2;
}

#criteria-picker {
  .rs-btn {
    height: 40px;
  }
}

.table-header-input-container {
  .rs-picker-select {
    max-height: 23px;
    padding-bottom: 21px;
    margin-top: -10px;
  }
}

.custom-rs-styles.rs-picker-default .rs-picker-toggle.rs-btn, .custom-rs-styles.rs-picker-default.rs-picker-date .rs-input {
  height: var(--selectPickerHeight) !important;
}