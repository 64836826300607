#okta-login-container {
  max-width: 600px;
  min-width: 335px;
  width: 56vw;
  margin: 50px auto;
  border-radius: 5px;
  cursor: pointer;
}
#okta-login-container .is-flex{
  height:100%;
}
#okta-o {
  border: 5px solid white;
  width: 30px;
  height: 30px;
  border-radius: 20px;
  display: inline-block;
  margin-right: 20px;
}
#okta-button{
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: none;
  height: 50px;
  background-color: #1f376b;
  color: white;
  text-transform: uppercase;
  font-weight: bold !important;
  font-size: 1.25rem;
  border-radius: 5px;
}